import http from '../http-common'
import authHeader from './AuthHeaderService'
const API_URL_PATH = 'api/token_requests/'

class TokenRequestsDataService {

    getRequests(org_id, role){
      return http.get(API_URL_PATH + 'find-all?organization_id=' + org_id + '&role=' + role, {headers: authHeader()})
    }

    create (data) {
      return http.post(API_URL_PATH + 'create', data ,  {headers: authHeader()})
    } 

    setRequestStatus(accept, id){
      let data = {
        id: id,
        accepted: accept
      }
      return http.put(API_URL_PATH + 'update', data ,  {headers: authHeader()})
    }
    
}
export default new TokenRequestsDataService ()
