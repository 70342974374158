<template>
  <div
    class="page-header header-filter"
    filter-color="green"
  >
  <div v-if="loading" id="toHidden"
                            style="
                                position: absolute;
                                top     :0;
                                left    : 0;
                                height  : 100%;
                                width   :  100%;
                                background-color:azure;
                                z-index : 1000;
                                opacity : 0.6;"
                            >
        </div>
        <b-icon  v-if="loading" style="
                                        z-index: 2000;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        "
                                icon="three-dots"
                                animation="cylon"
                                font-scale="4">
        </b-icon>
    <div
      class="page-header-image"
      :style="{'background-image': 'url(' + $getImgUrlSite('bg51.jpg') +')', opacity: '70%'}" 
    />
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto mt-auto">
          <card
            type="login"
            plain
          >
            <div
              slot="header"
              class="logo-container mt-5"
            >
              <img
                v-lazy="$getImgUrlSite('logoNCM.png')"
                alt="logo"
              >
            </div>
            <div class="row">
              <div class="col">
                <b-form-group class="formLoginLabel" :label=" $t('DashBoardStaff.Modals.NewStaff.Password')">
                
                  <div class="d-flex">
                    <b-form-input
                    v-model="resetPassData.password"
                    id="input-resetPassData-password"
                    name="input-resetPassData-password"
                    v-validate="{required: true}"
                    type="password"
                    aria-describedby="input-resetPassData-password-live-feedback"
                    :placeholder="$t('DashBoardStaff.Modals.NewStaff.Password')"
                    class="no-border input-lg formLogin"
                    :state="validateState('input-resetPassData-password')"
                  >
                  </b-form-input>
                </div>
                </b-form-group>
              </div>
              <div class="col-2 d-flex show-pass-col" style="margin-top: 2% !important">
                <span v-if="!variablePass" class="iconPass m-auto" @click="showPass()"><b-icon icon="eye" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
                <span v-if="variablePass" class="iconPass m-auto" @click="showPass()"><b-icon icon="eye-slash" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group class="formLoginLabel" :label=" $t('LoginStaff.ResetPass.RepeatPass')" >
                <div class="d-flex">
                    <b-form-input
                    v-model="resetPassData.rPass"
                    id="input-resetPassData-rPass"
                    name="input-resetPassData-rPass"
                    v-validate="{required: true}"
                    type="password"
                    aria-describedby="input-resetPassData-password-live-feedback"
                    :placeholder="$t('LoginStaff.ResetPass.RepeatPass')"
                    class="no-border input-lg formLogin"
                    :state="validateState('input-resetPassData-rPass')"
                  >
                  </b-form-input>
                </div>
                </b-form-group>
              </div>
              <div class="col-2 d-flex show-pass-col" style="margin-top: 2% !important">
                <span v-if="!variablePass1" class="iconPass m-auto" @click="showPass1()"><b-icon icon="eye" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
                <span v-if="variablePass1" class="iconPass m-auto" @click="showPass1()"><b-icon icon="eye-slash" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
              </div>
            </div>
            <template 
              slot="raw-content"
            >
              <div class="card-footer text-center">
                <button
                  class="btn btn-primary btn-round btn-lg btn-block"
                  @click.stop.prevent="reset()"   
                >
                  {{ $t("LoginStaff.ResetPass.Change") }}
                </button>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
    <main-footer />
  </div>
</template>
<script>
import { Card, Button, FormGroupInput } from '@/components';
import MainFooter from '@/layout/MainFooter';
import Login from '../../services/Login';
export default {
    name: 'ResetPassStaff',
    bodyClass: 'reset-pass-staff',
    components: {
        Card,
        MainFooter,
        [Button.name]: Button,
        [FormGroupInput.name]: FormGroupInput
    },
    data() {
        return {
            loading: false,
            errors: [],
            variablePass : false,
            variablePass1 : false,
            resetPassData: {
                password: '',
                rPass: ''
            }
        }
    },
    methods: {
        goLoginStaff () {
          this.$router.push({ path: '/staff' })
        },
        reset () {
          //alert(this.resetPassData.password + '  ' + this.resetPassData.rPass)
          if (this.resetPassData.password != this.resetPassData.rPass) {
            this.$swal.fire({
                  icon: 'warning',
                  title: 'Oops...',
                  text: this.$t('Alerts.PasswordDoNotMatch'),
            }).then(() => { 
                this.$validator.reset(['input-resetPassData-password','input-resetPassData-rPass']) 
            })
          }
          else {
            this.loading = true;
            Login.resetPass(this.resetPassData.password,this.$route.query.token).then(
              Response => {
              if (Response.status === 200) {
                this.$swal.fire({
                icon: 'success',
                title: this.$t('Alerts.PasswordChanged'),
                }).then(() => {
                  this.$router.push({path:'/staff'})
              });
              }
              this.loading = false;
            },
            Error => {  
              if (Error.response.status === 404) {
                this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.$t('Errors.NotFound')
                })
              }
              if (Error.response.status === 400) {
                this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.$t('Errors.BadRequest')
                })
              }
              if (Error.response.status === 500) {
                this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.$t('Errors.InternalServerError')
                })
              }
              this.loading = false;
            }
            )
          }
        },
        validateState (ref) {
          if (
            this.veeFields[ref] &&
            (this.veeFields[ref].dirty || this.veeFields[ref].validated)
          ) {
            return !this.veeErrors.has(ref)
          }
          return null
        },
        showPass () {
          if (!this.variablePass) this.variablePass = true
          else this.variablePass = false
          var tipo = document.getElementById("input-resetPassData-password");
          if (tipo.type == "password"){
              tipo.type = "text";
          } else {
              tipo.type = "password";
          }
        },
        showPass1 () {
          if (!this.variablePass1) this.variablePass1 = true
          else this.variablePass1 = false
          var tipo = document.getElementById("input-resetPassData-rPass");
          if (tipo.type == "password"){
              tipo.type = "text";
          } else {
              tipo.type = "password";
          }
        }
    }
}
</script>
<style>
</style>
    