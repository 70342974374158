import http from "../http-common";
const API_URL_PATH = "api/login/";
import authHeader from './AuthHeaderService'

class AuthDataService {
    checkSession () {
        return http.get("api/auth/check", { headers: authHeader() });
    }
    loginStaff (user) {
        const body = {mail: user.mail, psw: user.psw}
        return http.post(API_URL_PATH + "staff-login", body);
    }
    logout () {
        localStorage.removeItem("user");
    }
    activate (token) {
        return http.put(API_URL_PATH + "activate-account",{token: token});
    }
    resetPass (password, token) {
        const body = {psw: password}
        return http.put(API_URL_PATH + "reset-password?token=" + token, body);
    }
    passwordRecovery (email) {
        const body = {mail: email}
        return http.post(API_URL_PATH + "password-recovery", body);
    }
    changePass(data) {
        const body =  {
            current_password: data.currentPassword,
            password: data.newPassword
        }
        return http.put(API_URL_PATH + 'change-password',body, { headers: authHeader() })
    }
    sendEmail (data) {
        const body = {
            email : data.email,
            msg : data.text,
            name: data.name,
            subject: data.subject
        }
        return http.post(API_URL_PATH + 'contact-us',body, { headers: authHeader() })
    }
    
}
export default new AuthDataService();