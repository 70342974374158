<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar">
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                      {{ $t("EcosystemServicesTypes.Title") }}
                    </h2> 
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <card>
                        <b-row>
                            <b-col>
                                <h6 class="titleTypes">{{ $t("EcosystemServicesTypes.Title2") }}</h6>
                            </b-col>
                            <b-col>
                                <b-button-toolbar class="buttonTypes" key-nav aria-label="Toolbar with button groups" style="float: right">
                                    <b-button-group class="mx-1">
                                        <n-button class="btn-simple ncm-btn" target="_blank" @click.native="modals.create = true">
                                            <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                                            {{ $t("EcosystemServicesTypes.Button") }}
                                        </n-button>
                                    </b-button-group>
                                </b-button-toolbar>
                            </b-col>
                        </b-row>
                        <b-row> 
                            <b-col>
                                <b-table ref="typesTable"
                                    id="typesTable"
                                    :fields="headers" 
                                    :items="types"
                                    show-empty
                                    select-mode="single"
                                    style="overflow-x: auto;"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    sort-by="createdAt"
                                    :sort-desc="true"
                                    responsive="sm"> 
                                    <template #empty="">
                                        {{$t('EcosystemServicesTypes.Empty')}}
                                    </template> 
                                  <template #cell(createdAt)="data">
                                    {{ data.item.createdAt | formatDate}}
                                  </template>
                                  <template #cell(updatedAt)="data">
                                    {{ data.item.updatedAt | formatDate}}
                                  </template>
                                  <template #cell(options)="data">
                                    <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Modify')" @click="openEditModal(data.item)">
                                        <b-icon icon="pen"></b-icon>
                                    </b-button>
                                    <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('DashBoardStaff.Tooltips.Delete')" @click="openDeleteModal(data.item)">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                  </template>
                            </b-table>
                            <div class="pagination-container">
                                <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                                </b-pagination>
                                <b-form-select v-model="perPage" class="pagination-select">
                                    <b-form-select-option :value="2">2</b-form-select-option>
                                    <b-form-select-option :value="4">4</b-form-select-option>
                                    <b-form-select-option :value="6">6</b-form-select-option>
                                    <b-form-select-option
                                        :value="types.length">{{ $t('DashBoardStaff.Options') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                          </b-col>
                        </b-row>
                    </card>
                </b-col>
            </b-row>
        </b-container>
        <modal v-show="modals.create" :show.sync="modals.create" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t("EcosystemServicesTypes.Button") }}
        </h4>
        <b-form-group  :label=" $t('EcosystemServicesTypes.Labels.Name')">
          <b-form-input
              v-model="dataType.name"
              id="input-dataType-name"
              name="input-dataType-name"
              v-validate="{ required: true}"
              :state="validateState('input-dataType-name')"
              aria-describedby="input-dataType-name-live-feedback"
              size="sm"
              :placeholder="$t('EcosystemServicesTypes.Labels.Name')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-dataType-name-live-feedback">{{ veeErrors.first('input-dataType-name') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label=" $t('EcosystemServicesTypes.Labels.Description')">
          <b-form-input
              v-model="dataType.description"
              id="input-dataType-description"
              name="input-dataType-description"
              v-validate="{ required: true}"
              :state="validateState('input-dataType-description')"
              aria-describedby="input-dataType-description-live-feedback"
              size="sm"
              :placeholder="$t('EcosystemServicesTypes.Labels.Description')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-dataType-description-live-feedback">{{ veeErrors.first('input-dataType-description') }}</b-form-invalid-feedback>
        </b-form-group>
        <template slot="footer">
          <n-button class="btn-simple ncm-btn" @click="addType">
            {{ $t("Parameters.Modals.Create.Footer.Save") }}
          </n-button>
          <n-button class="btn-simple ncm-btn-danger" @click.native="closeCreate">
             {{ $t("Parameters.Modals.Create.Footer.Close") }}
          </n-button>
        </template>
      </modal>
      <modal v-show="modals.modify" :show.sync="modals.modify" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t("EcosystemServicesTypes.Modify") }}
        </h4>
        <b-form-group :label=" $t('EcosystemServicesTypes.Labels.Name')">
          <b-form-input
              v-model="selectedElement.name"
              id="input-dataType-name-modify"
              name="input-dataType-name-modify"
              v-validate="{ required: true}"
              :state="validateState('input-dataType-name-modify')"
              aria-describedby="input-dataType-name-modify-live-feedback"
              size="sm"
              :placeholder="$t('EcosystemServicesTypes.Labels.Name')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-dataType-name-modify-live-feedback">{{ veeErrors.first('input-dataType-name-modify') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label=" $t('EcosystemServicesTypes.Labels.Description')">
          <b-form-input
              v-model="selectedElement.description"
              id="input-dataType-description-modify"
              name="input-dataType-description-modify"
              v-validate="{ required: true}"
              :state="validateState('input-dataType-description-modify')"
              aria-describedby="input-dataType-description-modify-live-feedback"
              size="sm"
              :placeholder="$t('EcosystemServicesTypes.Labels.Description')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-dataType-description-modify-live-feedback">{{ veeErrors.first('input-dataType-description-modify') }}</b-form-invalid-feedback>
        </b-form-group>
        <template slot="footer">
          <n-button class="btn-simple ncm-btn" @click="modifyType">
           {{ $t("Parameters.Modals.Create.Footer.Save") }}
          </n-button>
          <n-button class="btn-simple ncm-btn-danger" @click.native="modals.modify = false">
           {{ $t("Parameters.Modals.Create.Footer.Close") }}
          </n-button>
        </template>
      </modal>
      <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
        <p>{{ $t("EcosystemServicesTypes.Delete") }}</p>
        <template slot="footer">
          <n-button type="neutral" link @click="deleteType" >
            {{ $t("Parameters.Modals.Delete.Footer.Save") }}
          </n-button>
          <n-button type="neutral" link @click.native="modals.delete = false">
            {{ $t("Parameters.Modals.Delete.Footer.Close") }}
          </n-button>
        </template>
      </modal>
    </div>
</template>

<script>
import {  Button, FormGroupInput, Card, Modal} from '@/components';
import {Table, TableColumn, Tooltip} from 'element-ui';
import EcosystemsServicesTypes from '@/services/EcosystemServicesTypesDataService'
export default {
  name: 'Ecosystems-Services-Types',
  components: {
      Modal,
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
  },
  data() {
      return {
          modals: {
            modify: false,
            delete: false,
            create: false,
          },
          dataType: {},
          selectedElement: {},
          types: [],
          headers: [
            { key: 'name', sortable: true, label: this.$t('EcosystemServicesTypes.Table.Labels.Name'), tdClass: 'table-title', thClass: 'table-title position-sticky' }, 
            { key: 'description',label: this.$t('EcosystemServicesTypes.Table.Labels.Description'), thClass: 'position-sticky'}, 
            { key: 'createdAt', label: this.$t('EcosystemServicesTypes.Table.Labels.CreatedAt'), thClass: 'position-sticky', sortable: true },
            { key: 'updatedAt',label: this.$t('EcosystemServicesTypes.Table.Labels.UpdatedAt'), thClass: 'position-sticky', sortable: true },
            { key: 'options', label:this.$t('EcosystemServicesTypes.Table.Labels.Actions'), thClass: 'position-sticky' }
          ],
          currentPage: 1,
          perPage: 4     
      }
  },
  created() {
    this.getData()
  },
  computed: {
    totalPages(){
      return this.types.length
    },
  },
  methods:{
    validateState (ref) {
        if (this.veeFields[ref] &&(this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.veeErrors.has(ref)
        }
        return null
    },
    getData(){
      EcosystemsServicesTypes.findAll().then(
        Response => {
          this.types = Response.data.data
        },
        Error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: Error.response.data.message
          })        
        }
      )
    },
    openEditModal (element) {
      this.selectedElement = {...element}
      this.modals.modify = true
    },
    openDeleteModal(element){
      this.selectedElement = {...element}
      this.modals.delete = true
    },
    closeCreate() {
      this.modals.create = false
      this.$validator.reset()
      this.dataType = {}
    },
    addType() {
        this.$validator.validateAll(['input-dataType-name', 'input-dataType-description']).then(async result => {
          if (!result) {
            return
          }
          EcosystemsServicesTypes.create(this.dataType).then(
            Response => {
              if (Response.status === 200) {
                this.$swal.fire({
                  icon: 'success',
                  title: this.$t('EcosystemServicesTypes.Alerts.Create.OK')
                  }).then(() => {
                    this.modals.create = false
                    this.getData()
                    this.dataType = {}
                    this.$validator.reset()
                  });
              }
            },
            Error => {
              if (Error.response.status === 404) {
                this.$swal.fire({
                icon: 'error',
                title: this.$t('EcosystemServicesTypes.Alerts.Create.Error')
                })
              }
              if (Error.response.status === 400) {
                this.$swal.fire({
                icon: 'error',
                title: this.$t('EcosystemServicesTypes.Alerts.Create.BadRequest')
                })
              }
              if (Error.response.status === 500) {
                this.$swal.fire({
                icon: 'error',
                title: this.$t('Errors.InternalServerError')
                })
              }
            })
        })
    },
    modifyType (){
      this.$validator.validateAll(['input-dataType-name-modify', 'input-dataType-description-modify']).then(async result => {
        if (!result) {
            return
        }
        EcosystemsServicesTypes.update(this.selectedElement).then(
          Response => {
            if (Response.status === 200) {
              this.$swal.fire({
                icon: 'success',
                title: this.$t('EcosystemServicesTypes.Alerts.Modify.OK')
                }).then(() => {
                  this.modals.modify = false
                  this.getData() 
                });
            }
          },
          Error => {
            if (Error.response.status === 404) {
              this.$swal.fire({
              icon: 'error',
              title: this.$t('EcosystemServicesTypes.Alerts.Modify.Error')
              })
            }
            if (Error.response.status === 400) {
              this.$swal.fire({
              icon: 'error',
              title: this.$t('EcosystemServicesTypes.Alerts.Modify.BadRequest')
              })
            }
            if (Error.response.status === 500) {
              this.$swal.fire({
              icon: 'error',
              title: this.$t('Errors.InternalServerError')
              })
            }
          }
        )
      })
      
    },
    deleteType() {
      EcosystemsServicesTypes.delete(this.selectedElement.id).then(
        Response => {
          if (Response.status === 200) {
            this.$swal.fire({
              icon: 'success',
              title: this.$t('EcosystemServicesTypes.Alerts.Delete.OK')
              }).then(() => {
                this.modals.delete = false
                this.getData() 
              });
          }
        },
        Error => {
          if (Error.response.status === 404) {
            this.$swal.fire({
            icon: 'error',
            title: this.$t('EcosystemServicesTypes.Alerts.Delete.Error')
            })
          }
          if (Error.response.status === 400) {
            this.$swal.fire({
            icon: 'error',
            title: this.$t('EcosystemServicesTypes.Alerts.Delete.BadRequest')
            })
          }
          if (Error.response.status === 500) {
            this.$swal.fire({
            icon: 'error',
            title: this.$t('Errors.InternalServerError')
            })
          }
      })
    },
  }
}
</script>

<style scoped>
@media (max-width: 465px) {
  .titleTypes {
    text-align: center;
  }

  .buttonTypes{
    float: center;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
</style>