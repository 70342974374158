<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar">
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col cols="4" style="display: flex">
                    <h2 class="card-title">
                      {{ $t("EcosystemCategories.Title") }}
                    </h2> 
                </b-col>
                <b-col cols="3" style="display: flex">
                  <drop-down class="dropdownCat">
                    <n-button style="margin-top: 2%"
                        slot="title"
                        class="dropdown-toggle"
                        type="primary"
                        data-toggle="dropdown"
                    >
                        <template v-if="type_filter == undefined">
                          {{$t("Utils.DropDownType")}}
                        </template>
                        <template v-else>
                          {{ $t("EcosystemCategories.Filter") }}: <b>{{type_filter.name}}</b>
                        </template>
                    </n-button>
                    <a v-for="(type, index) of types"
                        class="dropdown-item"
                        :key="index"
                        :class="{ 'active': type.name === type_filter.name }"
                        @click="filterCategory(type)"
                        style="cursor: pointer"
                    >{{type.name}}</a> 
                  </drop-down>
              </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <card>
                        <b-row>
                            <b-col>
                                <h6 class="titleCat">{{ $t("EcosystemCategories.List") }}</h6>
                            </b-col>
                            <b-col>
                                <b-button-toolbar class="buttonCat" key-nav aria-label="Toolbar with button groups" style="float: right">
                                    <b-button-group class="mx-1">
                                        <n-button class="btn-simple ncm-btn" target="_blank" @click.native="openCreateModal()">
                                          <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                                          {{ $t("EcosystemCategories.Create") }}
                                        </n-button>
                                    </b-button-group>
                                </b-button-toolbar>
                            </b-col>
                        </b-row>
                        <b-row> 
                            <b-col>
                                <b-table ref="categoriesTable"
                                    id="categoriesTable"
                                    :fields="headers" 
                                    :items="categories_to_show"
                                    show-empty
                                    select-mode="single"
                                    style="overflow-x: auto;"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    sort-by="created_at"
                                    :sort-desc="true"
                                    responsive="sm"> 
                                    <template #empty="">
                                    {{$t('EcosystemCategories.Empty')}}
                                </template>
                                      <template #cell(created_at)="data">
                                        {{ data.item.created_at | formatDate}}
                                      </template>
                                      <template #cell(last_edited)="data">
                                        {{ data.item.last_edited | formatDate}}
                                      </template>
                                      
                                    <template #cell(options)="data">
                                      <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Modify')" @click="openEditModal(data.item)">
                                        <b-icon icon="pen"></b-icon>
                                      </b-button>
                                      <b-button class="action-table" v-b-tooltip.hover.viewport title="Delete" @click="openDeleteModal(data.item)">
                                        <b-icon icon="trash"></b-icon>
                                      </b-button>
                                    </template>
                              </b-table>
                              <div class="pagination-container">
                                  <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                                  </b-pagination>
                                  <b-form-select v-model="perPage" class="pagination-select">
                                      <b-form-select-option :value="2">2</b-form-select-option>
                                      <b-form-select-option :value="4">4</b-form-select-option>
                                      <b-form-select-option :value="6">6</b-form-select-option>
                                      <b-form-select-option
                                          :value="categories.length">{{ $t('DashBoardStaff.Options') }}</b-form-select-option>
                                  </b-form-select>
                              </div>
                            </b-col>
                        </b-row>
                    </card>
                </b-col>
            </b-row>
        </b-container>
        <modal v-show="modals.create" :show.sync="modals.create" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t("EcosystemCategories.Create.Title") }}
        </h4>
        <b-form-group :label="$t('EcosystemCategories.Modals.Create.Name')">
          <b-form-input
              v-model="dataCategory.name"
              id="input-dataCategory-name"
              name="input-dataCategory-name"
              v-validate="{required: true}"
              :state="validateState('input-dataCategory-name')"
              aria-describedby="input-dataCategory-name-live-feedback"
              size="sm"
              :placeholder="$t('EcosystemCategories.Modals.Create.Name')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-dataCategory-name-live-feedback">{{ veeErrors.first('input-dataCategory-name') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('EcosystemCategories.Modals.Create.Description')">
          <b-form-input
              v-model="dataCategory.description"
              id="input-dataCategory-description"
              name="input-dataCategory-description"
              v-validate="{ required: true}"
              :state="validateState('input-dataCategory-description')"
              aria-describedby="input-dataCategory-description-live-feedback"
              size="sm"
              :placeholder="$t('EcosystemCategories.Modals.Create.Description')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-dataCategory-description-live-feedback">{{ veeErrors.first('input-dataCategory-description') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('EcosystemCategories.Modals.Create.Type')">
            <b-form-select 
              v-model="type_form" 
              id="input-type_form"
              name="input-type_form"
              v-validate="{required: true}"
              :state="validateState('input-type_form')"
              aria-describedby="input-type_form-live-feedback"
              :options="types"
              value-field="id"
              text-field="name">
              <b-form-select-option :value="null">{{ $t("EcosystemCategories.Create.Title") }}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback id="input-type_form-live-feedback">{{ veeErrors.first('input-type_form') }}</b-form-invalid-feedback>
        </b-form-group>
        <template slot="footer">
          <n-button class="btn-simple ncm-btn" @click="addCategory">
              {{ $t("Parameters.Modals.Create.Footer.Save") }}
          </n-button>
          <n-button class="btn-simple ncm-btn-danger" @click.native="closeCreate">
              {{ $t("Parameters.Modals.Create.Footer.Close") }}
          </n-button>
        </template>
      </modal>
      <modal v-show="modals.modify" :show.sync="modals.modify" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t("EcosystemCategories.Modify.Title") }}
        </h4>
        <b-form-group :label="$t('EcosystemCategories.Modals.Create.Name')">
          <b-form-input
              v-model="selectedElement.name"
              id="input-dataCategory-name-modify"
              name="input-dataCategory-name-modify"
              v-validate="{required: true}"
              :state="validateState('input-dataCategory-name-modify')"
              aria-describedby="input-dataCategory-name-modify-live-feedback"
              size="sm"
              :placeholder="$t('EcosystemCategories.Modals.Create.Name')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-dataCategory-name-modify-live-feedback">{{ veeErrors.first('input-dataCategory-name-modify') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('EcosystemCategories.Modals.Create.Description')">
          <b-form-input
              v-model="selectedElement.description"
              id="input-dataCategory-description-modify"
              name="input-dataCategory-description-modify"
              v-validate="{required: true}"
              :state="validateState('input-dataCategory-description-modify')"
              aria-describedby="input-dataCategory-description-modify-live-feedback"
              size="sm"
              :placeholder="$t('EcosystemCategories.Modals.Create.Description')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-dataCategory-description-modify-live-feedback">{{ veeErrors.first('input-dataCategory-description-modify') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('EcosystemCategories.Modals.Create.Type')">
              <b-form-select 
                v-model="selectedElement.category_type_id" 
                id="input-type_form-modify"
                name="input-type_form-modify"
                v-validate="{required: true}"
                :state="validateState('input-type_form-modify')"
                aria-describedby="input-type_form-modify-live-feedback"
                :options="types"
                value-field="id"
                text-field="name">
                <b-form-select-option :value="null">{{ $t("EcosystemCategories.Create.Title") }}</b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback id="input-type_form-modify-live-feedback">{{ veeErrors.first('input-type_form-modify') }}</b-form-invalid-feedback>
        </b-form-group>
        <template slot="footer">
          <n-button class="btn-simple ncm-btn" @click="modifyCategory">
            {{ $t("Parameters.Modals.Create.Footer.Save") }}
          </n-button>
          <n-button class="btn-simple ncm-btn-danger" @click.native="modals.modify = false">
            {{ $t("Parameters.Modals.Create.Footer.Close") }}
          </n-button>
        </template>
      </modal>
      <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
        <p>{{ $t("EcosystemCategories.SureDele") }}</p>
        <template slot="footer">
          <n-button type="neutral" link @click="deleteType" >
            {{ $t("Parameters.Modals.Delete.Footer.Save") }}
          </n-button>
          <n-button type="neutral" link @click.native="modals.delete = false">
            {{ $t("Parameters.Modals.Delete.Footer.Close") }}
          </n-button>
        </template>
      </modal>
    </div>
</template>

<script>
import {  Button, FormGroupInput, Card, Modal, DropDown} from '@/components';
import {Table, TableColumn, Tooltip} from 'element-ui';
import EcosystemCategoryDataService from '../../services/EcosystemCategoryDataService';
import EcosystemTypes from '@/services/EcosystemTypesDataService'
import StaffServices from '../../services/StaffServices';

export default {
  name: 'Ecosystems-Services-Categories',
  components: {
    Modal,
    Card,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [FormGroupInput.name]: FormGroupInput,
    [DropDown.name]: DropDown,
  },
  data () {
    return {
      modals: {
        modify: false,
        delete: false,
        create: false,
      },
      type_form: null,
      dataCategory: {},
      selectedElement: {},
      types: [],
      categories: [],
      categories_to_show: [],
      headers: [
        { key: 'name', sortable: true, label: this.$t('Ecosystems.Table.Category'), tdClass: 'table-title', thClass: 'table-title position-sticky' }, 
        { key: 'description', label: this.$t('Ecosystems.Table.Description'), thClass: 'position-sticky'}, 
        { key: 'category_type_name', label: this.$t('Ecosystems.Table.Type'), thClass: 'position-sticky'}, 
        { key: 'created_at', label: this.$t('Ecosystems.Table.CreatedAt'), thClass: 'position-sticky', sortable: true},
        { key: 'last_edited', label: this.$t('Ecosystems.Table.UpdatedAt'), thClass: 'position-sticky', sortable: true },
        { key: 'options', label: this.$t('DashBoardStaff.Table.Actions'), thClass: 'position-sticky' }
      ],
      currentPage: 1,
      perPage: 4,
      type_filter: null,     
    }
  },
  created() {
    this.getData()
  },
  computed: {
    totalPages(){
      return this.categories_to_show.length
    },
  },
  methods:{
    openCreateModal(){
      this.dataCategory.name = ''
      this.dataCategory.description = ''
      this.$validator.reset(['input-name', 'input-description','input-type_form',]);
      this.modals.create = true
    },
    filterCategory(type) {
      if (type != null){
        this.categories_to_show = this.categories.filter(f => f.category_type_id == type.id)
      }
      else
        this.categories_to_show = this.categories
        this.type_filter = type
        this.type_form = this.type_filter.id
    },
    validateState (ref) {
      if (this.veeFields[ref] &&(this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    async getData(){
      let data = JSON.parse(localStorage.getItem('user'))
      this.user_id = data.data.id 
      await StaffServices.findOne(this.user_id).then(
        Response => {
          this.user = Response.data.data
        }
      )
      await EcosystemCategoryDataService.findAll().then(
        Response => {
          this.categories = Response.data.data
          this.categories_to_show = this.categories
        },
        Error => {
          console.log('Error findAll categories')
        }
      )
      await EcosystemTypes.findAll().then(
        Response => {
          this.types = Response.data.data
          this.type_filter = this.types.filter(f => f.id == this.user.favourite_ecosystem_category_type)[0]
          this.type_form = this.type_filter.id
          this.filterCategory(this.type_filter)
        },
        Error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: Error.response.data.message
          })        
        }
      )
    },
    openEditModal (element) {
      this.selectedElement = {...element}
      this.modals.modify = true
      console.log(element)
    },
    openDeleteModal(element){
      this.selectedElement = {...element}
      this.modals.delete = true
    },
    closeCreate() {
      this.modals.create = false
      this.$validator.reset()
      this.dataCategory = {}
    },
    addCategory() {
        this.$validator.validateAll(['input-dataCategory-name', 'input-dataCategory-description','input-type_form']).then(async result => {
          if (!result) {
            return
          }
          EcosystemCategoryDataService.create(this.dataCategory,this.type_form).then(
            Response => {
              if (Response.status === 200) {
                this.$swal.fire({
                    icon: 'success',
                    title: this.$t('Ecosystems.Modals.Create.NewCategory'),
                  }).then(() => {
                    this.modals.create = false
                    this.getData()
                    this.dataCategory = {}
                    this.type_form = null
                    this.$validator.reset()
                  })
              }
            },
            Error => {
              if (Error.response.status === 404) {
                this.$swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: this.$t('Errors.NotFound')
                })
              }
              if (Error.response.status === 400) {
                this.$swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text:  this.$t('Errors.BadRequest')
                })
              }
              if (Error.response.status === 500) {
                this.$swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: this.$t('Errors.InternalServerError')
                })
              }
            })
        })
    },
    modifyCategory() {
        this.$validator.validateAll(['input-dataCategory-name-modify', 'input-dataCategory-description-modify','input-type_form-modify']).then(async result => {
            if (!result) {
              return
            }
            EcosystemCategoryDataService.update(this.selectedElement).then(
              Response => {
              if (Response.status === 200) {
                this.$swal.fire({
                  icon: 'success',
                  title: this.$t('Ecosystems.Modals.Create.ModifyCategory'),
                }).then(() => {
                    this.modals.modify = false
                    this.getData() 
                });
              }
              },
              Error => {
                if (Error.response.status === 404) {
                  this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: this.$t('Errors.NotFound')
                  })
                }
                if (Error.response.status === 400) {
                    this.$swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: this.$t('Errors.BadRequest')
                    })
                }
                if (Error.response.status === 500) {
                    this.$swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: this.$t('Errors.InternalServerError')
                    })
                }
              }
            )
        })
    },
    deleteType() {
      EcosystemCategoryDataService.delete(this.selectedElement.id).then(
        Response => {
          if (Response.status === 200) {
            this.$swal.fire({
                icon: 'success',
                title: this.$t('Ecosystems.Modals.Create.DeleteCategory'),
              }).then(() => {
                this.modals.delete = false
                this.getData() 
              })
          }
        },
        Error => {
          if (Error.response.status === 404) {
            this.$swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: this.$t('Errors.NotFound')
            })
          }
          if (Error.response.status === 400) {
            this.$swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: this.$t('Errors.BadRequest')
            })
          }
          if (Error.response.status === 500) {
            this.$swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: this.$t('Errors.InternalServerError')
            })
          }
        }
      )
    },
  }
}
</script>

<style scoped>
.dropup, .dropright, .dropdown, .dropleft {
    position: relative;
    margin-bottom: auto;
}

@media (max-width: 465px) {
  .titleCat {
    text-align: center;
  }

  .buttonCat{
    float: center;
  }
}

@media (max-width: 862px) {
  .dropdownCat {
    margin-top: 130px;
    margin-left: -50px;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
</style>