<template>
    <div class="wrapper-home  collapsed-sidebar" style="margin-top: 5%; max-width: 99%;">
        <div v-if="loading" id="toHidden"
                            style="
                                position: absolute;
                                top     :0;
                                left    : 0;
                                height  : 100%;
                                width   :  100%;
                                background-color:azure;
                                z-index : 1000;
                                opacity : 0.6;"
                            >
        </div>
        <b-icon  v-if="loading" style="
        z-index: 2000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        "
        icon="three-dots"
        animation="cylon"
        font-scale="4">
        </b-icon>
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                <h2 class="card-title">
                    {{$t('UsersManagement.Title')}}
                </h2> 
                </b-col>
            </b-row>
            <b-row>
            <b-col>
                <card class="card-h-100">
                    <b-row>
                        <b-col cols="4" style="float: left; display: flex;">
                            <span class="mt-auto mb-auto"><h6 class=" mb-0">{{$t('UsersManagement.Members')}}</h6></span>
                        </b-col>
                        <b-col>
                            <!-- <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right;">
                                <b-button-group class="mx-1">
                                    <n-button
                                        id="newAdmin"
                                        class="btn-simple ncm-btn"
                                        style="font-weight: 800 !important;"
                                        target="_blank"
                                        @click.native="modals.invite = true"
                                    >
                                    <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                                    {{$t('UsersManagement.Invite')}}
                                    </n-button>
                                </b-button-group>
                            </b-button-toolbar> -->
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-table
                            style=" max-height: 550px; overflow-x: auto;"
                            ref="projectTable"
                            id="projectTable"
                            :fields="headersUsers" 
                            :items="project_analyst"
                            responsive="sm"
                            show-empty
                            :current-page="currentPage"
                            :per-page="perPage"
                            > 
                            <template #empty="">
                                {{$t('UsersManagement.Empty')}}
                            </template>
                            <template #cell(phone)="data">
                                <b-button v-if="data.item.phone != null && data.item.phone != undefined && data.item.phone != ''" class="action-table" v-b-tooltip.hover.viewport@click="copyToClipboard(data.item.phone)"  :title=data.item.phone>
                                    <b-icon icon="telephone"></b-icon>
                                </b-button>
                                <b-button v-else class="action-table">
                                    <p style=" margin: 0">N/A</p>
                                </b-button>
                            </template>
                            <template #cell(email)="data">
                                <b-button v-if="data.item.email != null" class="action-table" v-b-tooltip.hover.viewport :title=data.item.email @click="copyToClipboard(data.item.email)">
                                    <b-icon icon="mailbox"></b-icon>
                                </b-button>
                                <b-button v-else class="action-table">
                                    <p style=" margin: 0">N/A</p>
                                </b-button>
                            </template>
                            <template #cell(roles)="data">
                                <select class="form-select" aria-label="Default select example" style="min-width: 150px !important">
                                    <option selected disabled>Roles</option>
                                    <template v-for="(role, i) in data.item.roles">
                                        <option v-if="role != null" :key="i" disabled>{{ role }}</option>
                                    </template>
                                </select>
                            </template>
                            <template #cell(is_active)="data">
                                <template v-if="!data.item.project_analyst.is_active">
                                <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('DashBoardStaff.Tooltips.EnableUser')" @click="changeStatus(data.item, true)">
                                    <b-icon icon="toggle-off"></b-icon>
                                </b-button>
                                </template>  
                                <template v-else>
                                <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('DashBoardStaff.Tooltips.DisableUser')" @click="changeStatus(data.item, false)">
                                    <b-icon icon="toggle-on"></b-icon>
                                </b-button>
                                </template>
                            </template>
                            </b-table>
                            <div style="display: flex">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalPages"
                                    :per-page="perPage">
                                </b-pagination>
                                <b-form-select v-model="perPage" class="pagination-select" style="min-width: 100px !important">
                                    <b-form-select-option :value="5">5</b-form-select-option>
                                    <b-form-select-option :value="10">10</b-form-select-option>
                                    <b-form-select-option :value="20">20</b-form-select-option>
                                    <b-form-select-option :value="users.length">{{$t('UsersManagement.All')}}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </b-col>
                    </b-row>
                </card>
            </b-col>
        </b-row>
    </b-container>
    <modal :show.sync="modals.invite" header-classes="justify-content-center" class="custom-width-modal">
        <h4 style="display: flex; justify-content: center; align-items: center;"><b>{{$t('UsersManagement.Modals.Invite.Title')}}</b></h4>
        <b-form-group label=" Email *" label-for="input-email_inv" style="margin-left: 270px;">
                <b-form-input
                    v-model="email_inv"
                    id="input-email_inv"
                    name="input-email_inv"
                    v-validate="{ required: true, email: true}"
                    :state="validateState('input-email_inv')"
                    aria-describedby="input-email_inv-live-feedback"
                    size="sm"
                    placeholder=" Email"
                    style="max-width: 400px;"
                ></b-form-input>
                <b-form-invalid-feedback id="input-email_inv-live-feedback">{{ veeErrors.first('input-email_inv') }}</b-form-invalid-feedback>
                </b-form-group>
                <div class="col-12">
                    <div class="col-6" style="float: left;">
                        <card style="border-radius: 20px; padding: 15px; display: flex; justify-content: center; align-items: center;">
                            <p slot="header" style="font-size: 1.2em;">
                                <b>
                                {{$t('UsersManagement.Modals.Invite.Title1')}}
                                </b>
                            </p>
                            <ul style="font-size: 0.8em;">
                                <li> {{$t('UsersManagement.Modals.Invite.TextNOT1')}}</li>
                                <li>{{$t('UsersManagement.Modals.Invite.TextNOT2')}} </li>
                                <li> {{$t('UsersManagement.Modals.Invite.TextNOT3')}}</li>
                            </ul>
                            <!-- <p style="font-size: 0.8em;"> 
                                {{$t('UsersManagement.Modals.Invite.TextNOT1')}}
                            </p>
                            <p style="font-size: 0.8em;"> 
                                {{$t('UsersManagement.Modals.Invite.TextNOT2')}}
                            </p>
                            <p style="font-size: 0.8em;"> 
                                {{$t('UsersManagement.Modals.Invite.TextNOT3')}}
                            </p> -->
                        </card>
                    </div>
                    <div class="col-6" style="float: right;">
                        <card style="border-radius: 20px; padding: 15px;">
                            <p slot="header" style="font-size: 1.2em; display: flex; justify-content: center; align-items: center;">
                                <b>
                                {{$t('UsersManagement.Modals.Invite.Title2')}}
                                </b>
                            </p>
                            <ul style="font-size: 0.8em;">
                                <li>{{$t('UsersManagement.Modals.Invite.TextYES1')}}</li>
                                <li>{{$t('UsersManagement.Modals.Invite.TextYES2')}}</li>
                                <li>{{$t('UsersManagement.Modals.Invite.TextYES3')}}</li>
                            </ul>
                            <!-- <p style="font-size: 0.8em;">
                                {{$t('UsersManagement.Modals.Invite.TextYES1')}}
                            </p>
                            <p style="font-size: 0.8em;">
                                {{$t('UsersManagement.Modals.Invite.TextYES2')}}
                            </p>
                            <p style="font-size: 0.8em;">
                                {{$t('UsersManagement.Modals.Invite.TextYES3')}}
                            </p> -->
                        </card>
                    </div>
                </div>
        <template slot="footer">
            <n-button class="btn-simple ncm-btn" @click.native="sendInv()">
                {{$t('UsersManagement.Modals.Invite.Send')}} 
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" style="float: right;" @click.native="modals.invite = false">
                {{ $t("DashBoardStaff.Modals.AssignRole.Footer.Close") }} 
            </n-button>
            </template>
    </modal>
    </div>
</template>
<script>
import { Badge, Button, InfoSection,Modal, FormGroupInput, Card, Tabs, TabPane, Checkbox, DropDown} from '@/components';
import InvitatonDataServices from '../../services/InvitationDataService'
import OrganizationsDataService from '../../services/OrganizationsDataService'
import ProjectsDataService from '../../services/ProjectsDataService'
import {Table, TableColumn, Tooltip} from 'element-ui';

import moment from 'moment'
import ExternalUserDataService from '../../services/ExternalUserDataService';


export default {
    components : {
        Card,
        Modal,
        [Checkbox.name]: Checkbox,
        [DropDown.name]: DropDown,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Tooltip.name]: Tooltip,
        [FormGroupInput.name]: FormGroupInput,
    },
    data() {
        return {
            // userLS: null,
            auxOrganizationID: -99,
            loading: false,
            modals: {
                invite: false
            },
            currentPage: 1,
            perPage: 10,
            currentPage2: 1,
            organization_id: 31,
            perPage2: 10,
            visibilityFilter: null,
            user: {},
            email_inv: null,
            users: [],
            inv: [],
            invitations: [],
            filteredArrayInvitations: [],
            projects: [],
            headersUsers: [
                { key: 'name', sortable: true, label: this.$t('DashBoardStaff.Table.Name'), tdClass: 'table-title', thClass: 'table-title' }, 
                { key: 'surname', sortable: true, label: this.$t('DashBoardStaff.Table.Surname') },
                // { key: 'roles', sortable: true, label: this.$t('DashBoardStaff.Table.Role') },
                // { key: 'phone', label: this.$t('DashBoardStaff.Table.Phone') },
                { key: 'email', label: this.$t('DashBoardStaff.Table.Email') },
                { key: 'is_active', label: this.$t('DashBoardStaff.Table.Active') },
            ],
            headersInvitations: [
                { key: 'email', label:'Destination'},
                { key: 'status', label:'Status'},
                // { key: 'reason', label:'Reason'},
                { key: 'expirationDate', label: 'Expiration date' },
            ],
            // auxUsers: [],
            auxOrganizationLS: [],
            projectSelected: undefined,
            project_analyst: [],
            selectedElement: null
        }
    },
    computed: {
        getUsers(){
            // let userFormat = users;
            let rolesT= null;
            console.log(this.users);
            this.users.forEach((user) => {
                console.log(user);
                rolesT = user.roles.map(role => {
                    console.log(role.user_role);
                    if(role.user_role == "project_manager"){
                        role.user_role = "project manager"
                    }
                    return role.user_role
                });
                user.roles = rolesT
            });
            return this.users
        },
        // onUpdate() {
        //     this.$refs.userTable.refresh();
        //     this.$forceUpdate();
        // },
        totalPages () {
            return this.users.length
        },
        totalPages2 () {
            return this.invitations.length
        },
    },
    watch: {
        $orgSelected(newValue){
            console.log('Org selected changed!', newValue)
            this.organization_id = newValue.id
            this.getData()
        },
        $projectSelected(newValue){
            console.log('project selected changed!', newValue)
            this.projectSelected = newValue
            this.getData()
        }
    },
    created () {
        this.organization_id = this.$orgSelected.id
        this.auxOrganizationID = JSON.parse(localStorage.getItem("user")).admin.organization_id
        this.auxOrganizationLS = JSON.parse(localStorage.getItem("user")).admin
        this.getData()
    },
    beforeUpdate(){
        if (this.auxOrganizationID != JSON.parse(localStorage.getItem("user")).admin.organization_id) { 
            this.auxOrganizationID = JSON.parse(localStorage.getItem("user")).admin.organization_id
            console.log("kikiki", this.auxOrganizationID);
        }
    },
    methods: {
        copyToClipboard(text) {
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            this.$swal.fire({
                icon: 'success',
                text: this.$t('Ecosystems.Copied')
            }) 
        },
        async findProjectsByOrganizationId() {
        
                await ProjectsDataService.findProjectsByOrganizationId(this.$orgSelected.id, this.$roleSelected.user_role).then(
                Response => {
                    let projects = Response.data.data   
                    console.log(projects)
                    this.projectSelected = projects.filter( f => f.id == this.$projectSelected.id)[0]
                    console.log(this.projectSelected)
                    this.getData()
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        changeStatus(item, is_active){
            console.log('AAAAAAAAAAA', item);
            console.log('AAAAAAAAAAA', is_active);
            ProjectsDataService.setAnalystStatus(this.$projectSelected.id, item.id, is_active).then(
                Response => {
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.SetAnalystStatusSuccess')
                    }).then(() => {
                        this.findProjectsByOrganizationId()
                    });
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Alerts.ErrorSetAnalystStatus')
                        
                    }).then(() => {
                    })
                }
            )
        },
        applyRowClass(row) {
            if (row != null) {
                if (row.invitation.is_rejected) {
                return 'disabled-row';
                }  else {
                    return '';
                }
            }
        },
        getData () {
            console.log(this.projectSelected.analysts)
            this.project_analyst = this.projectSelected.analysts
            console.log(this.project_analyst)
        },
        sendInv() {
            this.$validator.validateAll(['input-email_inv']).then(result => {
                if (!result) {
                    return
                }
                this.loading = true;
                this.modals.invite = false
                // Llamada a endpoint de invitar a external user 
                this.auxOrganizationID = JSON.parse(localStorage.getItem("user")).admin.organization_id
                InvitatonDataServices.invite(this.email_inv, this.organization_id).then(
                    Response => {
                        if (Response.data.email_exist) {
                                this.$swal.fire({
                                icon: 'success',
                                title: this.$t('Alerts.InvitationSent'),
                                text: this.$t('Alerts.AcceptOrDecline')
                            }).then(() => {
                                // this.modals.invite = false
                                this.getData()
                            });
                        }
                        // else if (['input-email_inv'] == this.users.email){
                        //     this.$swal.fire({
                        //         icon: 'error',
                        //         title: "This user is already in the organization",
                        //         text: "You can't invite a member that already exists"
                        //     }).then(() => {
                        //         // this.modals.invite = false
                        //         this.getData()
                        //     });
                        // }
                        else {
                            this.$swal.fire({
                                icon: 'success',
                                title: this.$t('Alerts.InvitationSent'),
                                text: this.$t('Alerts.PriceSubtracted')
                            }).then(() => {
                                // this.modals.invite = false
                                this.getData()
                            });
                        }
                        this.loading = false;
                    },
                    Error => {
                        this.loading = false
                        this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Alerts.ErrorSendingInvitation')
                    }).then(() => {
                        // this.$router.push('/')
                    })
                    this.loading = false;
                    }
                )
                // this.$router.push('/signup-invite')
            })
        },
        validateState (ref) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
            return !this.veeErrors.has(ref)
        }
        return null
        }
    }
    
}
</script>
<style>
.disabled-row {
  background-color: #f8f8f8;
  color: #999999;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.card-h-100 {
    height: 100%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #5D6176;
  background-color: #5D6176;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  border-color: #5D6176;
}
.custom-width-modal .modal-dialog {
    max-width: 1000px;
}
</style>