<template>
  <div class="page-header signup-page section-image">
        <div class="page-header-image"
        :style="{'background-image': 'url(' + $getImgUrlSite('bg17.jpg') +')'}"/>
        <div class="content-sign">
            <div class="container">
              <div v-if="loading" id="toHidden"
            style="
            position: absolute;
            top     :0;
            left    : 0;
            height  : 100%;
            width   :  100%;
            background-color:azure;
            z-index : 1060;
            opacity : 0.6;"
            >
        </div>
        <b-icon  v-if="loading" style="
            z-index: 2000;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            "
            icon="three-dots"
            animation="cylon"
            font-scale="4">
        </b-icon>
                <b-row>
                    <b-col class="m-auto col-md-8 col-lg-6">                        
                        <BCard class="mt-5" style="border-radius: 2.5%;"> 
                            <div
                                slot="header"
                                class="logo-container"
                            >
                                <img
                                    v-lazy="$getImgUrlSite('LogoNoSpinOff.png')"
                                    style="max-height: 75px; max-width: 175px; margin: 30px;"
                                    alt="logo"
                                >
                            </div>                           
                            <div class="row">
                                <div class="col">
                                    <span>
                                        <h6 class="highlighted-title" style="color: rgb(0, 0, 0)">PDF reports validator</h6>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-6" style="display: flex; margin:auto">
                                <b-form-radio style="color: black" v-model="typeOfCheck" value="0">PDF + polygon</b-form-radio>
                                <b-form-radio style="color: black; margin-left: 7%" v-model="typeOfCheck" value="1">Only PDF</b-form-radio>
                              </div>
                            </div>
                            <div class="row">
                                <div class="col" v-if="typeOfCheck == 0">
                                  <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-file-added="dropzoneAddedFile" @vdropzone-removed-file="dropzoneDeletedFile">
                                  </vue-dropzone>
                                </div>
                                <div class="col" v-if="typeOfCheck == 1">
                                  <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions2" @vdropzone-file-added="dropzoneAddedFile" @vdropzone-removed-file="dropzoneDeletedFile">
                                  </vue-dropzone>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                  <b-form-group label="" class="formSignUpLabel">
                                    <b-form-input
                                      v-model="email"
                                      id="input-email"
                                      name="input-email"
                                      v-validate="{ required: true, email: true}"
                                      :state="validateState('input-email')"
                                      aria-describedby="input-email-live-feedback"
                                      placeholder="Insert email for check validity"
                                      class="no-border input-lg"
                                      style="border: 1px solid #a2a0a0;"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="input-email-live-feedback">{{ veeErrors.first('input-email') }}</b-form-invalid-feedback>
                                  </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                              <div class="summary-container" v-if="summaryInfo" style="width: 100%">
                                <div class="row">
                                  <div class="col">
                                    <!-- <h5 slot="header" class="title title-up" style="margin-bottom: -15px;">
                                      Checking files
                                    </h5> -->
                                    <div class="error-list" v-if="msgReportError.length > 0" style="margin-top: 0; margin-bottom: 0; padding: 10px">
                                      
                                        <span v-for="(error, index) in msgReportError" :key="index" class="error-item" >
                                          {{ error }}
                                        </span>
                                    </div>
                                    
                                    <div class="checks-ok-list" v-if="msgReportOK.length > 0" style="margin-top: 0; margin-bottom: 0">
                                     
                                        <span v-for="(message, index) in msgReportOK" :key="index" class="checks-ok-item" >
                                          {{ message }}
                                        </span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col">
                                    <b-button class="btn-simple ncm-btn" @click="checkUploadFiles()">
                                        CHECK VALIDITY
                                        <b-icon icon="check"></b-icon>
                                    </b-button>
                                </div>
                                
                            </div>
                            
                            
                        </BCard>
                    </b-col>
                </b-row>
            </div>
        </div>
        <footer-logo background-color="#ffffff"/>
    </div>
</template>
<script>
  import { Badge, Button, InfoSection,Modal, FormGroupInput,Tabs, TabPane, Checkbox, DropDown} from '@/components';
  import { API_KEY } from '@/constants'
  import FooterLogo from '@/layout/FooterLogo';
  import { Card } from 'element-ui';
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  });

  import MapsDataService from '@/services/MapsDataService'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  export default {
  name: 'report-check',
  components: {
    FooterLogo,
    Card,
    vueDropzone: vue2Dropzone,
  },
  data() {
      return {
        dropzoneOptions: {
                maxFiles: 2,
                dictRemoveFile: 'X',
                addRemoveLinks: true,
                disablePreviews: true,
                createImageThumbnails: false,
                autoQueue: false,
                url: 'http://localhost',
                autoProcessQueue: false,
                thumbnailWidth: 20,
                maxFilesize: 5000,
                dictDefaultMessage: 'Drop files here or click to upload <b-icon icon="trash"></b-icon>',
                init: function() {
                    this.on("thumbnail", function(file) {
                        console.log('on thumbnail', file)
                    })
                    
                    this.on("complete", function(file, done) {
                        console.log('on complete', file)

                    })
                },
                
            },

            dropzoneOptions2: {
                maxFiles: 1,
                dictRemoveFile: 'X',
                addRemoveLinks: true,
                disablePreviews: true,
                createImageThumbnails: false,
                autoQueue: false,
                url: 'http://localhost',
                autoProcessQueue: false,
                thumbnailWidth: 20,
                maxFilesize: 5000,
                dictDefaultMessage: 'Drop files here or click to upload <b-icon icon="trash"></b-icon>',
                init: function() {
                    this.on("thumbnail", function(file) {
                        console.log('on thumbnail', file)
                    })
                    
                    this.on("complete", function(file, done) {
                        console.log('on complete', file)

                    })
                },
                
            },
          
          loading: false,
          filesSize: 0,
          organization_id: undefined,
          role: undefined,
          acceptedFiles: [],
          msgReportError: [],
          msgReportOK: [],
          filesChecked: false,
          summaryInfo: false,
          email: null,
          typeOfCheck: 0
      }
  },
  computed: {
       
      },
  watch: {
    $orgSelected(newValue){
        console.log('Org selected changed!', newValue)
        this.organization_id = newValue.id
        this.checkExternalRole(this.$store.state.auth.user)
        this.getData()
    }
  },
  created() {
    this.organization_id = this.$orgSelected.id
    this.checkExternalRole(this.$store.state.auth.user)
    this.getData()
  },
  methods:{
    async accept(){
      const formData = new FormData();    

      var reports = this.$refs.myVueDropzone.getAcceptedFiles()
      console.log(report)

      var i = 0
      for( var report of reports){
        console.log(report)
        formData.append('files[' + i +']', report)
        i++
      }

      if(this.typeOfCheck == 0){
        formData.append('zip', true)
      }
      else if(this.typeOfCheck == 1){
        formData.append('zip', false)
      }


      await MapsDataService.checkReport(formData, this.email).then(
              async response => {
                  console.log(response)
                  this.clearErrors()
                  this.msgReportOK.push('This document is an original document!')

              },
              error => {
                this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                    this.clearErrors()
                    this.msgReportError.push('This document isn\'t an original document')
              }
          )
    },
    validateState (ref) {
      if (
        this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.veeErrors.has(ref)
        }
        return null
    },
    clearErrors(){
      this.msgReportError = []
      this.msgReportOK = []
    },
    checkExternalRole(user){
        console.log('$roleSelected', this.$roleSelected)
        this.role = this.$roleSelected.user_role
        console.log(this.role)
    },
    dropzoneAddedFile(file){
      console.log(file)
      this.acceptedFiles = []
      this.acceptedFiles = this.$refs.myVueDropzone.getAcceptedFiles()
      console.log(acceptedFiles)
    },
    dropzoneDeletedFile(file){
      console.log(file)
      this.acceptedFiles = []
      this.acceptedFiles = this.$refs.myVueDropzone.getAcceptedFiles()
      console.log(acceptedFiles)
    },
    checkUploadFiles(){
            this.$validator.validateAll().then(async result => {
                if (!result) {
                    return
                }
                this.clearErrors()
                // Getting files and checking minimal files
                console.log(this.$refs.myVueDropzone.getAcceptedFiles())
                var files = this.$refs.myVueDropzone.getAcceptedFiles()
                console.log(files)
                if(this.typeOfCheck == 0){
                  if(files.length < 2){
                    this.msgReportError.push('Not enough files, you must upload 2 files, one PDF file and one ZIP file.')
                  }

                  if(files.length > 2){
                      this.msgReportError.push('You must upload only 2 files, one PDF file and one ZIP file.')
                  }

                  // Getting extension of files and check the basic formats
                  var ext_array = []
                  var i = 0
                  for (var file of files){
                      console.log(file)
                      var ext = file.name.split('.')[file.name.split('.').length - 1]
                      ext_array.push('.' + ext.toLowerCase())
                      i++
                  }
                  var formatsMissing = []
                  if (!ext_array.includes('.pdf') ){
                      formatsMissing.push('.pdf')
                  }
                  if (!ext_array.includes('.zip') ){
                      formatsMissing.push('.zip')
                  }
                }
                else if(this.typeOfCheck == 1){
                  if(files.length < 1){
                    this.msgReportError.push('Not enough files, you must upload 1 PDF file.')
                  }

                  if(files.length > 1){
                      this.msgReportError.push('You must upload only 1 PDF file.')
                  }

                  // Getting extension of files and check the basic formats
                  var ext_array = []
                  var i = 0
                  for (var file of files){
                      console.log(file)
                      var ext = file.name.split('.')[file.name.split('.').length - 1]
                      ext_array.push('.' + ext.toLowerCase())
                      i++
                  }
                  var formatsMissing = []
                  if (!ext_array.includes('.pdf') ){
                      formatsMissing.push('.pdf')
                  }
                }
                
                
                
                if (formatsMissing.length > 0){
                    var msg = 'Missing following format file: '
                    for (var format of formatsMissing)  msg += format + ', '
                    msg = msg.substring(0, msg.length - 2)
                    this.msgReportError.push(msg)
                }
                
                this.filesChecked = true
                this.summaryInfo = true

                if(this.msgReportError.length == 0 && this.filesChecked){
                  this.accept()
                }
                
            })
        },
    
    getData(){
        
    }
  }
  }
  </script>
  <style scoped>
  .disabled-row {
    background-color: #f8f8f8;
    color: #999999;
  }

  
  ::v-deep .dz-preview{
      min-width: 100px !important;
      max-width: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important;
  }

  ::v-deep .dz-image{
      min-width: 100px !important;
      max-width: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important;
  }


  ::v-deep .dz-progress{
      visibility: hidden;
  }

  ::v-deep .dz-remove{
    position: absolute !important;
    top: 0px !important;
    left: 69px !important;
    max-width: 20px !important;
    max-height: 20px !important;
    padding: 0px !important;
    border: none !important;
    color: #fe2626b0 !important;
  }

  .layers-size-modal{
    margin-bottom: 0;
    font-size: 13px;
    font-style: italic;
    color: #ffffff8a;
  }

  .error-list {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 1px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

.error-item {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #721c24;
}

.error-item::before {
  color: #721c24;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* .row .col.d-flex {
  justify-content: flex-end;
} */
.checks-ok-list {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
}

.checks-ok-item {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #155724;
}

.checks-ok-item::before {
  color: #155724;
  margin-right: 5px;
  margin-bottom: 5px;
}

::v-deep #dropzone{
  padding: 0;
  min-height: auto;
  margin-bottom: 16px;
}
::v-deep .card-modal > .card-body {
  padding: 0.8em!important;
  /* overflow-y: hidden; */
}

  </style>