import http from '../http-common'
import authHeader from './AuthHeaderService'

const API_URL_PATH = 'api/category-tutorial/'


class CategoriesBlogDataServices {
    findAll () {
        return http.get(API_URL_PATH + 'category-tutorials')
    }
    findOne (id) {
        const body = {id: id}
        return http.post(API_URL_PATH + 'category-tutorial', body)
    }
    findByCategory (category) {
        return http.get(API_URL_PATH + 'find-by-category?category=' + category)
    }
    create (name) {
        const body = {name: name}
        return http.post(API_URL_PATH + 'category-tutorial-create', body, {headers: authHeader()})
    }
    modify (id, name) {
        const body = {id: id, name: name}
        return http.put(API_URL_PATH + 'category-tutorial-update', body, {headers: authHeader()})
    }
    delete (id) {
        return http.delete(API_URL_PATH + 'category-tutorial-delete?id=' + id, {headers: authHeader()})
    }
    deleteAssociate(id_old,id_new) {
        return http.delete(API_URL_PATH + 'category-tutorial-delete-associate?id_category=' + id_old + '&id_category_update=' + id_new, {headers: authHeader()})
    }
}
export default new CategoriesBlogDataServices()