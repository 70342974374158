<template>
  <div class="page-header signup-page section-image" style="display: grid">
    <div v-if="loading" id="toHidden"
            style="
                position: absolute;
                top     :0;
                left    : 0;
                height  : 100%;
                width   :  100%;
                background-color:azure;
                z-index : 1000;
                opacity : 0.6;"
            >
    </div>
    <b-icon  v-if="loading" style="
                z-index: 2000;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                "
        icon="three-dots"
        animation="cylon"
        font-scale="4">
    </b-icon>
    <div class="page-header-image" :style="{'background-image': 'url(' + $getImgUrlSite('project11.jpg') +')'}"></div>
      <div class="content-sign flex-grow-1 content-responsive">
        <div class="container ">
          <div class="row d-flex ">
            <template v-if="!choose">
              <b-col class="m-auto col-md-6 col-lg-6">
                <div class="card card-pricing card-blog-tutorial plan-card">
                  <div class="card-body">
                    <h6 style="color: black;">{{ $t("SignUp.Title1") }}</h6>
                    <h1 class="card-title" style="color: black;"> {{ $t("SignUp.Free") }} </h1>
                    <div class="icon icon-primary"><i class="now-ui-icons objects_spaceship"></i></div>
                    <ul class="mt-4">
                      <li>------</li>
                      <li>------</li>
                      <li>------</li>
                      <li>------</li>
                    </ul>
                  </div>
                  <div class="card-footer">
                    <b-button type="button" class="btn btn-simple ncm-btn m-auto"  @click.prevent="choose=!choose">{{$t("SignUp.Choose")}}</b-button>
                  </div>
                </div>
              </b-col>
              <b-col class="m-auto col-md-6 col-lg-6">
                <div class="card card-pricing card-blog-tutorial plan-card" style="opacity: 0.5;">
                  <div class="card-body">
                    <h6 style="color: black;">{{ $t("SignUp.Title2") }}</h6>
                    <h1 class="card-title" style="color: black;"> Coming soon... </h1>
                    <div class="icon icon-primary"><i class="now-ui-icons objects_diamond"></i></div>
                    <ul class="mt-4">
                      <li>------</li>
                      <li>------</li>
                      <li>------</li>
                      <li>------</li>
                    </ul>
                  </div>
                  <div class="card-footer">
                    <b-button type="button" class="btn btn-simple ncm-btn m-auto" :disabled="disableRegister" @click.prevent="choose=!choose; is_admin=!is_admin">{{$t("SignUp.Choose")}}</b-button>
                  </div>
                </div>
              </b-col>
            </template>
            <template v-if="!is_admin && choose">
              <b-col class="m-auto col-md-6 col-lg-6"> 
                <BCard class="plan-card">
                  <div class="row">
                    <div class="col">
                      <span>
                        <h6 class="highlighted-title"><i class="now-ui-icons objects_spaceship mr-1"></i>{{ $t("SignUp.Title1") }}</h6>
                      </span>
                    </div>
                    <div class="col  d-flex">
                      <b-button type="button" class="btn btn-simple m-auto ncm-btn-back" @click.prevent="choose=!choose;">{{$t("SignUp.Back")}}</b-button>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col">
                      <b-form-group :label="$t('SignUp.Name')" class="formSignUpLabel">
                        <b-form-input
                          v-model="dataFormUser.name"
                          id="input-dataFormUser-name"
                          name="input-dataFormUser-name"
                          :rules="['required', 'alpha']"
                          aria-describedby="input-dataFormUser-name-live-feedback"
                          :placeholder="$t('SignUp.Name')"
                          class="no-border input-lg"
                          style="border: 1px solid #a2a0a0;"
                          :state="validateState('input-dataFormUser-name-live-feedback')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-dataFormUser-name-live-feedback">{{ veeErrors.first('input-dataFormUser-name-live-feedback') }}</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col">
                      <b-form-group :label="$t('SignUp.Surname')" class="formSignUpLabel">
                        <b-form-input
                          v-model="dataFormUser.surname"
                          id="input-dataFormUser-surname"
                          name="input-dataFormUser-surname"
                          :rules="['required', 'alpha']"
                          aria-describedby="input-dataFormUser-surname-live-feedback"
                          :placeholder="$t('SignUp.Surname')"
                          class="no-border input-lg"
                          style="border: 1px solid #a2a0a0;"
                          :state="validateState('input-dataFormUser-surname-live-feedback')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-dataFormUser-surname-live-feedback">{{ veeErrors.first('input-dataFormUser-surname-live-feedback') }}</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <b-form-group :label="$t('SignUp.Email')" class="formSignUpLabel">
                        <b-form-input
                          v-model="dataFormUser.email"
                          id="input-dataFormUser-email"
                          name="input-dataFormUser-email"
                          :rules="['required', 'email']"
                          type="email"
                          aria-describedby="input-dataFormUser-email-live-feedback"
                          :placeholder="$t('SignUp.Email')"
                          class="no-border input-lg"
                          style="border: 1px solid #a2a0a0;"
                          :state="validateState('input-dataFormUser-email-live-feedback')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-dataFormUser-email-live-feedback">{{ veeErrors.first('input-dataFormUser-email-live-feedback') }}</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <b-form-group :label="$t('SignUp.Phone')" class="formSignUpLabel">
                        <b-form-input
                          v-model="dataFormUser.phone"
                          id="input-dataFormUser-phone"
                          name="input-dataFormUser-phone"
                          :rules="['required']"
                          type="tel"
                          aria-describedby="input-dataFormUser-phone-live-feedback"
                          :placeholder="$t('SignUp.Phone')"
                          class="no-border input-lg"
                          style="border: 1px solid #a2a0a0;"
                          :state="validateState('input-dataFormUser-phone-live-feedback')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-dataFormUser-phone-live-feedback">{{ veeErrors.first('input-dataFormUser-phone-live-feedback') }}</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <b-form-group :label="$t('SignUp.Password')" class="formSignUpLabel">
                        <b-form-input
                          v-model="dataFormUser.psw"
                          ref="input-dataFormUser-password"
                          id="input-dataFormUser-password"
                          name="input-dataFormUser-password"
                          :rules="['required']"
                          type="password"
                          aria-describedby="input-dataFormUser-password-live-feedback"
                          :placeholder="$t('LoginStaff.Password')"
                          class="no-border input-lg"
                          style="border: 1px solid #a2a0a0;"
                          :state="validateState('input-dataFormUser-password')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-dataFormUser-password-live-feedback">{{ veeErrors.first('input-dataFormUser-password-live-feedback') }}</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-2 d-flex">
                      <span v-if="!variablePass" class="iconPass m-auto" @click="showPass()"><b-icon icon="eye" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
                      <span v-if="variablePass" class="iconPass m-auto" @click="showPass()"><b-icon icon="eye-slash" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <b-button class="btn-simple ncm-btn m-auto" @click="signUpExternalUser()">
                        {{ $t("SignUp.Sign") }}
                      <b-icon icon="file-person"></b-icon>
                    </b-button>
                    </div>
                  </div>
                </BCard>
              </b-col>
            </template>
            <template v-if="is_admin && choose">
              <div class="m-auto col-md-6 col-lg-6 col-sm-12"> 
                <BCard class=" plan-card">
                  <div class="row">
                    <div class="col">
                      <span>
                        <h6 class="highlighted-title"><i class="now-ui-icons objects_diamond mr-1"></i>{{ $t("SignUp.Title2") }}</h6>
                      </span>
                    </div>
                    <div class="col  d-flex">
                      <b-button type="button" class="btn btn-simple m-auto ncm-btn-back" @click.prevent="choose=!choose; is_admin=!is_admin">{{$t("SignUp.Back")}}</b-button>
                    </div>
                  </div>
                <div class="row mt-2">
                  <div class="col">
                    <b-form-group class="formSignUpLabel" :label=" $t('DashBoardStaff.Modals.Modify.Name')" label-for="input-admin-name">
                      <b-form-input
                        v-model="dataFormAdmin.name"
                        id="input-admin-name"
                        name="input-admin-name"
                        v-validate="{ required: true, alpha_spaces: true}"
                        class="no-border input-lg"
                        style="border: 1px solid #a2a0a0;"
                        :placeholder="$t('SignUp.Name')"
                        :state="validateState('input-admin-name')"
                        aria-describedby="input-admin-surname-live-feedback"
                        size="sm"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-admin-name-live-feedback">{{ veeErrors.first('input-admin-name') }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col">
                    <b-form-group class="formSignUpLabel" :label=" $t('DashBoardStaff.Modals.Modify.Surname')" label-for="input-admin-surname">
                      <b-form-input
                        v-model="dataFormAdmin.surname"
                        id="input-admin-surname"
                        name="input-admin-surname"
                        v-validate="{ required: true, alpha_spaces: true}"
                        class="no-border input-lg"
                        style="border: 1px solid #a2a0a0;"
                        :placeholder="$t('SignUp.Surname')"
                        :state="validateState('input-admin-surname')"
                        aria-describedby="input-admin-surname-live-feedback"
                        size="sm"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-admin-surname-live-feedback">{{ veeErrors.first('input-admin-surname') }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <b-form-group :label="$t('SignUp.Email')" class="formSignUpLabel" label-for="input-admin-email">
                      <b-form-input
                        v-model="dataFormAdmin.email"
                        id="input-admin-email"
                        name="input-admin-email"
                        v-validate="{ required: true, email: true}"
                        class="no-border input-lg"
                        style="border: 1px solid #a2a0a0;"
                        :placeholder="$t('SignUp.Email')"
                        :state="validateState('input-admin-email')"
                        aria-describedby="input-admin-email-live-feedback"
                        size="sm"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-admin-email-live-feedback">{{ veeErrors.first('input-admin-email') }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <b-form-group :label="$t('SignUp.Password')" class="formSignUpLabel" label-for="input-admin-password">
                      <b-form-input
                        v-model="dataFormAdmin.password"
                        id="input-admin-password"
                        name="input-admin-password"
                        v-validate="{ required: true}"
                        class="no-border input-lg"
                        style="border: 1px solid #a2a0a0;"
                        :placeholder="$t('SignUp.Password')"
                        :state="validateState('input-admin-password')"
                        type="password"
                        aria-describedby="input-admin-password-live-feedback"
                        size="sm"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-admin-password-live-feedback">{{ veeErrors.first('input-admin-password') }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-2 d-flex" style="margin-left: -20%;">
                    <span v-if="!variablePass" class="iconPass m-auto" @click="showPass()"><b-icon icon="eye" class="iconPass" style="color: #5D6176; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
                    <span v-if="variablePass" class="iconPass m-auto" @click="showPass()"><b-icon icon="eye-slash" class="iconPass" style="color: #5D6176; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
                  </div>
                </div>
                </BCard>
              </div>
              <div class="m-auto col-md-6 col-lg-6 col-sm-12"> 
                <BCard no-body class="plan-card d-flex">
                  <b-card-body class="d-flex">
                    <div class="m-auto">
                      <h6 style="color: black;">{{ $t("SignUp.Org") }}</h6>
                        <div class="row mt-4">
                          <div class="col">
                            <b-form-group :label="$t('SignUp.Name')" class="formSignUpLabel" label-for="input-org-name">
                                <b-form-input
                                  v-model="dataFormOrg.name"
                                  id="input-org-name"
                                  name="input-org-name"
                                  v-validate="{ required: true}"
                                  class="no-border input-lg"
                                  style="border: 1px solid #a2a0a0;"
                                  :placeholder="$t('SignUp.Name')"
                                  :state="validateState('input-org-name')"
                                  aria-describedby="input-org-name-live-feedback"
                                  size="sm"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-org-vat-live-feedback">{{ veeErrors.first('input-org-name') }}</b-form-invalid-feedback>
                              </b-form-group>
                          </div>
                          <div class="col">
                            <b-form-group label="VAT *" class="formSignUpLabel" label-for="input-org-vat">
                              <b-form-input
                                  v-model="dataFormOrg.vat"
                                  id="input-org-vat"
                                  name="input-org-vat"
                                  v-validate="{ required: true}"
                                  class="no-border input-lg"
                                  style="border: 1px solid #a2a0a0;"
                                  placeholder="VAT"
                                  :state="validateState('input-org-vat')"
                                  aria-describedby="input-org-vat-live-feedback"
                                  size="sm"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-org-vat-live-feedback">{{ veeErrors.first('input-org-vat') }}</b-form-invalid-feedback>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <b-form-group :label="$t('SignUp.Address')" class="formSignUpLabel " label-for="input-org-address1">
                              <b-form-input
                                  v-model="dataFormOrg.address1"
                                  id="input-org-address1"
                                  name="input-org-address1"
                                  v-validate="{ required: true}"
                                  class="no-border input-lg"
                                  style="border: 1px solid #a2a0a0;"
                                  :placeholder="$t('SignUp.Address')"
                                  :state="validateState('input-org-address1')"
                                  aria-describedby="input-org-address1-live-feedback"
                                  size="sm"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-org-address1-live-feedback">{{ veeErrors.first('input-org-address1') }}</b-form-invalid-feedback>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <b-form-group :label="$t('SignUp.Phone')" class="formSignUpLabel" label-for="input-org-phone">
                              <b-form-input
                                  v-model="dataFormOrg.phone"
                                  id="input-org-phone"
                                  name="input-org-phone"
                                  v-validate="{}"
                                  class="no-border input-lg"
                                  style="border: 1px solid #a2a0a0;"
                                  :placeholder="$t('SignUp.Phone')"
                                  :state="validateState('input-org-phone')"
                                  aria-describedby="input-org-phone-live-feedback"
                                  size="sm"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-org-phone-live-feedback">{{ veeErrors.first('input-org-phone') }}</b-form-invalid-feedback>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <b-form-group :label="$t('SignUp.Country')" class="formSignUpLabel" label-for="input-org-country">
                              <b-form-select 
                                  v-model="dataFormOrg.country" 
                                  id="input-org-country"
                                  name="input-org-country"
                                  v-validate="{ required: true}"
                                  :state="validateState('input-org-country')"
                                  aria-describedby="input-org-country-live-feedback"
                                  :options="countries"
                                  value-field="value"
                                  style="background-color: transparent; border: 1px solid #a2a0a0;"
                                  text-field="text">
                                </b-form-select>
                                <b-form-invalid-feedback id="input-org-country-live-feedback">{{ veeErrors.first('input-org-country') }}</b-form-invalid-feedback>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <b-button class="btn-simple ncm-btn m-auto"  @click="signUpExternalUserAdmin()">
                              {{ $t("SignUp.Sign") }}
                            <b-icon icon="file-person"></b-icon>
                          </b-button>
                          </div>
                        </div>
                      </div>  
                    </b-card-body>
                </BCard>
              </div>
            </template>
          </div>
        </div>
      </div>
    <div class="row row-logo" style="margin-top: 185px;">
      <div class="col">
        <footer-logo></footer-logo>
      </div>
    </div>
  </div>
</template>

<script>
import ExternalUserService from '../../services/ExternalUserDataService'
import { Card } from 'element-ui';
import { BCard } from 'bootstrap-vue';
import FooterLogo from '@/layout/FooterLogo';

export default {
  name: 'SignupPage',
  bodyClass: 'signup-page',
  components: {
    BCard,
    FooterLogo
  },
  data() {
    return {
      disableRegister: true,
      loading: false,
      is_admin: false,
      options: ['EXTERNAL USER', 'ADMIN USER'],
      dataFormUser: {},
      dataFormAdmin: {},
      dataFormOrg: {},
      variablePass: false,
      organization: null,
      organizations: [],
      org_exist: true,
      country: null,
      choose: false,
      countries : [
        { "text": "Afghanistan", "value": "AF" },
        { "text": "Åland Islands", "value": "AX" },
        { "text": "Albania", "value": "AL" },
        { "text": "Algeria", "value": "DZ" },
        { "text": "American Samoa", "value": "AS" },
        { "text": "Andorra", "value": "AD" },
        { "text": "Angola", "value": "AO" },
        { "text": "Anguilla", "value": "AI" },
        { "text": "Antarctica", "value": "AQ" },
        { "text": "Antigua and Barbuda", "value": "AG" },
        { "text": "Argentina", "value": "AR" },
        { "text": "Armenia", "value": "AM" },
        { "text": "Aruba", "value": "AW" },
        { "text": "Australia", "value": "AU" },
        { "text": "Austria", "value": "AT" },
        { "text": "Azerbaijan", "value": "AZ" },
        { "text": "Bahamas", "value": "BS" },
        { "text": "Bahrain", "value": "BH" },
        { "text": "Bangladesh", "value": "BD" },
        { "text": "Barbados", "value": "BB" },
        { "text": "Belarus", "value": "BY" },
        { "text": "Belgium", "value": "BE" },
        { "text": "Belize", "value": "BZ" },
        { "text": "Benin", "value": "BJ" },
        { "text": "Bermuda", "value": "BM" },
        { "text": "Bhutan", "value": "BT" },
        { "text": "Bolivia", "value": "BO" },
        { "text": "Bosnia and Herzegovina", "value": "BA" },
        { "text": "Botswana", "value": "BW" },
        { "text": "Bouvet Island", "value": "BV" },
        { "text": "Brazil", "value": "BR" },
        { "text": "British Indian Ocean Territory", "value": "IO" },
        { "text": "Brunei Darussalam", "value": "BN" },
        { "text": "Bulgaria", "value": "BG" },
        { "text": "Burkina Faso", "value": "BF" },
        { "text": "Burundi", "value": "BI" },
        { "text": "Cambodia", "value": "KH" },
        { "text": "Cameroon", "value": "CM" },
        { "text": "Canada", "value": "CA" },
        { "text": "Cape Verde", "value": "CV" },
        { "text": "Cayman Islands", "value": "KY" },
        { "text": "Central African Republic", "value": "CF" },
        { "text": "Chad", "value": "TD" },
        { "text": "Chile", "value": "CL" },
        { "text": "China", "value": "CN" },
        { "text": "Christmas Island", "value": "CX" },
        { "text": "Cocos (Keeling) Islands", "value": "CC" },
        { "text": "Colombia", "value": "CO" },
        { "text": "Comoros", "value": "KM" },
        { "text": "Congo", "value": "CG" },
        { "text": "Congo, The Democratic Republic of the", "value": "CD" },
        { "text": "Cook Islands", "value": "CK" },
        { "text": "Costa Rica", "value": "CR" },
        { "text": "Cote D'Ivoire", "value": "CI" },
        { "text": "Croatia", "value": "HR" },
        { "text": "Cuba", "value": "CU" },
        { "text": "Cyprus", "value": "CY" },
        { "text": "Czech Republic", "value": "CZ" },
        { "text": "Denmark", "value": "DK" },
        { "text": "Djibouti", "value": "DJ" },
        { "text": "Dominica", "value": "DM" },
        { "text": "Dominican Republic", "value": "DO" },
        { "text": "Ecuador", "value": "EC" },
        { "text": "Egypt", "value": "EG" },
        { "text": "El Salvador", "value": "SV" },
        { "text": "Equatorial Guinea", "value": "GQ" },
        { "text": "Eritrea", "value": "ER" },
        { "text": "Estonia", "value": "EE" },
        { "text": "Ethiopia", "value": "ET" },
        { "text": "Falkland Islands (Malvinas)", "value": "FK" },
        { "text": "Faroe Islands", "value": "FO" },
        { "text": "Fiji", "value": "FJ" },
        { "text": "Finland", "value": "FI" },
        { "text": "France", "value": "FR" },
        { "text": "French Guiana", "value": "GF" },
        { "text": "French Polynesia", "value": "PF" },
        { "text": "French Southern Territories", "value": "TF" },
        { "text": "Gabon", "value": "GA" },
        { "text": "Gambia", "value": "GM" },
        { "text": "Georgia", "value": "GE" },
        { "text": "Germany", "value": "DE" },
        { "text": "Ghana", "value": "GH" },
        { "text": "Gibraltar", "value": "GI" },
        { "text": "Greece", "value": "GR" },
        { "text": "Greenland", "value": "GL" },
        { "text": "Grenada", "value": "GD" },
        { "text": "Guadeloupe", "value": "GP" },
        { "text": "Guam", "value": "GU" },
        { "text": "Guatemala", "value": "GT" },
        { "text": "Guernsey", "value": "GG" },
        { "text": "Guinea", "value": "GN" },
        { "text": "Guinea-Bissau", "value": "GW" },
        { "text": "Guyana", "value": "GY" },
        { "text": "Haiti", "value": "HT" },
        { "text": "Heard Island and Mcdonald Islands", "value": "HM" },
        { "text": "Holy See (Vatican City State)", "value": "VA" },
        { "text": "Honduras", "value": "HN" },
        { "text": "Hong Kong", "value": "HK" },
        { "text": "Hungary", "value": "HU" },
        { "text": "Iceland", "value": "IS" },
        { "text": "India", "value": "IN" },
        { "text": "Indonesia", "value": "ID" },
        { "text": "Iran, Islamic Republic Of", "value": "IR" },
        { "text": "Iraq", "value": "IQ" },
        { "text": "Ireland", "value": "IE" },
        { "text": "Isle of Man", "value": "IM" },
        { "text": "Israel", "value": "IL" },
        { "text": "Italy", "value": "IT" },
        { "text": "Jamaica", "value": "JM" },
        { "text": "Japan", "value": "JP" },
        { "text": "Jersey", "value": "JE" },
        { "text": "Jordan", "value": "JO" },
        { "text": "Kazakhstan", "value": "KZ" },
        { "text": "Kenya", "value": "KE" },
        { "text": "Kiribati", "value": "KI" },
        { "text": "Korea, Democratic People'S Republic of", "value": "KP" },
        { "text": "Korea, Republic of", "value": "KR" },
        { "text": "Kuwait", "value": "KW" },
        { "text": "Kyrgyzstan", "value": "KG" },
        { "text": "Lao People'S Democratic Republic", "value": "LA" },
        { "text": "Latvia", "value": "LV" },
        { "text": "Lebanon", "value": "LB" },
        { "text": "Lesotho", "value": "LS" },
        { "text": "Liberia", "value": "LR" },
        { "text": "Libyan Arab Jamahiriya", "value": "LY" },
        { "text": "Liechtenstein", "value": "LI" },
        { "text": "Lithuania", "value": "LT" },
        { "text": "Luxembourg", "value": "LU" },
        { "text": "Macao", "value": "MO" },
        { "text": "Macedonia, The Former Yugoslav Republic of", "value": "MK" },
        { "text": "Madagascar", "value": "MG" },
        { "text": "Malawi", "value": "MW" },
        { "text": "Malaysia", "value": "MY" },
        { "text": "Maldives", "value": "MV" },
        { "text": "Mali", "value": "ML" },
        { "text": "Malta", "value": "MT" },
        { "text": "Marshall Islands", "value": "MH" },
        { "text": "Martinique", "value": "MQ" },
        { "text": "Mauritania", "value": "MR" },
        { "text": "Mauritius", "value": "MU" },
        { "text": "Mayotte", "value": "YT" },
        { "text": "Mexico", "value": "MX" },
        { "text": "Micronesia, Federated States of", "value": "FM" },
        { "text": "Moldova, Republic of", "value": "MD" },
        { "text": "Monaco", "value": "MC" },
        { "text": "Mongolia", "value": "MN" },
        { "text": "Montserrat", "value": "MS" },
        { "text": "Morocco", "value": "MA" },
        { "text": "Mozambique", "value": "MZ" },
        { "text": "Myanmar", "value": "MM" },
        { "text": "Namibia", "value": "NA" },
        { "text": "Nauru", "value": "NR" },
        { "text": "Nepal", "value": "NP" },
        { "text": "Netherlands", "value": "NL" },
        { "text": "Netherlands Antilles", "value": "AN" },
        { "text": "New Caledonia", "value": "NC" },
        { "text": "New Zealand", "value": "NZ" },
        { "text": "Nicaragua", "value": "NI" },
        { "text": "Niger", "value": "NE" },
        { "text": "Nigeria", "value": "NG" },
        { "text": "Niue", "value": "NU" },
        { "text": "Norfolk Island", "value": "NF" },
        { "text": "Northern Mariana Islands", "value": "MP" },
        { "text": "Norway", "value": "NO" },
        { "text": "Oman", "value": "OM" },
        { "text": "Pakistan", "value": "PK" },
        { "text": "Palau", "value": "PW" },
        { "text": "Palestinian Territory, Occupied", "value": "PS" },
        { "text": "Panama", "value": "PA" },
        { "text": "Papua New Guinea", "value": "PG" },
        { "text": "Paraguay", "value": "PY" },
        { "text": "Peru", "value": "PE" },
        { "text": "Philippines", "value": "PH" },
        { "text": "Pitcairn", "value": "PN" },
        { "text": "Poland", "value": "PL" },
        { "text": "Portugal", "value": "PT" },
        { "text": "Puerto Rico", "value": "PR" },
        { "text": "Qatar", "value": "QA" },
        { "text": "Reunion", "value": "RE" },
        { "text": "Romania", "value": "RO" },
        { "text": "Russian Federation", "value": "RU" },
        { "text": "RWANDA", "value": "RW" },
        { "text": "Saint Helena", "value": "SH" },
        { "text": "Saint Kitts and Nevis", "value": "KN" },
        { "text": "Saint Lucia", "value": "LC" },
        { "text": "Saint Pierre and Miquelon", "value": "PM" },
        { "text": "Saint Vincent and the Grenadines", "value": "VC" },
        { "text": "Samoa", "value": "WS" },
        { "text": "San Marino", "value": "SM" },
        { "text": "Sao Tome and Principe", "value": "ST" },
        { "text": "Saudi Arabia", "value": "SA" },
        { "text": "Senegal", "value": "SN" },
        { "text": "Serbia and Montenegro", "value": "CS" },
        { "text": "Seychelles", "value": "SC" },
        { "text": "Sierra Leone", "value": "SL" },
        { "text": "Singapore", "value": "SG" },
        { "text": "Slovakia", "value": "SK" },
        { "text": "Slovenia", "value": "SI" },
        { "text": "Solomon Islands", "value": "SB" },
        { "text": "Somalia", "value": "SO" },
        { "text": "South Africa", "value": "ZA" },
        { "text": "South Georgia and the South Sandwich Islands", "value": "GS" },
        { "text": "Spain", "value": "ES" },
        { "text": "Sri Lanka", "value": "LK" },
        { "text": "Sudan", "value": "SD" },
        { "text": "Suriname", "value": "SR" },
        { "text": "Svalbard and Jan Mayen", "value": "SJ" },
        { "text": "Swaziland", "value": "SZ" },
        { "text": "Sweden", "value": "SE" },
        { "text": "Switzerland", "value": "CH" },
        { "text": "Syrian Arab Republic", "value": "SY" },
        { "text": "Taiwan, Province of China", "value": "TW" },
        { "text": "Tajikistan", "value": "TJ" },
        { "text": "Tanzania, United Republic of", "value": "TZ" },
        { "text": "Thailand", "value": "TH" },
        { "text": "Timor-Leste", "value": "TL" },
        { "text": "Togo", "value": "TG" },
        { "text": "Tokelau", "value": "TK" },
        { "text": "Tonga", "value": "TO" },
        { "text": "Trinidad and Tobago", "value": "TT" },
        { "text": "Tunisia", "value": "TN" },
        { "text": "Turkey", "value": "TR" },
        { "text": "Turkmenistan", "value": "TM" },
        { "text": "Turks and Caicos Islands", "value": "TC" },
        { "text": "Tuvalu", "value": "TV" },
        { "text": "Uganda", "value": "UG" },
        { "text": "Ukraine", "value": "UA" },
        { "text": "United Arab Emirates", "value": "AE" },
        { "text": "United Kingdom", "value": "GB" },
        { "text": "United States", "value": "US" },
        { "text": "United States Minor Outlying Islands", "value": "UM" },
        { "text": "Uruguay", "value": "UY" },
        { "text": "Uzbekistan", "value": "UZ" },
        { "text": "Vanuatu", "value": "VU" },
        { "text": "Venezuela", "value": "VE" },
        { "text": "Viet Nam", "value": "VN" },
        { "text": "Virgin Islands, British", "value": "VG" },
        { "text": "Virgin Islands, U.S.", "value": "VI" },
        { "text": "Wallis and Futuna", "value": "WF" },
        { "text": "Western Sahara", "value": "EH" },
        { "text": "Yemen", "value": "YE" },
        { "text": "Zambia", "value": "ZM" },
        { "text": "Zimbabwe", "value": "ZW" }
      ]
    };
  },
  created(){
    if(this.$route.query.token == process.env.VUE_APP_VITE_REGISTER_TOKEN){
      this.disableRegister = false
    }
  },
  methods: {
    signUpExternalUserAdmin() {
      this.$validator.validateAll(['input-admin-name','input-admin-surname','input-admin-email','input-admin-password','input-org-name','input-org-vat','input-org-address1','input-org-phone','input-org-country',]).then(result => {
        if (!result) {
          return
        }
        this.loading = true;
        this.dataFormAdmin.isAdmin = true
        this.dataFormAdmin.organization = this.dataFormOrg
        ExternalUserService.create(this.dataFormAdmin).then(
          Response => {
            this.$swal.fire({
                icon: 'success',
                title: this.$t('Alerts.ExternalUserAdmin')
              }).then(() => {
                this.dataFormAdmin = {}
                this.$validator.reset()
                this.$router.push('/login')
              });
              this.loading = false;
          },
          Error => {
          this.loading = false;
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: Error.response.data.message
          })
          }
        )


      })
    },
    toggleForm() {
      this.is_admin = !this.is_admin;
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    showPass() {
      this.variablePass = !this.variablePass;
      var tipo = document.getElementById("input-dataFormUser-password");
      if (tipo.type === "password") {
        tipo.type = "text";
      } else {
        tipo.type = "password";
      }
    }
  }
};
</script>

<style scoped>


.highlighted-title {
  color: black;
  font-size: 22px;
  margin: revert;
  /* Agrega otros estilos según tus preferencias */
  font-weight: bold;
  text-transform: uppercase;
  /* ... */
}
.content-sign {
  
    margin-top: 8%;
    text-align: center;
    margin-bottom: 50px;
  
}

@media (max-width: 480px) {
  .row-logo {
    margin-top: 65% !important;
  }
  .content-responsive {
    margin-top: 17% !important;
  }
}
@media (max-width: 720px) {
  .row-logo {
    margin-top: 65% !important;
  }
  .content-responsive {
    margin-top: 17% !important;
  }
}
</style>
