<template>
    <div class="wrapper blog-post">
        <div class="page-header page-header-small ">
            <parallax
                class="page-header-image post-and-tutorial"
                :style="{'background-image': 'url(' + $getImgUrlSite('bg14.jpg') +')'}" 
            />
            <div class="content-center">
                <h1 class="title">
                POST
                </h1> 
            </div>
        </div>
        <div class="blogs-1">
            <div class="container">
                <div class="row">
                    <div class="col-9 m-auto">
                        <h6 class="category" style="line-height: 2; color: #5D6176">
                            <b-icon icon="pie-chart-fill"></b-icon> {{post.category_post_name}}
                        </h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-9 m-auto">
                        <h2 class="title" style="font-size: 36px;">
                            {{post.title}}
                        </h2>
                        <h3 style="font-size: 14px;">
                            {{post.subtitle}}
                        </h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 m-auto" style="margin-left: 40px">
                        <p style="line-height: 2; font-size: 12px;"><b>{{post.createdAt | formatDate}}</b></p>
                    </div>
                    <b-button class="buttonImage" style="margin-right: 160px;" @click="deleteImage(post.image.url)">Quitar imagen</b-button>
                </div>
                <div class="row">
                    <div class="col-9 m-auto">
                        <template v-if="post.image != null && isVideo(post.image.url)">
                            <video
                                class="img img-raised rounded"
                                controls
                                style="max-width: 100%;min-width: 100%"
                            >
                                <source :src="getVideoUrl(post.image.url)" type="video/mp4">
                            </video>
                        </template>
                        <template v-else>
                            <template v-if="post.image">
                                <img style="min-width: 100%; max-height: 500px;" class="img img-raised rounded"  :alt="post.image.description" :src="getImgUrl(post.image.url)">
                            </template>
                            <template v-else> 
                                <img  v-if="post.external_image == null" style="min-width: 100%; max-height: 500px;" class="img img-raised rounded"  alt="Image default" :src="$getImgUrlSite('blurred-image-1.jpg')">
                                <img  v-else class="img img-raised rounded" style="min-width: 100%; max-height: 500px;"  :alt="post.external_image_description" :src="post.external_image">
                            </template>
                        </template>
                    </div>
                </div>
                <div class="row mt-5"> 
                    <div class="col-9 m-auto" >
                        <template v-if="post.content">
                            <p style="font-size: 15px;"> <i class="now-ui-icons tech_watch-time" />  <b>{{ calcularTiempoLectura(post.content)}} min (Estimated reading time)</b></p>
                        </template>
                    </div>
                </div>
                <div class="row"> 
                    <div class="col-9 m-auto">
                        <hr class="row-divider"/>
                    </div>
                </div>
                <div class="row"> 
                    <div class="col-9 m-auto content-post" >
                        <p class="mt-3 text-justify" style="font-size: 13px;" v-html="modifiedContent"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Card } from '@/components';
import BlogDataService from '../../services/BlogDataService';

export default {
    components: {
    Card
    },
    data() {
    return {
        post: {},
        modifiedContent: '',
        exited: false,
    }
    },
    created () {
        this.getData()
        
    },
    props: ['category', 'title_slug', 'from_page'],
    beforeRouteLeave(to, from, next) {
        console.log(to)
        if(!this.exited){
            if(to.path == '/explore'){
                this.exited = true
                this.$router.push({name: 'explore', params: {page: this.$route.params.from_page}})
            }  
            else if (to.path == '/posts-landing'){
                this.exited = true
                this.$router.push({name: 'PostsLanding', params: {page: this.$route.params.from_page}})
            }
            else{
                this.exited = true
                next() 
            }
                 
        }
        else{
            next()
        }

        
    },
    methods: {
        deleteImage() {
            BlogDataService.deletePostImage(this.post.image.url).then(
                Response => {
                        console.log(Response.data)
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                    })
        },
        isVideo(url) {
            const videoExtensions = ['mp4', 'webm', 'ogg']; // Agrega las extensiones de video que deseas comprobar
            const extension = url.split('.').pop();
            return(videoExtensions.includes(extension.toLowerCase()))
        },
        getVideoUrl(url) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${url}?withheaders=yes`
            // try {
            //     if(url){
            //         return require('../../assets/multimedia/blog/images/' + url);
            //     }
            // } catch {
            //     console.log('error loading video')
            // }
            // Construye la ruta de archivo local para el video
        },
        getData() {
            BlogDataService.findOneBySlug(this.$route.params.title_slug).then(
                Response => {
                        console.log(Response.data)
                        this.post = Response.data.data
                        this.modifiedContent = this.post.content.replace(/<div class="spanblock">/g, '');
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                    })
        },
        calcularTiempoLectura (contenido) {
            const velocidadLectura = 300;
            const caracteresPorPalabra = 5;
            const palabras = Math.round(contenido.length / caracteresPorPalabra);
            const tiempoLectura = Math.ceil(palabras / velocidadLectura);
            return tiempoLectura;
        },
        getImgUrl (name) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${name}?withheaders=yes`
            //return 'https://p-ncm-public.s3.eu-south-2.amazonaws.com/' + name
            /*try{
                if(name){
                    var images = require.context('../../assets/multimedia/blog/images', false)
                    console.log(images('./' + name))
                    return images('./' + name)
                }
            }catch{
                console.log('error loading image')
            }*/
        },
        
    }
    // computed: {
    //     publicContent() {
    //         // Remover la clase "spanblock" del contenido público
    //         const modifiedContent = this.post.content.replace('<div class="spanblock">', '');
    //         return modifiedContent;
    //     },
    // },
    
}
</script>
<style scoped>
.page-header.page-header-small {
    min-height: 32vh !important;
    max-height: 440px;
}
.row-divider {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1); /* Color de la línea y opacidad */
  margin: 10px 0; /* Espacio antes y después de la línea */
}

</style>