import { render, staticRenderFns } from "./Testimonials.vue?vue&type=template&id=04e0fc2a&scoped=true"
import script from "./Testimonials.vue?vue&type=script&lang=js"
export * from "./Testimonials.vue?vue&type=script&lang=js"
import style0 from "./Testimonials.vue?vue&type=style&index=0&id=04e0fc2a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04e0fc2a",
  null
  
)

export default component.exports