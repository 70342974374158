import http from '../http-common'
import authHeader from './AuthHeaderService'

const API_URL_PATH = 'api/language/'

class LanguageDataService {

  findAll () {
    return http.get(API_URL_PATH + 'find-all')
  }

  create (language) {
    return http.post(API_URL_PATH + 'create', { language: language },{headers: authHeader()})
  }

  update (language) {
    return http.put(API_URL_PATH + 'update', { id: language.id, language: language },{headers: authHeader()})
  }

  delete (id) {
    return http.delete(API_URL_PATH + 'delete?id=' + id, {headers: authHeader()})
  }

}

export default new LanguageDataService()
