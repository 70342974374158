<template>
    <div class="wrapper-home  collapsed-sidebar" style="margin-top: 5%; max-width: 99%;">
        <div v-if="loading" id="toHidden" style="
                                position: absolute;
                                top     :0;
                                left    : 0;
                                height  : 100%;
                                width   :  100%;
                                background-color:azure;
                                z-index : 1000;
                                opacity : 0.6;">
        </div>
        <b-icon v-if="loading" style="
        z-index: 2000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        " icon="three-dots" animation="cylon" font-scale="4">
        </b-icon>
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                        {{$t('UsersManagement.Title')}}
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <card class="card-h-100">
                        <b-row>
                            <b-col cols="4" style="float: left; display: flex;">
                                <span class="mt-auto mb-auto">
                                    <h6 class=" mb-0">{{$t('UsersManagement.Members')}}</h6>
                                </span>
                            </b-col>
                            <b-col>
                                <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right;">
                                    <b-button-group class="mx-1">
                                        <n-button id="newAdmin" class="btn-simple ncm-btn"
                                            style="font-weight: 800 !important;" target="_blank"
                                            @click.native="modals.invite = true">
                                            <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                                            {{$t('UsersManagement.Invite')}}
                                        </n-button>
                                    </b-button-group>
                                </b-button-toolbar>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table style=" max-height: 550px; overflow-x: auto;" ref="projectTable"
                                    id="projectTable" :fields="headersUsers" :items="getUsers" select-mode="single"
                                    responsive="sm" selectable @row-selected="selectRow" @row-clicked="handleRowClicked"
                                    @sort-changed="sortingChanged" show-empty :current-page="currentPage"
                                    :per-page="perPage" :sort-desc="true">
                                    <template #empty="">
                                        {{$t('UsersManagement.Empty')}}
                                    </template>
                                    <template #cell(createdAt)="data">
                                        {{ data.item.createdAt | formatDate }}
                                    </template>
                                    <template #cell(phone)="data">
                                        <b-button
                                            v-if="data.item.phone != null && data.item.phone != undefined && data.item.phone != ''"
                                            @click="copyToClipboard(data.item.phone)" class="action-table"
                                            v-b-tooltip.hover.viewport :title=data.item.phone>
                                            <b-icon icon="telephone"></b-icon>
                                        </b-button>
                                        <b-button v-else class="action-table">
                                            <p style=" margin: 0">N/A</p>
                                        </b-button>
                                    </template>
                                    <template #cell(email)="data">
                                        <b-button v-if="data.item.email != null" class="action-table"
                                            v-b-tooltip.hover.viewport @click="copyToClipboard(data.item.email)"
                                            :title=data.item.email>
                                            <b-icon icon="mailbox"></b-icon>
                                        </b-button>
                                        <b-button v-else class="action-table">
                                            <p style=" margin: 0">N/A</p>
                                        </b-button>
                                    </template>
                                    <template #cell(roles)="data">
                                        <select class="form-select" aria-label="Default select example"
                                            style="min-width: 150px !important">
                                            <option selected disabled>Roles</option>
                                            <template v-for="(role, i) in data.item.roles">
                                                <option v-if="role != null" :key="i" disabled>{{ role }}</option>
                                            </template>
                                        </select>
                                    </template>
                                    <template #row-details="">
                                        <b-card class="row-details-card">
                                            <div v-if="selectedElement" class="info-container">
                                                <b-row>
                                                    <b-col>
                                                        <h5>{{ $t('UserHistory.Title') }}</h5>
                                                        <b-table ref="usersTable" id="usersTable"
                                                            :fields="headersUserHistory"
                                                            :items="dataToShowDetails" responsive="sm" show-empty>
                                                            <template #empty="">
                                                                No info
                                                            </template>

                                                            <template #cell(start_date)="data">
                                                                {{ data.item.start_date | formatDate }}
                                                            </template>
                                                            <template #cell(finish_date)="data">
                                                                {{ formattedFinishDate(data.item.finish_date) }}
                                                            </template>
                                                            <template #cell(user_role)="data">
                                                                {{ data.item.user_role ? data.item.user_role : 'No role' }}
                                                            </template>
                                                            <!-- <template #cell(project)="data">
                                                                {{ data.item.project ? data.item.project : 'No project' }}
                                                            </template> -->
                                                        </b-table>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-card>
                                    </template>
                                </b-table>
                                <div class="pagination-container">
                                    <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                                    </b-pagination>
                                    <b-form-select v-model="perPage" class="pagination-select">
                                        <b-form-select-option :value="5">5</b-form-select-option>
                                        <b-form-select-option :value="10">10</b-form-select-option>
                                        <b-form-select-option :value="20">20</b-form-select-option>
                                        <b-form-select-option :value="users.length">{{ $t('UsersManagement.All')
                                            }}</b-form-select-option>
                                    </b-form-select>
                                </div>
                            </b-col>
                        </b-row>
                    </card>
                </b-col>
            </b-row>
        </b-container>
        <modal :show.sync="modals.invite" header-classes="justify-content-center" class="custom-width-modal">
            <h4 style="display: flex; justify-content: center; align-items: center;">
                <b>{{$t('UsersManagement.Modals.Invite.Title')}}</b>
            </h4>
            <b-form-group label=" Email *" label-for="input-email_inv" style="margin-left: 270px;">
                <b-form-input v-model="email_inv" id="input-email_inv" name="input-email_inv"
                    v-validate="{ required: true, email: true}" :state="validateState('input-email_inv')"
                    aria-describedby="input-email_inv-live-feedback" size="sm" placeholder=" Email"
                    style="max-width: 400px;"></b-form-input>
                <b-form-invalid-feedback id="input-email_inv-live-feedback">{{ veeErrors.first('input-email_inv')
                    }}</b-form-invalid-feedback>
            </b-form-group>
            <div class="col-12">
                <div class="col-6" style="float: left;">
                    <card
                        style="border-radius: 20px; padding: 15px; display: flex; justify-content: center; align-items: center;">
                        <p slot="header" style="font-size: 1.2em;">
                            <b>
                                {{$t('UsersManagement.Modals.Invite.Title1')}}
                            </b>
                        </p>
                        <ul style="font-size: 0.8em;">
                            <li> {{$t('UsersManagement.Modals.Invite.TextNOT1')}}</li>
                            <li>{{$t('UsersManagement.Modals.Invite.TextNOT2')}} </li>
                            <li> {{$t('UsersManagement.Modals.Invite.TextNOT3')}}</li>
                        </ul>
                        <!-- <p style="font-size: 0.8em;"> 
                                {{$t('UsersManagement.Modals.Invite.TextNOT1')}}
                            </p>
                            <p style="font-size: 0.8em;"> 
                                {{$t('UsersManagement.Modals.Invite.TextNOT2')}}
                            </p>
                            <p style="font-size: 0.8em;"> 
                                {{$t('UsersManagement.Modals.Invite.TextNOT3')}}
                            </p> -->
                    </card>
                </div>
                <div class="col-6" style="float: right;">
                    <card style="border-radius: 20px; padding: 15px;">
                        <p slot="header"
                            style="font-size: 1.2em; display: flex; justify-content: center; align-items: center;">
                            <b>
                                {{$t('UsersManagement.Modals.Invite.Title2')}}
                            </b>
                        </p>
                        <ul style="font-size: 0.8em;">
                            <li>{{$t('UsersManagement.Modals.Invite.TextYES1')}}</li>
                            <li>{{$t('UsersManagement.Modals.Invite.TextYES2')}}</li>
                            <li>{{$t('UsersManagement.Modals.Invite.TextYES3')}}</li>
                        </ul>
                        <!-- <p style="font-size: 0.8em;">
                                {{$t('UsersManagement.Modals.Invite.TextYES1')}}
                            </p>
                            <p style="font-size: 0.8em;">
                                {{$t('UsersManagement.Modals.Invite.TextYES2')}}
                            </p>
                            <p style="font-size: 0.8em;">
                                {{$t('UsersManagement.Modals.Invite.TextYES3')}}
                            </p> -->
                    </card>
                </div>
            </div>
            <template slot="footer">
                <n-button class="btn-simple ncm-btn" @click.native="sendInv()">
                    {{$t('UsersManagement.Modals.Invite.Send')}}
                </n-button>
                <n-button class="btn-simple ncm-btn-danger" style="float: right;" @click.native="modals.invite = false">
                    {{ $t("DashBoardStaff.Modals.AssignRole.Footer.Close") }}
                </n-button>
            </template>
        </modal>
        <modal v-show="modals.Renewal" :show.sync="modals.Renewal" header-classes="justify-content-center">
            <h4 slot="header" class="title title-up">
                {{$t("Renewal")}}
            </h4>
            <card v-if="tokensValue" style="background-color: #5D6176; border-radius: 10px;">
                <b-row style="color: white; font-weight: 600;">
                    <b-col>
                        {{ $t('Wallet.WalletBalance') }}
                    </b-col>
                </b-row>
                <b-row style="color: rgb(255, 255, 255);  font-weight: 600;">
                    <b-col>
                        <h4 class="m-0" style="font-weight:500"><b-icon icon="coin"></b-icon>{{tokensValue}} → <span
                                style=" font-weight: 400; color: #c5c5c5;"><b-icon icon="coin"></b-icon>{{ tokensValue -
                                100}}</span> </h4>
                    </b-col>
                </b-row>
            </card>
            <b-row class="mt-3">
                <b-col>
                    <p>{{ $t("MsgRenewal2") }}</p>
                </b-col>
            </b-row>
            <template slot="footer">
                <n-button class="btn-simple ncm-btn" @click="renewalQuote">
                    {{ $t("DashBoardStaff.Modals.DeleteRole.Footer.Save") }}
                </n-button>
                <n-button class="btn-simple ncm-btn-danger" @click.native="modals.Renewal = false">
                    {{ $t("Parameters.Modals.Create.Footer.Close") }}
                </n-button>
            </template>
        </modal>
    </div>
</template>
<script>
import WalletDataServices from '../../services/WalletDataServices';

import { FormGroupInput, Button, InfoSection,Modal, Card, Tabs, TabPane, Checkbox, DropDown} from '@/components';
import InvitatonDataServices from '../../services/InvitationDataService'
import OrganizationsDataService from '../../services/OrganizationsDataService'
import {Table, TableColumn, Tooltip} from 'element-ui';
import moment from 'moment'
export default {
    components : {
        Card,
        Modal,
        [Checkbox.name]: Checkbox,
        [DropDown.name]: DropDown,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Tooltip.name]: Tooltip,
        [FormGroupInput.name]: FormGroupInput,
    },
    data() {
        return {
            // userLS: null,
            allOpenRows: [],
            dataWallet: [],
            auxOrganizationID: -99,
            loading: false,
            modals: {
                invite: false,
                Renewal: false
            },
            currentPage: 1,
            perPage: 10,
            currentPage2: 1,
            organization_id: null,
            role_id: null,
            perPage2: 10,
            visibilityFilter: null,
            user: {},
            email_inv: null,
            users: [],
            inv: [],
            invitations: [],
            filteredArrayInvitations: [],
            projects: [],
            headersUsers: [
                { key: 'name', sortable: true, label: this.$t('DashBoardStaff.Table.Name'), tdClass: 'table-title', thClass: 'table-title' }, 
                { key: 'surname', sortable: true, label: this.$t('DashBoardStaff.Table.Surname') },
                { key: 'roles', sortable: true, label: this.$t('DashBoardStaff.Table.Role') },
                { key: 'createdAt', sortable: true, label: this.$t('MemberUntil') },
                { key: 'organization_id_for_automatic_renewal.name', sortable: true, label: this.$t('AutomaticalRenewal') },
                { key: 'phone', label: this.$t('DashBoardStaff.Table.Phone') },
                { key: 'email', label: this.$t('DashBoardStaff.Table.Email') },
                { key: 'actions', label: this.$t('PayQuote') },
            ],
            headers:[
                { key:'project.name',label:'Project', sortable: true },
                { key:'organization.name',label:'Organization', sortable: true },
                { key:'user_role',label:'Role' },
                { key:'is_active',label:'Active' },
                { key:'start_date',label:'Start at', sortable: true },
                { key:'finish_date',label:'Finished at', sortable: true },
            ],
            headersUserHistory:[
                { key:'start_date',label:'Start date', sortable: true },
                { key:'finish_date',label:'Finish date' , sortable: true},
                { key: 'user_role', label: 'Role', sortable: true },
                { key:'project.name',label:'Project', sortable: true},
            ],
            headersInvitations: [
                { key: 'email', label:'Destination'},
                { key: 'status', label:'Status'},
                // { key: 'reason', label:'Reason'},
                { key: 'expirationDate', label: 'Expiration date' },
            ],
            // auxUsers: [],
            auxOrganizationLS: [],
            selectedElement: null,
            dataToShowDetails: [],
        }
    },
    computed: {
        formattedFinishDate() {
            return function(date) {
            return date ? this.$options.filters.formatDate(date) : 'No finish date';
            }
        },
        getUsers(){
            // let userFormat = users;
            let rolesT= null;
            this.users.forEach((user) => {
                rolesT = user.roles.map(role => {
                    if(role.user_role == "project_manager"){
                        role.user_role = "project manager"
                    }
                    return role.user_role
                });
                user.roles = rolesT
            });
            return this.users
        },
        onUpdate() {
            this.$refs.userTable.refresh();
            this.$forceUpdate();
        },
        totalPages () {
            return this.users.length
        },
        totalPages2 () {
            return this.invitations.length
        },
        filteredInvitations() {
            if (this.visibilityFilter === null || this.visibilityFilter === 'null') {
                // console.log("Estoy imprimiendo...", this.invitations);
                return this.invitations; // Return all invitations if no status is selected
            }else if(this.visibilityFilter === 'complete'){
                // console.log(this.invitations);
                // this.invitations.forEach(element => {
                //     if (element.invitation.is_complete) {
                //         auxInvitations.push(element)
                //     }
                // });
                return this.invitations.filter(item =>(item.invitation.is_complete === true && item.invitation.is_rejected === false));
            }else if(this.visibilityFilter === 'registered'){
                return this.invitations.filter(item =>(item.invitation.is_complete === false && item.invitation.is_rejected === false));
            }else if(this.visibilityFilter === 'rejected'){
                return this.invitations.filter(item =>(item.invitation.is_complete === false && item.invitation.is_rejected === true));
            }
            // return this.invitations.filter(invitation => invitation.status === this.visibilityFilter);
        },
    },
    watch: {
        $orgSelected(newValue){
            this.organization_id = newValue.id
            this.getData()
        },
        $tokensValue(newValue){
            console.log('tokens value changed! desde el nav ', newValue)
            this.tokensValue = newValue
        },
        $roleSelected(newValue){
            this.role_id = newValue
            this.getData()
        },
    },
    created () {
        if (this.$roleSelected) this.role_id = this.$roleSelected
        if (this.$tokensValue) this.tokensValue = this.$tokensValue
        console.log('Tokens value', this.tokensValue)
        this.organization_id = this.$orgSelected.id
        console.log('Org selected!', this.organization_id)
        this.getData()
        this.auxOrganizationID = JSON.parse(localStorage.getItem("user")).admin.organization_id
        this.auxOrganizationLS = JSON.parse(localStorage.getItem("user")).admin
        
    },
    // beforeUpdate(){
    //     if (this.auxOrganizationID != JSON.parse(localStorage.getItem("user")).admin.organization_id) { 
    //         this.auxOrganizationID = JSON.parse(localStorage.getItem("user")).admin.organization_id
    //         console.log("kikiki", this.auxOrganizationID);
    //     }
    // },
    // updated(){
    //     if (this.auxOrganizationLS.organization_id != JSON.parse(localStorage.getItem("user")).admin.organization_id) {
    //         this.getData()
    //         this.auxOrganizationLS = JSON.parse(localStorage.getItem("user")).admin
    //     }
    // },
    methods: {
        openPayQuote(item) {
            this.selectedElement = item
            this.modals.Renewal = true
        }
        ,
        renewalQuote() {
            let user = JSON.parse(localStorage.getItem("user"));
            let user_id  = user.data.id
          WalletDataServices.renewalQuote(user_id, this.organization_id, this.role_id, this.selectedElement.id).then(
            Response => {
              this.$swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Renewal quote sent successfully'
              })
              this.modals.Renewal = false
              this.getData()
            },
            Error => {
              this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: Error.response.data.message
              })
            }
          )
        },
        isNearExpiry(date) {
            let expiryDate = new Date(date);
            let currentDate = new Date();
            let oneWeekFromNow = new Date();
            oneWeekFromNow.setDate(currentDate.getDate() + 7);
            return expiryDate <= oneWeekFromNow && expiryDate >= currentDate;
        },
        rowClassProvider(item) {
            // Lógica para determinar la clase de la fila
            if (this.isNearExpiryNow(this.addYearsToDate(item.createdAt, 1))) {
                return 'row-expiring'; // Clase CSS para fila expirando
            }
            return ''; // Sin clase adicional
        },
        isNearExpiryNow(date) {
            let expiryDate = new Date(date);
            let currentDate = new Date();
            return expiryDate < currentDate;
        },
        addYearsToDate(date, years) {
            let newDate = new Date(date);
            newDate.setFullYear(newDate.getFullYear() + years);
            return newDate;
        },
        formatDate(date) {
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return `${day}-${month}-${year}`;
        },
        async handleRowClicked (item, index) { 
            // if (this.isNearExpiryNow(addYearsToDate(item.createdAt, 1))) {
            //     return; // Evitar acción si la fila está cerca de expirar
            // }
                this.allOpenRows.map((ele) => {
                    if (ele.id !== item.id && ele._showDetails) {
                    this.$set(ele, '_showDetails', !ele._showDetails)
                    }
                })
                this.allOpenRows = []
                this.selectedElement = item
                await OrganizationsDataService.finDataMember(this.selectedElement.id, this.$orgSelected.id).then(
                    Response => {
                        this.dataToShowDetails = Response.data.data
                        console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', this.dataToShowDetails)
                        this.$set(item, '_showDetails', !item._showDetails)
                        this.allOpenRows.push(item)
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                    }
                )
                // await OrganizationsDataService.findRolesByOrg(this.organization_id, this.projectSelected).then(
                //     Response => {
                //         this.dataToShowDetails = Response.data.data
                //         console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', this.dataToShowDetails)
                //         this.$set(item, '_showDetails', !item._showDetails)
                //         this.allOpenRows.push(item)
                //     },
                //     Error => {
                //         this.$swal.fire({
                //             icon: 'error',
                //             title: 'Error',
                //             text: Error.response.data.message
                //         })
                //     }
                // )
               
        },
        copyToClipboard(text) {
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            this.$swal.fire({
                icon: 'success',
                text: this.$t('Ecosystems.Copied')
            }) 
        },
        applyRowClass(row) {
            if (row != null) {
                if (row.invitation.is_rejected) {
                return 'disabled-row';
                }  else {
                    return '';
                }
            }
        },
        selectRow (items) {
            this.selectedElement = items[0]
        },
        sortingChanged(item){
            this.allOpenRows.map((ele) => {
                if (ele.id !== item.id && ele._showDetails) {
                this.$set(ele, '_showDetails', !ele._showDetails)
                }
            })
        },
        // handleRowClicked (item) {
        //     console.log(this.role)
        //     this.allOpenRows.map((ele) => {
        //         if (ele.id !== item.id && ele._showDetails) {
        //         this.$set(ele, '_showDetails', !ele._showDetails)
        //         }
        //     })
        //     this.allOpenRows = []
        //     this.$set(item, '_showDetails', !item._showDetails)
        //     this.allOpenRows.push(item)
        //     this.selectedElement = item
        // },
        getData () {
            if (this.organization_id) {
            const user = JSON.parse(localStorage.getItem("user"));
            InvitatonDataServices.findAll(this.organization_id).then(
                Response => {
                    this.invitations = []
                    this.inv = Response.data.data
                    let invitations_complete = this.inv.complete
                    let invitations_pending = this.inv.pending
                    let invitations_rejected = this.inv.rejected
                    for (let inv of invitations_complete) {
                        var date = inv.token.split('_')[1]
                        var date_obj = new Date()
                        date_obj.setUTCSeconds(date);
                        var date_formated = moment(String(date_obj)).format('DD/MM/YYYY')
                        inv.expirationDate = date_formated
                        this.invitations.push({invitation: inv})
                    }
                    for (let inv of invitations_pending) {
                        var date = inv.token.split('_')[1]
                        var date_obj = new Date()
                        date_obj.setUTCSeconds(date);
                        var date_formated = moment(String(date_obj)).format('DD/MM/YYYY')
                        inv.expirationDate = date_formated
                        this.invitations.push({invitation: inv})
                    }
                    for (let inv of invitations_rejected) {
                        var date = inv.token.split('_')[1]
                        var date_obj = new Date()
                        date_obj.setUTCSeconds(date);
                        var date_formated = moment(String(date_obj)).format('DD/MM/YYYY')
                        inv.expirationDate = date_formated
                        this.invitations.push({invitation: inv})
                    }                    
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
            const users = JSON.parse(localStorage.getItem("user"));
            OrganizationsDataService.findByOrganizationId(this.organization_id).then(
                Response => {
                    this.users = Response.data.data  
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
            /*ExternalUserDataService.getUserRole(newOrgFav, this.user_id).then(
                Response => {
                    this.roles = Response.data.data
                    const favoriteRole = this.roles.find(role => role.is_favourite === true)
                    this.role_fav = favoriteRole ? favoriteRole.user_role : null
                    console.log(this.role_fav)
                    console.log(this.roles)
                },
                Error => {
                    console.log('Error al recuperar los roles del user en la org', Error)
                }
            )*/
            }
            
        },
        sendInv() {
            this.$validator.validateAll(['input-email_inv']).then(result => {
                if (!result) {
                    return
                }
                this.loading = true;
                this.modals.invite = false
                // Llamada a endpoint de invitar a external user 
                this.auxOrganizationID = JSON.parse(localStorage.getItem("user")).admin.organization_id
                InvitatonDataServices.invite(this.email_inv, this.organization_id).then(
                    Response => {
                        if (Response.data.email_exist) {
                                this.$swal.fire({
                                icon: 'success',
                                title: this.$t('Alerts.InvitationSent'),
                                text: this.$t('Alerts.AcceptOrDecline')
                            }).then(() => {
                                // this.modals.invite = false
                                this.getData()
                            });
                        }
                        // else if (['input-email_inv'] == this.users.email){
                        //     this.$swal.fire({
                        //         icon: 'error',
                        //         title: "This user is already in the organization",
                        //         text: "You can't invite a member that already exists"
                        //     }).then(() => {
                        //         // this.modals.invite = false
                        //         this.getData()
                        //     });
                        // }
                        else {
                            this.$swal.fire({
                                icon: 'success',
                                title: this.$t('Alerts.InvitationSent'),
                                text: this.$t('Alerts.PriceSubtracted')
                            }).then(() => {
                                // this.modals.invite = false
                                this.getData()
                            });
                        }
                        this.loading = false;
                    },
                    Error => {
                        this.loading = false
                        this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Alerts.ErrorSendingInvitation')
                    }).then(() => {
                        // this.$router.push('/')
                    })
                    this.loading = false;
                    }
                )
                // this.$router.push('/signup-invite')
            })
        },
        validateState (ref) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
            return !this.veeErrors.has(ref)
        }
        return null
        }
    }
    
}
</script>
<style>
.disabled-row {
  background-color: #f8f8f8;
  color: #999999;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.card-h-100 {
    height: 100%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #5D6176;
  background-color: #5D6176;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  border-color: #5D6176;
}
.custom-width-modal .modal-dialog {
    max-width: 1000px;
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
.highlight-expiry {
  background-color: #8d9f04 !important;
  border: 2px solid #b6cd04 !important; 
  border-radius: 5px;
  padding: 5px; 
  font-weight: bold; 
  color: #ffffff; 
}
.highlight-expiry-now {
  background-color: #c30511 !important;
  border: 2px solid #da3c47 !important; 
  border-radius: 5px;
  padding: 5px; 
  font-weight: bold; 
  color: #ffffff; 
}
.wallet-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  color: #ffffff;
  background-color: #4d4c4c; 
  border: 2px solid #d2cfcf; 
  border-radius: 5px; 
}   
.row-expiring {
  cursor: not-allowed !important; 
  background-color: rgb(249, 165, 165);
  opacity: 0.5
}
</style>