<template>
  <footer
    class="footer"
    :class="{[`footer-${type}`]: type}"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div class="container">
        <nav>
          <ul style="margin-top: 24px;">
            <li class="ml-3">
              <ul>
                <router-link 
                tag="li" 
                class-active="active" 
                to="/cookies"
              > 
                {{ $t("Footer.Cookies") }}
              </router-link>
              </ul>
            </li>
            <li class="ml-3">
              <ul>
                <router-link 
                tag="li" 
                class-active="active" 
                to="/privacy"
              > 
                {{ $t("Footer.Privacy") }}
              </router-link>
              </ul>
            </li>
            <li class="ml-3">
              <ul>
                <router-link 
                tag="li" 
                class-active="active" 
                to="/data-policy" 
              >
                {{ $t("Footer.Data") }}
              </router-link>
              </ul>
            </li>
          </ul>
        </nav>
        
        <div class="ml-2">
          <b-row>
            <b-col class="d-flex">
              <a href="https://usal.es/" style="margin: auto; cursor: pointer" target="_blank">
                <img 
                :src="$getImgUrlSite('SpinOffUSAL-gris-mucho-menor.png')"
                alt="logo"
                >
              </a>
            </b-col>
            <b-col>
              <b-row style="font-size: 13px;">
                {{ $t("Footer.Logo") }}
              </b-row>
              <b-row>
                  <img 
                  :src="$getImgUrlSite('Logos-2048x477.jpg')"
                  alt="Footer"
                  >
              </b-row>
            </b-col>
            
          </b-row>
          </div>
        <div style="float: right; margin-right: 5%;" >
          

        </div>
        
            
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    props: {
      backgroundColor: String,
      type: String
    },
    data(){
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>

<style>
 nav li:hover,
 nav li.router-link-active,
 nav li.router-link-exact-active {
   color: rgb(159, 157, 157);
   cursor: pointer;
 }
</style>

