import http from '../http-common'
import authHeader from './AuthHeaderService'
const API_URL_PATH = 'api/organization/'


class OrganizationsDataService {
    findAll () {
        return http.get(API_URL_PATH + 'find-organizations', {headers: authHeader()})
    }
    findAllDataCards (org_id) {
        return http.get(API_URL_PATH + 'find-data-cards?org_id='+ org_id, {headers: authHeader()})
    }
    // create (data) {
    //     const body = {
    //       name: data.name,
    //       description: data.description,
    //       vat: data.vat,
    //       address1: data.address1,
    //       phone: data.phone,
    //       country: data.country
    //     }
    //     //console.log(authHeader());
    //     return http.post(API_URL_PATH + 'create-organization', body,  {headers: authHeader()})
    // }
    updateTokens (data) {
        const body = {
            organization_id: data.organization_id,  
            wallet_value: data.wallet_value,
        }
        return http.put(API_URL_PATH + 'update-tokens', body,  {headers: authHeader()})
    }
    delete (organization_id) {
        return http.delete(API_URL_PATH + 'delete?organization_id=' + organization_id,  {headers: authHeader()})
    }
    
    update (data) {
        return http.put(API_URL_PATH + 'update-organization', data,  {headers: authHeader()})
    }

    updateAlias (data) {
        return http.put(API_URL_PATH + 'update-organization-alias', data,  {headers: authHeader()})
    }
    create(data){
        const body = {
            user_id: data.user_id,
            name: data.name,
            description: data.description,
            vat: data.vat,
            address1: data.address1,
            country: data.country
        }
        return http.post(API_URL_PATH + 'create-organization-admin', body, { headers: authHeader() })
    }
    findByOrganizationId(organization_id, all=true){
        const body = {organization_id: organization_id}
        return http.get(API_URL_PATH + 'find-users?id=' + organization_id + '&all=' + all,{ headers: authHeader() })
    }
    getOrganizationsFromAUser(user_id){
        const body = {user_id: user_id}
        return http.get(API_URL_PATH + 'find-orgs-by-user?id=' + user_id,{ headers: authHeader() })
    }
    exitOrganization(user_id, organization_id){
        return http.delete(API_URL_PATH + 'delete-user-from-org?user_id='+ user_id + '&organization_id=' + organization_id, { headers: authHeader() })
    }

    findRolesByOrg(organization_id, project){
        const body = {organization_id: organization_id, project: project}
        if (project == null){
            return http.get(API_URL_PATH + 'find-org-role?id=' + body.organization_id, { headers: authHeader() })
        }
        return http.get(API_URL_PATH + 'find-org-role?id=' + body.organization_id + '&project=' + body.project,{ headers: authHeader() }) 
    }

    finDataMember(user_id, organization_id){
        const body = {user_id: user_id, organization_id: organization_id}
        return http.get(API_URL_PATH + 'find-user-role?id=' + body.user_id + '&organization_id=' + body.organization_id,{ headers: authHeader() })
    }

    updateAvatar(formData, original=false){
        var headers = authHeader()
        headers['Content-Type'] = 'multipart/form-data'
        if(!original){
            return http.put(API_URL_PATH + 'update-avatar-alias', formData, {
                headers: headers
            })
        }
        else{
            return http.put(API_URL_PATH + 'update-avatar', formData, {
                headers: headers
            })
        }
        
    }
    disable(organization_id){
        const body = {organization_id: organization_id}
        return http.put(API_URL_PATH + 'disable-organization', body, {headers: authHeader()})
    }   
    enable(organization_id) {
        const body = {organization_id: organization_id}
        return http.put(API_URL_PATH + 'enable-organization', body, {headers: authHeader()})
    }
}
export default new OrganizationsDataService()