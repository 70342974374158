import AuthService from "../services/Login";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

  export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
      loginStaff({ commit }, user) {
        return AuthService.login(user).then(
          (user) => {
            commit("loginSuccess", user);
            return Promise.resolve(user);
          },
          (error) => {
            commit("loginFailure");
            return Promise.reject(error);
          }
        );
      }
    },
    mutations: {
        loginSuccess(state, user) {
          state.status.loggedIn = true;
          state.user = user;
        },
        loginFailure(state) {
          state.status.loggedIn = false;
          state.user = null;
        },
        logout(state) {
          state.status.loggedIn = false;
          state.user = null;
        },
        registerSuccess(state) {
          state.status.loggedIn = false;
        },
        registerFailure(state) {
          state.status.loggedIn = false;
        },
    }
}