<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar">
        <div v-if="loading" id="toHidden"
            style="
            position: absolute;
            top     :0;
            left    : 0;
            height  : 100%;
            width   :  100%;
            background-color:azure;
            z-index : 1060;
            opacity : 0.6;"
            >
        </div>
        <b-icon  v-if="loading" style="
            z-index: 2000;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            "
            icon="three-dots"
            animation="cylon"
            font-scale="4">
        </b-icon>
      <b-container class="container" style="margin-top: 5%; max-width: 94%;">
        <b-row class="page-header-bs">
          <b-col style="display: flex">
            <h2 class="card-title">
              {{ $t('Languages.MapLayers') }}
            </h2> 
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <card>
              <b-row>
                <b-col>
                  <h6 class="titleMap">{{ $t('Languages.LayersList') }}</h6>
                </b-col>
                <!-- <b-col> -->
                  <!-- <input type="search" placeholder="Search" class="form-control " style="width:200px; max-height: 50px; float: right;" v-model="filter"> -->
  
                  <!-- <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right;">
                      <b-button-group class="mx-1">
                          <div class="col m-auto">
                              <div style="margin-top: 27px;">
                                  <b-form-radio-group v-model="visibilityFilter" class="d-flex">
                                      <b-form-radio value="null" class="custom-radio"><p style="font-size: 12px;margin-top: 3px;">{{ $t("ExternalOrganizations.All") }}</p></b-form-radio>
                                      <b-form-radio value="active" class="custom-radio"><p style="font-size: 12px;margin-top: 3px;">{{ $t("ExternalOrganizations.Active") }}</p></b-form-radio>
                                      <b-form-radio value="inactive" class="custom-radio"><p style="font-size: 12px;margin-top: 3px;">{{ $t("ExternalOrganizations.Inactive") }}</p></b-form-radio>
                                  </b-form-radio-group>
                              </div>
                          </div>
                      </b-button-group>
                  </b-button-toolbar> -->
              <!-- </b-col> -->
              <b-col>
                  <b-button-toolbar class="buttonMap" key-nav aria-label="Toolbar with button groups" style="float: right">
                    <b-button-group class="mx-1">
                      <n-button id="newAdmin" class="btn-simple ncm-btn"
                          target="_blank" @click.native="openEditModal()">
                          <b-icon icon="cloud-arrow-up" class="button-icon"></b-icon>
                          {{ $t('Languages.UploadLayer') }}
                      </n-button>
                    </b-button-group>
                  </b-button-toolbar>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table ref="ecosystemTable" 
                      id="ecosystemTable"
                      class="dashboard-table"
                      :fields="headers" 
                      :items="layers"
                      select-mode="single"
                      show-empty
                      @row-selected="selectRow"
                      :current-page="currentPage"
                      :per-page="perPage"
                      responsive="sm"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      sort-by="createdAt"
                      :sort-desc="true"
                      @filtered="onFiltered"> 
  
                      <template #empty="">
                        No layers yet
                      </template>

                      <template #cell(is_active)="data">
                                <div v-if="data.item.is_active == true">
                                    <b-icon icon="check2"></b-icon>
                                </div>
                                <div v-else>
                                    <b-icon icon="x"></b-icon>
                                </div>
                            </template>

                      <template #cell(Abstract)="data">
                        <template v-if="data.item.Abstract">
                            <template v-if="data.item.Abstract.length > 50">
                                <span :title="data.item.Abstract">{{data.item.Abstract.substr( 0, 50) + '…' }}</span>
                            </template>
                            <template v-else>
                                {{data.item.Abstract}}
                            </template>
                        </template>
                    </template>

                      
                      <template #cell(options)="data">
                          <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Delete')" @click="openDeleteModal(data.item)">
                              <b-icon icon="trash"></b-icon>
                          </b-button>
                      </template>
                      
                      <template #cell(createdAt)="data">
                          {{ data.item.createdAt | formatDate }}
                      </template>
                      <template #cell(updatedAt)="data">
                          {{ data.item.updatedAt | formatDate }}
                      </template>
  
                      <!-- <template #row-details="row">
                          <b-card class="row-details-card">
                              <div v-if="selectedElement" class="info-container">
                                <b-row>
                                  <b-form-group :label="$t('Languages.Table.Label')" class="info-group" label-class="info-group-label">
                                      <p class="info-group-input">
                                        {{ selectedElement.name }}
                                      </p>
                                  </b-form-group>
                                </b-row>
                              </div>
                          </b-card>
                          
                      </template> -->
  
                  </b-table>
                  <div class="pagination-container">
                    <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                    </b-pagination>
                    <b-form-select v-model="perPage" class="pagination-select">
                        <b-form-select-option :value="5">5</b-form-select-option>
                        <b-form-select-option :value="10">10</b-form-select-option>
                        <b-form-select-option :value="20">20</b-form-select-option>
                        <b-form-select-option
                            :value="layers.length">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                    </b-form-select>
                  </div>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
      </b-container>
  
    <!-- Modal upload shapefile  -->
    <modal :show.sync="modals.upload" class="modal-upload" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        Upload layer
      </h4>
      <b-form-group label="Label *">
          <b-form-input
            id="input-label"
            name="input-label"
            v-validate="{ required: true}"
            :state="validateState('input-label')"
            aria-describedby="input-label-live-feedback"
            size="sm"
            placeholder="Label *"
            v-model="label_form"
          ></b-form-input>
          <b-form-invalid-feedback id="input-label-live-feedback">{{ veeErrors.first('input-label') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Projection *" v-if="prjMissing">
          <b-form-input
            id="input-projection"
            name="input-projection"
            v-validate="{ required: true}"
            :state="validateState('input-projection')"
            aria-describedby="input-projection-live-feedback"
            size="sm"
            placeholder="Projection *"
            v-model="projection_form"
          ></b-form-input>
          <b-form-invalid-feedback id="input-projection-live-feedback">{{ veeErrors.first('input-projection') }}</b-form-invalid-feedback>
      </b-form-group>
      <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-file-added="dropzoneAddedFile" @vdropzone-removed-file="dropzoneDeletedFile">
        </vue-dropzone>
        <div class="summary-container" v-if="summaryInfo" style="margin-top: -20px; margin-bottom: -25px;">
            <div class="row">
            <div class="col">
                <h5 slot="header" class="title title-up" style="margin-bottom: -15px;">
                    Checking files
                </h5>
                <div class="error-list" v-if="msgReportError.length > 0">
                <ul>
                    <li v-for="(error, index) in msgReportError" :key="index" class="error-item">
                        {{ error }}
                    </li>
                </ul>
                </div>
                
                <div class="checks-ok-list" v-if="msgReportOK.length > 0">
                <ul>
                    <li v-for="(message, index) in msgReportOK" :key="index" class="checks-ok-item">
                    {{ message }}
                    </li>
                </ul>
                </div>
            </div>
            </div>
        </div>
        <template slot="footer">
            <n-button class="btn-simple ncm-btn" v-if="!msgReportError.length == 0 || !filesChecked" @click="checkUploadLayer()">
                CHECK
            </n-button>
            <n-button class="btn-simple ncm-btn" v-if="msgReportError.length == 0 && filesChecked" @click.native="uploadLayer()">
                Upload
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" @click.native="modals.upload = false;">
                {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
            </n-button>
        </template>
    </modal>
      <!-- Modal delete  -->
      <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
        <p>{{ $t('PanelCadastres.Modals.DeleteLayer') }}</p>
        <template slot="footer">
          <n-button type="neutral" link @click="deleteElement()">
            {{ $t("Languages.Modals.Delete.Footer.Save") }}
          </n-button>
          <n-button type="neutral" link @click.native="modals.delete = false">
            {{ $t("Languages.Modals.Delete.Footer.Close") }}
          </n-button>
        </template>
      </modal>
    </div>
  </template>
  <script>
  import { Badge, Button, InfoSection,Modal, FormGroupInput, Card, Tabs, TabPane, Checkbox, DropDown} from '@/components';
  import { API_KEY } from '@/constants'
  import {Table, TableColumn, Tooltip} from 'element-ui';
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  });

  import OrganizationsDataService from '@/services/OrganizationsDataService'
  import WalletDataServices from '../../services/WalletDataServices';
  import MapsDataService from '@/services/MapsDataService'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  export default {
  name: 'staff-layers',
  components: {
      Card,
      Modal,
      [Checkbox.name]: Checkbox,
      [DropDown.name]: DropDown,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
      vueDropzone: vue2Dropzone,
  },
  data() {
      return {
        dropzoneOptions: {
                dictRemoveFile: 'X',
                addRemoveLinks: true,
                disablePreviews: true,
                createImageThumbnails: false,
                autoQueue: false,
                url: 'http://localhost',
                autoProcessQueue: false,
                thumbnailWidth: 20,
                maxFilesize: 5000,
                dictDefaultMessage: 'Drop files here or click to upload <b-icon icon="trash"></b-icon>',
                init: function() {
                    this.on("thumbnail", function(file) {
                        console.log('on thumbnail', file)
                    })
                    
                    this.on("complete", function(file, done) {
                        console.log('on complete', file)

                    })
                },
                
            },
          modals: {
              upload: false,
              delete: false,
              create: false,
          },
          tokens_to_add:null,
          layers: [],
          selectedElement: null,
          visibilityFilter: null,
          headers: [
        { key: 'internal_name', label: this.$t('Languages.Table.Name'), sortable: true},
        { key: 'visible_name', label: this.$t('Languages.Table.VisibleName'), sortable: true},
        { key: 'projection', label: this.$t('Languages.Table.Projection'), sortable: true},
        { key: 'is_active', label: this.$t('Languages.Table.Active'), sortable: true},
        { key:'createdAt',label: this.$t('Parameters.Table.CreatedAt'), sortable: true },
          { key:'updatedAt',label:this.$t('Parameters.Table.UpdatedAt'), sortable: true },
          { key:'options', label: this.$t('Languages.Table.Actions'), thClass: 'position-sticky' }
        ],
          allOpenRows: [],
          currentPage: 1,
          perPage: 10,
          filterOrgs: '',
          filter: null,
          filterOn: [],
          loading: false,
          label_form: '',
          projection_form: '',
          filesSize: 0,
          msgReportError: [],
          msgReportOK: [],
          prjMissing: false,
          summaryInfo: false,
          filesChecked: false,
          acceptedFiles: [],
      }
  },
  computed: {
        totalPages () {
            return this.layers.length
        },
        // function to filter orgs with the text field
        searchOrgs() {
            return this.headers.filter((item) => {
                return item.filterOrgs.toLowerCase().includes(this.filterOrgs.toLowerCase()) || 
                item.filterOrgs.toLowerCase().includes(this.filterOrgs.toLowerCase()) ||
                item.name.toLowerCase().includes(this.filterOrgs.toLowerCase()) ||
                item.address1.toLowerCase().includes(this.filterOrgs.toLowerCase());
            });
        },
        filteredOrgs() {
              if (this.visibilityFilter === null || this.visibilityFilter === 'null') {
                  return this.organizations; // Return all invitations if no status is selected
              }else if(this.visibilityFilter === 'active'){
                  console.log(this.organizations);
                  return this.organizations.filter(item =>(item.is_active === true));
              }else if(this.visibilityFilter === 'inactive'){
                  return this.organizations.filter(item =>(item.is_active === false));
              }
              // return this.invitations.filter(invitation => invitation.status === this.visibilityFilter);
          },
        // sortOptions() {
        //   // Create an options list from our fields
        //   return this.headers
        //     .filter(f => f.sortable)
        //     .map(f => {
        //       return { text: f.label, value: f.key }
        //     })
        // }
      },
  created() {
    this.getData()
  },
  methods:{
    clearErrors(){
        this.msgReportError = []
        this.msgReportOK = []
    },
    checkUploadLayer(){
            this.$validator.validateAll().then(async result => {
                if (!result) {
                    return
                }
                this.clearErrors()
                // Getting files and checking minimal files
                console.log(this.$refs.myVueDropzone.getAcceptedFiles())
                var shape_files = this.$refs.myVueDropzone.getAcceptedFiles()
                console.log(shape_files)
                if(shape_files.length < 3){
                    this.msgReportError.push('Not enough files for layer, you must upload 3 files.')
                }
                // Getting extension of files and check the basic formats
                var ext_array = []
                var i = 0
                for (var shapefile of shape_files){
                    console.log(shapefile)
                    var ext = shapefile.name.split('.')[shapefile.name.split('.').length - 1]
                    ext_array.push('.' + ext.toLowerCase())
                    i++
                }
                var formatsMissing = []
                if (!ext_array.includes('.dbf') ){
                    formatsMissing.push('.dbf')
                }
                if (!ext_array.includes('.shx') ){
                    formatsMissing.push('.shx')
                }
                if (!ext_array.includes('.shp') ){
                    formatsMissing.push('.shp')
                }
                if (formatsMissing.length > 0){
                    var msg = 'Missing following format file: '
                    for (var format of formatsMissing)  msg += format + ', '
                    msg = msg.substring(0, msg.length - 2)
                    this.msgReportError.push(msg)
                }
                // Getting extension of files and check additional formats or not supported formats
                var formats = ['.shp', '.shx', '.dbf', '.sbn', '.sbx', '.fbn', '.fbx', '.ain', '.aih', '.atx', '.ixs', '.mxs', '.prj', '.xml', '.cpg', '.qmd']
                var additionalFormats = []
                var notSupportedFormats = []
                var repeatedFormats = []
                for(var ext of ext_array){
                    if (ext != '.dbf' && ext != '.shx' && ext != '.shp'){
                        if(!formats.includes(ext)) notSupportedFormats.push(ext)
                        else additionalFormats.push(ext)
                    }
                    if (ext_array.filter(extension => extension == ext).length > 1){
                        if(!repeatedFormats.includes(ext))
                        repeatedFormats.push(ext)
                    }
                }
                if (additionalFormats.length > 0){
                    var msg = 'You have additional files of these formats: '
                    for (var format of additionalFormats) msg += format + ', '
                    msg = msg.substring(0, msg.length - 2)
                    this.msgReportOK.push(msg)
                }
                // Check if there is any file not supported
                if (notSupportedFormats.length > 0){
                    var msg = 'You have not supported files of these formats: '
                    for (var format of notSupportedFormats) msg += format + ', '
                    msg = msg.substring(0, msg.length - 2)
                    this.msgReportError.push(msg)
                }
                // Check if there is repeated files
                if (repeatedFormats.length > 0){
                    var msg = 'You have repeated formats: '
                    for (var format of repeatedFormats) msg += format + ', '
                    msg = msg.substring(0, msg.length - 2)
                    this.msgReportError.push(msg)
                }
                // Check if there is prj file for detecting projection
                if (!additionalFormats.includes('.prj') && !this.projection_form){
                    this.msgReportError.push('Projection file dont detected, you must indicate the projection manually or use a .prj file')
                    this.prjMissing = true
                }
                    this.filesChecked = true
                    this.summaryInfo = true
            })
        },
        dropzoneAddedFile(file){
            this.acceptedFiles = []
            this.acceptedFiles = this.$refs.myVueDropzone.getAcceptedFiles()
        },
        dropzoneDeletedFile(file){
            this.acceptedFiles = []
            this.acceptedFiles = this.$refs.myVueDropzone.getAcceptedFiles()
        },

    uploadLayer(){
      this.$validator.validateAll().then(async result => {
                if (!result) {
                return
                }
                console.log(this.$refs.myVueDropzone.getAcceptedFiles())
           
                var formData = new FormData()
                // formData.append('id', user_id)
                // formData.append('delete_path', this.imagePath)
                //var shape_files = this.$refs['fileInput'].files
                var shape_files = this.$refs.myVueDropzone.getAcceptedFiles()
                console.log(shape_files)

                if(shape_files.length < 3){
                  this.$swal.fire({
                  icon: 'warning',
                  title: this.$t('Alerts.NotEnoughFiles'),
                  text: this.$t('Alerts.InfoFiles')
                  }).then(() => {
                      return
                  });
                }

                var ext_array = []


                var i = 0
                for( var shapefile of shape_files){
                  console.log(shapefile)
                  var ext = shapefile.name.split('.')[shapefile.name.split('.').length - 1]
                  ext_array.push(ext)
                  formData.append('files[' + i +']', shapefile)
                  i++
                  
                }

                console.log(ext_array)
                if(ext_array.includes('dbf') && ext_array.includes('shx') && ext_array.includes('shp')){
                  formData.append('label', this.label_form)
                  formData.append('projection', this.projection_form)
                  formData.append('is_data_layer', false)
                  var filename = shape_files[0]
                  var main_name = filename.name.split('.')[0]
                  formData.append('main_name', main_name)

                  this.loading = true
                  MapsDataService.uploadLayer(formData).then(
                      response => {
                          if(response.status == 200){
                              this.loading = false
                              this.$swal.fire({
                              icon: 'success',
                              title: this.$t('Alerts.LayerUploadedSuccessfully')
                              }).then(() => {
                                  this.modals.upload = false
                                  this.getData()
                              });
                          
                          }
                          
                      },
                      error => {
                          this.loading = false
                          if(error.response.status == 409){
                            this.$swal.fire({
                              icon: 'error',
                              title: this.$t('Alerts.ErrorUploadLayerDuplicated'),
                              text: this.$t('Alerts.ErrorUploadLayerDuplicatedMsg'),
                              });
                          }
                          else{
                            this.$swal.fire({
                              icon: 'error',
                              title: this.$t('Profile.Alerts.UpdateAvatar.Error')
                              });
                          }
                          console.log((error.response && error.response.data) || error.message || error.toString())
                          
                      }

                      )
                }
                else{
                  this.$swal.fire({
                    icon: 'warning',
                    title: this.$t('Alerts.NotEnoughFiles'),
                    text: this.$t('Alerts.InfoFiles')
                    }).then(() => {
                        return
                    });
                }
                
            })
        
        
    },
    applyRowClass(row) {
        if (row != null) {
          if (!row.is_active) {
          return 'disabled-row';
        } else {
          return '';
        }
        }
      },  
    disableOrg (org){
      console.log(org)
      OrganizationsDataService.disable(org.id).then(
        Response => {
          console.log(Response)
          this.getData()
        },
        Error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: Error.response.data.message
          })
        } 
      )
    },
    enableOrg (org){
      console.log(org)
      OrganizationsDataService.enable(org.id).then(
        Response => {
          this.getData()
        },
        Error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: Error.response.data.message
          })
        } 
      )
    },
    onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
    },
    selectRow(items){
      console.log('selected:', items)
      this.selectedElement = items[0]
    },
    validateState (ref) {
      if (
        this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.veeErrors.has(ref)
        }
        return null
    },
    handleRowClicked (item, index) {
        this.allOpenRows.map((ele) => {
            if (ele.id !== item.id && ele._showDetails) {
            this.$set(ele, '_showDetails', !ele._showDetails)
            }
        })
        this.allOpenRows = []
        this.$set(item, '_showDetails', !item._showDetails)
        this.allOpenRows.push(item)
    },
    getData(){
        MapsDataService.getNCMLayers().then(
            Response => {
                this.layers = Response.data.data
            },
            Error => {
              this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: Error.response.data.message
              })
            }
        )
    },
    updateElement(){
        this.$validator.validateAll(['input-token-modify']).then(async result => {
            if (!result) {
                return
            }
            let data = {
                organization_id: this.selectedElement.id,
                level: 'organization',
                token_value: this.tokens_to_add,
                stripe_token_uuid: 'abcd',
            }
            WalletDataServices.reloadTokens(data).then(
                Response => {
                    this.$swal.fire({
                                icon: 'success',
                                title: this.$t('Alerts.TokensAddedSuccessfully'),
                                text: this.$t('Alerts.AddedToBalance')
                            }).then(() => {
                                this.modals.upload = false
                                this.getData()
                            });
  
                },
                Error => {
                  this.$swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: Error.response.data.message
                  })
                }
            )
        })
    },
    openCreateModal(){
      // Reset form
      this.name_form = ''
      this.code_form = ''
      this.$validator.reset(['input-label']);
  
      // Show form
      this.modals.create = true
    },
    openEditModal(element){
        console.log('open edit modal')
        this.projection_form = ''
        this.label_form = ''
        this.$refs.myVueDropzone.removeAllFiles(true)
        this.modals.upload = true
    },
    openDeleteModal(element){
        console.log(element)
        this.selectedElement = element
        this.modals.delete = true
    },
    deleteElement(){
        console.log(this.selectedElement)
        MapsDataService.removeLayer(this.selectedElement.id).then(
            async response => {
                console.log(response.data)
                this.modals.delete = false
                this.$swal.fire({
                  icon: 'success',
                  title: this.$t('Alerts.LayerDeleted'),
                  text: ''
                  }).then(() => {
                    this.getData()
                  });
            },
            error => {
              this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: error.response.data.message
              })
            }
        )
    }
  }
  
  }
</script>
<style scoped>
  .disabled-row {
    background-color: #f8f8f8;
    color: #999999;
  }

  
  ::v-deep .dz-preview{
      min-width: 100px !important;
      max-width: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important;
  }

  ::v-deep .dz-image{
      min-width: 100px !important;
      max-width: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important;
  }


  ::v-deep .dz-progress{
      visibility: hidden;
  }

  ::v-deep .dz-remove{
    position: absolute !important;
    top: 0px !important;
    left: 69px !important;
    max-width: 20px !important;
    max-height: 20px !important;
    padding: 0px !important;
    border: none !important;
    color: #fe2626b0 !important;
  }

  .invalid-feedback {
  margin-left: 1% !important;
}
.error-list {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 1px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

.error-item {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #721c24;
}

.error-item::before {
  color: #721c24;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* .row .col.d-flex {
  justify-content: flex-end;
} */
.checks-ok-list {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
}

.checks-ok-item {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #155724;
}

.checks-ok-item::before {
  color: #155724;
  margin-right: 5px;
  margin-bottom: 5px;
}

@media (max-width: 465px) {
  .titleMap {
    text-align: center!important;
  }

  .buttonMap{
    float: center!important;
  }
}

@media (max-width: 465px) {
  .titleOrg {
    text-align: center;
  }

  .buttonOrg {
    float: center;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
</style>