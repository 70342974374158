<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar" >
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <div class="main-title">
                        <template v-if="!editing_title">
                            <h2> {{ selectedElement.label }}</h2>
                            <b-icon icon="pen" style="margin-left: 10px; cursor: pointer" @click="toggleTitleEdit()"></b-icon>
                        </template>
                        <template v-else>
                            <fg-input v-model="selectedElement.label"/>
                            <b-icon icon="check" class="check-title-icon" @click="toggleTitleEdit()"></b-icon>
                        </template>
                    </div>
                </b-col>
                <b-col>
                    <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right">
                        <b-button-group class="mx-1">
                            <n-button
                            id="newAdmin"
                            class="btn-simple ncm-btn-back"
                            target="_blank"
                            @click="goBack()"
                            >
                            <b-icon icon="box-arrow-in-left" class="button-icon"></b-icon>
                                {{$t("EcosystemEdit.ButtonBack")}}
                            </n-button>
                        </b-button-group>
                        <b-button-group class="mx-1">
                            <n-button
                            id="newAdmin"
                            class="btn-simple ncm-btn"
                            target="_blank"
                            @click="modals.save = true"
                            >
                            <b-icon icon="cloud-arrow-up-fill" class="button-icon"></b-icon>
                                {{$t("EcosystemEdit.ButtonSave")}}
                            </n-button>
                        </b-button-group>
                    </b-button-toolbar>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="3" style="margin-left: 1%">
                    <b-form-group :label="$t('EcosystemEdit.Type')">
                        <b-form-select v-model="selectedElement.category.category_type_id" 
                            id="input-type"
                            name="input-type"
                            :options="types"
                            v-validate="{required: true}"
                            :state="validateState('input-type')"
                            aria-describedby="input-type-live-feedback"
                            value-field="id"
                            text-field="name"
                            @change.native="changeType()">
                            <b-form-select-option :value="null">{{$t('EcosystemEdit.TypePH')}}</b-form-select-option>
                        </b-form-select>
                        <b-form-invalid-feedback id="input-type-live-feedback">{{ veeErrors.first('input-type') }}</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group :label="$t('EcosystemEdit.Category')">
                        <b-form-select v-model="selectedElement.category_id" 
                            id="input-category"
                            name="input-category"
                            :options="categories_available"
                            v-validate="{required: true}"
                            :state="validateState('input-category')"
                            aria-describedby="input-category-live-feedback"
                            value-field="id"
                            text-field="name">
                            <b-form-select-option :value="null">{{$t('EcosystemEdit.CategoryPH')}}</b-form-select-option>
                        </b-form-select>
                        <b-form-invalid-feedback id="input-category-live-feedback">{{ veeErrors.first('input-category') }}</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group :label="$t('EcosystemEdit.Parent')">
                        <b-form-select v-model="selectedElement.parent_id" 
                            id="input-parent"
                            name="input-parent"
                            :options="parents_available"
                            value-field="id"
                            text-field="label">
                            <b-form-select-option :value="null">{{$t('EcosystemEdit.ParentPH')}}</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <card plain>
                        <div class="services-container" >
                            <h6>{{$t('EcosystemEdit.ContainerTitle')}} {{ selectedElement.label }}</h6>
                            <div class="service-container">
                                <div class="service-element" v-for="(service_container, index) of selectedElement.services" :key="index" >
                                    
                                    <!-- <b-form-group :label="$t('EcosystemEdit.Type')">
                                            <b-form-select v-model="selectedElement.category.category_type_id" 
                                                id="input-type"
                                                name="input-type"
                                                :options="types"
                                                v-validate="{required: true}"
                                                :state="validateState('input-type')"
                                                aria-describedby="input-type-live-feedback"
                                                value-field="id"
                                                text-field="name"
                                                @change.native="changeTypeService()">
                                                <b-form-select-option :value="null">{{$t('EcosystemEdit.TypePH')}}</b-form-select-option>
                                            </b-form-select>
                                            <b-form-invalid-feedback id="input-type-live-feedback">{{ veeErrors.first('input-type') }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    
                                        <b-form-group :label="$t('EcosystemEdit.Category')">
                                            <b-form-select v-model="selectedElement.category_id" 
                                                id="input-category"
                                                name="input-category"
                                                :options="categories_available"
                                                v-validate="{required: true}"
                                                :state="validateState('input-category')"
                                                aria-describedby="input-category-live-feedback"
                                                value-field="id"
                                                text-field="name">
                                                <b-form-select-option :value="null">{{$t('EcosystemEdit.CategoryPH')}}</b-form-select-option>
                                            </b-form-select>
                                            <b-form-invalid-feedback id="input-category-live-feedback">{{ veeErrors.first('input-category') }}</b-form-invalid-feedback>
                                        </b-form-group> -->
                                    <div class="service-selection-row" style="display: flex; margin-left: 1%">
                                        <!-- Dropdown para TYPE-->
                                        <drop-down v-if="service_container.new">
                                            <n-button
                                                slot="title"
                                                class="dropdown-toggle"
                                                type="primary"
                                                data-toggle="dropdown"
                                                style="margin-left: 1.4%"
                                            >
                                                <template v-if="selectedType == undefined">
                                                    SELECT TYPE
                                                </template>
                                                <template v-else>
                                                    {{selectedType.name}}
                                                </template>
                                            </n-button>
                                            <!-- <a v-for="(service, index) of available_services" -->
                                            <a v-for="(types, index) of sortedTypes"
                                                class="dropdown-item"
                                                style="cursor: default;"
                                                :key="index"
                                                @click="selectType(types, service_container)"
                                            >{{types.name}}</a>
                                        </drop-down>
                                        <!-- Dropdown para CATEGORY-->
                                        <drop-down v-if="service_container.new">
                                            <n-button
                                                slot="title"
                                                class="dropdown-toggle"
                                                type="primary"
                                                data-toggle="dropdown"
                                                style="margin-left: 1.4%"
                                            >
                                                <template v-if="selectedCategory == undefined">
                                                    SELECT CATEGORY
                                                </template>
                                                <template v-else>
                                                    {{selectedCategory.name}}
                                                </template>
                                            </n-button>
                                            <!-- <a v-for="(service, index) of available_services" -->
                                            <a v-for="(filteredCategories, index) of sortedFilteredCategories"
                                                class="dropdown-item"
                                                style="cursor: default;"
                                                :key="index"
                                                @click="selectFilteredCategory(filteredCategories, service_container)"
                                            >{{filteredCategories.name}}</a>
                                        </drop-down>
                                        <!-- Dropdown para SERVICE-->
                                        <drop-down v-if="service_container.new">
                                            <n-button
                                                slot="title"
                                                class="dropdown-toggle"
                                                type="primary"
                                                data-toggle="dropdown"
                                                style="margin-left: 1.4%"
                                            >
                                                <template v-if="service_container.label == undefined">
                                                    {{$t("EcosystemEdit.DropDownPH")}}
                                                </template>
                                                <template v-else>
                                                    {{service_container.label}}
                                                </template>
                                            </n-button>
                                            <!-- <a v-for="(service, index) of available_services" -->
                                            <a v-for="(service, index) of sortedFilteredServices"
                                                class="dropdown-item"
                                                style="cursor: default;"
                                                :key="index"
                                                @click="selectService(service, service_container)"
                                            >{{service.label}}</a>
                                        </drop-down>
                                        
                                        <p class="label-dashboard" v-if="!service_container.new">{{ service_container.label }}</p>
                                        <p class="label-dashboard" v-if="!service_container.new" style="margin-left: 15px;">Type: {{selectedElement.category.type.name}}</p>
                                        <p class="label-dashboard" v-if="!service_container.new" style="margin-left: 15px;">Category: {{selectedElement.category.name}}</p>
                                        <!-- <template v-if="creatingService && service_container.new">
                                                <n-button  type="primary" class="btn-simple btn-round btn-icon icon-title-dashboard" v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Save')" @click.native="selectService(service, service_container)">
                                                    <b-icon icon="check-lg"></b-icon>
                                                </n-button>
                                        </template> -->
                                        <b-icon icon="x-circle" @click="showModalDeleteService(service_container)" style="margin-left: auto; margin-top: 1%; cursor: pointer !important"></b-icon>
                                    </div>
                                    <b-form-tags id="tags-with-dropdown" v-model="service_container.parameters_names" no-outer-focus class="mb-2">
                                        <template v-slot="{ tags, disabled, addTag, removeTag }">
                                            <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                                                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                                    <b-form-tag
                                                    @remove="deleteByParameter(tag, service_container, removeTag)"
                                                    :title="tag"
                                                    :disabled="disabled"
                                                    variant="info"
                                                    >{{ tag }}</b-form-tag>
                                                </li>
                                            </ul>
                                            <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
                                                <template #button-content>
                                                    <b-icon icon="plus"></b-icon> {{ $t("EcosystemEdit.ParametersPH")}}
                                                </template>
                                                <b-dropdown-form @submit.stop.prevent="() => {}">
                                                    <b-form-group
                                                        :label="$t('EcosystemEdit.SearchLabel')"
                                                        label-for="tag-search-input"
                                                        label-cols-md="auto"
                                                        class="mb-0"
                                                        label-size="sm"
                                                        :description="searchDesc(service_container.parameters_names)"
                                                        :disabled="disabled"
                                                    >
                                                        <b-form-input
                                                            v-model="search"
                                                            id="tag-search-input"
                                                            type="search"
                                                            size="sm"
                                                            autocomplete="off"
                                                            v-on:keyup.enter="onSearchEnter({ addTag })"
                                                        />
                                                    </b-form-group>
                                                </b-dropdown-form>
                                                <b-dropdown-divider/>
                                                <b-dropdown-item-button 
                                                    v-for="option in availableOptions(service_container.parameters_names)"
                                                    style="cursor: pointer;"
                                                    :key="option"
                                                    :style="{ cursor: 'pointer' }"
                                                    @click.native.capture.stop="onOptionClick({ option, service_container, addTag })"
                                                >
                                                    {{ option }}
                                                </b-dropdown-item-button>
                                                <b-dropdown-text v-if="availableOptions(service_container.parameters_names).length === 0">
                                                    {{$t("EcosystemEdit.SearchEmpty")}}
                                                </b-dropdown-text>
                                            </b-dropdown>
                                        </template>
                                    </b-form-tags>
                                </div>
                                <p v-if="selectedElement.services.length == 0">{{$t("EcosystemEdit.ServicesEmpty")}} {{ selectedElement.label }}</p>
                            </div>
                        </div>
                        <n-button v-if="selectedElement.services.length != services.length"
                        class="btn-simple ncm-btn"
                        style="margin-left: 1.4%"
                        @click="addElement()"
                        :disabled="creatingService"
                        >
                            <b-icon icon="plus-circle-fill" class="button-icon"/>{{$t("EcosystemEdit.AddService")}}
                        </n-button>
                        <n-button v-else
                            class="btn-simple ncm-btn"
                            style="margin-left: 1.4%"
                            disabled
                        >
                            <b-icon icon="plus-circle-fill" class="button-icon"/>{{$t("EcosystemEdit.AllServicesSelected")}}
                        </n-button>
                    </card>
                </b-col>
            </b-row>
        </b-container>
        <!-- Modal save  -->
        <modal :show.sync="modals.save" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
            <p>{{$t("EcosystemEdit.SaveChangesMessage")}}</p>
            <template slot="footer">
                <n-button type="neutral" link @click="editEcosystem()">
                    {{ $t("Ecosystems.Modals.Delete.Footer.Save") }}
                </n-button>
                <n-button type="neutral" link @click.native="modals.save = false">
                    {{ $t("Ecosystems.Modals.Delete.Footer.Close") }}
                </n-button>
            </template>
        </modal>
        <!-- Modal exit  -->
        <modal :show.sync="modals.exit" class="modal-primary" :show-close="false" header-classes="justify-content-center">
            <p>{{$t("EcosystemEdit.ExitViewMessage")}}</p>
            <template slot="footer">
                <n-button type="neutral" link @click="exitWithoutChanges()">
                    {{ $t("Ecosystems.Modals.Delete.Footer.Save") }}
                </n-button>
                <n-button type="neutral" link @click="modals.exit = false">
                    {{ $t("Ecosystems.Modals.Delete.Footer.Close") }}
                </n-button>
            </template>
        </modal>
        <!-- Modal delete  -->
        <modal :show.sync="modals.delete_service" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
            <p>{{$t("EcosystemEdit.DeleteMessage")}} <b>{{ selectedElementDelete.label }} </b>?</p>
            <template slot="footer">
                <n-button type="neutral" link @click="deleteByService()">
                    {{ $t("Ecosystems.Modals.Delete.Footer.Save") }}
                </n-button>
                <n-button type="neutral" link @click.native="modals.delete_service = false">
                    {{ $t("Ecosystems.Modals.Delete.Footer.Close") }}
                </n-button>
            </template>
        </modal>
    </div>
</template>
<script>
    import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal, DropDown} from '@/components';
    import { API_KEY } from '@/constants'
    import {Table, TableColumn, Tooltip} from 'element-ui';
    import Vue from 'vue'
    import * as VueGoogleMaps from 'vue2-google-maps'
    Vue.use(VueGoogleMaps, {
        load: {
            key: API_KEY
        }   
    });
    import EcosystemDataService from '@/services/EcosystemDataService'
    import EcosystemServiceDataService from '@/services/EcosystemServiceDataService'
    import ParameterDataService from '@/services/ParameterDataService'
    import EcosystemServiceParameterDataService from '@/services/EcosystemServiceParameterDataService'
    import EcosystemCategoryDataService from '@/services/EcosystemCategoryDataService';
    import EcosystemTypesDataService from '@/services/EcosystemTypesDataService';

export default {
    name: 'Ecosystems',
    bodyClass: 'profile-page',
    components: {
        Modal,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Tooltip.name]: Tooltip,
        [FormGroupInput.name]: FormGroupInput,
        [DropDown.name]: DropDown,
        Card
    },
    data() {
        return {
            modals: {
                modify: false,
                delete_service: false,
                save: false,
                create: false,
                exit: false,
            },
            tableData: [],
            ecosystems: [],
            parameters: [],
            services: [],
            categories: [],
            types: [],
            selectedElement: {},
            selectedElementDelete: {},
            selectedService: undefined,
            selectedType: undefined,
            selectedCategory: undefined,
            label_form: '',
            search: '',
            value_parameters: [],
            servicesArray: [],
            editing_title: false,
            editing: false,
            servicesToDelete: [],
            parametersToDelete: [],
            available_services: [],
            filteredCategories: [],
            filteredServices: [],
            creatingService: false,
            view_to: '/ecosystems'
        }
    },
    computed: {
        criteria() {
            return this.search.trim().toLowerCase()
        },
        categories_available(){
            var available = this.categories.filter(f => f.category_type_id == this.selectedElement.category.category_type_id)
            return available
        },
        parents_available(){
            var available = this.ecosystems.filter(f => f.category_id == this.selectedElement.category_id)
            var available_without_me = available.filter(f => f.id != this.selectedElement.id)
            return available_without_me
        },
        sortedServices() {
            return this.available_services.slice().sort((a, b) => {
                return a.label > b.label ? 1 : -1;
            });
        },
        sortedTypes() {
            return this.types.slice().sort((a, b) => {
                return a.name > b.name ? 1 : -1;
            });
        },
        sortedFilteredCategories() {
            return this.filteredCategories.slice().sort((a, b) => {
                return a.name > b.name ? 1 : -1;
            });
        },
        sortedFilteredServices() {
            return this.filteredServices.slice().sort((a, b) => {
                return a.label > b.label ? 1 : -1;
            });
        },
    },
    created() {
        this.getData()
    },
    beforeRouteLeave(to, from, next) {
        this.view_to = to
        if (this.editing)  
            this.modals.exit = true
        else
            next()
    },
    methods:{
        changeTypeService(){
            this.selectedElement.category_id = null
            this.selectedElement.parent_id = null
        },
        changeType(){
            this.selectedElement.category_id = null
            this.selectedElement.parent_id = null
        },
        goBack (){
            this.$router.push('/ecosystems')
        },
        validateState (ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref)
            }
            return null
        },
        searchDesc (parameters_names) {
            if (this.criteria && this.availableOptions(parameters_names).length === 0) {
                return 'There are no tags matching your search criteria'
            }
                return ''
        },
        availableOptions(parameters_names) {
            const criteria = this.criteria
            var values = this.parameters.map(f => f.label)
            const options = values.filter(opt => parameters_names.indexOf(opt) === -1)
            if (criteria) {
                // Show only options that match criteria
                var match = options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1);
                return match
            }
            // Show all options available
            return options
        },
        exitWithoutChanges(){
            this.editing = false
            this.$router.push(this.view_to)
        },
        toggleTitleEdit(){
            this.editing = true
            this.editing_title = !this.editing_title
        },
        showModalDeleteService(service){
            this.modals.delete_service = true
            this.selectedElementDelete = service
        },
        async editEcosystem(){
            this.$validator.validateAll().then(async result => {
                if (!result) {
                    this.modals.save = false
                    console.log('not validated')
                    return
                }
                for (var service_container of this.selectedElement.services) {
                    if (service_container.id == undefined) {
                        this.$swal.fire({
                            icon: 'warning',
                            title: this.$t('Alerts.SelectAllServices'),
                            text: this.$t('EcosystemEdit.Message')
                        })
                        this.modals.save = false
                        return
                    }
                }


                if(this.selectedElement.label == undefined || this.selectedElement.label == ''){
                    this.$swal.fire({
                            icon: 'warning',
                            title: this.$t('Alerts.LabelsCanNotBeEmpty'),
                            text: this.$t('EcosystemEdit.Message')
                        })
                        this.modals.save = false
                        return
                }

                // Chek if delete services relations
                for (var service_delete of this.servicesToDelete) {
                    console.log(service_delete)
                    EcosystemServiceParameterDataService.deleteByService(this.selectedElement.id, service_delete.id).then(
                        async response => {
                            console.log(response.data)
                        },
                        error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: error.response.data.message
                            })
                        }
                    )
                }
                for (var parameter_delete of this.parametersToDelete) {
                    console.log(parameter_delete)
                    EcosystemServiceParameterDataService.deleteByParameter(this.selectedElement.id, parameter_delete.service_id, parameter_delete.id).then(
                        async response => {
                            console.log(response.data)
                        },
                        error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: error.response.data.message
                            })
                        }
                    )
                }
                //Chek if create new services relations
                for (var service_container of this.selectedElement.services) {
                console.log(service_container)
                console.log('service', service_container)
                var parameters = []
                if (service_container.new_parameters != undefined) {
                    for (var parameter_name of service_container.new_parameters) {
                        await ParameterDataService.exists(parameter_name).then(
                            async response => {
                                if (!response.data.result) {
                                    var element = {
                                        label: parameter_name
                                    }
                                    await ParameterDataService.create(element).then(
                                        async response => {
                                            console.log(response.data)
                                            parameters.push(response.data.parameter)                               
                                        },
                                        error => {
                                            this.$swal.fire({
                                                icon: 'error',
                                                title: 'Error',
                                                text: error.response.data.message
                                            })
                                        }
                                    )
                                }
                                else{
                                    var parameter = this.parameters.filter(f => f.label == parameter_name)[0]
                                    parameters.push(parameter)
                                }
                            },
                            error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error.response.data.message
                                })
                            }
                        )
                    }
                }
                    for (var parameter of parameters){ 
                        var element = {
                            ecosystem_id: this.selectedElement.id,
                            ecosystem_service_id: service_container.id,
                            parameter_id: parameter.id
                        }
                        EcosystemServiceParameterDataService.create(element).then(
                            async response => {
                                console.log(response.data)
                            },
                            error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error.response.data.message
                                })
                            }
                        )
                    }
                    if (service_container.new) {
                        var element = {
                            ecosystem_id: this.selectedElement.id,
                            ecosystem_service_id: service_container.id,
                        }
                        EcosystemServiceParameterDataService.create(element).then(
                            async response => {
                                console.log(response.data)
                            },
                            error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error.response.data.message
                                })
                            }
                        )
                    }    
                } 
                await EcosystemDataService.update(this.selectedElement).then(
                    async response => {
                        console.log(response.data)
                        this.modals.save = false
                        this.editing = false
                        this.label_form = ''
                        this.$swal.fire({
                            icon: 'success',
                            title: this.$t('EcosystemEdit.Message2')
                        }).then(() => {
                            this.$router.push(this.view_to)
                        });
                    },
                    error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.message
                        })
                    }
                )       
            })     
        },
        addElement(){
            this.editing = true
            this.creatingService = true
            var service = {
                label: undefined,
                id: undefined,
                parameters_names: [],
                new: true
            }
            var filteredCategories = {
                id: undefined,
                name: undefined,
                description: undefined,
                createdat: undefined,
                updatedat: undefined,
                new: true
            }
            var types = {
                id: undefined,
                name: undefined,
                description: undefined,
                created_at: undefined,
                last_edited: undefined,
                category_type_id: undefined,
                category_type_name: undefined,
                new: true
            }
            var filteredServices = {
                label: undefined,
                id: undefined,
                parameters_names: [],
                new: true
            }
            this.selectedElement.services.push(service)
            
            this.selectedCategory.categories.push(filteredCategories)
            this.selectedService.services.push(filteredServices)
            this.selectedType.types.push(types)
        },
        selectService(selected_service, service_container){
            this.$set(service_container, 'label', selected_service.label)
            this.$set(service_container, 'id', selected_service.id)
            console.log(service_container)
            var available_services = []
            console.log(this.selectedElement)
            for (var service of this.services) {
                if(this.selectedElement.services.filter(f => f.id == service.id).length == 0)
                available_services.push(service)
            }
            console.log(available_services)
            this.available_services = available_services

            

            this.creatingService = false
            service_container.new = false
        },
        selectType(selected_type, service_container){
            this.$set(service_container, 'label', selected_type.label)
            this.$set(service_container, 'id', selected_type.id)
            console.log(service_container)
            this.selectedType = selected_type
            this.selectedCategory = undefined
            this.selectedService = undefined
            this.filteredCategories = this.categories.filter(f => f.category_type_id == selected_type.id)
        },
        selectFilteredCategory(selected_category, service_container){
            this.$set(service_container, 'label', selected_category.label)
            this.$set(service_container, 'id', selected_category.id)
            console.log(service_container)
            this.selectedCategory = selected_category
            this.selectedService = undefined
            this.filteredServices = this.services.filter(f => f.category_id == selected_category.id)
        },
        selectFilteredService(selected_filtered_service, service_container){
            this.$set(service_container, 'label', selected_filtered_service.label)
            this.$set(service_container, 'id', selected_filtered_service.id)
            console.log(service_container)
            this.selectedService = selected_filtered_service

        },
        onOptionClick({ option, service_container, addTag }) {
        this.editing = true
        addTag(option)
        console.log('option', option)
        this.search = ''
        if(service_container.new_parameters == undefined){
            service_container.new_parameters = []
            service_container.new_parameters.push(option)
        }
        else{
            service_container.new_parameters.push(option)
        }
        },
        onSearchEnter ({ addTag }) {
            this.editing = true
            addTag(this.search)
            this.search = ''
        },
        async getData () {
            await EcosystemDataService.findOne(this.$route.params.id).then(
                async response => {
                    console.log(response.data)
                    this.selectedElement = response.data
                    
                },
                error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                }
            )
            await EcosystemServiceDataService.findAll('eraefaef').then(
                async response => {
                    console.log(response.data)
                    this.services = response.data
                    var available_services = []
                    console.log(this.selectedElement)
                    for(var service of this.services){
                        if(this.selectedElement.services.filter(f => f.id == service.id).length == 0)
                        available_services.push(service)
                    }
                    this.available_services = available_services
                },
                error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                }
            )
            await  EcosystemDataService.findAll('eraefaef').then(
                async response => {
                    console.log(response.data)
                    this.ecosystems = response.data
                },
                error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                }
            )
            await ParameterDataService.findAll('eraefaef').then(
                async response => {
                    console.log(response.data)
                    this.parameters = response.data
                },
                error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                }
            )
            await EcosystemCategoryDataService.findAll().then(
                async response => {
                    console.log(response.data)
                    this.categories = response.data.data
                },
                error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                }
            )
            await EcosystemTypesDataService.findAll().then(
                async response => {
                    console.log(response.data)
                    this.types = response.data.data
                },
                error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                }
            )
        },
        updateElement(){
            EcosystemDataService.update(this.selectedElement).then(
                async response => {
                    console.log(response.data)
                    this.modals.modify = false
                    this.getData()
                },
                error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                }
            )
        },
        deleteElement(){
            EcosystemDataService.delete(this.selectedElement.id).then(
                async response => {
                    console.log(response.data)
                    this.modals.delete = false
                    this.getData()
                },
                error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                }
            )
        },
        deleteByService(){
            this.editing = true
            if (!this.selectedElementDelete.new)
                this.servicesToDelete.push({id: this.selectedElementDelete.id})
            console.log(this.parametersToDelete)
            var paremeters_delete_find = this.parametersToDelete.filter(f => f.service_id == this.selectedElementDelete.id)
            for (var parameter_delete of paremeters_delete_find) {
                this.parametersToDelete.splice(this.parametersToDelete.indexOf(parameter_delete), 1)
            }
            console.log(this.parametersToDelete)
            this.modals.delete_service = false
            this.selectedElement.services.splice(this.selectedElement.services.indexOf(this.selectedElementDelete), 1)
            this.creatingService = false
        },
        deleteByParameter(parameter, service_container, removeTag){
            this.editing = true
            removeTag (parameter) 
            if (service_container.new_parameters != undefined) {
                var search_tag = service_container.new_parameters.filter(f => f == parameter)
                if(search_tag.length > 0){
                    service_container.new_parameters.splice(service_container.new_parameters.indexOf(parameter), 1)
                }
                else{
                    var parameter = this.parameters.filter(f => f.label == parameter)[0]
                    this.parametersToDelete.push({id: parameter.id, service_id: service_container.id})
                }
                // checkear si el parametro esta en bd: else?
            } else {
                var parameter = this.parameters.filter(f => f.label == parameter)[0]
                this.parametersToDelete.push({id: parameter.id, service_id: service_container.id})
            }
            console.log(service_container)
            console.log('parameters delete:', this.parametersToDelete)
        }
    }
}
</script>
<style scoped>

.services-container{
    padding: 20px;
    border: 1px solid rgb(0 0 0 / 13%);
    border-radius: 20px;
}

.service-element{
    margin-top: 2rem;
    padding: 8px;
    border: 1px solid #00000052;
    border-radius: 13px;
    min-width: 48%;
    margin-left: 1%;
    max-width: 48%;
}

.service-container{
    display: flex;
    flex-wrap: wrap;
}

.main-title {
    display: flex;
    width: 100% !important;
    margin-left: 2% !important;
}

.main-title > .form-group{
    font-size: 2.5em;
    font-weight: 400;
}

.select-service-element{
    display: flex;
}

.label-dashboard{
  background-color: #5D6176;
  color: #fff;
  padding: 10px;
  border-radius: 15px;
}


</style>