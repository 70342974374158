<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar" >
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <div class="main-title" v-if="selectedElement != null">             
                        <h2> {{ selectedElement.label }}</h2>                      
                    </div>
                </b-col>
                <b-col>
                <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right">
                    <b-button-group class="mx-1">
                        <n-button
                        id="newAdmin"
                        class="btn-simple ncm-btn-back"
                        target="_blank"
                        @click="goBack()"
                        >
                        <b-icon icon="box-arrow-in-left" class="button-icon"></b-icon>
                            {{$t("ParameterLanguageEdit.ButtonBack")}}
                        </n-button>
                    </b-button-group>
                    <b-button-group class="mx-1">
                        <n-button
                        id="newAdmin"
                        class="btn-simple ncm-btn"
                        target="_blank"
                        @click="modals.save = true"
                        >
                        <b-icon icon="cloud-arrow-up-fill" class="button-icon"></b-icon>
                            {{$t("ParameterLanguageEdit.ButtonSave")}}
                        </n-button>
                    
                    </b-button-group>
                </b-button-toolbar>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col cols="12">
                <card plain>
                    <div class="services-container" >
                        <h6>{{$t("ParameterLanguageEdit.Title")}} {{ selectedElement.label }}</h6>
                        <div class="language-container">
                            <div class="table-container" v-if="selectedElement.languages.length > 0">
                                <b-table ref="languagesTable"
                                    id="languagesTable"
                                    :fields="headers" 
                                    :items="selectedElement.languages"
                                    show-empty
                                    select-mode="single"
                                    style="overflow-x: auto;"
                                    responsive="sm"> 

                                    <template #empty="scope">
                                        {{$t('ParameterLanguageEdit.Table.Empty')}}
                                    </template>

                                    <template #cell(language.name)="data">
                                        <template v-if="!data.item.new">
                                            {{data.item.language.name}}
                                        </template>
                                        <template v-else>
                                            <b-form-select v-model="data.item.language_id" 
                                            :options="availableLanguages"
                                            value-field="id"
                                            text-field="name"
                                            size="sm"
                                            :disabled="!data.item.editing"
                                            :id="'input-language' + data.index"
                                            :name="'input-language' + data.index"
                                            v-validate="{ required: true}"
                                            :state="validateState('input-language' + data.index)"
                                            :aria-describedby="'input-language-live-feedback' + data.index">
                                            <b-form-select-option :value="null">{{$t('Ecosystems.Modals.Create.LanguagePH')}}</b-form-select-option>
                                            </b-form-select>
                                            <!-- <b-form-invalid-feedback :id="'input-language-live-feedback' + data.index">{{ veeErrors.first('input-language' + data.index) }}</b-form-invalid-feedback> -->
                                        </template>
                                        
                                    </template>

                                    <template #cell(name)="data">
                                            <b-form-input
                                                class="table-input"
                                                v-model="data.item.name"
                                                placeholder="Traduction"
                                                :disabled="!data.item.editing"
                                                :id="'input-traduction' + data.index"
                                                :name="'input-traduction' + data.index"
                                                v-validate="{ required: true}"
                                                :state="validateState('input-traduction' + data.index)"
                                                :aria-describedby="'input-traduction-live-feedback' + data.index"
                                            ></b-form-input>
                                            <!-- <b-form-invalid-feedback :id="'input-traduction-live-feedback' + data.index">{{ veeErrors.first('input-traduction' + data.index) }}</b-form-invalid-feedback> -->
                                    </template>                            
                                    <template #cell(createdAt)="data">
                                        {{ data.item.createdAt | formatDate}}
                                    </template>

                                    <template #cell(updatedAt)="data">
                                        {{ data.item.updatedAt | formatDate}}
                                    </template>

                                    <template #cell(options)="data">
                                        <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Modify')" @click="openEditModal(data.item)">
                                            <b-icon icon="pen"></b-icon>
                                        </b-button>
                                        <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Delete')" @click="showModalDeleteLanguage(data.item)">
                                            <b-icon icon="trash"></b-icon>
                                        </b-button>
                                    </template>

                                    
                                </b-table>
                                <div style="display: flex">
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalPages"
                                        :per-page="perPage">
                                    </b-pagination>
                                    <b-form-select v-model="perPage" class="pagination-select">
                                        <b-form-select-option :value="5">5</b-form-select-option>
                                        <b-form-select-option :value="10">10</b-form-select-option>
                                        <b-form-select-option :value="20">20</b-form-select-option>
                                        <b-form-select-option :value="selectedElement.languages.length">{{$t('Utils.SelectAll')}}</b-form-select-option>
                                    </b-form-select>
                                </div>
                            </div>
                            <p v-else>{{$t('ParameterLanguageEdit.Empty')}} {{ selectedElement.label }}</p>
                        </div>
                    </div>
                        <n-button v-if="selectedElement.languages.length != languages.length"
                        class="btn-simple ncm-btn"
                        style="margin-left: 1.4%"
                        @click="addElement()"
                        >
                        <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                        {{$t("ParameterLanguageEdit.AddLanguage")}}
                        </n-button>
                        <n-button v-else
                        class="btn-simple ncm-btn"
                        style="margin-left: 1.4%"
                        @click="addElement()"
                        disabled
                        >
                        <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                        {{$t("ParameterLanguageEdit.AllLanguagesSelected")}}
                        </n-button>
                </card>
                </b-col>
            </b-row>
        </b-container>

        <!-- Modal save  -->
        <modal :show.sync="modals.save" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
        <p>{{$t("ParameterLanguageEdit.SaveChangesMessage")}}</p>
        <template slot="footer">
            <n-button type="neutral" link @click="editParameter()">
            {{ $t("Ecosystems.Modals.Delete.Footer.Save") }}
            </n-button>
            <n-button type="neutral" link @click.native="modals.save = false">
            {{ $t("Ecosystems.Modals.Delete.Footer.Close") }}
            </n-button>
        </template>
        </modal>

        <!-- Modal exit  -->
        <modal :show.sync="modals.exit" class="modal-primary" :show-close="false" header-classes="justify-content-center">
        <p>{{$t("ParameterLanguageEdit.ExitViewMessage")}}</p>
        <template slot="footer">
            <n-button type="neutral" link @click="exitWithoutChanges()">
            {{ $t("Ecosystems.Modals.Delete.Footer.Save") }}
            </n-button>
            <n-button type="neutral" link @click="modals.exit = false">
            {{ $t("Ecosystems.Modals.Delete.Footer.Close") }}
            </n-button>
        </template>
        </modal>

        <!-- Modal delete  -->
        <modal :show.sync="modals.delete_language" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
        <p>{{$t("ParameterLanguageEdit.DeleteMessage")}} <b>{{ selectedElementDelete.name }} </b>?</p>
        <template slot="footer">
            <n-button type="neutral" link @click="deleteLanguage()">
            {{ $t("Ecosystems.Modals.Delete.Footer.Save") }}
            </n-button>
            <n-button type="neutral" link @click.native="modals.delete_language = false">
            {{ $t("Ecosystems.Modals.Delete.Footer.Close") }}
            </n-button>
        </template>
        </modal>
    </div>
</template> 
<script>
    import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal, DropDown} from '@/components';
    import { API_KEY } from '@/constants'
    import {Table, TableColumn, Tooltip} from 'element-ui';
    import Vue from 'vue'
    import * as VueGoogleMaps from 'vue2-google-maps'
    Vue.use(VueGoogleMaps, {
    load: {
        key: API_KEY
    }
    });
    import ParameterDataService from '@/services/ParameterDataService'
    import LanguageDataService from '@/services/LanguageDataService'
    import EcosystemServiceParameterDataService from '@/services/EcosystemServiceParameterDataService'

export default {
name: 'Ecosystems',
bodyClass: 'profile-page',
components: {
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [FormGroupInput.name]: FormGroupInput,
    [DropDown.name]: DropDown,
    Card
},
data() {
    return {
        modals: {
            modify: false,
            delete_language: false,
            save: false,
            create: false,
            exit: false,
        },
        tableData: [
            
        ],
        ecosystems: [],
        parameters: [],
        languages: [],
        availableLanguages: [],
        selectedElement: null,
        selectedElementDelete: {},
        editing: false,
        languagesToDelete: [],
        headers: [
            { key: 'language.name', sortable: true, label: this.$t('ParameterLanguageEdit.Table.Language'), tdClass: 'table-title input-language-table', thClass: 'table-title position-sticky input-language-table' }, 
            //{ key: 'language.code', sortable: true, label: this.$t('ParameterLanguageEdit.Table.Code'), thClass: 'position-sticky' }, 
            { key: 'name', sortable: true, label: this.$t('ParameterLanguageEdit.Table.Traduction'), tdClass: 'input-traduction-table', thClass: 'position-sticky input-traduction-table' }, 
            { key: 'createdAt', label: this.$t('Ecosystems.Table.CreatedAt'), thClass: 'position-sticky', sortable: true },
            { key: 'updatedAt', label: this.$t('Ecosystems.Table.UpdatedAt'), thClass: 'position-sticky' , sortable: true},
            { key: 'options', label: 'Actions', thClass: 'position-sticky' }
        ],
        currentPage: 1,
        perPage: 10,
        view_to: '/parameters'
      }
  },
  computed: {
    totalPages(){
        if(this.selectedElement)
            return this.selectedElement.languages.length
    },
    // availableLanguages(){
    //     var avilable_languages = []
    //     console.log(this.selectedElement)
    //     for(var language of this.languages){
    //         if(this.selectedElement.languages.filter(f => f.language_id == language.id).length == 0)
    //             avilable_languages.push(language)
    //     }
    //     //this.available_languages = languages
    //     return avilable_languages
    // }
    
    },
  created() {
    this.getData()
  },
  beforeRouteLeave(to, from, next) {
    this.view_to = to
    if(this.editing)  
        this.modals.exit = true
    else
        next()
  },
  methods:{
    goBack(){
        this.$router.push('/parameters')
    },
    openEditModal(item){
        this.editing = true
        this.$set(item, 'editing', !item.editing)
        //this.$set(item, 'name', !item.editing)

    },
    validateState (ref) {
        if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
        ) {
        return !this.veeErrors.has(ref)
        }
        return null
    },
    exitWithoutChanges(){
        console.log('exit without changes')
        this.editing = false
        this.$router.push(this.view_to)
    },
    toggleTitleEdit(){
        this.editing = true
        this.editing_title = !this.editing_title
    },
    showModalDeleteLanguage(language){
        this.modals.delete_language = true
        this.selectedElementDelete = language
    },
    async editParameter(){

        this.$validator.validateAll().then(async result => {
            if (!result) {
            this.modals.save = false
            this.modals.exit = false
            this.$swal.fire({
                icon: 'warning',
                title: this.$t('Alerts.ErrorEditing'),
                text: this.$t('Alerts.ErrorEditingMsg')
                })
            return
            }

            for(var language of this.languages){
                var matched = this.selectedElement.languages.filter(f => f.language_id == language.id).length
                if(matched > 1){
                    this.modals.save = false
                    this.modals.exit = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: this.$t('Alerts.RequiredDiferentLanguages'),
                        text: this.$t('Alerts.RequiredDiferentLanguagesMsg'),
                        })
                    return
                }
            }
            // Chek if delete languages relations

            for(var language_delete of this.languagesToDelete){
                console.log(language_delete)
                ParameterDataService.deleteParameterLanguage(this.selectedElement.id, language_delete.id).then(
                    async response => {
                        console.log(response.data)
                    },
                    error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.message
                        })
                })
            }
            //Chek if create new languages relations
            for(var language of this.selectedElement.languages){
            console.log(language)
                if(language.new){
                    var element = {
                        parameter_id: this.selectedElement.id,
                        language_id: language.language_id,
                        name: language.name
                    }
                    ParameterDataService.createParameterLanguage(element).then(
                        async response => {
                            console.log(response.data)
                        },
                        error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: error.response.data.message
                            })
                    }
                    )
                }
                else{
                    
                    console.log(language)

                    ParameterDataService.updateParameterLanguage(language).then(
                        async response => {
                            console.log(response.data)
                        },
                        error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: error.response.data.message
                            })
                    }
                    )
                }
                
            }
            
            this.editing = false
            this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.ParameterEdited'),
                        text: ''
                        }).then(() => {
                            this.$router.push(this.view_to)
            });

            
        }) 
        
    },
    addElement(){

        this.editing = true
        console.log('addElement')
        var language = {
            name: undefined,
            language: {
                name: undefined,
                code: undefined,
                id: null
            },
            language_id: null,
            new: true,
            editing: true

        }
        this.selectedElement.languages.push(language)
    },
    
    
    async getData(){
    console.log('getData')
        await ParameterDataService.findOne(this.$route.params.id).then(
        async response => {
            console.log(response.data)
            var element = response.data
            for(var language of element.languages){
                language.editing = false
            }
            this.selectedElement = element
            
        },
        error => {
            this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.message
            })
        }
        )
  
        await LanguageDataService.findAll('eraefaef').then(
        async response => {
            console.log(response.data)
            this.languages = response.data
            
            var available_languages = []
            for(var language of response.data){
                if(this.selectedElement.languages.filter(f => f.language_id == language.id).length == 0){
                    available_languages.push(language)
                }
                    
            }
            this.availableLanguages = available_languages
            

        },
        error => {
            this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.message
            })
        }
        )

    },
    updateElement(){
        ParameterDataService.update(this.selectedElement).then(
            async response => {
                console.log(response.data)
                this.modals.modify = false
                this.getData()

            },
            error => {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response.data.message
                })
        }
        )
    },
    deleteElement(){
        ParameterDataService.delete(this.selectedElement.id).then(
                async response => {
                    console.log(response.data)
                    this.modals.delete = false
                    this.getData()
                },
                error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
            }
            )
    },
    deleteLanguage(){
        this.editing = true
        if(!this.selectedElementDelete.new)
            this.languagesToDelete.push({id: this.selectedElementDelete.language.id})
        
        console.log(this.languagesToDelete)

        
        this.modals.delete_language = false
        this.selectedElement.languages.splice(this.selectedElement.languages.indexOf(this.selectedElementDelete), 1)
    },

    
  }
  }
  </script>
<style scoped>

.services-container{
    padding: 20px;
    border: 1px solid rgb(0 0 0 / 13%);
    border-radius: 20px;
}

.service-element{
    margin-top: 2rem;
    padding: 8px;
    border: 1px solid #00000052;
    border-radius: 13px;
    min-width: 48%;
    margin-left: 1%;
    max-width: 48%;
}

.service-container{
    display: flex;
    flex-wrap: wrap;
}

.main-title {
    display: flex;
    width: 100% !important;
    margin-left: 2% !important;
}

.main-title > .form-group{
    font-size: 2.5em;
    font-weight: 400;
}

.select-service-element{
    display: flex;
}





</style>