<template>
    <div class="wrapper-home  collapsed-sidebar" style="margin-top: 5%; max-width: 99%;">
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                        {{ $t('UserHistory.Title') }}
                    </h2> 
                </b-col>
            </b-row>
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <b-alert variant="success" show="" class="custom-alert">
                        <p class="m-auto">
                            {{ $t('MsgUserHistory') }}
                        </p>
                   </b-alert> 
                </b-col>
            </b-row>
            <b-row>
            <b-col>
                <card>
                    <b-row>
                        <b-col cols="12" md="6">
                            <span class="mt-auto mb-auto"><h6 class=" mb-0">{{ $t('UserHistory.Title') }}</h6></span>
                        </b-col>
                        <b-col cols="12" md="4" class="filterCol">
                            <drop-down>
                                <n-button style="margin-top: 2%"
                                    slot="title"
                                    class="dropdown-toggle"
                                    type="primary"
                                    data-toggle="dropdown"
                                >
                                <template v-if="visibilityFilter == undefined">
                                    {{ $t('UserHistory.SelectFilter') }}
                                </template>
                                <template v-else>
                                    {{ $t('UserHistory.Filter') }} <b>{{visibilityFilter}}</b>
                                </template>
                            </n-button>
                                <a v-for="(filter, index) of filters"
                                    style="cursor: default;" class="dropdown-item" :key="index" @click="visibilityFilter = filter" :class="{ 'active': visibilityFilter === filter }"
                                >{{filter}}</a> 
                            </drop-down>
                        </b-col>
                        <b-col cols="12" md="2" class="searchCol">
                            <input type="search" placeholder="Search" class="searcher" v-model="filter">
                        </b-col>
                        
                        <!-- <b-col v-if="role == 'admin'">
                            <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right;">
                                <b-button-group class="mx-1">
                                    <b-button
                                        class="btn-simple ncm-btn"
                                        style="font-weight: 800 !important;"
                                        target="_blank"
                                        @click="$router.push({ path: '/projects-management/add-project' })"
                                    >
                                    <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                                    CREATE
                                    </b-button>
                                </b-button-group>
                            </b-button-toolbar>
                        </b-col> -->
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-table
                            style="overflow-x:auto;" 
                            ref="usersTable"
                            id="usersTable"
                            :fields="headers" 
                            :items="filteredUsers"
                            select-mode="single"
                            responsive="sm"
                            show-empty
                            :current-page="currentPage"
                            :per-page="perPage"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                            sort-by="created_at"
                            :sort-desc="true"> 
                            
                            <template #empty="">
                                {{ $t('UserHistory.NoRole') }}
                            </template>
                            <template #cell(name)="data">
                                {{ data.item.name }} {{ data.item.surname }}
                            </template>
                            <template #cell(email)="data">
                                <b-button v-if="data.item.email != null" class="action-table" v-b-tooltip.hover.viewport :title=data.item.email>
                                    <b-icon icon="mailbox"></b-icon>
                                </b-button>
                                <b-button v-else class="action-table">
                                    <p style=" margin: 0">N/A</p>
                                </b-button>
                            </template>
                            <template #cell(finish_date)="data">
                                <div v-if="!data.item.finish_date">
                                    <b-icon icon="check2"></b-icon>
                                </div>
                                <div v-else>
                                    <b-icon icon="x"></b-icon>
                                </div>
                            </template>

                            <template #cell(user_role)="data">
                                {{ data.item.user_role | formatRole  }}
                            </template>

                            
                            <template #cell(start_date)="data">
                                {{ data.item.start_date | formatDate }}
                            </template>
                            <template #cell(finish_at)="data">
                                <template v-if="data.item.finish_at">
                                    {{ data.item.finish_at | formatDate }}
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </template>
                            </b-table>
                            <div class="pagination-container">
                                <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                                </b-pagination>
                                <b-form-select v-model="perPage" class="pagination-select">
                                    <b-form-select-option :value="5">5</b-form-select-option>
                                    <b-form-select-option :value="10">10</b-form-select-option>
                                    <b-form-select-option :value="20">20</b-form-select-option>
                                    <b-form-select-option
                                        :value="totalPages">{{ $t('UserHistory.All') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </b-col>
                    </b-row>
                </card>
            </b-col>
        </b-row>
    </b-container>
    <!-- Modal modify  -->
    <modal :show.sync="modals.modify" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
            {{ $t('UserHistory.Modify') }}
        </h4>
        <b-form-group :label="$t('Languages.Modals.Modify.Name') + ' *' ">
            <b-form-input v-if="selectedElement"
            v-model="selectedElement.name"
            id="input-name-modify"
            name="input-name-modify"
            v-validate="{ required: true}"
            :state="validateState('input-name-modify')"
            aria-describedby="input-name-modify-live-feedback"
            size="sm"
            :placeholder=" $t('Languages.Modals.Modify.Name') + ' *'  "
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-modify-live-feedback">{{ veeErrors.first('input-name-modify') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Description">
            <b-form-input v-if="selectedElement"
            v-model="selectedElement.description"
            id="input-description-modify"
            name="input-description-modify"
            :state="validateState('input-description-modify')"
            aria-describedby="input-description-modify-live-feedback"
            size="sm"
            placeholder="Description"
            ></b-form-input>
            <b-form-invalid-feedback id="input-description-modify-live-feedback">{{ veeErrors.first('input-description-modify') }}</b-form-invalid-feedback>
        </b-form-group>
        <template slot="footer">
            <n-button class="btn-simple ncm-btn" @click="updateElement">
                {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" @click="modals.modify = false">
                {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
            </n-button>
        </template>
    </modal>
    <!-- Modal delete 
    <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
        <p>Delete Role</p>
        <template slot="footer">
            <n-button type="neutral" link @click="deleteElement">
            {{ $t("Languages.Modals.Delete.Footer.Save") }}
            </n-button>
            <n-button type="neutral" link @click="modals.delete = false">
            {{ $t("Languages.Modals.Delete.Footer.Close") }}
            </n-button>
        </template>
    </modal> -->
    
    </div>
</template>
<script>
  import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal, DropDown} from '@/components';
import ProjectsDataService from '../../services/ProjectsDataService'
import {Table, TableColumn, Tooltip} from 'element-ui';
import OrganizationsDataService from '../../services/OrganizationsDataService';
import RoleServices from '../../services/RoleServices';
export default {
    components : {
        Modal,
        Card,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Tooltip.name]: Tooltip,
        [FormGroupInput.name]: FormGroupInput,
        [DropDown.name]: DropDown,
    },
    data() {
        return {
            projectSelected: null,
            role: null,
            modals: {
                modify: false,
                delete: false,
                create: false,
            },
            selectedAnalysts: [],
            is_active: false,
            shared_wallet: false,
            currentPage: 1,
            perPage: 10,
            user: {},
            name: null,
            description: null,
            visibilityFilter: null,
            organization_id: 31,
            user_id: null,
            proj: [],
            roles: [],
            filters: [this.$t('Languages.Table.Active'),this.$t('Languages.Table.Inactive'),this.$t('Languages.Table.Analyst'),this.$t('Languages.Table.ProjectManager'), this.$t('Languages.Table.Admin')],
            selectedElement: null,
            organizations: [
                { id: "31", name:'Organization 1' },
                { id: "26", name:'Organization 2' },
            ],
            headers:[

                { key:'external_user.name',label:'Name', sortable: true },
                // { key:'surname',label:'Surname'},
                { key:'external_user.email',label:'Email', sortable: true },
                { key:'project.name',label:'Project', sortable: true },
                { key:'organization.name',label:'Organization', sortable: true },
                { key:'user_role',label:'Role' },
                { key:'finish_date',label:'Active' },
                { key:'start_date',label:'Start at', sortable: true },
                { key:'finish_date',label:'Finished at', sortable: true },
            ],
            auxOrganizationID: -99,
            auxOrganizationLS: [],
            allOpenRows: [],
            users: [],
            filterProjects: '',
            filter: null,
            filterOn: [],
        }
    },
    computed : {
        getRoles() {
            return this.roles
        },
        totalPages () {
            return this.getRoles.length
        },
        filteredUsers() {
            if (this.visibilityFilter === null || this.visibilityFilter === 'null') {
                // console.log("Estoy imprimiendo...", this.invitations);
                return this.getRoles; // Return all invitations if no status is selected
            }else if(this.visibilityFilter === 'active'){
                return this.getRoles.filter(item =>(item.finish_date === null));
            }else if(this.visibilityFilter === 'inactive'){
                return this.getRoles.filter(item =>(item.finish_date !== null));
            }else if(this.visibilityFilter === 'analyst'){
                return this.getRoles.filter(item =>(item.user_role === "analyst"));
            }else if(this.visibilityFilter === 'projectManager'){
                return this.getRoles.filter(item =>(item.user_role === "project_manager"));
            }else if(this.visibilityFilter === 'admin'){
                return this.getRoles.filter(item =>(item.user_role === "admin"));
            }else{
                return this.getRoles;
            }
            // return this.invitations.filter(invitation => invitation.status === this.visibilityFilter);
        },
        searchProjects () {
            return this.headers.filter((item) => {
                return item.filterProjects.toLowerCase().includes(this.filterProjects.toLowerCase()) || 
                item.filterProjects.toLowerCase().includes(this.filterProjects.toLowerCase()) ||
                item.name.toLowerCase().includes(this.filterProjects.toLowerCase()) ||
                item.address1.toLowerCase().includes(this.filterProjects.toLowerCase());
            });
        },
      // sortOptions() {
      //   // Create an options list from our fields
      //   return this.headers
      //     .filter(f => f.sortable)
      //     .map(f => {
      //       return { text: f.label, value: f.key }
      //     })
      // }
    },
    watch: {
        $orgSelected(newValue){
            console.log('Org selected changed!', newValue)
            this.organization_id = newValue.id
            this.getData()
        },
        $projectSelected(newValue){
            console.log('project selected changed!', newValue)
            this.projectSelected = newValue
            this.getData()
        }
    },
    created () {
        if (this.$orgSelected.id) {
            this.organization_id = this.$orgSelected.id
        }  
        if (this.$projectSelected.id) {
            this.projectSelected = this.$projectSelected.id
        }   
        // CAMBIAR LA LOGICA PARA CUANDO SON ANALISTAS O ADMINS O VARIOS ROLES Y VER EL ROLE ACTUAL EN EL LOCAL
        // this.role = this.$store.state.auth.user.user_history[0].user_role
        this.checkExternalRole(this.$store.state.auth.user)
        // this.role = this.$store.state.auth.user.user_history[this.$store.state.auth.user.user_history.length - 1 ].user_role
        // this.role = this.$store.state.auth.user.user_history[this.$store.state.auth.user.user_history.length - 1 ].user_role
        if (this.organization_id) {
            this.getData()
        }
       
    },
    // beforeUpdate() {
    //     if (this.tempCheckLanguage != this.$i18n.t('UserHistory.Filter')) {
    //         this.tempCheckLanguage = this.$i18n.t('UserHistory.Filter')
    //         this.getData()
    //     }
    // },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkExternalRole(user){
            if(this.$roleSelected.user_role == 'admin')
                this.role = 'admin'
            else
                this.role = 'other'
        },
        getData () {
            if (!this.organization_id) {
                return 
            } 
            if (!this.projectSelected) {
                OrganizationsDataService.findRolesByOrg(this.organization_id, null).then(
                    Response => {
                        this.roles = Response.data.data
                        this.roles.forEach(element => {
                            if (element.role == "admin") {
                                element.role = localStorage.getItem('lang') == "es" ? "Administrador" : "Admin"
                            }
                            if (element.role == "project_manager") {
                                element.role = localStorage.getItem('lang') == "es" ? "Jefe de proyecto" : "Project Manager"
                            }
                            if (element.role == "analyst") {
                                element.role = localStorage.getItem('lang') == "es" ? "Analista" : "Analyst"
                            }
                        });
                        return
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                    }
                )
            } else {
                if (this.projectSelected.id) {
                    this.projectSelected = this.projectSelected.id
                }
                OrganizationsDataService.findRolesByOrg(this.organization_id, this.projectSelected).then(
                    Response => {
                        this.roles = Response.data.data
                        console.log('ESTOS SON LOS ROLES QUE LLEGAN', this.roles)
                        this.roles.forEach(element => {
                            if (element.role == "admin") {
                                element.role = localStorage.getItem('lang') == "es" ? "Administrador" : "Admin"
                            }
                            if (element.role == "project_manager") {
                                element.role = localStorage.getItem('lang') == "es" ? "Jefe de proyecto" : "Project Manager"
                            }
                            if (element.role == "analyst") {
                                element.role = localStorage.getItem('lang') == "es" ? "Analista" : "Analyst"
                            }
                        });
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                    }
                )
            }
            
        },
        updateElement(){
            this.$validator.validateAll(['input-name-modify', 'input-description-modify']).then(async result => {
                if (!result) {
                    return
                }
                console.log(this.selectedElement)
                ProjectsDataService.update(this.selectedElement).then(
                    async response => {
                        console.log(response.data)
                        this.modals.modify = false
                        this.$swal.fire({
                            icon: 'success',
                            title: this.$t('Alerts.ProjectModified'),
                            text: ''
                            }).then(() => {
                                this.getData()
                        });

                    },
                    error => {
                        this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                }
                )
            })
        },
        validateState (ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref)
            }
            return null
        },
    },    
}
</script>

<style>
.custom-alert {
    background-color: #597656 !important; 
    color: #ffffff !important;
    margin-top: 10px !important;
    border-radius: 5px !important;
    font-size: 11px;
    padding: 8px 12px; 
    font-weight: bold; 
    text-align: center !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.user-details {
    margin-bottom: 0px;
    font-size: 11px
}

.user-details-name{
    margin-bottom: 0px;
    font-size: 14px;
}
.dropdownFilter{
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #666666;
    color: #0a0a0a;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
}
.dropdown-item.active {
  font-weight: bold; 
  background-color: white !important;
  color: #5D6176 !important;
}

.searcher{
    border: 1px solid #a5a5a5;
    border-radius: 25px;
    padding-left: 1em;
    padding-right: 1em;
    height: 80%;
    background: transparent;
}

@media(max-width: 1590px){
    .searchCol{
        margin-right: 20px;
        justify-items: center;
    }

}

@media(max-width: 1024px){
    .filterCol{
        margin-right: 20px;
    }
}

@media (max-width: 768px) {
  .filterCol,
  .searchCol {
    margin-top: 10px;
    /* float: left!important; */
    width: auto;
    justify-content: center;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
</style>
