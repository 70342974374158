<template>
    <div class="wrapper  dashboard-wrapper collapsed-sidebar">
        <div class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                        {{ $t("Settings.Title") }}
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="4">
                    <card type="profile" class="mt-0" style="height: 468px;">
                        <div class="divProfile">
                            <div class="card-avatar">
                                <template v-if="editingAvatar">
                                    <img v-if="imagePathEditing == null" class="img img-raised"
                                        :src="$getImgUrlSite('default-avatar.png')">
                                    <img class="img img-raised" :src="imagePathEditing">
                                </template>
                                <template v-else>
                                    <img v-if="imagePath == null" class="img img-raised"
                                        :src="$getImgUrlSite('default-avatar.png')">
                                    <img class="img img-raised" v-else :src="getImgUrl(imagePath)">
                                </template>
                            </div>
                            <h6 class="card-category">
                                {{ $t("Settings.User") }}
                            </h6>
                            <h4 class="card-title">
                                {{ this.copyUser.name }} {{ this.copyUser.surname }}
                            </h4>
                            <h5 class="card-title">
                                {{ this.copyUser.email }}
                            </h5>

                            <div class="card-footer">
                                <n-button id="newAdmin" class="btn-simple ncm-btn" target="_blank"
                                    @click.native="openFileInput()">
                                    <b-icon icon="cloud-arrow-up-fill" class="button-icon"></b-icon>
                                    {{ $t("Profile.Image.Upload") }}
                                </n-button>
                                <input ref="fileInput" type="file" name="profile-images" @change="setImage">
                                <n-button id="newAdmin" class="btn-simple ncm-btn-danger" target="_blank"
                                    @click.native="deleteImage()">
                                    <b-icon icon="trash-fill" class="button-icon"></b-icon>
                                    {{ $t("Profile.Image.Delete") }}
                                </n-button>
                                <template v-if="editingAvatar">
                                    <n-button type="primary" class="btn-simple btn-round btn-icon icon-title-dashboard"
                                        v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Save')"
                                        @click.native="updateAvatar()">
                                        <b-icon icon="check-lg"></b-icon>
                                    </n-button>
                                    <n-button type="danger" class="btn-simple btn-icon icon-title-dashboard"
                                        v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Cancel')"
                                        @click.native="toggleEditCancelAvatar()">
                                        <b-icon icon="x-lg"></b-icon>
                                    </n-button>
                                </template>
                            </div>
                        </div>
                    </card>
                </b-col>

                <b-col cols="12" lg="8">
                    <card>
                        <b-row>
                            <b-col cols="12" lg="4">
                                <h3 class="category text-primary float-left">
                                    <b-icon icon="gear-wide-connected"></b-icon>
                                    {{ $t("Profile.AdvancedInfo") }}
                                </h3>
                            </b-col>
                            <b-col cols="12" lg="2">
                                <b-form-group label="Favourite Org ★">
                                    <b-form-select v-if="!changeFav" value-field="id" text-field="name"
                                        v-model="org_fav" disabled>
                                        <b-form-select-option value="null">{{ $t("Settings.NoFavOrg")
                                            }}</b-form-select-option>
                                        <b-form-select-option v-for="org in getOrganizations" :key="org.id"
                                            :value="org.id">
                                            {{ org.name }}
                                        </b-form-select-option>
                                    </b-form-select>
                                    <b-form-select v-else v-model="org_fav">
                                        <b-form-select-option value="null">{{ $t("Settings.NoFavOrg")
                                            }}</b-form-select-option>
                                        <b-form-select-option v-for="org in getOrganizations" :key="org.id"
                                            :value="org.id">
                                            {{ org.name }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3">
                                <b-form-group label="Favourite Role ★">
                                    <b-form-select v-if="!changeFav" value-field="id" text-field="name"
                                        v-model="role_fav" disabled>
                                        <b-form-select-option value="null">{{ $t("Settings.NoFavRole")
                                            }}</b-form-select-option>
                                        <b-form-select-option v-for="role in getRolesOrg" :key="role.user_role"
                                            :value="role.user_role">
                                            {{ role.user_role }}
                                        </b-form-select-option>
                                    </b-form-select>
                                    <b-form-select v-else v-model="role_fav">
                                        <b-form-select-option value="null">{{ $t("Settings.NoFavRole")
                                            }}</b-form-select-option>
                                        <b-form-select-option v-for="role in getRolesOrg" :key="role.user_role"
                                            :value="role.user_role">
                                            {{ role.user_role }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3">
                                <b-form-group>
                                    <template v-if="!changeFav">
                                        <n-button type="primary" class="btn-simple ncm-btn btn-icon mt-3"
                                            @click.native="changeFav = true">
                                            <b-icon icon="star-half" class="button-icon"></b-icon>
                                        </n-button>
                                    </template>
                                    <template v-else>
                                        <div class="mt-3">
                                            <n-button type="primary"
                                                class="btn-simple btn-round btn-icon icon-title-dashboard m-auto"
                                                v-b-tooltip.hover :title="$t('Profile.Tooltips.Save')"
                                                @click.native="setFav()">
                                                <b-icon icon="check-lg"></b-icon>
                                            </n-button>
                                            <n-button type="danger"
                                                class="btn-simple btn-icon icon-title-dashboard m-auto"
                                                v-b-tooltip.hover :title="$t('Profile.Tooltips.Cancel')"
                                                @click.native="changeFav = false">
                                                <b-icon icon="x-lg"></b-icon>
                                            </n-button>
                                        </div>
                                    </template>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <template>
                            <b-row>
                                <b-col>
                                    <b-form-group label="Organizations" class="font-weight-bold" />
                                </b-col>
                                <b-col>
                                    <b-button class="btn-simple ncm-btn float-right mt-n2"><b-icon
                                            icon="plus-circle-fill" class="button-icon"></b-icon>Add Org</b-button>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-table style="overflow-x:auto;" ref="usersTable" id="usersTable" :fields="headers"
                                        :items="getOrganizations" responsive="sm" show-empty :current-page="currentPage"
                                        :per-page="perPage">
                                        <template #empty>
                                            {{ $t("Settings.NoOrgs") }}
                                        </template>
                                        <template #cell(options)>
                                            <b-button class="action-table" v-b-tooltip.hover.viewport
                                                title="Exit Organization">
                                                <b-icon icon="box-arrow-right"></b-icon>
                                            </b-button>
                                        </template>
                                    </b-table> 
                                    <div class="pagination-container">
                                        <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                                        </b-pagination>
                                        <b-form-select v-model="perPage" class="pagination-select">
                                            <b-form-select-option :value="5">5</b-form-select-option>
                                            <b-form-select-option :value="10">10</b-form-select-option>
                                            <b-form-select-option :value="20">20</b-form-select-option>
                                            <b-form-select-option
                                                :value="getOrganizations.length">{{ $t('Settings.All') }}</b-form-select-option>
                                        </b-form-select>
                                    </div>
                                </b-col>
                            </b-row>
                        </template>
                    </card>
                </b-col>
                <b-col cols="12" lg="4">
                    <card>
                        <b-row>
                            <b-col>
                                <h3 class="category text-primary float-left">
                                    <b-icon icon="file-person-fill" class="mr-2"></b-icon>
                                    {{ $t("Profile.PersonalInfo") }}
                                </h3>
                                <template v-if="!editPersonalInfo">
                                    <n-button type="primary" class="btn-simple btn-icon icon-title-dashboard"
                                        @click.native="toggleEdit()">
                                        <b-icon icon="pen"></b-icon>
                                    </n-button>
                                </template>
                                <template v-else>
                                    <n-button type="primary" class="btn-simple btn-round btn-icon icon-title-dashboard"
                                        v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Save')"
                                        @click.native="editPersonalInformation()">
                                        <b-icon icon="check-lg"></b-icon>
                                    </n-button>
                                    <n-button type="danger" class="btn-simple btn-icon icon-title-dashboard"
                                        v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Cancel')"
                                        @click.native="toggleEditCancel()">
                                        <b-icon icon="x-lg"></b-icon>
                                    </n-button>
                                </template>
                            </b-col>
                        </b-row>
                        <b-row class="mb-4">
                            <template v-if="!editPersonalInfo">
                                <b-col md="6">
                                    <b-form-group :label="$t('Profile.Labels.Name')">
                                        <b-form-input v-model="user.name" disabled></b-form-input>
                                    </b-form-group>
                                    <b-form-group :label="$t('Profile.Labels.LastName')">
                                        <b-form-input v-model="user.surname" disabled></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group :label="$t('Profile.Labels.Phone')">
                                        <b-form-input v-model="user.phone" disabled></b-form-input>
                                    </b-form-group>
                                    <b-form-group :label="$t('Profile.Labels.Email')">
                                        <b-form-input v-model="user.email" disabled></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </template>
                            <template v-else>
                                <b-col md="6">
                                    <b-form-group :label="$t('Profile.Labels.Name')">
                                        <b-form-input v-model="user.name" id="input-dataPersonalInformation-name"
                                            name="input-dataPersonalInformation-name"
                                            v-validate="{ required: true, alpha_spaces: true }"
                                            :state="validateState('input-dataPersonalInformation-name')"
                                            aria-describedby="input-dataPersonalInformation-name-live-feedback"
                                            size="sm" :placeholder="$t('DashBoardStaff.Modals.Modify.Name')">
                                        </b-form-input>
                                        <b-form-invalid-feedback id="input-dataPersonalInformation-name-live-feedback">
                                            {{ veeErrors.first('input-dataPersonalInformation-name') }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                    <b-form-group :label="$t('Profile.Labels.LastName')">
                                        <b-form-input v-model="user.surname" id="input-dataPersonalInformation-surname"
                                            name="input-dataPersonalInformation-surname"
                                            v-validate="{ required: true, alpha_spaces: true }"
                                            :state="validateState('input-dataPersonalInformation-surname')"
                                            aria-describedby="input-dataPersonalInformation-surname-live-feedback"
                                            size="sm" :placeholder="$t('DashBoardStaff.Modals.Modify.Surname')">
                                        </b-form-input>
                                        <b-form-invalid-feedback
                                            id="input-dataPersonalInformation-surname-live-feedback">
                                            {{ veeErrors.first('input-dataPersonalInformation-surname') }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group :label="$t('Profile.Labels.Phone')">
                                        <b-form-input v-model="user.phone" id="input-dataPersonalInformation-phone"
                                            name="input-dataPersonalInformation-phone" v-validate="{ integer: true }"
                                            :state="validateState('input-dataPersonalInformation-phone')"
                                            aria-describedby="input-dataPersonalInformation-phone-live-feedback"
                                            size="sm" :placeholder="$t('DashBoardStaff.Modals.Modify.Phone')">
                                        </b-form-input>
                                        <b-form-invalid-feedback id="input-dataPersonalInformation-phone-live-feedback">
                                            {{ veeErrors.first('input-dataPersonalInformation-phone') }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </template>
                        </b-row>
                    </card>
                </b-col>
                <b-col cols="12" lg="8">
                    <card>
                        <b-row>
                            <b-col cols="12">
                                <h3 style="float: left;" class="category text-primary">
                                    <b-icon icon="lock-fill"></b-icon> {{ $t("Profile.Security") }}
                                </h3>
                                <template v-if="changePass">
                                    <n-button type="primary" class="btn-simple btn-round btn-icon icon-title-dashboard"
                                        v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Save')"
                                        @click="editPassword()">
                                        <b-icon icon="check-lg"></b-icon>
                                    </n-button>
                                    <n-button type="danger" class="btn-simple btn-icon icon-title-dashboard"
                                        v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Cancel')"
                                        @click="toggleEditCancelPass()">
                                        <b-icon icon="x-lg"></b-icon>
                                    </n-button>
                                </template>
                            </b-col>
                        </b-row>
                        <template v-if="!changePass">
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group style="font-weight: 600;">
                                        <div>
                                            <n-button id="newAdmin" class="btn-simple ncm-btn" target="_blank"
                                                @click.native="openChangePass()">
                                                <b-icon icon="key-fill" class="button-icon"></b-icon>
                                                {{ $t("Profile.ChangePassword") }}
                                            </n-button>
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </template>
                        <template v-else>
                            <b-row>
                                <b-col cols="10" md="8">
                                    <b-form-group :label="$t('Profile.Labels.CurrentPassword')">
                                        <div class="d-flex">
                                            <b-form-input v-model="dataPass.currentPassword"
                                                id="input-dataPass-currentPassword"
                                                name="input-dataPass-currentPassword" v-validate="{ required: true }"
                                                type="password"
                                                aria-describedby="input-dataPass-currentPassword-live-feedback"
                                                :placeholder="$t('DashBoardStaff.Modals.NewStaff.Password')"
                                                class="no-border input-lg"
                                                :state="validateState('input-dataPass-currentPassword')">
                                            </b-form-input>
                                        </div>
                                        <b-form-invalid-feedback id="input-dataPass-currentPassword-live-feedback">
                                            {{ veeErrors.first('input-dataPass-currentPassword') }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="2" class="d-flex align-items-center justify-content-center">
                                    <span v-if="!variablePass" class="iconPass m-auto" @click="showPass()">
                                        <b-icon icon="eye" class="iconPass" style="color: black;"></b-icon>
                                    </span>
                                    <span v-if="variablePass" class="iconPass m-auto" @click="showPass()">
                                        <b-icon icon="eye-slash" class="iconPass" style="color: black;"></b-icon>
                                    </span>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="10" md="8">
                                    <b-form-group :label="$t('Profile.Labels.Password')">
                                        <div class="d-flex">
                                            <b-form-input v-model="dataPass.newPassword" id="input-dataPass-newPassword"
                                                name="input-dataPass-newPassword" v-validate="{ required: true }"
                                                type="password"
                                                aria-describedby="input-dataPass-newPassword-live-feedback"
                                                :placeholder="$t('DashBoardStaff.Modals.NewStaff.Password')"
                                                class="no-border input-lg"
                                                :state="validateState('input-dataPass-newPassword')">
                                            </b-form-input>
                                        </div>
                                        <b-form-invalid-feedback id="input-dataPass-newPassword-live-feedback">
                                            {{ veeErrors.first('input-dataPass-newPassword') }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="2" class="d-flex align-items-center justify-content-center">
                                    <span v-if="!variablePass1" class="iconPass m-auto" @click="showPass1()">
                                        <b-icon icon="eye" class="iconPass" style="color: black;"></b-icon>
                                    </span>
                                    <span v-if="variablePass1" class="iconPass m-auto" @click="showPass1()">
                                        <b-icon icon="eye-slash" class="iconPass" style="color: black;"></b-icon>
                                    </span>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="10" md="8">
                                    <b-form-group :label="$t('Profile.Labels.RepeatPassword')">
                                        <div class="d-flex">
                                            <b-form-input v-model="dataPass.newPasswordRepeat"
                                                id="input-dataPass-newPasswordRepeat"
                                                name="input-dataPass-newPasswordRepeat" v-validate="{ required: true }"
                                                type="password"
                                                aria-describedby="input-dataPass-newPasswordRepeat-live-feedback"
                                                :placeholder="$t('DashBoardStaff.Modals.NewStaff.Password')"
                                                class="no-border input-lg"
                                                :state="validateState('input-dataPass-newPasswordRepeat')">
                                            </b-form-input>
                                        </div>
                                        <b-form-invalid-feedback id="input-dataPass-newPasswordRepeat-live-feedback">
                                            {{ veeErrors.first('input-dataPass-newPasswordRepeat') }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="2" class="d-flex align-items-center justify-content-center">
                                    <span v-if="!variablePass2" class="iconPass m-auto" @click="showPass2()">
                                        <b-icon icon="eye" class="iconPass" style="color: black;"></b-icon>
                                    </span>
                                    <span v-if="variablePass2" class="iconPass m-auto" @click="showPass2()">
                                        <b-icon icon="eye-slash" class="iconPass" style="color: black;"></b-icon>
                                    </span>
                                </b-col>
                            </b-row>
                        </template>
                    </card>
                </b-col>
            </b-row>
        </div>

        <!-- Modal delete  -->
        <modal :show.sync="modals.delete" class="modal-primary" :show-close="false"
            header-classes="justify-content-center" type="mini">
            <p>{{ $t("Settings.Exit") }}</p>
            <template slot="footer">
                <n-button type="neutral" link @click="exitOrganization">
                    {{ $t("Languages.Modals.Delete.Footer.Save") }}
                </n-button>
                <n-button type="neutral" link @click="modals.delete = false">
                    {{ $t("Languages.Modals.Delete.Footer.Close") }}
                </n-button>
            </template>
        </modal>
    </div>
</template>

<script>
const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $
import StaffServices from '../../services/StaffServices'
import ExternalUserDataService from '../../services/ExternalUserDataService';
import EcosystemServicesTypes from '../../services/EcosystemServicesTypesDataService';
import OrganizationsDataService from '@/services/OrganizationsDataService'
import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal } from '@/components';
import { API_KEY } from '@/constants'
import { Table, TableColumn, Tooltip } from 'element-ui';
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import Login from '../../services/Login';
Vue.use(VueGoogleMaps, {
    load: {
        key: API_KEY
    }
});
export default {
    name: 'DashboardUser',
    bodyClass: 'dashboard-user',
    components: {
        Card,
        Modal,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Tooltip.name]: Tooltip,
        [FormGroupInput.name]: FormGroupInput,
    },
    data() {
        return {
            modals: {
                modify: false,
                delete: false,
            },
            org_fav: null,
            role_fav: null,
            currentPage: 1,
            perPage: 5,
            name: null,
            description: null,
            organization_id: -1,
            proj: [],
            // organizations: [],
            selectedElement: null,
            changeFav: false,
            organizations: [
                { id: "31", name: 'Organization 1', info: 'This organization is for Salamanca' },
                { id: "26", name: 'Organization 2', info: 'This organization is for Avila' },

            ],
            headers: [
                // { key: 'id',label: 'Id' },
                { key: 'name', label: 'Name' },
                { key: 'info', label: 'Info' },
                { key: 'options', label: 'Actions', thClass: 'position-sticky' }
            ],
            variablePass: false,
            variablePass1: false,
            variablePass2: false,
            user_id: null,
            user: {},
            copyUser: {},
            dataPass: {},
            // modals: {
            //     exit: false
            // },
            editPersonalInfo: false,
            changePass: false,
            changeFav: false,
            // myRole: [],
            // headersRole: [
            // { key: 'ecosystem_language_module', label: this.$t('DashBoardStaff.Table.EcosystemLanguage') },
            // { key: 'layer_module', label: this.$t('DashBoardStaff.Table.LayerModule') },
            // { key: 'message_module', label: this.$t('DashBoardStaff.Table.MessageModule') },
            // { key: 'organization_module', label: this.$t('DashBoardStaff.Table.OrganizationModule') },
            // { key: 'report_module', label: this.$t('DashBoardStaff.Table.ReportModule') },
            // { key: 'blog_module', label: this.$t('DashBoardStaff.Table.BlogModule') },
            // { key: 'budget_module', label: this.$t('DashBoardStaff.Table.BudgetModule') },
            // ],
            types: [],
            roles: [],
            ecosystem_types: [],
            categories: [],
            favourite_ecosystem_service_category_type: null,
            favourite_ecosystem_category_type: null,
            copy_favourite_ecosystem_service_category_type: null,
            copy_favourite_ecosystem_category_type: null,
            image: null,
            imagePath: null,
            imagePathEditing: null,
            editingAvatar: false,
            view_to: '/'
        }
    },
    computed: {
        getOrganizations() {
            return this.organizations
        },
        totalPages() {
            return this.getOrganizations.length
        },
        getRolesOrg() {
            return this.roles
        }
    },
    created() {
        let data = JSON.parse(localStorage.getItem('user'))
        this.user_id = data.data.id
        this.org_fav = data.data.favourite_organization
        this.getData()
        console.log(this.role_fav)
    },
    watch: {
        org_fav(newOrgFav) {
            ExternalUserDataService.getUserRole(newOrgFav, this.user_id, 'false').then(
                Response => {
                    this.roles = Response.data.data
                    const favoriteRole = this.roles.find(role => role.is_favourite === true)
                    this.role_fav = favoriteRole ? favoriteRole.user_role : null
                    console.log(this.role_fav)
                    console.log(this.roles)

                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            );
        }
    },

    // beforeRouteLeave(to, from, next) {
    //     console.log('view to:', to)
    //     this.view_to = to
    //     if (this.editing)  
    //         this.modals.exit = true
    //     else
    //         next()
    // },
    methods: {
        getData() {
            // this.myRole = []
            ExternalUserDataService.findOne(this.user_id).then(
                Response => {
                    this.user = Response.data.data
                    this.imagePath = this.user.profile_image
                    this.copyUser = { ...this.user }
                }
            )
            OrganizationsDataService.getOrganizationsFromAUser(this.user_id).then(
                Response => {
                    this.organizations = Response.data.data
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
            ExternalUserDataService.getUserRole(this.org_fav, this.user_id, 'false').then(
                Response => {
                    this.roles = Response.data.data
                    const favoriteRole = this.roles.find(role => role.is_favourite === true)
                    this.role_fav = favoriteRole ? favoriteRole.user_role : null
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        openDeleteModal(element) {
            console.log(element)
            this.selectedElement = element
            this.organization_id = element.id
            this.modals.delete = true
        },
        exitOrganization() {
            console.log(this.organization_id);
            OrganizationsDataService.exitOrganization(this.user_id, this.organization_id).then(
                async Response => {
                    console.log(Response.data)
                    this.modals.delete = false
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.OrganizationWithdrawn'),
                        text: ''
                    }).then(() => {
                        this.getData()
                    });

                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        setFav() {
            let data = {
                id: this.user_id,
                organization_id: this.org_fav,
                favourite_role: this.role_fav
            }
            console.log(data)
            ExternalUserDataService.setFav(data).then(
                Repsonse => {
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.FavOrgChanged')
                    }).then(() => {
                        this.getData()
                        this.changeFav = !this.changeFav
                    });
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        editPersonalInformation() {
            this.$validator.validateAll(['input-dataPersonalInformation-name', 'input-dataPersonalInformation-surname', 'input-dataPersonalInformation-phone']).then(result => {
                if (!result) {
                    return
                }
                this.user.id = this.user_id
                ExternalUserDataService.update(this.user).then(
                    Response => {
                        if (Response.status === 200) {
                            this.$swal.fire({
                                icon: 'success',
                                title: this.$t('Alerts.UserUpdated')
                            }).then(() => {
                                this.getData()
                                // this.editing = false
                                this.editPersonalInfo = !this.editPersonalInfo
                            });
                        }
                    },
                    Error => {
                        if (Error.Response.status === 404) {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Profile.Alerts.EditPersonalInfo.NotFound')
                            })
                        }
                        if (Error.Response.status === 400) {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Profile.Alerts.EditPersonalInfo.BadRequest')
                            })
                        }
                        if (Error.Response.status === 500) {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Errors.InternalServerError')
                            })
                        }
                    })
            })
        },
        toggleEdit() {
            // this.editing = true
            this.$swal.fire({
                icon: 'info',
                text: this.$t('Profile.Alerts.EditPersonalInfo.Info')

                // text: this.$t('Profile.Alerts.EditPersonal.Info.')
            })
            this.editPersonalInfo = !this.editPersonalInfo
        },
        toggleEditCancel() {
            // this.editing = false
            this.getData()
            this.editPersonalInfo = !this.editPersonalInfo
        },

        toggleEditCancelAvatar() {
            // this.editing = false
            this.editingAvatar = false
            this.$refs['fileInput'].value = ''
            this.getData()
        },
        openChangePass() {
            this.editing = true
            this.changePass = true
        },
        openChangeFav() {
            this.changeFav = true
        },
        editPassword() {
            this.$validator.validateAll(['input-dataPass-currentPassword', 'input-dataPass-newPassword', 'input-dataPass-newPasswordRepeat']).then(result => {
                if (!result) {
                    return
                }
                let dataChange = this.dataPass
                if (dataChange.newPassword != dataChange.newPasswordRepeat) {
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Profile.Alerts.EditPersonalInfo.DontMatch')
                    })
                    return
                }
                console.log(this.dataPass);
                // End point
                ExternalUserDataService.changePass(this.dataPass).then(
                    Response => {
                        if (Response.status === 200) {
                            this.$swal.fire({
                                icon: 'success',
                                title: this.$t('Alerts.PassordUpdated')
                            }).then(() => {
                                this.getData()
                                this.changePass = !this.changePass
                                this.dataPass = {}
                            })
                        }
                    },
                    Error => {
                        if (Error.Response.status === 404) {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Errors.NotFound')
                            })
                        }
                        if (Error.Response.status === 400) {
                            console.log(Error.Response)
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Errors.BadRequest')
                            })
                        }
                        if (Error.Response.status === 500) {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Errors.InternalServerError')
                            })
                        }
                    }
                )
            })
        },

        exitWithoutChanges() {
            console.log('exit without changes')
            this.modals.exit = false
            this.editing = false
            this.$router.push(this.view_to)
        },
        toggleEditCancelPass() {
            // this.editing = false
            this.$validator.reset()
            this.dataPass = {}
            this.changePass = !this.changePass
        },
        toggleEditCancelFav() {
            this.changeFav = !this.changeFav
            if (this.copy_favourite_ecosystem_category_type == null) this.favourite_ecosystem_category_type = null
            else this.favourite_ecosystem_category_type = this.copy_favourite_ecosystem_category_type
            if (this.copy_favourite_ecosystem_service_category_type == null) this.favourite_ecosystem_service_category_type = null
            else this.favourite_ecosystem_service_category_type = this.copy_favourite_ecosystem_service_category_type
            console.log(this.copy_favourite_ecosystem_category_type)
            console.log(this.favourite_ecosystem_category_type)
        },
        showPass() {
            if (!this.variablePass) this.variablePass = true
            else this.variablePass = false
            var tipo = document.getElementById("input-dataPass-currentPassword");
            if (tipo.type == "password") {
                tipo.type = "text";
            } else {
                tipo.type = "password";
            }
        },
        showPass1() {
            if (!this.variablePass1) this.variablePass1 = true
            else this.variablePass1 = false
            var tipo = document.getElementById("input-dataPass-newPassword");
            if (tipo.type == "password") {
                tipo.type = "text";
            } else {
                tipo.type = "password";
            }
        },
        showPass2() {
            if (!this.variablePass2) this.variablePass2 = true
            else this.variablePass2 = false
            var tipo = document.getElementById("input-dataPass-newPasswordRepeat");
            if (tipo.type == "password") {
                tipo.type = "text";
            } else {
                tipo.type = "password";
            }
        },
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref)
            }
            return null
        },
        openFileInput() {
            this.$refs['fileInput'].click()
        },
        setImage() {
            this.editingAvatar = true
            this.editing = true
            this.image = this.$refs['fileInput'].files[0]
            this.imagePathEditing = URL.createObjectURL(this.image)
            console.log(this.image)
            console.log(this.imagePathEditing)

        },
        deleteImage() {
            this.editingAvatar = true
            this.image = null
            this.imagePathEditing = null
        },
        updateAvatar() {

            var user_id = this.$store.state.auth.user.data.id
            console.log("user_id:", this.$store.state.auth.user.data.id);
            var formData = new FormData()
            console.log(this.image)
            // formData.append('id', user_id)
            // formData.append('delete_path', this.imagePath)
            formData.append('profile-images', this.image) // appending file
            console.log("formData:", formData);


            ExternalUserDataService.updateAvatar(formData).then(
                Response => {
                    if (Response.status == 200) {
                        this.$swal.fire({
                            icon: 'success',
                            title: this.$t('Profile.Alerts.UpdateAvatar.OK')
                        }).then(() => {
                            this.editingAvatar = false
                            this.$profileImageGlobal = Response.data.user.profile_image
                            this.$store.state.auth.user.data.profile_image = Response.data.user.profile_image
                            this.imagePath = Response.data.user.profile_image
                            let user = this.$store.state.auth.user
                            localStorage.setItem('user', JSON.stringify(user))
                            this.getData()
                        });

                    }

                },
                Error => {
                    console.log((Error.Response && Error.Response.data) || Error.message || Error.toString())
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Profile.Alerts.UpdateAvatar.Error')
                    });
                }

            )

        },
        getImgUrl(name) {
            try {
                if (name) {
                    return `${process.env.VUE_APP_VITE_BUCKET_CDN}/profile-images/${name}?withheaders=yes`
                    /// Regular exp for png: \.png$/
                    //var images = require.context('../../assets/profile_images/', false, /\.png$/)
                    //console.log(images('./' + name))
                    //return images('./' + name)
                }
            } catch {
                this.imagePath = null
            }
        },
        // addFav () {
        //     let data = {
        //         user_id : this.user_id,
        //         ecosystem_category_type: this.favourite_ecosystem_category_type,
        //         ecosystem_service_category_type: this.favourite_ecosystem_service_category_type
        //     }
        //     console.log(data)
        //     StaffServices.updateFav(data).then(
        //         Response => {
        //         console.log(Response)
        //         this.$swal.fire({
        //                 icon: 'success',
        //                 title: this.$t('Profile.Alerts.UpdateFav.OK')
        //                 }).then(() => {
        //                     this.getData()
        //                     this.changeFav = false
        //                 });
        //         },
        //         Error => {
        //         console.log('Error upateFav categories')
        //         }
        //     )
        // }

    }
}
</script>
<style scoped>
input[type="file"] {
    display: none;
}
.divProfile{
    margin-top: 35px;
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
</style>