import http from '../http-common'
import authHeader from "./AuthHeaderService"
const API_TUTORIAL_URL_PATH = 'api/tutorial/'
class TutorialDataService { 
    
    findAll () {
        return http.get(API_TUTORIAL_URL_PATH + 'tutorials', {headers: authHeader()})
    }

    findAllExplore () {
        return http.get(API_TUTORIAL_URL_PATH + 'explore')
    }

    findOne (id) {
        const body = {id: id}
        return http.get(API_TUTORIAL_URL_PATH + 'tutorial?id=' + id)
    }
    findOneByType (type) {
        const body = {type: type}
        return http.post(API_TUTORIAL_URL_PATH + 'blog-type', body)
    }
    create (data) {
        return http.post(API_TUTORIAL_URL_PATH + 'create-tutorial', data, {headers: authHeader()})
    }
    modify (data) {
        return http.put(API_TUTORIAL_URL_PATH + 'update-tutorial', data, {headers: authHeader()})
    }
    delete (id) {
        return http.delete(API_TUTORIAL_URL_PATH + 'delete-tutorial?id=' + id, {headers: authHeader()})
    }

    findOneBySlug (title_slug) {
        return http.get(API_TUTORIAL_URL_PATH + 'find-by-slug?slug='+title_slug)
    }
    
}
export default new TutorialDataService()
