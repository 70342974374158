<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar">
        <div v-if="loading" id="toHidden"
            style="
            position: absolute;
            top     :0;
            left    : 0;
            height  : 100%;
            width   :  100%;
            background-color:azure;
            z-index : 1060;
            opacity : 0.6;"
            >
        </div>
        <b-icon  v-if="loading" style="
            z-index: 2000;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            "
            icon="three-dots"
            animation="cylon"
            font-scale="4">
        </b-icon>
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                        {{$t('PanelCadastres.DataLayers')}}
                    </h2> 
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <card>
                        <b-row>
                            <b-col>
                                <h6 class="titlePanel">{{$t('PanelCadastres.DataLayersList')}}</h6>
                            </b-col>
                            <b-col>
                                <b-button-toolbar class="buttonPanel" key-nav aria-label="Toolbar with button groups" style="float: right">
                                    <b-button-group class="mx-1">
                                        <n-button id="newAdmin" class="btn-simple ncm-btn"
                                            target="_blank" @click.native="openEditModal()">
                                            <b-icon icon="cloud-arrow-up" class="button-icon"></b-icon>
                                            {{$t('Languages.UploadLayer')}}
                                        </n-button>
                                    </b-button-group>
                                </b-button-toolbar>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table id="tabl_layers" class="table table-hover"
                                    style="max-height: 400px !important; min-height: 400px !important; overflow-x: auto !important;"
                                    ref="table_layers"
                                    :items="data_layers"
                                    :fields="data_layers_headers"
                                    responsive="sm"
                                    show-empty
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    selectable
                                    sort-by="Name"
                                    select-mode="single"
                                    @row-selected="selectRow"
                                    @row-clicked="handleRowClicked"
                                    @sort-changed="sortingChanged"
                                    :sort-desc="true"
                                > 
                                    <template #empty="">
                                        No data layers
                                    </template>
                                    <template #cell(is_active)="data">
                                        <div v-if="data.item.is_active == true">
                                            <b-icon icon="check2"></b-icon>
                                        </div>
                                        <div v-else>
                                            <b-icon icon="x"></b-icon>
                                        </div>
                                    </template>
                                    <template #cell(createdAt)="data">
                                        {{ data.item.createdAt | formatDate }}
                                    </template>
                                    <template #cell(updatedAt)="data">
                                        {{ data.item.updatedAt | formatDate }}
                                    </template>
                                    <template #cell(options)="data">
                                        <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Delete')" @click="openDeleteModal(data.item)">
                                            <b-icon icon="trash"></b-icon>
                                        </b-button>
                                    </template>
                                    <template #row-details="">
                                        <!-- <b-card class="row-details-card">
                                            <div v-if="selectedElement" class="info-container">
                                                <b-row>
                                                    <b-col>
                                                        <b-row style="display: flex; justify-content: center;">
                                                            <h5>Info</h5>
                                                        </b-row>
                                                            <b-table
                                                            ref="usersTable"
                                                            id="usersTable"
                                                            :fields="info_headers" 
                                                            :items="data_layers"
                                                            responsive="sm"
                                                            show-empty
                                                            > 
                                                            <template #empty="">
                                                                {{ $t('ProjectsManagement.NoPM') }}
                                                            </template>

                                                            <template #cell(createdAt)="data">
                                                                {{ data.item.createdAt | formatDate }}
                                                            </template>

                                                            <template #cell(options)="data">
                                                                <b-button v-if="!selectedElement.share_wallet" class="action-table" v-b-tooltip.hover.viewport title="Add tokens" @click="openAssignTokensModal(data.item)">
                                                                    <b-icon icon="coin"></b-icon>
                                                                </b-button>
                                                            </template>
                                                            </b-table>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-card> -->
                                    </template>
                                </b-table>
                                <div class="pagination-container">
                                    <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                                    </b-pagination>
                                    <b-form-select v-model="perPage" class="pagination-select">
                                        <b-form-select-option :value="5">5</b-form-select-option>
                                        <b-form-select-option :value="10">10</b-form-select-option>
                                        <b-form-select-option :value="20">20</b-form-select-option>
                                        <b-form-select-option
                                            :value="data_layers.length">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                                    </b-form-select>
                                </div>
                            </b-col>
                        </b-row>
                    </card>
                </b-col>
            </b-row>
        </b-container>
        <modal :show.sync="modals.upload" class="modal-upload" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up" style="padding: 5px">
            Upload layer
        </h4>
        <b-form-group label="Label *" style="margin-top: -14px">
            <b-form-input
                id="input-internal_name_form"
                name="input-internal_name_form"
                v-validate="{ required: true}"
                :state="validateState('input-internal_name_form')"
                aria-describedby="input-internal_name_form-live-feedback"
                size="sm"
                placeholder="Label *"
                v-model="label_form"
            ></b-form-input>
            <b-form-invalid-feedback id="input-internal_name_form-live-feedback" style="margin-bottom: -10px;">{{ veeErrors.first('input-internal_name_form') }}</b-form-invalid-feedback>
        </b-form-group>
        <!-- <b-form-group label="Visible name *" >
            <b-form-input
                id="input-visible_name_form"
                name="input-visible_name_form"
                v-validate="{ required: true}"
                :state="validateState('input-visible_name_form')"
                aria-describedby="input-visible_name_form-live-feedback"
                size="sm"
                placeholder="Visible name *"
                v-model="visible_name_form"
            ></b-form-input>
            <b-form-invalid-feedback id="input-visible_name_form-live-feedback" style="margin-bottom: -10px;">{{ veeErrors.first('input-visible_name_form') }}</b-form-invalid-feedback>
        </b-form-group> -->
        <b-form-group label="Description" >
            <b-form-input
                id="input-description"
                name="input-description"
                v-validate="{ required: false}"
                :state="validateState('input-description')"
                aria-describedby="input-description-live-feedback"
                size="sm"
                placeholder="Description"
                v-model="description_form"
            ></b-form-input>
            <b-form-invalid-feedback id="input-description-live-feedback" style="margin-bottom: -10px;">{{ veeErrors.first('input-description') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Projection *" v-if="prjMissing">
            <b-form-input
                id="input-projection"
                name="input-projection"
                v-validate="{ required: true}"
                :state="validateState('input-projection')"
                aria-describedby="input-projection-live-feedback"
                size="sm"
                placeholder="Projection *"
                v-model="projection_form"
            ></b-form-input>
            <b-form-invalid-feedback id="input-projection-live-feedback" style="margin-bottom: -10px;">{{ veeErrors.first('input-projection') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group style="width:100%" label="Projects *"  label-class="info-group-label" v-if="role == 'admin'">
            <b-form-select  multiple v-model="selectedProjects">
                <b-form-select-option v-for="project in projects" :value="project.id" :key="project.id">{{ project.name }}</b-form-select-option>
            </b-form-select>
        </b-form-group>
        <b-form-group label="Country *">
        <b-form-select id="layer" v-model="country_form"
            :options="countries"
            value-field="alpha-3" 
            text-field="name" 
            class="form-select form-select-sm" 
            aria-label=".form-select-sm example">
            <b-form-select-option :value="null">{{ $t('PanelDataLayers.Modals.Select') }}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback id="input-country-live-feedback">{{ veeErrors.first('input-country') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Code *">
            <b-form-input
                id="input-country"
                name="input-country"
                v-validate="{ required: true}"
                :state="validateState('input-country')"
                aria-describedby="input-country-live-feedback"
                size="sm"
                placeholder="Country *"
                v-model="country_form"
                disabled
            ></b-form-input>
            <b-form-invalid-feedback id="input-country-live-feedback">{{ veeErrors.first('input-country') }}</b-form-invalid-feedback>
        </b-form-group>
        <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-file-added="dropzoneAddedFile" @vdropzone-removed-file="dropzoneDeletedFile"/>
        <div class="summary-container" v-if="summaryInfo" style="margin-top: -20px; margin-bottom: -25px;">
            <div class="row">
            <div class="col">
                <h5 slot="header" class="title title-up" style="margin-bottom: -15px;">
                    Checking files
                </h5>
                <div class="error-list" v-if="msgReportError.length > 0">
                <ul>
                    <li v-for="(error, index) in msgReportError" :key="index" class="error-item">
                        {{ error }}
                    </li>
                </ul>
                </div>
                
                <div class="checks-ok-list" v-if="msgReportOK.length > 0">
                <ul>
                    <li v-for="(message, index) in msgReportOK" :key="index" class="checks-ok-item">
                    {{ message }}
                    </li>
                </ul>
                </div>
            </div>
            </div>
        </div>
        <template slot="footer">
            <n-button class="btn-simple ncm-btn" v-if="!msgReportError.length == 0 || !filesChecked" @click="checkUploadLayer()">
                CHECK
            </n-button>
            <n-button class="btn-simple ncm-btn" v-if="msgReportError.length == 0 && filesChecked" @click.native="uploadLayer()">
                Upload
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" @click.native="modals.upload = false;">
                {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
            </n-button>
        </template>
        </modal>

        <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
        <p>{{ $t('PanelCadastres.Modals.DeleteDataLayer') }}</p>
        <template slot="footer">
          <n-button type="neutral" link @click="deleteElement">
            {{ $t("Languages.Modals.Delete.Footer.Save") }}
          </n-button>
          <n-button type="neutral" link @click.native="modals.delete = false">
            {{ $t("Languages.Modals.Delete.Footer.Close") }}
          </n-button>
        </template>
      </modal>
    </div>
</template>
<script>
import { Badge, Button, InfoSection,Modal, FormGroupInput, Card, Tabs, TabPane, Checkbox, DropDown} from '@/components';
import { API_KEY } from '@/constants'
import {Table, TableColumn, Tooltip} from 'element-ui';
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
load: {
    key: API_KEY
}
});
import MapsDataService from '@/services/MapsDataService'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import countries_json from '@/assets/country_codes/slim-3.json'

export default {
name: 'staff-layers',
components: {
    Card,
    Modal,
    [Checkbox.name]: Checkbox,
    [DropDown.name]: DropDown,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [FormGroupInput.name]: FormGroupInput,
    vueDropzone: vue2Dropzone,
},
data() {
    return {
        dropzoneOptions: {
            dictRemoveFile: 'X',
            addRemoveLinks: true,
            disablePreviews: true,
            createImageThumbnails: false,
            autoQueue: false,
            url: 'http://localhost',
            autoProcessQueue: false,
            thumbnailWidth: 20,
            maxFilesize: 5000,
            dictDefaultMessage: 'Drop files here or click to upload <b-icon icon="trash"></b-icon>',
            init: function() {
                this.on("thumbnail", function(file) {
                    console.log('on thumbnail', file)
                })
                this.on("complete", function(file, done) {
                    console.log('on complete', file)
                })
            },
        },
        modals: {
            upload: false,
            delete: false,
            create: false,
            modify: false,
        },
        tokens_to_add:null,
        data_layers: [],
        selectedElement: null,
        visibilityFilter: null,
        data_layers_headers: [
            { key: 'internal_name', label: this.$t('Languages.Table.Name'), sortable: true},
            { key: 'visible_name', label: this.$t('Languages.Table.VisibleName'), sortable: true},
            { key: 'country', label: this.$t('Languages.Table.Country'), sortable: true},
            { key: 'is_active', label: this.$t('Languages.Table.Active'), sortable: true},
            { key:'createdAt',label: this.$t('Parameters.Table.CreatedAt'), sortable: true },
            { key:'updatedAt',label: this.$t('Parameters.Table.UpdatedAt'), sortable: true },
            { key:'projection',label: this.$t('Languages.Table.Projection'), sortable: true },
            { key:'options', label: this.$t('Languages.Table.Actions'), thClass: 'position-sticky' }
        ],
        allOpenRows: [],
        currentPage: 1,
        perPage: 10,
        filterOrgs: '',
        filter: null,
        filterOn: [],
        loading: false,
        label_form: '',
        visible_name_form: '',
        filesSize: 0,
        projection_form: '',
        description_form: '',
        linkwfs_form: '',
        linkwms_form: '',
        country_form: null,
        code_form: '',
        type_name_form: '',
        countries: countries_json,
        acceptedFiles: [],
        dataWallet: [],
        selectedProjects: [],
        projects: [],
        msgReportError: [],
        msgReportOK: [],
        prjMissing: false,
        summaryInfo: false,
        filesChecked: false,
    }
},
computed: {
    totalPages () {
        return this.data_layers.length
    }
},
created() {
        this.getData()
},
methods:{
        dropzoneAddedFile(file){
            this.acceptedFiles = []
            this.acceptedFiles = this.$refs.myVueDropzone.getAcceptedFiles()
        },
        dropzoneDeletedFile(file){
            this.acceptedFiles = []
            this.acceptedFiles = this.$refs.myVueDropzone.getAcceptedFiles()
        },
        applyRowClass(row) {
            if (row != null) {
                if (!row.is_active) {
                    return 'disabled-row';
                } else {
                    return '';
                }
            }
        },  
        selectRow(items){
            this.selectedElement = items[0]
        },
        sortingChanged(item, index){
            this.allOpenRows.map((ele) => {
                if (ele.id !== item.id && ele._showDetails) {
                    this.$set(ele, '_showDetails', !ele._showDetails)
                }
            })
        },
        validateState (ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref)
            }
            return null
        },
        handleRowClicked (item, index) {
            this.allOpenRows.map((ele) => {
                if (ele.id !== item.id && ele._showDetails) {
                this.$set(ele, '_showDetails', !ele._showDetails)
                }
            })
            this.allOpenRows = []
            this.$set(item, '_showDetails', !item._showDetails)
            this.allOpenRows.push(item)
        },
        getData(){
            MapsDataService.getDataLayers().then(
                Response => {
                    this.data_layers = Response.data
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        openCreateModal(){
            this.modals.create = true
        },
        openEditModal(element){
            this.modals.upload = true
            this.$refs.myVueDropzone.removeAllFiles(true)
        },
        openDeleteModal(element){
            this.selectedElement = element
            this.modals.delete = true
        },
        openModifyModal(){
            this.modals.modify = true
        },
        deleteElement(){
            console.log(this.selectedElement)
            MapsDataService.removeDataLayer(this.selectedElement.id).then(
                async response => {
                    console.log(response.data)
                    this.modals.delete = false
                    this.$swal.fire({
                    icon: 'success',
                    title: this.$t('Alerts.LayerDeleted'),
                    text: ''
                    }).then(() => {
                        this.getData()
                    });
                },
                error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                }
            )
        },
        clearErrors(){
            this.msgReportError = []
            this.msgReportOK = []
        },
        checkUploadLayer(){
            this.$validator.validateAll().then(async result => {
                if (!result) {
                    return
                }
                this.clearErrors()
                // Getting files and checking minimal files
                console.log(this.$refs.myVueDropzone.getAcceptedFiles())
                var shape_files = this.$refs.myVueDropzone.getAcceptedFiles()
                console.log(shape_files)
                if(shape_files.length < 3){
                    this.msgReportError.push('Not enough files for layer, you must upload 3 files.')
                }
                // Getting extension of files and check the basic formats
                var ext_array = []
                var i = 0
                for (var shapefile of shape_files){
                    console.log(shapefile)
                    var ext = shapefile.name.split('.')[shapefile.name.split('.').length - 1]
                    ext_array.push('.' + ext.toLowerCase())
                    i++
                }
                var formatsMissing = []
                if (!ext_array.includes('.dbf') ){
                    formatsMissing.push('.dbf')
                }
                if (!ext_array.includes('.shx') ){
                    formatsMissing.push('.shx')
                }
                if (!ext_array.includes('.shp') ){
                    formatsMissing.push('.shp')
                }
                if (formatsMissing.length > 0){
                    var msg = 'Missing following format file: '
                    for (var format of formatsMissing)  msg += format + ', '
                    msg = msg.substring(0, msg.length - 2)
                    this.msgReportError.push(msg)
                }
                // Getting extension of files and check additional formats or not supported formats
                var formats = ['.shp', '.shx', '.dbf', '.sbn', '.sbx', '.fbn', '.fbx', '.ain', '.aih', '.atx', '.ixs', '.mxs', '.prj', '.xml', '.cpg', '.qmd']
                var additionalFormats = []
                var notSupportedFormats = []
                var repeatedFormats = []
                for(var ext of ext_array){
                    if (ext != '.dbf' && ext != '.shx' && ext != '.shp'){
                        if(!formats.includes(ext)) notSupportedFormats.push(ext)
                        else additionalFormats.push(ext)
                    }
                    if (ext_array.filter(extension => extension == ext).length > 1){
                        if(!repeatedFormats.includes(ext))
                        repeatedFormats.push(ext)
                    }
                }
                if (additionalFormats.length > 0){
                    var msg = 'You have additional files of these formats: '
                    for (var format of additionalFormats) msg += format + ', '
                    msg = msg.substring(0, msg.length - 2)
                    this.msgReportOK.push(msg)
                }
                // Check if there is any file not supported
                if (notSupportedFormats.length > 0){
                    var msg = 'You have not supported files of these formats: '
                    for (var format of notSupportedFormats) msg += format + ', '
                    msg = msg.substring(0, msg.length - 2)
                    this.msgReportError.push(msg)
                }
                // Check if there is repeated files
                if (repeatedFormats.length > 0){
                    var msg = 'You have repeated formats: '
                    for (var format of repeatedFormats) msg += format + ', '
                    msg = msg.substring(0, msg.length - 2)
                    this.msgReportError.push(msg)
                }
                // Check if there is prj file for detecting projection
                if (!additionalFormats.includes('.prj') && !this.projection_form){
                    this.msgReportError.push('Projection file dont detected, you must indicate the projection manually or use a .prj file')
                    this.prjMissing = true
                }
                    this.filesChecked = true
                    this.summaryInfo = true
            })
        },
        uploadLayer(){
            var formData = new FormData()
            var shape_files = this.$refs.myVueDropzone.getAcceptedFiles()
            var i = 0
            for ( var shapefile of shape_files){
                formData.append('files[' + i +']', shapefile)
                i++
            }
            formData.append('label', this.label_form)
            // formData.append('visible_name', this.visible_name_form)
            formData.append('description', this.description_form)
            formData.append('projection', this.projection_form)
            formData.append('country', this.country_form)
            formData.append('is_data_layer', true)
            // Getting main name of files
            var filename = shape_files[0]
            var main_name = filename.name.split('.')[0]
            formData.append('main_name', main_name)
            this.loading = true
            MapsDataService.uploadLayer(formData).then(
                response => {
                    if (response.status == 200){
                        this.loading = false
                        this.$swal.fire({
                            icon: 'success',
                            title: this.$t('Alerts.LayerUploadedCorrectly')
                        }).then(() => {
                            this.modals.upload = false
                            this.getData()
                        });
                    
                    }
                    
                },
                error => {
                    this.loading = false
                    if(error.response.status == 409){
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Alerts.ErrorUploadLayerDuplicated'),
                        text: this.$t('Alerts.ErrorUploadLayerDuplicatedMsg'),
                        });
                    }
                    else{
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Profile.Alerts.UpdateAvatar.Error')
                        });
                    }
                    console.log((error.response && error.response.data) || error.message || error.toString())
                }
            ) 
        },
    }
}
</script>
<style scoped>
.disabled-row {
    background-color: #f8f8f8;
    color: #999999;
}


::v-deep .dz-preview{
    min-width: 100px !important;
    max-width: 100px !important;
    min-height: 100px !important;
    max-height: 100px !important;
}

::v-deep .dz-image{
    min-width: 100px !important;
    max-width: 100px !important;
    min-height: 100px !important;
    max-height: 100px !important;
}
::v-deep .dz-progress{
    visibility: hidden;
}
.invalid-feedback {
  margin-left: 1% !important;
}
.error-list {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 1px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

.error-item {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #721c24;
}

.error-item::before {
  color: #721c24;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* .row .col.d-flex {
  justify-content: flex-end;
} */
.checks-ok-list {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
}

.checks-ok-item {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #155724;
}

.checks-ok-item::before {
  color: #155724;
  margin-right: 5px;
  margin-bottom: 5px;
}

::v-deep #dropzone{
  padding: 0;
  min-height: auto;
  margin-bottom: 16px;
}
::v-deep .card-modal > .card-body {
  padding: 0.8em!important;
  /* overflow-y: hidden; */
}

::v-deep .dz-remove{
    position: absolute !important;
    top: 0px !important;
    left: 69px !important;
    max-width: 20px !important;
    max-height: 20px !important;
    padding: 0px !important;
    border: none !important;
    color: #fe2626b0 !important;
  }

@media (max-width: 465px) {
  .titlePanel {
    text-align: center!important;
  }

  .buttonPanel{
    float: center!important;
  }
}

@media (max-width: 465px) {
  .titleOrg {
    text-align: center;
  }

  .buttonOrg {
    float: center;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
</style>