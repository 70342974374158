import http from '../http-common'
import authHeader from './AuthHeaderService'
const API_URL_PATH = 'api/ecosystems-category-type/'


class EcosystemTypes {
  findAll () {
    return http.get(API_URL_PATH + 'ecosystems-category-type', {headers: authHeader()})
  }
  create (data) {
    const body = {
      name: data.name,
      description: data.description
    }
    return http.post(API_URL_PATH + 'create-ecosystem-category-type',body,  {headers: authHeader()})
  }
  update (data) {
    const body = {
      id: data.id,
      name: data.name,
      description: data.description,
    }
    return http.put(API_URL_PATH + 'update-ecosystem-category-type', body,  {headers: authHeader()})
  }
  delete (id) {
    return http.delete(API_URL_PATH + 'delete-ecosystem-category-type?id=' + id,  {headers: authHeader()})
  }
}

export default new EcosystemTypes()