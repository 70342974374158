<template>
  <div
    class="page-header header-filter"
    filter-color="green"
  >
  <div v-if="loading" id="toHidden"
                            style="
                                position: absolute;
                                top     :0;
                                left    : 0;
                                height  : 100%;
                                width   :  100%;
                                background-color:azure;
                                z-index : 1000;
                                opacity : 0.6;"
                            >
        </div>
        <b-icon  v-if="loading" style="
                                        z-index: 2000;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        "
                                icon="three-dots"
                                animation="cylon"
                                font-scale="4">
        </b-icon>
    <div
      class="page-header-image"
      :style="{'background-image': 'url(' + $getImgUrlSite('bg51.jpg') +')', opacity: '70%'}"  
    />
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto mt-auto">
          <card
            type="login"
            plain
          >
            <div
              slot="header"
              class="logo-container mt-5"
            >
              <img
                v-lazy="$getImgUrlSite('LogoNoSpinOff.png')"
              >
            </div>
          
            <b-form-group v-if="!resetPass" class="formLoginLabel" label="Email">
              <b-form-input
              v-model="dataLogin.mail"
              id="input-dataFormLogin-username"
              name="input-dataFormLogin-username"
              v-validate="{ required: true, email: true}"
              type="email"
              aria-describedby="input-dataFormLogin-username-live-feedback"
              :placeholder="$t('LoginStaff.Username')"
              class="no-border input-lg formLogin"
              :state="validateState('input-dataFormLogin-username')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-dataFormLogin-username-live-feedback">{{ veeErrors.first('input-dataFormLogin-username-live-feedback') }}</b-form-invalid-feedback>
            </b-form-group>
            <div class="row" v-if="!resetPass">
              <div class="col">
            <b-form-group class="formLoginLabel" :label="$t('LoginStaff.Password')">
              <p class="d-flex">
                <b-form-input
                style="font-size: 0.7em !important;"
                v-model="dataLogin.psw"
                id="input-dataFormLogin-password"
                name="input-dataFormLogin-password"
                v-validate="{ required: true}"
                type="password"
                aria-describedby="input-dataFormLogin-password-live-feedback"
                :placeholder="$t('LoginStaff.Password')"
                class="no-border input-lg formLogin"
                :state="validateState('input-dataFormLogin-password')"
                @keyup.enter="login"
              >
              </b-form-input>
            </p>
            <b-form-invalid-feedback id="input-dataFormLogin-password-live-feedback">{{ veeErrors.first('input-dataFormLogin-password-live-feedback') }}</b-form-invalid-feedback>
            </b-form-group>
            </div>
            <div class="col-2 d-flex show-pass-col" style="margin-top: -1% !important">
              <span v-if="!variablePass" class="iconPass m-auto" @click="showPass()"><b-icon icon="eye" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
              <span v-if="variablePass" class="iconPass m-auto" @click="showPass()"><b-icon icon="eye-slash" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
            </div>
            </div>
            <b-form-group v-if="resetPass" class="formLoginLabel" :label="$t('LoginStaff.Username')">
                <b-form-input
                  v-model="reset.mail"
                  id="input-dataResetPassword"
                  name="input-dataResetPassword"
                  type="email"
                  aria-describedby="input-dataResetPassword-live-feedback"
                  :placeholder="$t('LoginStaff.Username')"
                  class="no-border input-lg formLogin"
                ></b-form-input>
            </b-form-group>

            <template 
              v-if="!resetPass" 
              slot="raw-content"
            >
              <div class="card-footer text-center" style="display: flex;">
                <n-button
                  type="primary"
                  class="btn-simple ncm-btn"
                  style="background-color: white;"
                  @click.stop.prevent="login()"
                >
                  <b-icon icon="box-arrow-in-right" class="mr-2"></b-icon>{{ $t("LoginStaff.Button") }}
                </n-button>
                <h6 class="login-button-link">
                  <a
                    class="link footer-link link-forgot"
                    href="/staff"
                    @click.stop.prevent="trueReset()"
                  >{{ $t("LoginStaff.ForgotPass") }}</a>
                </h6>
              </div>
            </template>
            <template 
              v-if="resetPass" 
              slot="raw-content"
            >
              <div class="card-footer text-center" style="display: flex">
                <n-button
                  class="btn-simple ncm-btn"
                  style="background-color: white;"
                  @click.stop.prevent="resetPsw()"
                >
                  <b-icon icon="envelope" class="mr-2"></b-icon>
                  {{ $t("LoginStaff.ButtonForgot") }}
                </n-button>
                <h6 class="login-button-link ">
                  <a
                    class="link footer-link link-forgot"
                    href="/staff"
                    @click.stop.prevent="falseReset()"
                  >{{ $t("LoginStaff.Button") }}</a>
                </h6> 
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
    <main-footer />
  </div>
</template>
<script>
    import { Card, Button, FormGroupInput } from '@/components';
    import MainFooter from '@/layout/MainFooter';
    import Login from '../../services/Login'
    import StaffServices from '../../services/StaffServices';
    export default {
      name: 'LoginStaff',
      bodyClass: 'login-page-staff',
      components: {
        Card,
        MainFooter,
        [Button.name]: Button,
        [FormGroupInput.name]: FormGroupInput
      },
      data() {
        return {
          loading: false,
          resetPass: false,
          dataLogin: {
            mail:'',
            psw: ''     
          },
          reset: {
            mail: ''
          },
          variablePass: false
        }
      },
      methods: {
        trueReset () {
          this.$validator.reset()
          this.resetPass = true
          this.dataLogin.mail = null
          this.dataLogin.psw = null
          this.reset.mail = null
          
        },
        falseReset () {
          this.$validator.reset()
          this.resetPass = false
          this.dataLogin.email = null
          this.dataLogin.psw = null
          this.reset.mail = null
          
        },
        login () {
          this.$validator.validateAll(['input-dataFormLogin-username','input-dataFormLogin-password']).then(result => {
          if (!result) {
            return
          }
          this.loading = true;
          Login.loginStaff(this.dataLogin).then(
            Response => {
              if (Response.status === 200) {
                this.$store.state.auth.status.loggedIn = true
                this.$store.state.auth.user = Response.data
                localStorage.setItem('user', JSON.stringify(Response.data))
                this.$router.push({path:'/dashboard-staff'})
              }
              this.loading = false;
            },
            Error => {  
              console.log(Error)
              if (Error.response.status === 404) {
                this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.$t('Errors.NotFound')
                })
              }
              if (Error.response.status === 400) {
                this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.$t('Errors.BadRequest')
                })
              }
              if (Error.response.status === 500) {
                this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.$t('Errors.InternalServerError')
                })
              }
              this.loading = false;
            }
          )
        })
        },
        resetPsw () {
          Login.passwordRecovery(this.reset.mail).then(
            Response => {
              if (Response.status === 200) {
                this.$swal.fire({
                icon: 'success',
                title: this.$t('Alerts.EmailSent'),
                timer: 3000
                })
                this.falseReset()
              }
            },
            Error => {
              if (Error.response.status === 404) {
                this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.$t('Alerts.NotFoundEmailStaff')
                })
              }
              if (Error.response.status === 500) {
                this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.$t('Errors.InternalServerError')
                })
              }
            }
          )
        },
        validateState (ref) {
          if (this.veeFields[ref] &&(this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
            return !this.veeErrors.has(ref)
          }
          return null
        },
        showPass () {
          if (!this.variablePass) this.variablePass = true
          else this.variablePass = false
          var tipo = document.getElementById("input-dataFormLogin-password");
          if (tipo.type == "password"){
              tipo.type = "text";
          } else {
              tipo.type = "password";
          }
        }
      }
    }
  </script>
  <style>
  .formLogin{
    background-color: aliceblue !important;
    height: 43px !important;

  }
  .formLoginLabel{
    color: rgb(248, 248, 248);
    font-weight: 500;
    text-align: left;
  }
  .iconPass{
    margin: auto!important;
    height: 20px!important;
    width: 20px!important;
    margin-left: 10px!important;
    margin-top: 20px;
    padding-top: 1px;
  }
  </style>
  