<template>
    <div class="wrapper blog-post">
        <div class="page-header page-header-small ">
            <parallax
                class="page-header-image post-and-tutorial"
                :style="{'background-image': 'url(' + $getImgUrlSite('bg14.jpg') +')'}" 
            />
            <div class="content-center">
                <h1 class="title">
                TUTORIAL
                </h1> 
            </div>
        </div>
        <div class="blogs-1">
            <div class="container">
                <div class="row">
                    <div class="col-9 m-auto">
                        <h6 class="category" style="line-height: 2; color: #5D6176">
                            <b-icon icon="pie-chart-fill"></b-icon> {{tutorial.category_tutorial_name}}
                        </h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-9 m-auto" >
                        <h2 class="title" style="font-size: 36px;">
                            {{tutorial.title}}
                        </h2>
                        <h3 style="font-size: 14px;">
                            {{tutorial.subtitle}}
                        </h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-9 m-auto">
                        <p style="line-height: 2; font-size: 12px;"><b>{{tutorial.createdAt | formatDate}}</b></p>
                    </div>
                </div>
                <!-- <div class="row" v-if="tutorial.image_main_view"> -->
                    <div class="col-9 m-auto">
                        <template v-if="tutorial.image != null && isVideo(tutorial.image.url)">
                            <video
                                class="img img-raised rounded"
                                controls
                                style="max-width: 100%;min-width: 100%"
                            >
                                <source :src="getVideoUrl(tutorial.image.url)" type="video/mp4">
                            </video>
                        </template>
                        <template v-else>
                            <template v-if="tutorial.image">
                                <img style="min-width: 100%; max-height: 500px;" class="img img-raised rounded"  :alt="tutorial.image.description" :src="getImgUrl(tutorial.image.url)">
                            </template>
                            <template v-else> 
                                <img  v-if="tutorial.external_image == null" style="min-width: 100%; max-height: 500px;" class="img img-raised rounded"  alt="Image default" :src="$getImgUrlSite('blurred-image-2.jpg')">
                                <img  v-else class="img img-raised rounded" style="min-width: 100%; max-height: 500px;"  :alt="tutorial.external_image_description" :src="tutorial.external_image">
                            </template>
                        </template>
                    </div>
                <!-- </div> -->
                <div class="row mt-3"> 
                    <div class="col-9 m-auto">
                        <template v-if="tutorial.content">
                            <p style="font-size: 15px;"> <i class="now-ui-icons tech_watch-time" />  <b>{{ calcularTiempoLectura(tutorial.content)}} min (Estimated reading time)</b></p>
                        </template>
                    </div>
                </div>
                <div class="row"> 
                    <div class="col-9 m-auto">
                        <hr class="row-divider"/>
                    </div>
                </div>
                <div class="row"> 
                    <div class="col-md-9 m-auto" >
                        <p class="mt-3 text-justify" style="font-size: 13px;" v-html="tutorial.content"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Card } from '@/components';
import TutorialDataService from '../../services/TutorialDataService';

export default {
    components: {
        Card
    },
    data() {
    return {
        tutorial: {}
    }
    },
    created () {
        this.getData()
    },
    methods: {
        getData() {
            TutorialDataService.findOneBySlug(this.$route.params.title_slug).then(
                Response => {
                        console.log(Response.data)
                        this.tutorial = Response.data.data
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                    })
        },
        isVideo(url) {
            const videoExtensions = ['mp4', 'webm', 'ogg']; // Agrega las extensiones de video que deseas comprobar
            const extension = url.split('.').pop();
            return(videoExtensions.includes(extension.toLowerCase()))
        },
        getVideoUrl(url) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${url}?withheaders=yes`
            // try {
            //     if(url){
            //         return require('../../assets/multimedia/blog/images/' + url);
            //     }
            // } catch {
            //     console.log('error loading video')
            // }
            // Construye la ruta de archivo local para el video
        },
        calcularTiempoLectura (contenido) {
            const velocidadLectura = 300;
            const caracteresPorPalabra = 5;
            const palabras = Math.round(contenido.length / caracteresPorPalabra);
            const tiempoLectura = Math.ceil(palabras / velocidadLectura);
            return tiempoLectura;
        },
        getImgUrl (name) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${name}?withheaders=yes`
            //return 'https://p-ncm-public.s3.eu-south-2.amazonaws.com/' + name
            /*try{
                if(name){
                    var images = require.context('../../assets/multimedia/blog/images', false)
                    console.log(images('./' + name))
                    return images('./' + name)
                }
            }catch{
                console.log('error loading image')
            }*/
        },
    }
    
}
</script>
<style scoped>
.page-header.page-header-small {
    min-height: 32vh !important;
    max-height: 440px;
}

</style>