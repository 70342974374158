import http from '../http-common'
import authHeader from './AuthHeaderService'
const API_URL_PATH = 'api/project/'


class ProjectsDataService {
  findAll() {
    return http.get(API_URL_PATH + 'find-all', { headers: authHeader() })
  }
  findProjectsByOrganizationId(organization_id, role) {
    return http.get(API_URL_PATH + 'find-by-org?organization_id=' + organization_id + '&role=' + role, { headers: authHeader() })
  }

  findProjectsByOrganizationIdSideBar(organization_id, role) {
    return http.get(API_URL_PATH + 'find-by-org-sidebar?organization_id=' + organization_id + '&role=' + role, { headers: authHeader() })
  }
  findOne(project_id){
    return http.get(API_URL_PATH + 'find-one?id=' + project_id, { headers: authHeader() })
  }
  create(data) {
    const body = {
      name: data.name,
      description: data.description,
      organization_id: data.organization_id,
      user_id: data.user_id,
    }
    //console.log(authHeader());
    return http.post(API_URL_PATH + 'create', body, { headers: authHeader() })
  }
  update(data) {
    const body = {
      id: data.id,
      name: data.name,
      description: data.description,
      organization_id: data.organization_id,
      user_id: data.user_id
    }
    return http.put(API_URL_PATH + 'update', body, { headers: authHeader() })
  }
  finish(id) {
    const body = {
      id: id,
    }
    return http.put(API_URL_PATH + 'finish', body , { headers: authHeader() })
  }
  SetProject(body) {
    return http.post(API_URL_PATH + 'set-project', body, { headers: authHeader() })
  }
  SetAnalysts(body) {
    return http.post(API_URL_PATH + 'set-analyst', body, { headers: authHeader() })
  }
  acceptInvitationAnalyst(token) {
    const body = {
      token: token,
    }
    return http.post(API_URL_PATH + 'set-analyst', body);
  }
  inviteAnalysts(body) {
    return http.post(API_URL_PATH + 'invite-analysts', body, { headers: authHeader() })
  }

  getProjectsFromAUser(project_id, organization_id) {
    return http.get(API_URL_PATH + 'find-projects-from-a-user?project_id=' + project_id + '&organization_id=' + organization_id, { headers: authHeader() })
  }
  setAnalystStatus(project_id, user_id, is_active){
    console.log('sss',project_id, user_id, is_active);
    const body = {
      project_id: project_id,
      user_id: user_id,
      is_active: is_active,
    }
    console.log('body:', body);
    return http.put(API_URL_PATH + 'set-analyst-status', body , { headers: authHeader() })
  }
}

export default new ProjectsDataService()
