<template>
    <div class="wrapper-home  collapsed-sidebar" style="margin-top: 5%; max-width: 99%;">
        <div v-if="loading" id="toHidden"
                            style="
                                position: absolute;
                                top     :0;
                                left    : 0;
                                height  : 100%;
                                width   :  100%;
                                background-color:azure;
                                z-index : 1000;
                                opacity : 0.6;"
                            >
        </div>
        <b-icon  v-if="loading" style="
        z-index: 2000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        "
        icon="three-dots"
        animation="cylon"
        font-scale="4">
        </b-icon>
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                <h2 class="card-title">
                    Invitations
                </h2> 
                </b-col>
            </b-row>
            <b-row>
                <card class="card-h-100" style="margin-left: 15px;">
                    <b-row>
                        <b-col cols="4" style="float: left; display: flex;">
                            <span class="mt-auto mb-auto"><h6 class=" mb-0">{{$t('UsersManagement.Invitations')}}</h6></span>
                        </b-col>
                        <b-col>
                            <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right;">
                                <b-button-group class="mx-1">
                                    <div class="col m-auto">
                                        <!-- <div style="margin-top: 27px;"> -->
                                            <b-form-group label="Visibility">
                                                <b-form-select v-model="visibilityFilter"  style="font-size: 12px;">
                                                    <b-form-select-option value="null" append-top>{{$t('UsersManagement.All')}}</b-form-select-option>
                                                    <b-form-select-option value="complete">{{$t('UsersManagement.Completed')}}</b-form-select-option>
                                                    <b-form-select-option value="pending">{{$t('UsersManagement.Pending')}}</b-form-select-option>
                                                    <b-form-select-option value="rejected">{{$t('UsersManagement.Rejected')}}</b-form-select-option>
                                                    <b-form-select-option value="cancelled">{{$t('UsersManagement.Cancelled')}}</b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                            <!-- <b-form-radio-group v-model="visibilityFilter" class="d-flex">
                                                <b-form-radio value="null" class="custom-radio"><p style="font-size: 12px;margin-top: 3px;">{{$t('UsersManagement.All')}}</p></b-form-radio>
                                                <b-form-radio value="complete" class="custom-radio"><p style="font-size: 12px;margin-top: 3px;">{{$t('UsersManagement.Completed')}}</p></b-form-radio>
                                                <b-form-radio value="registered" class="custom-radio"><p style="font-size: 12px;margin-top: 3px;">{{$t('UsersManagement.Pending')}}</p></b-form-radio>
                                                <b-form-radio value="rejected" class="custom-radio"><p style="font-size: 12px;margin-top: 3px;">{{$t('UsersManagement.Rejected')}}</p></b-form-radio>
                                            </b-form-radio-group> -->
                                        <!-- </div> -->
                                    </div>
                                </b-button-group>
                            </b-button-toolbar>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-table
                            ref="userTable"
                            id="usersTable"
                            :fields="headersInvitations" 
                            :items="filteredInvitations"
                            responsive="sm"
                            style=" min-height:400px; max-height: 550px; overflow-x: auto;"
                            :tbody-tr-class="applyRowClass"
                            :current-page="currentPage2"
                            :per-page="perPage2"
                            show-empty
                            > 
                            <template #empty="">
                                {{$t('UsersManagement.EmptyInvitations')}}
                            </template>
                            <template #cell(email)="data">
                                {{ data.item.invitation.email }}
                            </template>
                            <template #cell(status)="data">
                                <div v-if="data.item.invitation.is_rejected">
                                    <span class="invitation-status rejected" v-b-tooltip.hover.viewport title='This invitation is rejected'>
                                        {{ data.item.invitation | formatInvitation }}
                                    </span>
                                </div>
                                <div v-else-if="data.item.invitation.is_cancelled">
                                    <span class="invitation-status cancelled" v-b-tooltip.hover.viewport title='This invitation is cancelled'>
                                        {{ data.item.invitation | formatInvitation }}
                                    </span>
                                </div>
                                <div v-else>
                                    <template v-if="!data.item.invitation.is_complete">
                                        <span class="invitation-status pending" v-b-tooltip.hover.viewport title='This invitation is pending'>
                                            {{ data.item.invitation | formatInvitation }}

                                        </span>
                                    </template>
                                    <template v-else>
                                        <span class="invitation-status completed" v-b-tooltip.hover.viewport title='This invitation is completed'>
                                        {{ data.item.invitation | formatInvitation }}
                                    </span>
                                    </template>
                                </div>
                            </template>

                            <template #cell(options)="data">
                                <b-button v-if="!data.item.invitation.is_complete && !data.item.invitation.is_cancelled" class="action-table" v-b-tooltip.hover.viewport title="Cancel invitation" @click="cancelInv(data.item)">
                                    <b-icon icon="x-circle"></b-icon>
                                </b-button>
                            </template>
                            <!-- <template #cell(reason)="data">
                                {{ data.item.invitation | formatReason}}
                            </template> -->
                            <template #cell(expirationDate)="data">
                                {{ data.item.invitation.expirationDate }}
                            </template>
                            </b-table>
                            <div class="pagination-container">
                                <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                                </b-pagination>
                                <b-form-select v-model="perPage" class="pagination-select">
                                    <b-form-select-option :value="5">5</b-form-select-option>
                                    <b-form-select-option :value="10">10</b-form-select-option>
                                    <b-form-select-option :value="20">20</b-form-select-option>
                                    <b-form-select-option
                                        :value="invitations.length">{{ $t('UsersManagement.All') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </b-col>
                    </b-row>
                </card>
            </b-row>
        </b-container>
    <modal :show.sync="modals.invite" header-classes="justify-content-center" class="custom-width-modal">
        <h4 style="display: flex; justify-content: center; align-items: center;"><b>{{$t('UsersManagement.Modals.Invite.Title')}}</b></h4>
        <b-form-group label=" Email *" label-for="input-email_inv" style="margin-left: 270px;">
                <b-form-input
                    v-model="email_inv"
                    id="input-email_inv"
                    name="input-email_inv"
                    v-validate="{ required: true, email: true}"
                    :state="validateState('input-email_inv')"
                    aria-describedby="input-email_inv-live-feedback"
                    size="sm"
                    placeholder=" Email"
                    style="max-width: 400px;"
                ></b-form-input>
                <b-form-invalid-feedback id="input-email_inv-live-feedback">{{ veeErrors.first('input-email_inv') }}</b-form-invalid-feedback>
                </b-form-group>
                <div class="col-12">
                    <div class="col-6" style="float: left;">
                        <card style="border-radius: 20px; padding: 15px; display: flex; justify-content: center; align-items: center;">
                            <p slot="header" style="font-size: 1.2em;">
                                <b>
                                {{$t('UsersManagement.Modals.Invite.Title1')}}
                                </b>
                            </p>
                            <ul style="font-size: 0.8em;">
                                <li> {{$t('UsersManagement.Modals.Invite.TextNOT1')}}</li>
                                <li>{{$t('UsersManagement.Modals.Invite.TextNOT2')}} </li>
                                <li> {{$t('UsersManagement.Modals.Invite.TextNOT3')}}</li>
                            </ul>
                            <!-- <p style="font-size: 0.8em;"> 
                                {{$t('UsersManagement.Modals.Invite.TextNOT1')}}
                            </p>
                            <p style="font-size: 0.8em;"> 
                                {{$t('UsersManagement.Modals.Invite.TextNOT2')}}
                            </p>
                            <p style="font-size: 0.8em;"> 
                                {{$t('UsersManagement.Modals.Invite.TextNOT3')}}
                            </p> -->
                        </card>
                    </div>
                    <div class="col-6" style="float: right;">
                        <card style="border-radius: 20px; padding: 15px;">
                            <p slot="header" style="font-size: 1.2em; display: flex; justify-content: center; align-items: center;">
                                <b>
                                {{$t('UsersManagement.Modals.Invite.Title2')}}
                                </b>
                            </p>
                            <ul style="font-size: 0.8em;">
                                <li>{{$t('UsersManagement.Modals.Invite.TextYES1')}}</li>
                                <li>{{$t('UsersManagement.Modals.Invite.TextYES2')}}</li>
                                <li>{{$t('UsersManagement.Modals.Invite.TextYES3')}}</li>
                            </ul>
                            <!-- <p style="font-size: 0.8em;">
                                {{$t('UsersManagement.Modals.Invite.TextYES1')}}
                            </p>
                            <p style="font-size: 0.8em;">
                                {{$t('UsersManagement.Modals.Invite.TextYES2')}}
                            </p>
                            <p style="font-size: 0.8em;">
                                {{$t('UsersManagement.Modals.Invite.TextYES3')}}
                            </p> -->
                        </card>
                    </div>
                </div>
        <template slot="footer">
            <n-button class="btn-simple ncm-btn" @click.native="sendInv()">
                {{$t('UsersManagement.Modals.Invite.Send')}} 
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" style="float: right;" @click.native="modals.invite = false">
                {{ $t("DashBoardStaff.Modals.AssignRole.Footer.Close") }} 
            </n-button>
            </template>
    </modal>
    </div>
</template>
<script>
import { Badge, Button, InfoSection,Modal, FormGroupInput, Card, Tabs, TabPane, Checkbox, DropDown} from '@/components';
import InvitatonDataServices from '../../services/InvitationDataService'
import OrganizationsDataService from '../../services/OrganizationsDataService'
import {Table, TableColumn, Tooltip} from 'element-ui';

import moment from 'moment'
import ExternalUserDataService from '../../services/ExternalUserDataService';


export default {
    components : {
        Card,
        Modal,
        [Checkbox.name]: Checkbox,
        [DropDown.name]: DropDown,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Tooltip.name]: Tooltip,
        [FormGroupInput.name]: FormGroupInput,
    },
    data() {
        return {
            // userLS: null,
            auxOrganizationID: -99,
            loading: false,
            modals: {
                invite: false
            },
            currentPage: 1,
            perPage: 10,
            currentPage2: 1,
            organization_id: null,
            perPage2: 10,
            visibilityFilter: null,
            user: {},
            email_inv: null,
            users: [],
            inv: [],
            invitations: [],
            filteredArrayInvitations: [],
            projects: [],
            headersUsers: [
                { key: 'name', sortable: true, label: this.$t('DashBoardStaff.Table.Name'), tdClass: 'table-title', thClass: 'table-title' }, 
                { key: 'surname', sortable: true, label: this.$t('DashBoardStaff.Table.Surname') },
                { key: 'roles', sortable: true, label: this.$t('DashBoardStaff.Table.Role') },
                { key: 'phone', label: this.$t('DashBoardStaff.Table.Phone') },
                { key: 'email', label: this.$t('DashBoardStaff.Table.Email') },
            ],
            headersInvitations: [
                { key: 'email', label: this.$t('Invitations.Destination')},
                { key: 'status', label: this.$t('Invitations.Status')},
                // { key: 'reason', label:'Reason'},
                { key: 'expirationDate', label: this.$t('Invitations.ExpirationDate')},
                { key: 'options', label: this.$t('Languages.Table.Actions'), thClass: 'position-sticky' }
            ],
            // auxUsers: [],
            auxOrganizationLS: []
        }
    },
    computed: {
        getUsers(){
            // let userFormat = users;
            let rolesT= null;
            console.log(this.users);
            this.users.forEach((user) => {
                console.log(user);
                rolesT = user.roles.map(role => {
                    console.log(role.user_role);
                    if(role.user_role == "project_manager"){
                        role.user_role = "project manager"
                    }
                    return role.user_role
                });
                user.roles = rolesT
            });
            return this.users
        },
        onUpdate() {
            this.$refs.userTable.refresh();
            this.$forceUpdate();
        },
        totalPages () {
            return this.users.length
        },
        totalPages2 () {
            return this.invitations.length
        },
        filteredInvitations() {
            if (this.visibilityFilter === null || this.visibilityFilter === 'null') {
                // console.log("Estoy imprimiendo...", this.invitations);
                return this.invitations; // Return all invitations if no status is selected
            }else if(this.visibilityFilter === 'complete'){
                // console.log(this.invitations);
                // this.invitations.forEach(element => {
                //     if (element.invitation.is_complete) {
                //         auxInvitations.push(element)
                //     }
                // });
                console.log(this.invitations);
                return this.invitations.filter(item =>(item.invitation.is_complete === true && item.invitation.is_rejected === false));
            }else if(this.visibilityFilter === 'pending'){
                return this.invitations.filter(item =>(item.invitation.is_complete === false && item.invitation.is_rejected === false && item.invitation.is_cancelled === false));
            }else if(this.visibilityFilter === 'rejected'){
                return this.invitations.filter(item =>(item.invitation.is_complete === false && item.invitation.is_rejected === true));
            }
            else if(this.visibilityFilter === 'cancelled'){
                return this.invitations.filter(item =>(item.invitation.is_cancelled === true));
            }
            // return this.invitations.filter(invitation => invitation.status === this.visibilityFilter);
        },
    },
    watch: {
        $orgSelected(newValue){
            console.log('Org selected changed desde invitations!', newValue)
            this.organization_id = newValue.id
            this.getData()
        }
    },
    created () {
        if (this.$orgSelected){
            this.organization_id = this.$orgSelected.id
        }
        this.auxOrganizationID = JSON.parse(localStorage.getItem("user")).admin.organization_id
        this.auxOrganizationLS = JSON.parse(localStorage.getItem("user")).admin
        this.getData()
    },
    beforeUpdate(){
        if (this.auxOrganizationID != JSON.parse(localStorage.getItem("user")).admin.organization_id) { 
            this.auxOrganizationID = JSON.parse(localStorage.getItem("user")).admin.organization_id
            console.log("kikiki", this.auxOrganizationID);
        }
    },
    updated(){
        if (this.auxOrganizationLS.organization_id != JSON.parse(localStorage.getItem("user")).admin.organization_id) {
            console.log("2");
            this.getData()
            this.auxOrganizationLS = JSON.parse(localStorage.getItem("user")).admin
        }
    },
    methods: {
        cancelInv (item) {  
            InvitatonDataServices.cancelInvitation(item.invitation).then(
                Response => {
                    item.invitation.is_cancelled = true
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('InvitationCancelled'),
                    }).then(() => {
                        this.getData()
                    });
                },
                Error => {
                    this.$swal.fire({
                            icon: 'error',
                            text: Error.response.data.message
                    })
                }
            )
        },
        applyRowClass(row) {
            if (row != null) {
                if (row.invitation.is_rejected) {
                return 'disabled-row';
                }  else {
                    return '';
                }
            }
        },
        getData () {
            if (this.organization_id){
                InvitatonDataServices.findAll(this.organization_id).then(
                    Response => {
                        this.invitations = [];
                        this.inv = Response.data.data;
                        let all_invitations = [
                            ...this.inv.complete,
                            ...this.inv.pending,
                            ...this.inv.rejected,
                            ...this.inv.cancelled
                        ];

                        for (let inv of all_invitations) {
                            // Convert createdAt to a moment object and add one month
                            let date_formated = moment(inv.createdAt).add(1, 'month').format('DD/MM/YYYY');
                            inv.expirationDate = date_formated;
                            this.invitations.push({invitation: inv});
                        }
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                    }
                )
                const users = JSON.parse(localStorage.getItem("user"));
                OrganizationsDataService.findByOrganizationId(this.organization_id).then(
                    Response => {
                        this.users = Response.data.data  
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                    }
                )
            }
        },
        sendInv() {
            this.$validator.validateAll(['input-email_inv']).then(result => {
                if (!result) {
                    return
                }
                this.loading = true;
                this.modals.invite = false
                // Llamada a endpoint de invitar a external user 
                this.auxOrganizationID = JSON.parse(localStorage.getItem("user")).admin.organization_id
                InvitatonDataServices.invite(this.email_inv, this.organization_id).then(
                    Response => {
                        if (Response.data.email_exist) {
                                this.$swal.fire({
                                icon: 'success',
                                title: this.$t('Alerts.InvitationSent'),
                                text: this.$t('Alerts.AcceptOrDecline')
                            }).then(() => {
                                // this.modals.invite = false
                                this.getData()
                            });
                        }
                        // else if (['input-email_inv'] == this.users.email){
                        //     this.$swal.fire({
                        //         icon: 'error',
                        //         title: "This user is already in the organization",
                        //         text: "You can't invite a member that already exists"
                        //     }).then(() => {
                        //         // this.modals.invite = false
                        //         this.getData()
                        //     });
                        // }
                        else {
                            this.$swal.fire({
                                icon: 'success',
                                title: this.$t('Alerts.InvitationSent'),
                                text: this.$t('Alerts.PriceSubtracted')
                            }).then(() => {
                                // this.modals.invite = false
                                this.getData()
                            });
                        }
                        this.loading = false;
                    },
                    Error => {
                        this.loading = false
                        this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Alerts.ErrorSendingInvitation')
                    }).then(() => {
                        // this.$router.push('/')
                    })
                    this.loading = false;
                    }
                )
                // this.$router.push('/signup-invite')
            })
        },
        validateState (ref) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
            return !this.veeErrors.has(ref)
        }
        return null
        }
    }
    
}
</script>
<style>
.disabled-row {
  background-color: #f8f8f8;
  color: #999999;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.card-h-100 {
    height: 100%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #5D6176;
  background-color: #5D6176;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  border-color: #5D6176;
}
.custom-width-modal .modal-dialog {
    max-width: 1000px;
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}

.invitation-status{
    font-size: 14px;
    border-radius: 0.1875rem;
    padding: 5px 15px;
    color: #FFFFFF;
    text-transform: uppercase;
    min-width: 120px;
    width: 120px;
    display: inline-block;
}

.pending{
    background-color: #9d8989;
}

.cancelled{
    background-color: red;
}

.rejected{
    background-color: black;
}

.completed{
    background-color: green;
}
</style>