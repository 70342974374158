<template>
  <div class="wrapper dashboard-wrapper collapsed-sidebar">
    <b-container class="container" style="margin-top: 5%; max-width: 94%;">
      <b-row class="page-header-bs">
        <b-col cols="2" style="display: flex">
          <h2 class="card-title">
              {{ $t("Ecosystems.Title") }}
          </h2> 
        </b-col>
        <b-col cols="3" style="display: flex">
            <drop-down class="dropdownEco">
              <n-button style="margin-top: 2%"
                  slot="title"
                  class="dropdown-toggle"
                  type="primary"
                  data-toggle="dropdown"
              >
                  <template v-if="type_filter == undefined">
                      {{$t("Utils.DropDownType")}}
                  </template>
                  <template v-else>
                    {{ $t("EcosystemCategories.Filter") }}: <b>{{type_filter.name}}</b>
                  </template>
              </n-button>
              <a v-for="(type, index) of types"
              style="cursor: pointer;"
              class="dropdown-item" :key="index" @click="filterCategory(type)" :class="{ 'active': type.name === type_filter.name }"
              >{{type.name}}</a> 
            </drop-down>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <card>
            <b-row>
              <b-col>
                <h6 class="titleEco">{{$t("Ecosystems.Table.Title")}}</h6>
              </b-col>
              <b-col>
                <b-button-toolbar class="buttonEco" key-nav aria-label="Toolbar with button groups" style="float: right">
                  <b-button-group class="mx-1">
                    <n-button id="newAdmin" class="btn-simple ncm-btn" target="_blank" @click.native="openCreateModal()">
                        <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                        {{$t("Ecosystems.Table.Button")}}
                    </n-button>
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table ref="ecosystemTable"
                    id="ecosystemTable"
                    class="dashboard-table"
                    :fields="headers" 
                    :items="ecosystems_to_show"
                    select-mode="single"
                    selectable
                    show-empty
                    @row-selected="selectRow"
                    @row-clicked="handleRowClicked"
                    @sort-changed="sortingChanged"
                    :current-page="currentPage"
                    :per-page="perPage"
                    sort-by="createdAt"
                    :sort-desc="true"
                    responsive="sm"> 
                    <template #empty="scope">
                        {{$t('Ecosystems.Table.Empty')}}
                    </template>
                    <template #cell(label)="data">
                        {{ searchTranslate(data.item) }}
                    </template>
                    <template #cell(createdAt)="data">
                        {{ data.item.createdAt | formatDate}}
                    </template>
                    <template #cell(updatedAt)="data">
                        {{ data.item.updatedAt | formatDate}}
                    </template>
                    <template #cell(ecosystem_childs)="data">
                        <template  v-if="data.item.ecosystem_childs.length > 0">
                          {{ data.item.ecosystem_childs.length }}
                        </template>
                        <template v-else>
                          -
                        </template>
                    </template>
                    <template #cell(services)="data">
                        <template v-if="data.item.services.length > 0">
                          {{ data.item.services.length }}
                        </template>
                        <template v-else>
                          -
                        </template>
                    </template>
                    <template #cell(options)="data">
                        <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Ecosystems.Tooltips.Modify')" @click="openEditModal(data.item)">
                          <b-icon icon="pen"></b-icon>
                        </b-button>
                        <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Ecosystems.Tooltips.Language')" @click="openEditLanguageModal(data.item)">
                          <b-icon icon="translate"></b-icon>
                        </b-button>
                        <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Ecosystems.Tooltips.Delete')" @click="openDeleteModal(data.item)">
                          <b-icon icon="trash"></b-icon>
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-card class="row-details-card">
                            <div v-if="selectedElement" class="info-container">
                              <b-row>
                                <b-form-group :label="$t('Ecosystems.Title')" class="info-group" label-class="info-group-label">
                                    <p class="info-group-input">
                                      {{ selectedElement.label }}
                                    </p>
                                </b-form-group>
                              </b-row>
                              <b-row>
                                <b-form-group :label="$t('Ecosystems.Table.Childs')" class="info-group" label-class="info-group-label">
                                  <p class="info-group-input">
                                      <template v-if="selectedElement.ecosystem_childs.length > 0">
                                        {{ selectedElement.ecosystem_childs.map(f => f.label).join(', ') }}
                                      </template>
                                      <template v-else>
                                        {{$t("Ecosystems.Table.ChildsEmpty")}}
                                      </template>
                                    </p>
                                </b-form-group>
                              </b-row>
                              <b-row>
                                <b-form-group :label="$t('Ecosystems.Table.Services')" class="info-group" label-class="info-group-label">
                                  <p class="info-group-input">
                                    <template v-if="selectedElement.services.length > 0">
                                      {{ selectedElement.services.map(f => f.label).join(', ') }}
                                      </template>
                                      <template v-else>
                                        {{$t("Ecosystems.Table.ServicesEmpty")}}
                                      </template>
                                    </p>
                                </b-form-group>
                              </b-row>
                            </div>
                        </b-card>   
                    </template>
                </b-table>
                <div class="pagination-container">
                    <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                    </b-pagination>
                    <b-form-select v-model="perPage" class="pagination-select">
                        <b-form-select-option :value="5">5</b-form-select-option>
                        <b-form-select-option :value="10">10</b-form-select-option>
                        <b-form-select-option :value="20">20</b-form-select-option>
                        <b-form-select-option
                            :value="ecosystems.length">{{ $t('DashBoardStaff.Options') }}</b-form-select-option>
                    </b-form-select>
                </div>
              </b-col>
            </b-row>
          </card>
        </b-col>
        
      </b-row>
    </b-container>
    <!-- Modal create  -->
    <modal :show.sync="modals.create" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("Ecosystems.Modals.Create.Title") }}
      </h4>
      <b-form-group :label="$t('Ecosystems.Table.Label') + ' *' ">
          <b-form-input
            v-model="label_form"
            id="input-label"
            name="input-label"
            v-validate="{ required: true}"
            :state="validateState('input-label')"
            aria-describedby="input-label-live-feedback"
            size="sm"
            :placeholder=" $t('Ecosystems.Table.Label') + ' *' "
          ></b-form-input>
          <b-form-invalid-feedback id="input-label-live-feedback">{{ veeErrors.first('input-label') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('Ecosystems.Modals.Create.Type') + ' *'  ">
            <b-form-select v-model="type_form" 
              id="input-type"
              name="input-type"
              v-validate="{ required: true}"
              :state="validateState('input-type')"
              aria-describedby="input-type-live-feedback"
              :options="types"
              value-field="id"
              text-field="name">
              <b-form-select-option :value="null">{{$t('Ecosystems.Modals.Create.TypePH')}}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback id="input-type-live-feedback">{{ veeErrors.first('input-type') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('Ecosystems.Modals.Create.Category') + ' *'  ">
            <b-form-select v-model="category_form" 
              id="input-category"
              name="input-category"
              v-validate="{ required: true}"
              :state="validateState('input-category')"
              aria-describedby="input-category-live-feedback"
              :options="categories_available"
              value-field="id"
              text-field="name"
              @change="filterParents()">
              <b-form-select-option :value="null">{{$t('Ecosystems.Modals.Create.CategoryPH')}}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback id="input-category-live-feedback">{{ veeErrors.first('input-category') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('Ecosystems.Modals.Create.Parent')">
            <b-form-select v-model="parent_form" 
              id="input-parent"
              name="input-parent"
              >
              <b-form-select-option :value="null">{{$t('Ecosystems.Modals.Create.ParentPH')}}</b-form-select-option>
              <b-form-select-option v-for="(ecosystem, index) of parents_to_show"
              :value="ecosystem" :key="index">
                {{ ecosystem.label }}
              </b-form-select-option>
            </b-form-select>
      </b-form-group> 
      <template slot="footer">
        <n-button class="btn-simple ncm-btn" @click="addElement">
          {{ $t("Ecosystems.Modals.Create.Footer.Save") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.create = false">
          {{ $t("Ecosystems.Modals.Create.Footer.Close") }}
        </n-button>
      </template>
    </modal>
    <!-- Modal delete  -->
    <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
      <p>{{ $t("Ecosystems.Modals.Delete.Title") }}</p>
      <template slot="footer">
        <n-button type="neutral" link @click="deleteElement">
          {{ $t("Ecosystems.Modals.Delete.Footer.Save") }}
        </n-button>
        <n-button type="neutral" link @click.native="modals.delete = false">
          {{ $t("Ecosystems.Modals.Delete.Footer.Close") }}
        </n-button>
      </template>
    </modal>
  </div>
</template>

<script>
  import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal, DropDown} from '@/components';
  import { API_KEY } from '@/constants'
  import {Table, TableColumn, Tooltip} from 'element-ui';
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  });

  import EcosystemDataService from '@/services/EcosystemDataService'
  import EcosystemServiceDataService from '@/services/EcosystemServiceDataService'
  import ParameterDataService from '@/services/ParameterDataService'
  import LanguageDataService from '@/services/LanguageDataService';
  import EcosystemCategoryDataService from '@/services/EcosystemCategoryDataService';
  import EcosystemTypesDataService from '@/services/EcosystemTypesDataService';
  import StaffServices from '../../services/StaffServices';

export default {
  name: 'Ecosystems',
  bodyClass: 'profile-page',
  components: {
      Modal,
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
      [DropDown.name]: DropDown,
  },
  data() {
      return {
        modals: {
          modify: false,
          delete: false,
          create: false,
        },
        user: {},
        user_id: null,
        ecosystems: [],
        ecosystems_to_show: [],
        parameters: [],
        services: [],
        languages: [],
        categories: [],
        types: [],
        selectedElement: null,
        label_form: '',
        language_form: null,
        parent_form: null,
        type_form: null,
        category_form: null,
        options: ['Apple', 'Orange', 'Banana', 'Lime', 'Peach', 'Chocolate', 'Strawberry'],
        search: '',
        search_parameters: '',
        value_services: [],
        value_parameters: [],
        headers: [
          { key: 'category.name', sortable: true, label: this.$t('Ecosystems.Table.Category'), thClass: 'position-sticky' },
          { key: 'label', sortable: true, label: this.$t('Ecosystems.Table.LabelTable'), tdClass: 'table-title', thClass: 'table-title position-sticky' }, 
          { key: 'category.type.name', sortable: true, label: this.$t('Ecosystems.Table.Type'), thClass: 'position-sticky' },  
          { key: 'services', sortable: true, label: this.$t('Ecosystems.Table.Services'), thClass: 'position-sticky' }, 
          { key: 'createdAt', label: this.$t('Ecosystems.Table.CreatedAt'), thClass: 'position-sticky', sortable: true },
          { key: 'updatedAt', label: this.$t('Ecosystems.Table.UpdatedAt'), thClass: 'position-sticky', sortable: true },
          { key: 'options', label: this.$t('DashBoardStaff.Table.Actions'), thClass: 'position-sticky' }
        ],
        allOpenRows: [],
        currentPage: 1,
        perPage: 10,
        type_filter: null,
        view_to: '/ecosystems',
        parents_to_show: []
      }
  },
computed: {
      totalPages () {
        return this.ecosystems.length
      },
      criteria() {
        return this.search.trim().toLowerCase()
      },
      availableOptions() {
        const criteria = this.criteria
        const options = this.services.filter(opt => this.value_services.indexOf(opt) === -1)
        if (criteria) {
          var match = options.filter(opt => opt.label.toLowerCase().indexOf(criteria) > -1);
          return match.map(f => f.label)
        }
        return options.map(f => f.label)
      },
      searchDesc () {
        if (this.criteria && this.availableOptions.length === 0) {
          return 'There are no tags matching your search criteria'
        }
        return ''
      },
      criteriaParameters () {
        return this.search_parameters.trim().toLowerCase()
      },
      availableOptionsParameters () {
        const criteria = this.criteria
        // Filter out already selected options
        const options = this.parameters.filter(opt => this.value_parameters.indexOf(opt) === -1)
        if (criteria) {
          // Show only options that match criteria
          var match = options.filter(opt => opt.label.toLowerCase().indexOf(criteria) > -1);
          return match.map(f => f.label)
        }
        // Show all options available
        return options.map(f => f.label)
      },
      searchDescParameters() {
        if (this.criteria && this.availableOptions.length === 0) {
          return 'There are no tags matching your search criteria'
        }
        return ''
      },
      categories_available(){
        var available = this.categories.filter(f => f.category_type_id == this.type_form)
        return available
      }
      
    },
  created() {
    this.getData(true)
  },
  methods:{

    filterParents(){
      this.parents_to_show = this.ecosystems.filter(f => f.category.id == this.category_form)
    },
    filterCategory(type) {
      if (type != null){
        this.ecosystems_to_show = this.ecosystems.filter(f => f.category.category_type_id == type.id)
      }
      else {
        this.ecosystems_to_show = this.ecosystems
        this.type_filter = type
        this.type_form = this.type_filter.id
      }
      console.log(this.ecosystems_to_show)
    },
    selectRow (items) {
      this.selectedElement = items[0]
    },
    validateState (ref) {
      if (this.veeFields[ref] &&(this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    sortingChanged(item, index){
      this.allOpenRows.map((ele) => {
        if (ele.id !== item.id && ele._showDetails) {
          this.$set(ele, '_showDetails', !ele._showDetails)
        }
      })
    },
    handleRowClicked (item, index) {
      this.allOpenRows.map((ele) => {
        if (ele.id !== item.id && ele._showDetails) {
        this.$set(ele, '_showDetails', !ele._showDetails)
        }
      })
      this.allOpenRows = []
      this.$set(item, '_showDetails', !item._showDetails)
      this.allOpenRows.push(item)
    },
    onOptionClick({ option, addTag }) {
      addTag(option)
      console.log('option', option)
      this.search = ''
    },
    onSearchEnter({ addTag }) {
      addTag(this.search)
      this.search = ''
    },
    async getData(filter_favourite){
      let data = JSON.parse(localStorage.getItem('user'))
      this.user_id = data.data.id 
      await StaffServices.findOne(this.user_id).then(
        Response => {
          this.user = Response.data.data
        }
      )
      await EcosystemDataService.findAll('eraefaef').then(
        async response => {
          this.ecosystems = response.data
        },
        error => {
            this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.message
            })
            if (error.response.status == '401') {
                this.$router.push('dashboard-staff')
            }
        }
      )
      await EcosystemServiceDataService.findAll('eraefaef').then(
        async response => {
          this.services = response.data
        },
        error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message
          })
        }
      )

      await ParameterDataService.findAll('eraefaef').then(
        async response => {
            console.log(response.data)
            this.parameters = response.data
        },
        error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message
          })
        }
      )
      await LanguageDataService.findAll().then(
        async response => {
            this.languages = response.data
        },
        error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message
          })
        }
      )
      await  EcosystemCategoryDataService.findAll().then(
        async response => {
          this.categories = response.data.data
        },
        error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message
          })
        }
      )    
      EcosystemTypesDataService.findAll().then(
        async response => {
            this.types = response.data.data
            if (filter_favourite)
              this.type_filter = this.types.filter(f => f.id == this.user.favourite_ecosystem_category_type)[0]
            else
              this.type_filter =  this.types.filter(f => f.id == this.type_filter)[0]
              this.type_form = this.type_filter.id
              this.filterCategory(this.type_filter)
        },
        error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message
          })
        }
      )  
    },
  async addElement(){
        this.$validator.validateAll(['input-label', 'input-type', 'input-category']).then(async result => {
          if (!result) {
            return
          }
          var element = {}
          var language = this.languages.find(f => f.name.toLowerCase() === 'english'.toLowerCase());
          if (this.parent_form != null && this.parent_form !== undefined) {
            element = {
              label: this.label_form,
              parent_id: this.parent_form.id,
              language_id: language.id,
              category_id: this.category_form
            }
          }
          else{
            element = {
              label: this.label_form,
              language_id: language.id,
              category_id: this.category_form
            }
          }       
          await EcosystemDataService.create(element).then(
              async response => {
                this.$swal.fire({
                  icon: 'success',
                  title: this.$t('EcosystemEdit.Message3')
                }).then(() => {
                  this.modals.create = false
                  this.type_filter = this.type_form
                  this.getData(false)
                })
              },
              error => {
                console.log((error.response && error.response.data) ||
                error.message ||
                error.toString())
                this.$swal.fire({
                  icon: 'error',
                  title: this.$t('EcosystemEdit.Message3Error')
                });
              }
            )
        })  
    },
    openCreateModal(){
      // Reset form
      this.label_form = ''
      this.parent_form = null
      this.language_form = null
      this.category_form = null
      this.$validator.reset(['input-label', 'input-category','input-type',]);
      // Show form
      this.modals.create = true
    },
    openEditLanguageModal(element){
        this.$router.push('/ecosystems/languages/' + element.id)
    },
    openEditModal(element){
        this.$router.push('/ecosystems/' + element.id)
    },
    openDeleteModal(element){
        console.log(element)
        this.selectedElement = element
        this.modals.delete = true
    },
    deleteElement(){
      EcosystemDataService.delete(this.selectedElement.id).then(
        async response => {
          this.modals.delete = false
          this.$swal.fire({
              icon: 'success',
              title: this.$t('EcosystemEdit.Message4')
            }).then(() => {
              this.type_filter = this.selectedElement.category.type.id
              this.getData(false)
            });
        },
        error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message
          })
        }
      )
    },
    searchTranslate (item) {
      const translate = item.languages.find(lang => lang.language.code.startsWith(this.$i18n.locale));
      if (translate) {
          return translate.name;
      } else {
          return item.label;
      }
    }
  }
}
</script>
<style scoped>

.dropup, .dropright, .dropdown, .dropleft {
    position: relative;
    margin-bottom: auto;
}

@media (max-width: 465px) {
  .titleEco {
    text-align: center;
  }

  .buttonEco{
    float: center;
  }
}

@media (max-width: 1774px) {
  .dropdownEco {
    margin-top: 50px;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
</style>