<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar">
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                        {{ $t("Tutorials.Title") }}
                    </h2> 
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <card>
                        <b-row>
                            <b-col>
                                <h6 class="titleTuto">{{ $t("Tutorials.AllT") }}</h6>
                            </b-col>
                            <b-col>
                                <b-button-toolbar class="buttonTuto" key-nav aria-label="Toolbar with button groups" style="float: right;">
                                    <b-button-group class="mx-1">
                                        <n-button
                                        id="newAdmin"
                                        class="btn-simple ncm-btn"
                                        style="font-weight: 800 !important;"
                                        target="_blank"
                                        @click.prevent="$router.push({ path: '/tutorials/add-tutorial' })"
                                        >
                                        <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                                        {{ $t("Tutorials.Create") }}
                                        </n-button>
                                    </b-button-group>
                                </b-button-toolbar>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table
                                ref="parametersTable"
                                id="parametersTable"
                                :fields="headers" 
                                :items="tutorials"
                                show-empty
                                select-mode="single"
                                style="overflow-x: auto;"
                                :current-page="currentPage"
                                :per-page="perPage"
                                sort-by="createdAt"
                                :sort-desc="true"
                                responsive="sm"> 
                                <template #empty="">
                                    {{$t('Tutorials.Empty')}}
                                </template>
                                <template #cell(title)="data">
                                    <template v-if="data.item.image">
                                        <video
                                            v-if="isVideo(data.item.image.url)"
                                            class="img img-raised table-image"
                                            controls
                                            height="56"
                                            width="100"
                                        >
                                            <source :src="getVideoUrl(data.item.image.url)" type="video/mp4">
                                        </video>
                                        <img v-else
                                        class="img img-raised table-image"
                                        :src="getImgUrl(data.item.image.url)"
                                        width="100"
                                        height="56"
                                        >
                                    </template>
                                    <template v-else>
                                        <img 
                                        v-if="data.item.external_image"
                                        class="img img-raised table-image"
                                        :src="data.item.external_image"
                                        width="100"
                                        height="56" 
                                        >
                                        <img 
                                        v-if="!data.item.external_image"
                                        class="img img-raised table-image"
                                        :src="$getImgUrlSite('blurred-image-2.jpg')"
                                        width="100"
                                        height="56" 
                                        >

                                    </template>
                                    <h6>{{ data.item.title }}</h6>
                                </template>
                                <template #cell(subtitle)="data">
                                    <template v-if="data.item.subtitle.length > 100">
                                        {{data.item.subtitle.substr( 0, 100) + '…' }}
                                    </template>
                                    <template v-else>
                                        {{data.item.subtitle}}
                                    </template>
                                </template>
                                
                                <template #cell(createdAt)="data">
                                    {{ data.item.createdAt | formatDate}}
                                </template>
                                
                                <template #cell(updatedAt)="data">
                                    {{ data.item.updatedAt | formatDate}}
                                </template>
                                <template #cell(options)="data">
                                    <b-button v-if="!data.item.is_public" class="action-table" v-b-tooltip.hover.viewport :title="$t('Posts.Tooltips.TogglePublic')" @click="togglePublic(data.item)">
                                        <b-icon icon="lock"></b-icon>
                                    </b-button>
                                    <b-button v-else class="action-table" v-b-tooltip.hover.viewport :title="$t('Posts.Tooltips.TogglePrivate')" @click="togglePublic(data.item)">
                                        <b-icon icon="unlock"></b-icon>
                                    </b-button>
                                    <b-button v-if="!data.item.visibility" class="action-table" v-b-tooltip.hover.viewport :title="$t('Posts.Tooltips.ToggleVisualization')" @click="toggleVisualization(data.item)">
                                        <b-icon icon="eye-slash"></b-icon>
                                    </b-button>
                                    <b-button v-else class="action-table" v-b-tooltip.hover.viewport :title="$t('Posts.Tooltips.ToggleNotVisualization')" @click="toggleVisualization(data.item)">
                                        <b-icon icon="eye"></b-icon>
                                    </b-button>
                                    <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Modify')" @click="openEditModal(data.item)">
                                        <b-icon icon="pen"></b-icon>
                                    </b-button>
                                    <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Delete')" @click="openDeleteModal(data.item)">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                </template>
                                <template #row-details="row">
                                    <b-card class="row-details-card">
                                        <b-row>
                                            <b-col class="row-details-col">
                                                {{ row.item }}
                                            </b-col>
                                        </b-row>
                                    </b-card>
                                </template>
                                
                            </b-table>
                            <div class="pagination-container">
                                <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                                </b-pagination>
                                <b-form-select v-model="perPage" class="pagination-select">
                                    <b-form-select-option :value="5">5</b-form-select-option>
                                    <b-form-select-option :value="10">10</b-form-select-option>
                                    <b-form-select-option :value="20">20</b-form-select-option>
                                    <b-form-select-option
                                        :value="tutorials.length">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                            </b-col>
                        </b-row>
                    </card>
                </b-col>
            </b-row>
            <modal :show.sync="modals.modify" header-classes="justify-content-center">
                <h4 slot="header" class="title title-up">
                    {{ $t("Tutorials.Modals.Modify.Title") }}
                </h4>
                <b-form-group :label="$t('Tutorials.Modals.Modify.TitleF')">
                    <b-form-input
                        v-model="selectedElement.title"
                        id="input-tutorial-title-modify"
                        name="input-tutorial-title-modify"
                        v-validate="{ required: true}"
                        :state="validateState('input-tutorial-title-modify')"
                        aria-describedby="input-tutorial-title-modify-live-feedback"
                        size="sm"
                        :placeholder="$t('Tutorials.Modals.Modify.TitleF')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-tutorial-title-modify-live-feedback">{{ veeErrors.first('input-tutorial-title-modify') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group :label="$t('Tutorials.Modals.Modify.Slug')">
                    <b-form-input
                        v-model="selectedElement.title_slug"
                        id="input-tutorial-slug-modify"
                        name="input-tutorial-slug-modify"
                        v-validate="{ required: true}"
                        :state="validateState('input-tutorial-slug-modify')"
                        aria-describedby="input-tutorial-slug-modify-live-feedback"
                        size="sm"
                        :placeholder="$t('Tutorials.Modals.Modify.Slug')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-tutorial-slug-modify-live-feedback">{{ veeErrors.first('input-tutorial-slug-modify') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group :label="$t('Tutorials.Modals.Modify.Subtitle')">
                    <b-form-input
                        v-model="selectedElement.subtitle"
                        id="input-tutorial-subtitle-modify"
                        name="input-tutorial-subtitle-modify"
                        v-validate="{ required: true}"
                        :state="validateState('input-tutorial-subtitle-modify')"
                        aria-describedby="input-tutorial-subtitle-modify-live-feedback"
                        size="sm"
                        :placeholder="$t('Tutorials.Modals.Modify.Subtitle')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-tutorial-subtitle-modify-live-feedback">{{ veeErrors.first('input-tutorial-subtitle-modify') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group :label="$t('Ecosystems.Modals.Create.Type') + ' *'  ">
                    <b-form-select 
                        v-model="selectedElement.category_tutorial_id" 
                        id="input-tutorial-category_tutorial_name-modify"
                        name="input-tutorial-category_tutorial_name-modify"
                        v-validate="{ required: true}"
                        :state="validateState('input-tutorial-category_tutorial_name-modify')"
                        aria-describedby="input-tutorial-category_tutorial_name-modify-live-feedback"
                        :options="categories"
                        value-field="id"
                        text-field="name">
                    </b-form-select>
                    <b-form-invalid-feedback id="input-tutorial-category_tutorial_name-modify-live-feedback">{{ veeErrors.first('input-tutorial-category_tutorial_name-modify') }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group>
                    <vue-editor 
                        v-model="selectedElement.content" 
                        :editor-toolbar="customToolbar" 
                    />
                </b-form-group>
                <template slot="footer">
                    <n-button class="btn-simple ncm-btn" @click.native="editPost()" >
                    {{ $t("DashBoardStaff.Modals.AssignRole.Footer.Save") }} 
                    </n-button>
                    <n-button class="btn-simple ncm-btn-danger" @click.native="modals.modify = false">
                    {{ $t("DashBoardStaff.Modals.AssignRole.Footer.Close") }} 
                    </n-button>
                </template>
                </modal>
            <!-- Modal delete  -->
            <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
                <p>Are you sure delete: <b></b><b>{{titleDelete}} </b>?</p>
                <template slot="footer">
                    <n-button type="neutral" link @click.native="deletePost()">
                    {{ $t("Ecosystems.Modals.Delete.Footer.Save") }}
                    </n-button>
                    <n-button type="neutral" link @click.native="modals.delete = false">
                    {{ $t("Ecosystems.Modals.Delete.Footer.Close") }}
                    </n-button>
                </template>
            </modal>
        </b-container>
    </div>
</template>
<script>
import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal} from '@/components';
import { API_KEY } from '@/constants'
import {Table, TableColumn, Tooltip} from 'element-ui';
import TutorialDataService from '../../services/TutorialDataService'; 
import CategoriesPosts from '../../services/CategoriesPostsDataServices'
import { VueEditor } from "vue2-editor";

export default {
    components: {
        Modal,
        VueEditor,
    Card,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [FormGroupInput.name]: FormGroupInput,

    }, 
    data() {
        return {
            modals: {
                modify: false,
                delete: false
            },
            categories: [],
            tutorials: [],
            idDelete: null,
            headers: [
                { key: 'title', sortable: true, label: this.$t('Languages.Table.Titles'), tdClass: 'img-title', thClass: 'table-title position-sticky img-title' }, 
                { key: 'title_slug',  label: "Slug", thClass: 'position-sticky'}, 
                { key: 'subtitle',  label: this.$t('Languages.Table.Subtitle'), thClass: 'position-sticky', tdClass: 'max-w-300'}, 
                { key: 'category_tutorial_name', sortable: true, label: this.$t('Languages.Table.Category'), thClass: 'position-sticky'}, 
                { key: 'language_name',  label: this.$t('Languages.Table.Language'), thClass: 'position-sticky'}, 
                { key: 'createdAt', label: this.$t('Parameters.Table.CreatedAt'), thClass: 'position-sticky', sortable: true },
                { key: 'updatedAt', label: this.$t('Parameters.Table.UpdatedAt'), thClass: 'position-sticky', sortable: true },
                { key: 'options', label: this.$t('Languages.Table.Actions'), thClass: 'position-sticky' }
            ],
            selectedElement: {},
            titleDelete: '',
            currentPage: 1,
            perPage: 10,
            customToolbar: [
                [{ font: [] }],
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike"],
                [{ align: "" },{ align: "center" },{ align: "right" },{ align: "justify" }],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["link",  "video", "formula"],
                [{ direction: "rtl" }], 
            ]
        }
    },
    created () {
        this.getData()
    },
    computed: {
        totalPages(){
            return this.tutorials.length
        },
    },
    methods : {
        toggleVisualization(tutorial){
            tutorial.visibility = !tutorial.visibility
            TutorialDataService.modify(tutorial).then(
                Response => {
                    
                },
                Error =>{
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        isVideo(url) {
            const videoExtensions = ['mp4', 'webm', 'ogg'];
            const extension = url.split('.').pop();
            // console.log(videoExtensions.includes(extension.toLowerCase()))

            return(videoExtensions.includes(extension.toLowerCase()))
        },
        getVideoUrl(url) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${url}?withheaders=yes`
            // try {
            //     if(url){
            //         return require('../../assets/multimedia/blog/images/' + url);
            //     }
            // } catch {
            //     console.log('error loading video')
            // }
            // Construye la ruta de archivo local para el video
        },
        togglePublic(tutorial){
            tutorial.is_public = !tutorial.is_public
            TutorialDataService.modify(tutorial).then(
                Response => {
                    
                },
                Error =>{
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        getData() {
            TutorialDataService.findAll().then(
                Response => {
                    console.log(Response.data)
                    this.tutorials = Response.data.data
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                    if (Error.response.status == '401') {
                        this.$router.push('/dashboard-staff')
                    }
                }
            )
            CategoriesPosts.findAll().then(
                Response => {
                    this.categories = Response.data.data
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        openDeleteModal (row) {
            this.titleDelete = row.title
            console.log(row)
            this.idDelete = row.id
            this.modals.delete = true
        },
        openEditModal (row) {
            console.log('ROW',row)
            this.selectedElement = {...row}
            this.$router.push('/tutorials/edit/' + this.selectedElement.id)
            // this.modals.modify = true
        },
        deletePost () {
            TutorialDataService.delete(this.idDelete).then(
                Response => {
                    this.$swal.fire({
                    icon: 'success',
                    title: this.$t('Alerts.PostDeleted')
                    }).then(() => {
                        this.getData()
                        this.modals.delete = false
                    });
                },
                Error =>{
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        editPost () {
            this.selectedElement.type = 'tutorial'
            // TutorialDataService.modify(this.selectedElement).then(
            //     Response => {
            //         this.$swal.fire({
            //         icon: 'success',
            //         title: 'Post Modify!!'
            //         }).then(() => {
            //             this.getData()
            //             this.modals.modify = false
            //         });
            //     },
            //     Error =>{
            //         console.log('Error al modificar el tutorial')
            //     }
            // )
        },
        validateState (ref) {
            if (this.veeFields[ref] &&(this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref)
            }
                return null
        },
        getImgUrl (name) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${name}?withheaders=yes`
            //return 'https://p-ncm-public.s3.eu-south-2.amazonaws.com/' + name
            /*try{
                if(name){
                    var images = require.context('../../assets/multimedia/blog/images', false)
                    console.log(images('./' + name))
                    return images('./' + name)
                }
            }catch{
                console.log('error loading image')
            }*/
        },
    },
  }
</script>

<style scoped>
@media (max-width: 465px) {
  .titleTuto {
    text-align: center;
  }

  .buttonTuto{
    float: center;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}

</style>