import http from '../http-common'
import authHeader from './AuthHeaderService'
const API_URL_PATH = 'api/cadastre/'

class CadastreDataService {
    
    findAll(){
        return http.get(API_URL_PATH + 'get-cadastres', {headers: authHeader()})
    }

    findOne(id){
        return http.get(API_URL_PATH + 'get-cadastre?id=' + id, {headers: authHeader()})
    }

    uploadCadastre(data) {
        const body = {
          country: data.country,
          projection: data.projection,
          wms_link: data.wms_link,
          wfs_link: data.wfs_link,
          type_name: data.type_name
        }
        console.log(body);
        return http.post(API_URL_PATH + 'create', body, { headers: authHeader() })
      }

    updateCadastre(data) {
        const body = {
        //   id: data.id,
        //   name: data.name,
        //   description: data.description,
        //   organization_id: data.organization_id,
        //   user_id: data.user_id
        }
        return http.put(API_URL_PATH + 'update', body, { headers: authHeader() })
      }

    delete(id) {
        return http.delete(API_URL_PATH + 'delete?id=' + id, { headers: authHeader() })
    }

}

export default new CadastreDataService()
  