import http from '../http-common'
import authHeader from './AuthHeaderService'

const API_URL_PATH = 'api/ecosystem/'

class EcosystemDataService {

  findAll () {
    return http.get(API_URL_PATH + 'find-all',{headers: authHeader()})
  }

  findOne (id) {
    return http.get(API_URL_PATH + 'find-one?id=' + id,{headers: authHeader()})
  }

  create (ecosystem) {
    return http.post(API_URL_PATH + 'create', { ecosystem: ecosystem },{headers: authHeader()})
  }

  update (ecosystem) {
    return http.put(API_URL_PATH + 'update', { id: ecosystem.id, ecosystem: ecosystem },{headers: authHeader()})
  }

  delete (id) {
    return http.delete(API_URL_PATH + 'delete?id=' + id,{headers: authHeader()})
  }

  createEcosystemLanguage (ecosystem_language) {
    return http.post(API_URL_PATH + 'create-ecosystem-language', { ecosystem_language: ecosystem_language },{headers: authHeader()})
  }

  updateEcosystemLanguage (ecosystem_language) {
    return http.put(API_URL_PATH + 'update-ecosystem-language', { ecosystem_language: ecosystem_language },{headers: authHeader()})
  }

  deleteEcosystemLanguage (ecosystem_id, language_id) {
    return http.delete(API_URL_PATH + 'delete-ecosystem-language?ecosystem_id=' + ecosystem_id + '&language_id=' + language_id,{headers: authHeader()})
  }

  

}

export default new EcosystemDataService()
