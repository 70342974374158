<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar" >
    <b-container class="container" style="margin-top: 5%; max-width: 94%;">
        <b-row class="page-header-bs">
            <b-col style="display: flex">
                <h2 class="card-title">
                    {{ $t("EditTutorial.Title") }}
                </h2> 
            </b-col>
            <b-col>
                <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right">
                    <b-button-group class="mx-1">
                        <n-button
                        id="newAdmin"
                        class="btn-simple ncm-btn-back"
                        target="_blank"
                        @click="goBack()"
                        >
                        <b-icon icon="box-arrow-in-left" class="button-icon"></b-icon>
                            {{$t("EcosystemEdit.ButtonBack")}}
                        </n-button>
                    </b-button-group>
                    <b-button-group class="mx-1">
                        <n-button
                        id="newAdmin"
                        class="btn-simple ncm-btn"
                        target="_blank"
                        @click="editPost()"
                        >
                        <b-icon icon="cloud-arrow-up-fill" class="button-icon"></b-icon>
                            {{$t("EcosystemEdit.ButtonSave")}}
                        </n-button>
                    </b-button-group>
                </b-button-toolbar>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <card>
                    <b-row>
                        <b-col cols="4" class="mr-auto" style="float: left; margin-bottom: 15px">
                            <span><h6 class=" mb-0">{{ $t("AddTutorial.PostDetails") }}</h6></span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="8">
                            <b-row>
                                <b-col>
                                    <b-form-group :label="$t('AddTutorial.Form.Title')" label-for="input-tutorial-title">
                                        <b-form-input
                                        v-model="tutorial.title"
                                        id="input-tutorial-title"
                                        name="input-tutorial-title"
                                        v-validate="{ required: true}"
                                        :state="validateState('input-tutorial-title')"
                                        aria-describedby="input-tutorial-title-live-feedback"
                                        size="sm"
                                        :placeholder="$t('AddTutorial.Form.Title')"
                                        @input="updateSlug"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-tutorial-title-live-feedback">{{ veeErrors.first('input-tutorial-title') }}</b-form-invalid-feedback>
                                    </b-form-group>
                                    <b-form-group :label="$t('AddTutorial.Form.Slug')"  label-for="input-tutorial-slug">
                                        <b-form-input
                                        v-model="tutorial.title_slug"
                                        id="input-tutorial-slug"
                                        name="input-tutorial-slug"
                                        v-validate="{ required: true, 'kebab-case': true}"
                                        :state="validateState('input-tutorial-slug')"
                                        aria-describedby="input-tutorial-slug-live-feedback"
                                        size="sm"
                                        :placeholder="$t('AddTutorial.Form.Slug')"
                                        disabled
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-tutorial-slug-live-feedback">{{ veeErrors.first('input-tutorial-slug') }}</b-form-invalid-feedback>
                                    </b-form-group>
                                    <b-form-group :label="$t('AddTutorial.Form.Subtitle')" label-for="input-tutorial-subtitle">
                                        <b-form-input
                                        v-model="tutorial.subtitle"
                                        id="input-tutorial-subtitle"
                                        name="input-tutorial-subtitle"
                                        v-validate="{ required: true}"
                                        :state="validateState('input-tutorial-subtitle')"
                                        aria-describedby="input-tutorial-subtitle-live-feedback"
                                        size="sm"
                                        :placeholder="$t('AddTutorial.Form.Subtitle')"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-tutorial-subtitle-live-feedback">{{ veeErrors.first('input-tutorial-subtitle') }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="3">
                                    <b-form-group :label="$t('AddTutorial.Form.Content')">
                                        <b-button class="btn-success" @click="modals.image_content = true"><b-icon icon="images" class="mr-2"></b-icon>{{ $t('TutorialEdit.Multimedia') }}</b-button>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="">
                                            <b-form-checkbox
                                            id="checkbox-1"
                                            v-model="tutorial.visibility"
                                            name="checkbox-1"
                                            style="margin-top: 45px"
                                            >
                                            <label style="margin-top: 2px;">{{ $t("AddTutorial.ShowInExplore") }}</label>
                                            </b-form-checkbox>  
                                        </b-form-group>  
                                </b-col>
                                <b-col>
                                    <b-form-group label="">
                                        <b-button class="btn-refresh" @click="refreshEditDate()" style="margin-top: 35px; float: right;">{{ $t('TutorialEdit.UpdateDate') }}</b-button>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>                                      
                                    <vue-editor
                                    ref="vue-editor"
                                    v-model="tutorial.content" 
                                    :customModules="customModulesForEditor"
                                    :editorOptions="editorSettings"
                                    :editor-toolbar="toolbarOptions"
                                    />
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="4">
                            <card>
                                <b-row>
                                    <b-col>
                                        <span><h6 class>{{ $t("AddTutorial.MainImage") }}</h6></span>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <div v-if="!tutorial.image && !tutorial.external_image" class="image-upload-container" @click="modals.image = true">
                                            <div class="image-upload-box">
                                                <div class="image-upload-text">{{ $t("AddTutorial.SelectImage") }}</div>
                                                <div class="image-upload-icon">
                                                    <b-icon icon="image-fill" aria-hidden="true"></b-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="image-upload-container" @click="modals.image = true">
                                            <div class="image-upload-box">
                                                <img class="img img-raised image-gallery" v-if="tutorial.image && !isVideo(tutorial.image.url)" :src="getImgUrl(tutorial.image.url)" :alt="tutorial.image.description" />                            
                                                    <video
                                                        v-if="tutorial.image && isVideo(tutorial.image.url)"
                                                        class="img img-raised"
                                                        controls
                                                        style="max-width: 100%"
                                                    >
                                                        <source :src="getVideoUrl(tutorial.image.url)" type="video/mp4">
                                                    </video>
                                                    <img class="img img-raised image-gallery" v-if="tutorial.external_image" :src="tutorial.external_image" :alt="tutorial.external_image_description">
                                            </div>
                                        </div>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <b-alert variant="success" show="" class="custom-alert">
                                                {{ $t("AddTutorial.ThisImageWill") }}                                          
                                            </b-alert>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <b-form-group :label="$t('AddTutorial.OrAddUrl')">
                                            <b-form-input 
                                                v-model="url_external"
                                                @change="setImgExternal"
                                                id="input-external-image"
                                                name="input-external-image"
                                                v-validate="{url: {require_protocol: true }}"
                                                :state="validateState('input-external-image')"
                                                aria-describedby="input-external-image-live-feedback"
                                                :placeholder="$t('AddTutorial.UrlOfImage')">                                        
                                            </b-form-input>
                                            <b-form-invalid-feedback id="input-external-image-live-feedback">{{ veeErrors.first('input-external-image') }}</b-form-invalid-feedback>
                                        </b-form-group> 
                                        <b-form-group :label="$t('AddTutorial.ImageDescription')">
                                            <b-form-input 
                                                v-model="image_description" 
                                                id="input-external-image-description"
                                                name="input-external-image-description"
                                                v-validate="{alpha_spaces:true}"
                                                :state="validateState('input-external-image-description')"
                                                aria-describedby="input-external-image-description-live-feedback"
                                                :placeholder="$t('AddTutorial.ImageDescription')">                                        
                                            </b-form-input>
                                            <b-form-invalid-feedback id="input-external-imag-descriptione-live-feedback">{{ veeErrors.first('input-external-image-description') }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="d-flex justify-content-center align-items-center">
                                        <b-form-group>
                                            <b-form-checkbox
                                                id="checkbox-2"
                                                v-model="tutorial.image_main_view"
                                                name="checkbox-2"
                                                style="margin-top: 14px"
                                                >
                                                <label style="margin-top:2px">{{ $t("AddTutorial.ImgAlso") }}</label>
                                                </b-form-checkbox>
                                        </b-form-group> 
                                    </b-col>
                                    <b-col>
                                        <b-alert variant="success" show="" class="custom-alert">
                                            {{ $t("AddTutorial.ThisImageWill2") }}                                              
                                        </b-alert>
                                    </b-col>
                                </b-row>
                            </card>
                            <card>
                                <b-row>
                                    <b-col>
                                        <span><h6>{{ $t("AddTutorial.Category") }}   </h6></span>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group style="margin-top: 10.5px;">
                                            <b-form-select 
                                                v-model="tutorial.category_tutorial_id" 
                                                id="input-category"
                                                name="input-category"
                                                v-validate="{ required: true}"
                                                :state="validateState('input-category')"
                                                aria-describedby="input-category-live-feedback"
                                                :options="categories"
                                                value-field="id"
                                                text-field="name">
                                                <b-form-select-option :value="null">{{ $t("AddTutorial.PleaseC") }}</b-form-select-option>
                                            </b-form-select>
                                            <b-form-invalid-feedback id="input-category-live-feedback">{{ veeErrors.first('input-category') }}</b-form-invalid-feedback>
                                        </b-form-group> 
                                    </b-col>
                                </b-row>
                            </card>
                            <card>
                                <b-row>
                                    <b-col>
                                        <span><h6>{{ $t("AddTutorial.Language") }}</h6></span>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group label="Language">
                                            <b-form-select 
                                                v-model="tutorial.language_id" 
                                                id="input-language"
                                                name="input-language"
                                                v-validate="{ required: true}"
                                                :state="validateState('input-language')"
                                                aria-describedby="input-language-live-feedback"
                                                :options="languages"
                                                value-field="id"
                                                text-field="name">
                                                <b-form-select-option :value="null">{{ $t("AddTutorial.PleaseL") }}</b-form-select-option>
                                            </b-form-select>
                                            <b-form-invalid-feedback id="input-language-live-feedback">{{ veeErrors.first('input-language') }}</b-form-invalid-feedback>
                                        </b-form-group>   
                                    </b-col>
                                </b-row>
                            </card>
                        </b-col>
                    </b-row>
                </card>
            </b-col>
        </b-row>
    </b-container>
    <modal :close-outside="true" modal-classes="modal-big" v-show="modals.image" :show.sync="modals.image" header-classes="justify-content-center">
            <h4 slot="header" class="title">
                {{ $t("AddTutorial.Modals.Image.Title") }}
            </h4>
            <b-form-group :label="$t('EcosystemCategories.Filter2')">
                <b-form-input
                v-model="search"
                id="input-search"
                name="input-search"
                v-validate="{alpha_spaces: true}"
                :state="validateState('input-search')"
                aria-describedby="input-search-live-feedback"
                size="sm"
                :placeholder="$t('AddTutorial.Modals.Image.Search')"
                ></b-form-input>
                <b-form-invalid-feedback id="input-search-live-feedback">{{ veeErrors.first('input-search') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-row style="max-height: 300px; overflow: auto">
                <b-col cols="3" v-for="(image, index) of filteredImages" :key="index">
                    <div class="image-preview" v-bind:style= "[image.url == checkImg ? {border: '3px solid #5D6176'} : {}]">
                        <img class="img img-raised image-gallery" v-if="image.is_external == false && !isVideo(image.url)" :src="getImgUrl(image.url)" :alt="image.description" @click="setImage(image)" />                            
                            <video
                                v-if="image.is_external == false && isVideo(image.url)"
                                class="img img-raised image-gallery"
                                controls
                                height="160"
                                width="160"
                                @click="setVideo(image)"
                            >
                                <source :src="getVideoUrl(image.url)" type="video/mp4">
                            </video>
                        <img class="img img-raised image-gallery" v-if="image.is_external == true" :src="image.url" :alt="image.description" @click="setImage(image)">
                    </div>
                </b-col>
            </b-row>
            <template slot="footer">
                <n-button class="btn-simple ncm-btn" style="width: 100%;" @click="modals.image = false; search=''; $validator.reset()">
                    {{$t("AddTutorial.Modals.Image.Done")}}
                </n-button>
            </template>
        </modal> 
        <modal :show.sync="modals.exit" class="modal-primary" :show-close="false" header-classes="justify-content-center">
            <p>{{$t("EcosystemEdit.ExitViewMessage")}}</p>
            <template slot="footer">
                <n-button type="neutral" link @click="exitWithoutChanges()">
                {{ $t("Ecosystems.Modals.Delete.Footer.Save") }}
                </n-button>
                <n-button type="neutral" link @click="modals.exit = false">
                {{ $t("Ecosystems.Modals.Delete.Footer.Close") }}
                </n-button>
            </template>
        </modal>
        <modal :close-outside="true" modal-classes="modal-big" v-show="modals.image_content" :show.sync="modals.image_content" header-classes="justify-content-center">
            <h4 slot="header" class="title">
                {{ $t("AddTutorial.Modals.ImageContent.Title") }}
            </h4>
            <b-form-group :label="$t('EcosystemCategories.Filter2')">
                <b-form-input
                v-model="search"
                id="input-search"
                name="input-search"
                v-validate="{alpha_spaces: true}"
                :state="validateState('input-search')"
                aria-describedby="input-search-live-feedback"
                size="sm"
                :placeholder="$t('AddTutorial.Modals.ImageContent.Search')"
                ></b-form-input>
                <b-form-invalid-feedback id="input-search-live-feedback">{{ veeErrors.first('input-search') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-row style="max-height: 300px; overflow: auto">
                <b-col cols="3" v-for="(image, index) of filteredImages" :key="index">
                    <div class="image-preview">
                        <img v-if="image.is_external == false && !isVideo(image.url)" class="img img-raised image-gallery" :src="getImgUrl(image.url)" :alt="image.description" @click="setImageContent(image)" />                            
                            <video
                                v-if="image.is_external == false && isVideo(image.url)"
                                class="img img-raised"
                                controls
                                height="160"
                                width="160"
                                @click="setVideoContent(image)"
                                
                            >
                                <source :src="getVideoUrl(image.url)" type="video/mp4">
                            </video>
                        <img v-if="image.is_external == true" class="img img-raised image-gallery" :src="image.url" :alt="image.description" @click="setImageContent(image)">
                    </div>
                </b-col>
            </b-row>
            <template slot="footer">
                <n-button class="btn-simple ncm-btn" style="width: 100%;" @click="modals.image_content = false; search=''; $validator.reset()">
                    {{$t("AddTutorial.Modals.ImageContent.Done")}} 
                </n-button>
            </template>
        </modal> 
</div>
</template>

<script>
import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal, DropDown} from '@/components';
import {Table, TableColumn, Tooltip} from 'element-ui';
import TutorialDataService from '../../services/TutorialDataService';
import MultimediaBlogDataService from '../../services/MultimediaBlogDataService';
import CategoriesTutorials from '../../services/CategoriesTutorialsDataService'
import LanguageDataService from '@/services/LanguageDataService'
import { VueEditor } from "vue2-editor";
import Quill from 'quill'

import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-vue";
import VideoResize from 'quill-video-resize-module';

export default {
components: {
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [FormGroupInput.name]: FormGroupInput,
    [DropDown.name]: DropDown,
    Card,
    VueEditor
},
data() {
    return {
    modals: {
        image: false,
        exit: false,
        image_content: false
    },
    search: '',
    editing: true,
    tutorial: {},
    checkImg: '',
    content:'',
    category: null,
    language: null,
    categories: [],
    languages: [],
    images: [],
    url_external: null,
    image_description: null,
    customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" },{ align: "center" },{ align: "right" },{ align: "justify" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link",  "video", "formula"],
    ],
    toolbarOptions: [
    [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" },{ align: "center" },{ align: "right" },{ align: "justify" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link", "video", "formula"],
           ["customControl"] // authorship color on/off
    ],
    
    customModulesForEditor: [
        { alias: "imageDrop", module: ImageDrop },
        { alias: "imageResize", module: ImageResize },
        { alias: "videoResize", module: VideoResize },
        ],
    editorSettings: {
    modules: {
         //imageDrop: true,
        imageResize: {},
        toolbar: {
            container: [
                    [{ font: [] }],
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike"],
                [{ align: "" },{ align: "center" },{ align: "right" },{ align: "justify" }],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["link","video", "formula"],
                ['spanblock']
                    ],
            handlers: {
               spanblock () { // just give your custom elements a class like "ql-customThingy" and the handlers will just work
                var range = this.quill.getSelection();
                if(range){
                    console.log('range is valid');
                    var format = this.quill.getFormat(range)
                    if(format.spanblock)
                        this.quill.formatText(range.index,range.length, 'spanblock', false);
                    else
                        this.quill.formatText(range.index,range.length, 'spanblock', true);
                }else{
                    console.log('it it invalid');
                }
            },
            image(){
                console.log('image added')
            } 
            },
        },
    }
    },
    }
},
created () {
    this.getData()
},
mounted(){
        
        let Inline = Quill.import('blots/inline');
        class SpanBlock extends Inline{    
            static create(value){
                let node = super.create();
                node.setAttribute('class','spanblock');
                return node;    
            }  
        }
        SpanBlock.blotName = 'spanblock';
        SpanBlock.tagName = 'div';
        Quill.register(SpanBlock);

        // const Video = Quill.import('formats/video');
        // const Link = Quill.import('formats/link');


        // class CoustomVideo extends Video {
        //      static create(value) {
        //         const node = super.create(value);

        //         const video = document.createElement('video')
        //         video.setAttribute('controls', true);
        //         video.setAttribute('style', "max-height: 200px");
        //         const source = document.createElement('source')
        //         source.setAttribute('type', "video/mp4");
        //         source.setAttribute('src', require('../../assets/multimedia/blog/images/' + value));
                
        //         video.appendChild(source);
        //         node.appendChild(video)
        //         return node;

        //      }

        //      static finishIframe(value){
        //         var iframe = document.getElementById('newIframe' + value)
        //         console.log(iframe)
                
        //         iframe.contentWindow.document.body.appendChild(video);
        //     }

        //      static sanitize(url) {
        //          return Link.sanitize(url);
        //      }
        //  };
        //  CoustomVideo.blotName = 'video';
        //  CoustomVideo.className = 'ql-video';
        //  CoustomVideo.tagName = 'DIV';

        //  Quill.register('formats/video', CoustomVideo);

    },
beforeRouteLeave(to, from, next) {
        this.view_to = to
        if (this.editing)  
            this.modals.exit = true
        else
            next()
},
    computed: {
        filteredImages() {
            if (!this.search) {
                return this.images; 
            }
            const search = this.search.toLowerCase();
            return this.images.filter(image => image.description.toLowerCase().includes(search));
        }
    },
    methods : {
        refreshEditDate(){
            TutorialDataService.modify(this.tutorial).then(
                Response => {
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.TutorialModified')
                    })
                },
                Error =>{
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        isVideo(url) {
            const videoExtensions = ['mp4', 'webm', 'ogg']; // Agrega las extensiones de video que deseas comprobar
            const extension = url.split('.').pop();
            return(videoExtensions.includes(extension.toLowerCase()))
        },
        getVideoUrl(url) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${url}?withheaders=yes`
            // try {
            //     if(url){
            //         return require('../../assets/multimedia/blog/images/' + url);
            //     }
            // } catch {
            //     console.log('error loading video')
            // }
            // Construye la ruta de archivo local para el video
        },
    updateSlug() {
        this.tutorial.title_slug = this.tutorial.title
            .toLowerCase()
            .replace(/\s+/g, '-') // Reemplaza espacios en blanco por guiones
            .replace(/[^\w-]+/g, ''); // Elimina caracteres no alfanuméricos excepto guiones
    },
    exitWithoutChanges(){
        console.log('exit without changes')
        this.editing = false
        this.$router.push(this.view_to)
    },
    setVideoContent(image){  
        this.modals.image_content = false
        var src = this.getVideoUrl(image.url)
        this.tutorial.content = this.tutorial.content + `<div class="ql-video" frameborder="0" allowfullscreen="true" src=${image.url}><video controls style="max-height: 200px"><source src="${this.getVideoUrl(image.url)}" type="video/mp4"></video></div>`
        //this.content = this.content + `<iframe class="ql-video" :src="${this.getVideoUrl(image.url)}">`
        console.log(this.content)
    }, 
    setImageContent(image){  
        console.log(image)
        this.modals.image_content = false
        var src = ''
        if(!image.is_external)
            src = this.getImgUrl(image.url)
        else
            src = image.url

        this.tutorial.content = this.tutorial.content + `<img src="${src}" class="img img-raised image-gallery">`
    },
    setImage(image){ 
        this.tutorial.image = image
        this.image_description = image.description
        this.checkImg = image.url
        if(image.is_external)
            this.url_external = image.url
        else   
            this.url_external = null
    },
    setVideo(video){  
            this.post.image = video
            this.checkImg = video.url
            this.image_description = video.description
            if(image.is_external)
                this.url_external = video.url
            else   
                this.url_external = null
        },
        setImgExternal(){  
            this.image = {}
            this.image.is_external = true
            this.image.url = this.url_external
        },
    getData () {
        MultimediaBlogDataService.getMultimediaImages().then(
        async response => {
            console.log(response.data)
            this.images = response.data.data
        },
        error => {
            this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                        })
            }
        )
        TutorialDataService.findOne(this.$route.params.id).then(
            Response => {
                this.tutorial = Response.data.data
                if(this.tutorial.external_image){
                    this.url_external = this.tutorial.external_image
                    this.image_description = this.tutorial.external_image_description
                    this.tutorial.image = null
                    // this.tutorial.image.url = this.url_external
                    this.checkImg = this.url_external
                }
                else{
                    this.checkImg = this.tutorial.image.url
                    this.image_description = this.tutorial.image.description
                }
            },
            Error => {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: Error.response.data.message
                })
            }
        )
        CategoriesTutorials.findAll().then(
            Response => {
                this.categories = Response.data.data
            },
            Error => {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: Error.response.data.message
                })
            }
        )
        LanguageDataService.findAll().then(
            async Response => {
                this.languages = Response.data
            },
            Error => {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: Error.response.data.message
                })
                }
        )
    },
    editPost () {
        this.$validator.validateAll(['input-tutorial-title','input-tutorial-slug','input-tutorial-subtitle','input-category','input-language', 'input-external-image', 'input-external-image-description']).then(result => {
            if (!result) {
                return
                }
        this.tutorial.type = 'tutorial'
        console.log(this.tutorial)
        
        if(this.url_external && this.url_external != ''){
            this.tutorial.external_image = this.url_external
            this.tutorial.external_image_description = this.image_description
        }
        else{
            this.tutorial.image.description = this.image_description
            this.tutorial.multimedia_blog_id = this.tutorial.image.id
        }
        

        var pattern = "<\\s*div class=\"spanblock\"[^>]*>(.*?)<\\s*/\\s*div>";
        var regExp = new RegExp(pattern, 'g')
        var content = this.tutorial.content
        console.log(content)
        var match = content.match(regExp)
        var public_content = ''
        if(match != null){
            for(var div of match){
                var div_content = div.replace('<div class="spanblock">', '')
                div_content = div_content.replace('</div>', '')
                public_content += div_content + '\n'
            }
        }
        
        console.log(public_content)
        this.tutorial.public_content = public_content
        
        if (this.url_external && this.url_external != ''){ 
            this.tutorial.image = {}, this.tutorial.image.url = this.url_external
        }

        if (this.tutorial.image.url != this.tutorial.external_image) 
            this.tutorial.external_image = null

       
            
        
        TutorialDataService.modify(this.tutorial).then(
                Response => {
                    this.$swal.fire({
                    icon: 'success',
                    title: this.$t('Alerts.TutorialModified')
                    }).then(() => {
                        this.getData()
                        this.editing = false
                        this.$router.push('/tutorials')
                        
                    });
                },
                Error =>{
                    console.log('Error al modificar el tutorial')
                    if (Error.response.status === 409) {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: this.$t('Alerts.TutorialSlug')
                            })
                    }
                }
                
            )
        })
    },
    goBack () {
        this.$router.push('/tutorials')
    }, 
    validateState (ref) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
            return !this.veeErrors.has(ref)
        }
            return null
    },
    getImgUrl (name) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${name}?withheaders=yes`
            //return 'https://p-ncm-public.s3.eu-south-2.amazonaws.com/' + name
            /*try{
                if(name){
                    var images = require.context('../../assets/multimedia/blog/images', false)
                    console.log(images('./' + name))
                    return images('./' + name)
                }
            }catch{
                console.log('error loading image')
            }*/
        },
}
}
</script>