<template>
  <div 
    class="page-header section-image"
  >
    <div
      class="page-header-image"
      :style="{'background-image': 'url(' + $getImgUrlSite('bg18.jpg') +')', opacity: '70%'}" 
    />
  </div>
</template>
<script>
  import {Button} from '@/components';
  import Login from '../../services/Login';
  
  export default {
    name: 'ActiveUser',
    bodyClass: 'active-user',
    components: {
      [Button.name]: Button
    },
    data() {
      return {
      }
    },
    created () {
      var isAuthenticated = false;
      if (localStorage.getItem('user')) isAuthenticated = true
      else isAuthenticated = false
      
      if (isAuthenticated) {
          this.$swal.fire({
              icon: 'warning',
              title: this.$t('AcceptInvitation.Session')
          }).then(() => {
              this.$router.push('/')
          })
      } else {
        Login.activate(this.$route.query.token).then(
          Response => {
            if (Response.status === 200) {
              this.$swal.fire({
                  icon: 'success',
                  title: this.$t('ActiveUser.Message')
                }).then(() => {
                  this.$router.push('/staff')
                });
            }
          },
          Error => {
            if (Error.response.status === 404) {
              this.$swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: this.$t('Errors.NotFound')
              }).then(() => {
                        this.$router.push('/')
                    });
            }
            if (Error.response.status === 500) {
              this.$swal.fire({
              icon: 'error',
              title: 'Oops...',
              title: this.$t('Errors.InternalServerError')
              }).then(() => {
                        this.$router.push('/')
                    });
            }
          })
      }
      
      }
  }
</script>