import http from "../http-common";
const API_URL_PATH = "api/invitation/";
import authHeader from './AuthHeaderService'

class InvitationDataService {
    findAll(org) {
        return http.get(API_URL_PATH + 'filter-invitations?id=' + org, { headers: authHeader() })
    }
    invite (email, organization_id) {
        const body = {
            email: email,
            organization_id: organization_id
        }
        console.log(organization_id);
        return http.post(API_URL_PATH + 'create-invitation',body, { headers: authHeader() });
    }
    checkInvitation (token) {
        return http.get(API_URL_PATH + 'check-invitation?token=' + token );
    }
    accept (token) {
        const body = {
            token: token,
        }
        return http.post(API_URL_PATH + 'accept-invitation',body);
    }
    decline (token) {
        const body = {
            token: token,
        }
        return http.put(API_URL_PATH + 'decline-invitation',body);
    }
    acceptInvitationAnalyst(token){
        const body = {
            token: token,
        }
        return http.post(API_URL_PATH + 'set-analyst',body);
    }
    cancelInvitation(inv){
        const body = {
            invitation: inv,
        }
        console.log('BODY',body);
        return http.put(API_URL_PATH + 'cancel-invitation',body, { headers: authHeader() });
    }
}

export default new InvitationDataService();