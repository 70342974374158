<template>
  <navbar
    position="fixed"
    class="navBarDash "
    menu-classes="ml-auto"
  >

  <template>
    <b-row>
      <b-col>
      <router-link 
        class="nav-item"
        to="/"
      > 
        <img
          :src="$getImgUrlSite('LogoNoSpinOff.png')"
          style="width: 100%; max-width: 140px; margin-top: 13px;"
          alt="LogoNCM"
          class="logoNCM"
        >
        <!-- <img 
          :src="$getImgUrlSite('LogoNoSpinOff-negative.png')"
          height="34;"
          width="200"
          alt="LogoNCM"
        > -->
      </router-link>
    </b-col>
      <b-breadcrumb class="responsive-nav responsive-nav-2" id="routerData">
        <b-breadcrumb-item to="/dashboard-staff">
          <!-- <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon> -->
          {{ $t('LogStaffNavBar.Dashboard') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :key="index" v-for="(route, index) of routeArray" :to="route.route" :active="route.active">{{route.title}}</b-breadcrumb-item>
      </b-breadcrumb>
    </b-row>
  </template>
    
      
      


    <template slot="navbar-menu">
      <div class="locale country-selector"></div>
      <div class="country-selector"><LocaleSwitcher /></div>
      <b-nav-item-dropdown class="user" right style="padding: 4px">
          <template #button-content>
            <img :src="getImgUrl($store.state.auth.user.data.profile_image)" width="42" class="img-rounded">
            <span id="spanRole" v-if="$store.state.auth.user.data.is_superadmin" style="color: #ffffffad; margin-left: 5px;">Superadmin</span>
            <span v-else style="color: #ffffffad; margin-left: 5px;">Staff</span>
          </template>
          <b-dropdown-item href="#"  @click="goProfile()" style="color: black !important; cursor:pointer;"><i class="now-ui-icons users_circle-08" style="margin-right: 0;"/>{{ $t('LogStaffNavBar.Profile') }}</b-dropdown-item>
          <b-dropdown-item href="#" @click="logout()" style="color: black !important; cursor:pointer;"><i class="now-ui-icons media-1_button-power" style="margin-right: 7px;"/>{{ $t('LogStaffNavBar.LogOut') }}</b-dropdown-item>
      </b-nav-item-dropdown> 

      <!-- Futura imagen -->
      <!-- <img :src="getImgUrl($store.state.auth.user.data.img)" width="42;" style="margin-left: -50px; border-radius: 50%; margin-top: 2px;">  -->

      <!-- <div>
        <img :src="getImgUrl($store.state.auth.user.data.img)" width="42;" style="margin-left: -50px; border-radius: 50%; margin-top: 2px;"> 
        <img :src="userImage" width="42;" style="margin-left: -50px; border-radius: 50%; margin-top: 2px;">
        <drop-down
          tag="li"
          :title="$store.state.auth.user.data.email"
          class="nav-item nav-dropdown"
          style="font-weight: 600; margin-top: -40px"
        >
        
          <a 
            class="dropdown-item" 
            @click="goProfile()"
            style="color: black !important;cursor:pointer;"
          >
            <i class="now-ui-icons users_circle-08" /> Profile
          </a>
          <a 
            class="dropdown-item" 
            @click="logout()"
            style="color: black !important;cursor:pointer;"
          >
            <i class="now-ui-icons media-1_button-power" /> Log Out
          </a>
        </drop-down>
      </div> -->
    </template>
  </navbar>
</template>
  
<script>
  import { DropDown, Navbar, NavLink, Button } from '@/components';
  import { Popover } from 'element-ui'
  import Login from '../services/Login'
  import LocaleSwitcher from "../components/LocaleSwitcher.vue";

  export default {
    name: 'MainNavbar',
    components: {
      Navbar,
      LocaleSwitcher,
      DropDown,
      [Button.name]: Button,
    },
    props: {
      transparent: Boolean,
      colorOnScroll: Number,
    },
    data() {
      return {
        userImage: this.$getImgUrlSite('default-avatar.png'),
        route: this.$route.name,
        route_dict: {

          // Staff
          'staff-management': [
            {route: '', title: 'Staff management', active: true}
          ],

          // Ecosystems
          'ecosystems': [
            {route: '', title: 'Ecosystems', active: true}
          ],
          'ecosystems-edit': [
            {route: '/ecosystems', title: 'Ecosystems'},
            {route: '', title: 'Edit', active: true},
          ],

          'ecosystems-language-edit': [
            {route: '/ecosystems', title: 'Ecosystems'},
            {route: '', title: 'Language', active: true},
          ],

          'ecosystem-types': [
            {route: '', title: 'Ecosystem types', active: true}
          ],

          'ecosystem-categories': [
            {route: '', title: 'Ecosystem categories', active: true}
          ],

          // Ecosystem services
          'ecosystem-services': [
            {route: '', title: 'Ecosystem services', active: true}
          ],

          'ecosystem-services-language-edit': [
            {route: '/ecosystem-services', title: 'Ecosystem services'},
            {route: '', title: 'Language', active: true},
          ],

          'services-types': [
            {route: '', title: 'Ecosystem services types', active: true}
          ],

          'services-categories': [
            {route: '', title: 'Ecosystem services categories', active: true}
          ],

          // Parameters
          'parameters': [
            {route: '', title: 'Parameters', active: true}
          ],

          'parameters-language-edit': [
            {route: '/parameters', title: 'Parameters'},
            {route: '', title: 'Language', active: true},
          ],

          // Parameters
          'languages': [
            {route: '', title: 'Languages', active: true}
          ],
          // Profile
          'profile': [
            {route: '', title: 'Profile', active: true}
          ],
          // Profile
          'settings-cookies': [
            {route: '', title: 'Settings Cookies', active: true}
          ],
          'settings-emails': [
            {route: '', title: 'Settings Emails', active: true}
          ],

          // Post

          'posts': [
            {route: '', title: 'Posts', active: true},
          ],
          'add-post': [
            {route: '/posts', title: 'Posts'},
            {route: '', title: 'Add post', active: true}
          ],
          'posts-edit': [
            {route: '/posts', title: 'Posts'},
            {route: '', title: 'Edit post', active: true}
          ],
          'post-categories': [
            {route: '', title: 'Posts categories', active: true},
          ],
          'tutorials-categories': [
            {route: '', title: 'Tutorials categories', active: true}
          ],
          'tutorials': [
            {route: '', title: 'Tutorials', active: true},
          ],
          'tutorials-edit': [
            {route: '/tutorials', title: 'Tutorials'},
            {route: '', title: 'Edit tutorial', active: true}
          ],
          'multimedia': [
            {route: '/', title: 'Multimedia'}
          ]

        }
          
      }
    },
    computed:{
        routeArray(){
          return this.route_dict[this.$route.name]
        }
    },
    methods: {
      logout () {
        Login.logout()
        this.$router.push({ path: '/' })
        this.$store.state.auth.status.loggedIn = false
        this.$store.state.auth.user = null
      },
      goProfile () {
        this.$router.push({ path: '/profile' })
      },
      getImgUrl (name) {
            try{
                if(name){
                    return `${process.env.VUE_APP_VITE_BUCKET_CDN}/profile-images/${name}?withheaders=yes`
                    /// Regular exp for png: \.png$/
                    //var images = require.context('../../assets/profile_images/', false, /\.png$/)
                    //console.log(images('./' + name))
                    //return images('./' + name)
                }
                else{
                  return this.$getImgUrlSite('default-avatar.png')
                }
            }catch{
              return this.$getImgUrlSite('default-avatar.png')
            }
        },
    }
  }
</script>
<style scoped>
.responsive-nav-1 {
  margin-left: 0px !important;
  margin-top: 5px;
}

.responsive-nav-2 {
  margin-left: 22px !important;
  margin-top: 5px;
}

@media (max-width: 991px) {
  .logoNCM {
    margin-left: 14px;
    /* margin-bottom: 15px; */
  }
}

@media (max-width: 595px) {
  .logoNCM {
    margin-left: 25px;
    /* margin-bottom: 15px; */
  }
}

@media (max-width: 640px) {
  #routerData {
    display: none;
  }
}

@media (max-width: 991px) {
  .user {
    margin-right: 0px;
  }

  #spanRole {
    color: black !important;
  }
}
</style>