<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar">
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                    {{ $t('TutorialsMultimedia.Title') }}
                    </h2> 
                </b-col>
            </b-row>
            <b-row>
                <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-queue-complete="completeUpload"></vue-dropzone>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <card>
                        <b-row>
                            <b-col class="m-auto">
                                <h6>{{ $t('TutorialsMultimedia.Images') }}</h6>
                            </b-col>
                            
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table
                                ref="parametersTable"
                                id="parametersTable"
                                :fields="headers" 
                                :items="images"
                                show-empty
                                select-mode="single"
                                style="overflow-x: auto;"
                                :current-page="currentPage"
                                :per-page="perPage"
                                responsive="sm"> 
                                <template #cell(image)="data">
                                    <template v-if="data.item.is_external == false">
                                        <img 
                                            class="img img-raised"
                                            :src="getImgUrl(data.item.url)"
                                            width="50"
                                        >
                                        {{ data.item.url }}
                                    </template>
                                    <template v-else>
                                        <img 
                                            class="img img-raised"
                                            :src="data.item.url"
                                            width="50"
                                        >
                                        {{ data.item.url }}  
                                    </template>
                                </template>
                                <template #cell(createdAt)="data">
                                    {{ data.item.createdAt | formatDate}}
                                </template>
                                <template #cell(updatedAt)="data">
                                    {{ data.item.updatedAt | formatDate}}
                                </template>
                                <template #cell(options)="data">
                                    <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Ecosystems.Tooltips.Modify')" @click="openEditModal(data.item)">
                                    <b-icon icon="pen"></b-icon>
                                    </b-button>
                                    <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Delete')" @click="openDeleteModal(data.item)">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                </template>
                            </b-table>
                            <div style="display: flex">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalPages"
                                    :per-page="perPage">
                                </b-pagination>
                                <b-form-select v-model="perPage" class="pagination-select">
                                    <b-form-select-option :value="5">5</b-form-select-option>
                                    <b-form-select-option :value="10">10</b-form-select-option>
                                    <b-form-select-option :value="20">20</b-form-select-option>
                                    <b-form-select-option :value="images.length">{{ $t('TutorialsMultimedia.All') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                            </b-col>
                        </b-row>
                    </card>
                </b-col>
            </b-row>
        </b-container>

        <!-- Modal modify  -->
    <modal :show.sync="modals.modify" header-classes="justify-content-center">
    <h4 slot="header" class="title title-up">
        {{ $t("EcosystemServices.Modals.Modify.Title") }}
    </h4>
    <b-form-group :label="'Path' + ' *' ">
        <b-form-input v-if="selectedElement"
            v-model="selectedElement.url"
            id="input-path-modify"
            name="input-path-modify"
            v-validate="{ required: true}"
            :state="validateState('input-path-modify')"
            aria-describedby="input-path-modify-live-feedback"
            size="sm"
            :placeholder=" 'Path' + ' *'  "
        ></b-form-input>
        <b-form-invalid-feedback id="input-path-modify-live-feedback">{{ veeErrors.first('input-path-modify') }}</b-form-invalid-feedback>
    </b-form-group>
      <b-form-group :label="'Description' + ' *' ">
        <b-form-input v-if="selectedElement"
            v-model="selectedElement.description"
            id="input-description-modify"
            name="input-description-modify"
            v-validate="{ required: true}"
            :state="validateState('input-description-modify')"
            aria-describedby="input-description-modify-live-feedback"
            size="sm"
            :placeholder=" 'Description' + ' *'  "
        ></b-form-input>
        <b-form-invalid-feedback id="input-description-modify-live-feedback">{{ veeErrors.first('input-description-modify') }}</b-form-invalid-feedback>
    </b-form-group>
    <template slot="footer">
        <n-button type="primary" @click="updateElement">
        {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
        </n-button>
        <n-button type="danger" @click.native="modals.modify = false">
        {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
        </n-button>
    </template>
    </modal>

        <!-- Modal delete  -->
    <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" >
        <p>{{ $t("PostsMultimedia.Modals.Delete.Title") }} <b>{{ selectedElement.url }}</b></p>
        <template slot="footer">
        <n-button type="neutral" link @click="deleteElement" >
            {{ $t("Parameters.Modals.Delete.Footer.Save") }}
        </n-button>
        <n-button type="neutral" link @click.native="modals.delete = false">
            {{ $t("Parameters.Modals.Delete.Footer.Close") }}
        </n-button>
        </template>
    </modal>
</div>
</template>
<script>
    import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal} from '@/components';
    import { API_KEY } from '@/constants'
    import {Table, TableColumn, Tooltip} from 'element-ui';
    import MultimediaBlogDataService from '@/services/MultimediaBlogDataService'
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import authHeader from "@/services/AuthHeaderService"

export default {
    components: {
        Modal,
    Card,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [FormGroupInput.name]: FormGroupInput,
    vueDropzone: vue2Dropzone
    }, 
    data() {
    return {
        dropzoneOptions: {
            acceptedFiles: 'image/*, video/*',
            url: `${process.env.VUE_APP_VITE_API_URL}/api/multimedia-blog/upload-images?is_post=false`,
            thumbnailWidth: 200,
            maxFilesize: 1000,
            headers: authHeader(),
            dictDefaultMessage: 'Drop files here or click to upload <b-icon icon="trash"></b-icon>',
            addRemoveLinks: true,
        },
        modals: {
            delete: false,
            modify: false,
        },
        images: [   
            
        ],
        headers: [
            { key: 'image', label: 'Image', thClass: 'table-title position-sticky', tdClass:'table-title' },
            { key: 'description', label: 'Description', thClass: 'table-title position-sticky', tdClass:'table-title' },
            { key: 'createdAt', label: this.$t('Ecosystems.Table.CreatedAt'), thClass: 'position-sticky', sortable: true },
            { key: 'updatedAt', label: this.$t('Ecosystems.Table.UpdatedAt'), thClass: 'position-sticky', sortable: true }, 
            { key: 'options', label: 'Actions', thClass: 'position-sticky' }
        ],
        selectedElement: '',
        currentPage: 1,
        perPage: 10,
    }
    
    },
    computed: {
        totalPages(){
        return this.images.length
        },
    },
    created(){
        this.getData()
    },
    methods: {
        
        handleAddedFile(file) {
            if (!this.isFileAccepted(file)) {
                this.$refs.myVueDropzone.removeFile(file);
            }
        },
        isFileAccepted(file) {
    const acceptedTypes = this.dropzoneOptions.acceptedFiles.split(',');
    const fileType = file.type;

    return acceptedTypes.some(type => {
        const acceptedType = type.trim();

        if (acceptedType.endsWith('/*')) {
        const acceptedCategory = acceptedType.split('/')[0];
        return fileType.startsWith(acceptedCategory);
        }

        return fileType === acceptedType;
    }) 
    },
        updateElement(){
        this.$validator.validateAll(['input-description-modify', 'input-path-modify']).then(async result => {
            if (!result) {
            return
            }
            console.log(this.selectedElement)
            MultimediaBlogDataService.update(this.selectedElement).then(
                async response => {
                    console.log(response.data)
                    this.modals.modify = false
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.ImageModified'),
                        text: ''
                        }).then(() => {
                            
                            this.getData()
                    });
                },
                error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
            }
            )
        })
        
    },
        validateState (ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref)
            }
            return null
        },
        openEditModal(element){
            console.log(element)
            this.selectedElement = {...element}
            this.modals.modify = true
        },
        completeUpload( file, xhr, formData){
            console.log('Complete upload!')
            console.log(file)
            console.log(xhr)
            console.log(formData)
            // this.$swal.fire({
            //     icon: 'success',
            //     title: 'Images uploaded!'
            // }).then(() => {
            //     this.getData()
            // });
            this.getData()
        },
        getData(){
            console.log('getData')
            MultimediaBlogDataService.getTutorialImages().then(
            async response => {
                console.log(response.data)
                this.images = response.data.data
            },
            error => {
                this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
            }
            )
        },
        getImgUrl (name) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${name}?withheaders=yes`
            //return 'https://p-ncm-public.s3.eu-south-2.amazonaws.com/' + name
            /*try{
                if(name){
                    var images = require.context('../../assets/multimedia/blog/images', false)
                    console.log(images('./' + name))
                    return images('./' + name)
                }
            }catch{
                console.log('error loading image')
            }*/
        },
        openFileInput () {
            this.$refs['fileInput'].click()
        },
        openDeleteModal(element){
            console.log(element)
            this.selectedElement = element
            this.modals.delete = true
        },
        
        uploadImages () {
                
                var formData = new FormData()
                
                var files = this.$refs['fileInput'].files
                console.log(files)
                for(var file of files){
                formData.append(file.name, file) // appending file
                }
                

                MultimediaBlogDataService.uploadPostImagesMulti(formData).then(
                response => {
                    if(response.status == 200){
                        this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.ImageUploaded')
                        }).then(() => {
                            this.getData()
                        });
                    
                    }
                    
                },
                error => {
                    console.log((error.response && error.response.data) || error.message || error.toString())
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Alerts.ErrorUploading')
                        });
                }

                )
            
        },
        deleteElement(){
            console.log(this.selectedElement)
            MultimediaBlogDataService.deleteTutorialImage(this.selectedElement.id).then(
                    async response => {
                        this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.ImageDeleted'),
                        text: ''
                        }).then(() => {
                            this.modals.delete = false
                            this.getData()
                        });

                    },
                    error => {
                        this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                }
                )
        }
}
}
</script>
<style scoped>
input[type="file"] {
    display: none;
}

.uploadBox {
    background-color: transparent !important;
    width: 100% !important;
}

.uploadBox .dropArea {
    border: 4px dashed #80808066 !important;
}

.uploadBox h3 {
    padding-top: 0em !important;
    font-size: 24px !important;
}

.dropzone{
    width: 100%;
    margin: 3%;
}
</style>