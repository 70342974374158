<template>
  <div class="wrapper  dashboard-wrapper collapsed-sidebar">
    <div class="container" style="margin-top: 5%; max-width: 94%;">
      <div class="row">
        <div class="col">
          <div class="small-box admin-small-box bg-success" @click="$router.push({ path: '/staff-management' })" style="cursor: pointer !important">
            <div class="inner">
                <h3 class="increase-count">{{staffs}}</h3>
                <a href="">
                    <p>{{ $t("HomeStaff.Staffs")}}</p>
                </a>
            </div>
            <div class="icon">
                <a href="">
                    <i class="fa fa-user"></i>
                </a>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="small-box admin-small-box bg-success" @click="$router.push({ path: '/ecosystem-services' })" style="cursor: pointer !important">
            <div class="inner">
                <h3 class="increase-count">{{ecosystems_services}}</h3>
                <a href="">
                    <p>{{ $t("HomeStaff.EcosysServ")}}</p>
                </a>
            </div>
            <div class="icon">
                <a href="">
                  <i class="now-ui-icons media-2_sound-wave" />
                </a>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="small-box admin-small-box bg-success" @click="$router.push({ path: '/ecosystems' })" style="cursor: pointer !important">
            <div class="inner">
                <h3 class="increase-count">{{ecosystems}}</h3>
                <a href="">
                    <p>{{ $t("HomeStaff.Ecosystems")}}</p>
                </a>
            </div>
            <div class="icon">
                <a href="">
                  <i class="now-ui-icons location_world" />
                </a>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="small-box admin-small-box bg-success" @click="$router.push({ path: '/parameters' })" style="cursor: pointer !important">
            <div class="inner">
                <h3 class="increase-count">{{parameters}}</h3>
                <a href="">
                    <p>{{ $t("HomeStaff.Parameters")}}</p>
                </a>
            </div>
            <div class="icon">
                <a href="">
                  <i class="now-ui-icons files_paper" />
                </a>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="small-box admin-small-box bg-success" @click="$router.push({ path: '/languages' })" style="cursor: pointer !important">
            <div class="inner">
                <h3 class="increase-count">{{languages}}</h3>
                <a href="">
                    <p>{{ $t("HomeStaff.Languages")}}</p>
                </a>
            </div>
            <div class="icon">
                <a href="">
                  <i class="now-ui-icons business_globe" />
                </a>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  const $ = require('jquery')
  // Lo declaramos globalmente
  window.$ = $
  import StaffServices from '../../services/StaffServices'
  import LanguageDataService from '../../services/LanguageDataService'
  import ParameterDataService from '../../services/ParameterDataService'
  import EcosystemDataService from '../../services/EcosystemDataService'
  import EcosystemServiceDataService from '../../services/EcosystemServiceDataService'
  import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal} from '@/components';
  import { API_KEY } from '@/constants'
  import {Table, TableColumn, Tooltip} from 'element-ui';
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
import { methods } from 'vue2-dropzone'
  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  });
export default {
  name: 'DashboardStaff',
  bodyClass: 'dashboard-staff',
  components: {
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
  }, 
  data() {
      return {
        staffs: 0,
        parameters: 0,
        ecosystems: 0,
        ecosystems_services: 0,
        languages: 0
      }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      StaffServices.findAll().then(
        Response => {
          if (Response.status === 200) {
            this.staffs = Response.data.data.length
          }
        },
        Error => {
          if (Error.response.status === 404) {
              console.log('Error 404 al obtener los datos de los staffs')
            }
            if (Error.response.status === 400) {
              console.log('Error 400 al obtener los datos de los staffs')
            }
            if (Error.response.status === 500) {
              console.log('Internal server error al obtener datos de los staffs')
            }
            this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: Error.response.data.message
            })
        })
        LanguageDataService.findAll().then(
        Response => {
          if (Response.status === 200) {
            this.languages = Response.data.length
          }
        },
        Error => {
          if (Error.response.status === 404) {
              console.log('Error 404 al obtener los datos de los languages')
            }
            if (Error.response.status === 400) {
              console.log('Error 400 al obtener los datos de los languages')
            }
            if (Error.response.status === 500) {
              console.log('Internal server error al obtener datos de los languages')
            }
            this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: Error.response.data.message
            })
        })
        ParameterDataService.findAll().then(
        Response => {
          if (Response.status === 200) {
            
            this.parameters = Response.data.length
          }
        },
        Error => {
          if (Error.response.status === 404) {
              console.log('Error 404 al obtener los datos de los parameters')
            }
            if (Error.response.status === 400) {
              console.log('Error 400 al obtener los datos de los parameters')
            }
            if (Error.response.status === 500) {
              console.log('Internal server error al obtener datos de los parameters')
            }
            this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: Error.response.data.message
            })
        })
        EcosystemDataService.findAll().then(
        Response => {
          if (Response.status === 200) {
            this.ecosystems = Response.data.length
          }
        },
        Error => {
          if (Error.response.status === 404) {
              console.log('Error 404 al obtener los datos de los ecosystems')
            }
            if (Error.response.status === 400) {
              console.log('Error 400 al obtener los datos de los ecosystems')
            }
            if (Error.response.status === 500) {
              console.log('Internal server error al obtener datos de los ecosystems')
            }
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: Error.response.data.message
            })
        })
        EcosystemServiceDataService.findAll().then(
        Response => {
          if (Response.status === 200) {
            this.ecosystems_services = Response.data.length
          }
        },
        Error => {
          if (Error.response.status === 404) {
              console.log('Error 404 al obtener los datos de los ecosystems_services')
            }
            if (Error.response.status === 400) {
              console.log('Error 400 al obtener los datos de los ecosystems_services')
            }
            if (Error.response.status === 500) {
              console.log('Internal server error al obtener datos de los ecosystems_services')
            }
            this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: Error.response.data.message
            })
        })
    }
  }
}
</script>
<style scoped>
.card-categories {
  white-space: nowrap; /* Evita el salto de línea */
}

.card-categories h6 {
  display: inline-block; /* Muestra los elementos en línea */
  margin-right: 10px; /* Agrega un margen derecho entre los elementos */
}

.admin-small-box:hover {
  /* Add your hover styles here */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}
</style>