import http from '../http-common'
import authHeader from './AuthHeaderService'
const API_URL_PATH = 'api/ecosystems-service-category/'


class EcosystemServicesCategory {
  findAll () {
    return http.get(API_URL_PATH + 'ecosystems-service-category', {headers: authHeader()})
  }
  create (data,category_type_id) {
    const body = {
      name: data.name,
      description: data.description,
      category_type_id: category_type_id
    }
    return http.post(API_URL_PATH + 'create-ecosystem-service-category', body,  {headers: authHeader()})
  }
  update (data) {
    const body = {
      id: data.id,
      name: data.name,
      description: data.description,
      category_type_id: data.category_type_id
    }
    return http.put(API_URL_PATH + 'update-ecosystem-service-category', body,  {headers: authHeader()})
  }
  delete (id) {
    return http.delete(API_URL_PATH + 'delete-ecosystem-service-category?id=' + id,  {headers: authHeader()})
  }
}

export default new EcosystemServicesCategory()
