import http from '../http-common'
import authHeader from './AuthHeaderService'
const API_URL_PATH = 'api/maps/'

class MapsDataService {
    
    getLayers(id){
        return http.get(API_URL_PATH + 'get-layers?organization_id=' + id, {headers: authHeader()})
    }

    getLayersWFS(){
        return http.get(API_URL_PATH + 'get-layers-wfs', {headers: authHeader()})
    }

    getLayersStaff(){
        return http.get(API_URL_PATH + 'get-ncm-staff-layers', {headers: authHeader()})
    }
    getDataLayers(){
        return http.get(API_URL_PATH + 'get-data-layers', {headers: authHeader()})
    }

    getDataLayersByCountry(country){
        return http.get(API_URL_PATH + 'get-data-layers-by-country?country=' + country, {headers: authHeader()})
    }

    getCadastreLayers(){
        return http.get(API_URL_PATH + 'cadastre/get-layers', {headers: authHeader()})
    }

    getNCMLayers(id, role){
        return http.get(API_URL_PATH + 'get-ncm-layers?organization_id=' + id + '&role=' + role, {headers: authHeader()})
    }

    simpleLayerIntersection(data){
        return http.post(API_URL_PATH + 'simple-polygon/single-layer-intersection', data, {headers: authHeader()})
    }

    multipleLayerIntersection(data){
        return http.post(API_URL_PATH + 'simple-polygon/multi-layer-intersection', data, {headers: authHeader()})
    }

    getLegend(layerName){
        return http.get(API_URL_PATH + 'get-legend?layer=' + layerName, {headers: authHeader()})
    }
    
    getWMS(){
        return http.get(API_URL_PATH + 'get-wms', {headers: authHeader()})
    }
    uploadLayer(data){
        var headers = authHeader()
        headers['Content-Type'] = 'multipart/form-data'
        headers['Label'] = data.get('label')
        console.log(data.get('label'))
        console.log(headers)
        return http.post(API_URL_PATH + 'layers/upload', data, {headers: headers})
    }
    getInfo(data){
        var headers = authHeader()
        headers['Content-Type'] = 'multipart/form-data'
        return http.get(API_URL_PATH + 'get-info', data, {headers: headers})
    }

    getDefinition(proj){
        return http.get(API_URL_PATH + 'projection/get-definition?proj=' + proj, {headers: authHeader()})
    }

    getWMSCadastre(id){
        console.log("IDDDDDDDDDDDDDDDD",id);
        return http.get(API_URL_PATH + 'cadastre/get-wms-cadastre?id=' + id, {headers: authHeader()})
      }

    getInformationExternal(id, bbox){
        console.log("Esto es id y bbox: ", id, bbox);
        return http.get(API_URL_PATH + 'cadastre/get-information-external?id=' + id + '&bbox=' + bbox, {headers: authHeader()})
    }
    
    getCountryLocation(country){
        return http.get(API_URL_PATH + 'get-country-location?country=' + country, {headers: authHeader()})
    }

    uploadReport(data){
        var headers = authHeader()
        headers['Content-Type'] = 'multipart/form-data'
        return http.post(API_URL_PATH + 'reports/upload', data, {headers: headers})
    }
    checkReport(data, email){
        console.log('eeeeeeeh')
        console.log(data)
        var headers = authHeader()
        headers['Content-Type'] = 'multipart/form-data'
       
        headers['User-Email'] = email
        return http.post(API_URL_PATH + 'reports/check-report', data, {headers: headers})
    }

    removeLayer(id){
        return http.delete(API_URL_PATH + 'remove-layer?id=' + id, {headers: authHeader()})
      }
    removeDataLayer(id){
        return http.delete(API_URL_PATH + 'remove-layer?id=' + id + '&data_layer=true', {headers: authHeader()})
      }
    
    downloadSHP(filename){
        return http.get(API_URL_PATH + 'download/' + filename, {headers: authHeader()})
    }
    
}
  
export default new MapsDataService()
  