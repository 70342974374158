<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar">
      <b-container class="container" style="margin-top: 5%; max-width: 94%;">
        <b-row class="page-header-bs">
          <b-col style="display: flex">
            <h2 class="card-title">
              {{ $t("Parameters.Title") }}
            </h2> 
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <card>
              <b-row>
                <b-col>
                  <h6 class="titleParam">{{ $t("Parameters.Table.Title") }}</h6>
                </b-col>
                <b-col>
                  <b-button-toolbar class="buttonParam" key-nav aria-label="Toolbar with button groups" style="float: right">
                    <b-button-group class="mx-1">
                      <n-button class="btn-simple ncm-btn" target="_blank" @click.native="modals.create = true">
                        <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                        {{ $t("Parameters.Table.Button") }}
                      </n-button>
                    </b-button-group>
                    <b-button-group class="mx-1">
                      <n-button class="btn-simple ncm-btn" target="_blank" @click="downloadCSV">
                        <b-icon icon="file-earmark-arrow-down-fill" class="button-icon"></b-icon>
                        {{ $t("Parameters.Download") }}
                      </n-button>
                    </b-button-group>
                    <b-button-group class="mx-1">
                      <n-button for="fileInput" class="btn-simple ncm-btn" @click.native="openFileInput()">
                        <b-icon icon="cloud-upload" class="button-icon"></b-icon>
                        {{ $t("Parameters.Upload") }}
                      </n-button>
                      <input ref="fileInput" id="fileInput" type="file" accept=".csv" class="d-none" @change="handleCSVUpload"> 
                    </b-button-group>
                  </b-button-toolbar>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table ref="parametersTable"
                      id="parametersTable"
                      :fields="headers" 
                      :items="parameters"
                      show-empty
                      select-mode="single"
                      style="overflow-x: auto;"
                      :current-page="currentPage"
                      :per-page="perPage"
                      responsive="sm"
                      :tbody-tr-class="applyRowClass"
                      sort-by="createdAt"
                      :sort-desc="true"
                      > 
                      <template #empty="">
                        {{$t('Parameters.Table.Empty')}}
                      </template>
                      
                      <template #cell(createdAt)="data">
                          {{ data.item.createdAt | formatDate}}
                          
                      </template>
                      <template #cell(updatedAt)="data">
                          {{ data.item.updatedAt | formatDate}}
                      </template>
                      <template #cell(options)="data">
                         <b-button v-if="!data.item.disabled" class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Toggle.Disabled')" @click.prevent="toggle(data.item)">
                              <b-icon icon="eye"></b-icon>
                          </b-button>
                          <b-button v-else class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Toggle.Enabled')" @click.prevent="toggle(data.item)">
                              <b-icon icon="eye-slash"></b-icon>
                          </b-button>
                          <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Modify')" @click="openEditModal(data.item)">
                            <b-icon icon="pen"></b-icon>
                          </b-button>
                          <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('EcosystemServices.Tooltips.Language')" @click="openEditLanguageModal(data.item)">
                          <b-icon icon="translate"></b-icon>
                        </b-button>
                        <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Delete')" @click="openDeleteModal(data.item)">
                          <b-icon icon="trash"></b-icon>
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-card class="row-details-card">
                            <b-row>
                                <b-col class="row-details-col">
                                    {{ row.item }}
                                </b-col>
                            </b-row>
                        </b-card>
                    </template>
                </b-table>
                <div class="pagination-container">
                    <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                    </b-pagination>
                    <b-form-select v-model="perPage" class="pagination-select">
                        <b-form-select-option :value="5">5</b-form-select-option>
                        <b-form-select-option :value="10">10</b-form-select-option>
                        <b-form-select-option :value="20">20</b-form-select-option>
                        <b-form-select-option
                            :value="parameters.length">{{ $t('DashBoardStaff.Options') }}</b-form-select-option>
                    </b-form-select>
                </div>
              </b-col>
            </b-row>
            
          </card>
        </b-col>
      </b-row>
    </b-container>

    <!-- Modal create  -->
    <modal v-show="modals.create" :show.sync="modals.create" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("Parameters.Modals.Create.Title") }}
      </h4>
      <b-form-group :label="$t('Parameters.Modals.Create.Label') + ' *' ">
        <b-form-input
            v-model="label_form"
            id="input-label"
            name="input-label"
            v-validate="{ required: true}"
            :state="validateState('input-label')"
            aria-describedby="input-label-live-feedback"
            size="sm"
            :placeholder=" $t('Parameters.Modals.Create.Label') + ' *'  "
          ></b-form-input>
          <b-form-invalid-feedback id="input-label-live-feedback">{{ veeErrors.first('input-label') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('Parameters.Modals.Create.ValueType') + ' *' ">
          <b-form-select v-model="value_type_form" 
            id="input-value"
            name="input-value"
            v-validate="{ required: true}"
            :state="validateState('input-value')"
            aria-describedby="input-value-live-feedback">
            <b-form-select-option :value="null">{{$t('Parameters.Modals.Create.ValueTypePH')}}</b-form-select-option>
            <b-form-select-option value="boolean">boolean</b-form-select-option>
            <b-form-select-option value="integer">integer</b-form-select-option>
            <b-form-select-option value="float">float</b-form-select-option>
            <b-form-select-option value="varchar">varchar</b-form-select-option>
            <b-form-select-option value="text">text</b-form-select-option>
          </b-form-select>
          <b-form-invalid-feedback id="input-value-live-feedback">{{ veeErrors.first('input-value') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group v-if="value_type_form != 'boolean'" :label="$t('Parameters.Modals.Create.Units')">
        <b-form-input
            v-model="units_form"
            id="input-units"
            name="input-units"
            v-validate="{ required: true}"
            :state="validateState('input-units')"
            aria-describedby="input-units-live-feedback"
            size="sm"
            :placeholder=" $t('Parameters.Modals.Create.Units')"
          ></b-form-input>
          <b-form-invalid-feedback id="input-units-live-feedback">{{ veeErrors.first('input-units') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group v-if="value_type_form == 'boolean'" :label="$t('Parameters.Modals.Create.TrueFalse')">
        <b-form-radio v-model="units_form" :value="true">True</b-form-radio>
        <b-form-radio v-model="units_form" :value="false">False</b-form-radio>
      </b-form-group>
      <template slot="footer">
        <n-button class="btn-simple ncm-btn" @click="addElement">
           {{ $t("Parameters.Modals.Create.Footer.Save") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.create = false">
           {{ $t("Parameters.Modals.Create.Footer.Close") }}
        </n-button>
      </template>
    </modal>

    <!-- Modal modify  -->
    <modal v-show="modals.modify" :show.sync="modals.modify" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("Parameters.Modals.Modify.Title") }}
      </h4>
      <b-form-group :label=" $t('Parameters.Modals.Modify.Label') + ' *'  ">
        <b-form-input
            v-model="selectedElement.label"
            id="input-label-modify"
            name="input-label-modify"
            v-validate="{ required: true}"
            :state="validateState('input-label-modify')"
            aria-describedby="input-label-modify-live-feedback"
            size="sm"
            :placeholder=" $t('Parameters.Modals.Modify.Label') + ' *'  "
          ></b-form-input>
          <b-form-invalid-feedback id="input-label-modify-live-feedback">{{ veeErrors.first('input-label-modify') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('Parameters.Modals.Modify.ValueType') + ' *' ">
          <b-form-select v-model="selectedElement.value_type" 
            id="input-value-modify"
            name="input-value-modify"
            v-validate="{ required: true}"
            :state="validateState('input-value-modify')"
            aria-describedby="input-value-modify-live-feedback">
            <b-form-select-option :value="null">{{$t('Parameters.Modals.Modify.ValueTypePH')}}</b-form-select-option>
            <b-form-select-option value="boolean">boolean</b-form-select-option>
            <b-form-select-option value="integer">integer</b-form-select-option>
            <b-form-select-option value="float">float</b-form-select-option>
            <b-form-select-option value="varchar">varchar</b-form-select-option>
            <b-form-select-option value="text">text</b-form-select-option>
          </b-form-select>
          <b-form-invalid-feedback id="input-value-modify-live-feedback">{{ veeErrors.first('input-value-modify') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group v-if="selectedElement.value_type != 'boolean'" :label="$t('Parameters.Modals.Modify.Units')">
        <b-form-input
            v-model="selectedElement.units"
            id="input-units-modify"
            name="input-units-modify"
            v-validate="{ required: true}"
            :state="validateState('input-units-modify')"
            aria-describedby="input-units-modify-live-feedback"
            size="sm"
            :placeholder=" $t('Parameters.Modals.Modify.Units')"
          ></b-form-input>
          <b-form-invalid-feedback id="input-units-modify-live-feedback">{{ veeErrors.first('input-units-modify') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group v-if="selectedElement.value_type == 'boolean'" :label="$t('Parameters.Modals.Create.TrueFalse')">
        <b-form-radio v-model="selectedElement.units" :value="true">True</b-form-radio>
        <b-form-radio v-model="selectedElement.units" :value="false">False</b-form-radio>
      </b-form-group>
      <template slot="footer">
        <n-button class="btn-simple ncm-btn" @click="updateElement">
           {{ $t("Parameters.Modals.Modify.Footer.Save") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger"
          @click.native="modals.modify = false">
          {{ $t("Parameters.Modals.Modify.Footer.Close") }}
        </n-button>
      </template>
    </modal>

      <!-- Modal delete  -->
      <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
        <p>{{ $t("Parameters.Modals.Delete.Title") }}</p>
        <template slot="footer">
          <n-button type="neutral" link @click="deleteElement" >
            {{ $t("Parameters.Modals.Delete.Footer.Save") }}
          </n-button>
          <n-button type="neutral" link @click.native="modals.delete = false">
            {{ $t("Parameters.Modals.Delete.Footer.Close") }}
          </n-button>
        </template>
      </modal>
      
      <modal v-show="modals.reportError" :show.sync="modals.reportError" header-classes="justify-content-center">
        <div class="row">
          <div class="col">
            <h4 slot="header" class="title title-up">
              {{ $t("Parameters.ErrorList") }}
            </h4>
            <div class="error-list">
              <ul>
                <li v-for="(error, index) in msgReportError" :key="index" class="error-item">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex">
            <n-button class="btn-simple ncm-btn-danger m-auto" @click.native="modals.reportError = false; getData()">
              {{ $t("Parameters.CancelChanges") }}
            </n-button>
          </div>
        </div>
      </modal>
      <modal v-show="modals.reportOK" :show.sync="modals.reportOK" header-classes="justify-content-center">
      <div class="row">
              <div class="col">
                <h4 slot="header" class="title title-up">
                  {{ $t("Parameters.UpdateList") }}
                </h4>
                  <div class="counts-list" v-if="contadores">
                    <ul>
                      <li class="counts-item" v-if="contadores.nuevos"> New records: {{ contadores.nuevos.length }}</li>
                      <li class="counts-item" v-if="contadores.actualizar"> Records to update: {{ contadores.actualizar.length }}</li>
                      <li class="counts-item" v-if="contadores.deshabilitar"> Records to disable: {{ contadores.deshabilitar.length }}</li>
                    </ul>
                  </div>
                
              </div>
            </div>
            <div class="row">
              <div class="col d-flex">
                <n-button class="btn-simple ncm-btn m-auto" @click.native="applyChanges()">
                  {{ $t("Parameters.ApplyChanges") }}
                </n-button>
                <n-button class="btn-simple ncm-btn-danger m-auto" @click.native="modals.reportOK = false; getData()">
                  {{ $t("Parameters.CancelChanges") }}
                </n-button>
              </div>
            </div>
      </modal>
    </div>
  </template>
  
  <script>
    import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal} from '@/components';
    import { API_KEY } from '@/constants'
    import {Table, TableColumn, Tooltip} from 'element-ui';
    import Vue from 'vue'
    import * as VueGoogleMaps from 'vue2-google-maps'
    Vue.use(VueGoogleMaps, {
      load: {
        key: API_KEY
      }
    });

  import ParameterDataService from '@/services/ParameterDataService'
  import LanguageDataService from '@/services/LanguageDataService'
  
  export default {
  name: 'Parameters',
  bodyClass: 'profile-page',
  components: {
      Modal,
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
  },
  data() {
      return {
          modals: {
              modify: false,
              delete: false,
              create: false,
              reportError: false,
              reportOK: false
          },
          csvData: [],
          parameters: [],
          parametersActive: [],
          selectedElement: {},
          label_form: '',
          value_type_form: null,
          units_form: '',
          headers: [
            { key: 'label', sortable: true, label: this.$t('Parameters.Table.Label'), tdClass: 'table-title', thClass: 'table-title position-sticky' }, 
            { key: 'value_type', sortable: true, label: this.$t('Parameters.Table.Type'), thClass: 'position-sticky'}, 
            { key: 'units', sortable: true, label: this.$t('Parameters.Table.Units'), thClass: 'position-sticky'}, 
            { key: 'createdAt', label: this.$t('Parameters.Table.CreatedAt'), thClass: 'position-sticky', sortable: true },
            { key: 'updatedAt', label: this.$t('Parameters.Table.UpdatedAt'), thClass: 'position-sticky', sortable: true },
            { key: 'options', label: this.$t('Languages.Table.Actions'), thClass: 'position-sticky' }
          ],
          allOpenRows: [],
          currentPage: 1,
          perPage: 10,
          languages: [],
          language_form: null,
          msgReportError : [] ,
          contadores: null,
          errorCSV: false,
      }
  },
  computed: {
    totalPages(){
      return this.parameters.length
    },
  },
  created() {
    this.getData()
  },
  methods:{
    toggle(parameter){
      parameter.disabled = !parameter.disabled
        ParameterDataService.update(parameter).then(
          Response => {
            this.getData()
          },
            Error =>{
              this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: Error.response.data.message
              })
            }
        )
    },
    applyRowClass(row) {
      if (row != null) {
        if (row.disabled) {
        return 'disabled-row';
      } else {
        return '';
      }
      }
    },
    handleCSVUpload(event) {

      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const csvString = reader.result;
          this.parseCSV(csvString);
        };
        reader.readAsText(file,'ISO-8859-1');
      }
    },
  async parseCSV(csvString) { 
      const rows = csvString.split('\n');
      const headers = rows[0].split(';');
      var languages_table = []
      for (let language of this.languages) {
        languages_table.push(language.name)
      }
      // let headersCheck = headers.filter(elemento => elemento !== '');
      // if (headersCheck.length != 4 + languages_table.length){
      //   this.$swal.fire({
      //     icon: 'error',
      //     text: 'Headers of csv error'   
      //   }).then(() => {
      //     return
      //   }) 
      // } else {
        const rowCount = rows.length - 1; // Obtener el número de filas excluyendo la última que falla 
        this.csvData = rows.slice(1, rowCount).map((row) => {
          const columns = row.split(';');
            const rowData = {};
            columns.forEach((column, index) => {
              var header = headers[index];
              header = header.replace('\r','')
              rowData[header] = column.trim()
            });
            if (Object.keys(rowData).length != headers.length) {
              this.$swal.fire({
                icon: 'error',
                text: this.$t('Alerts.FieldsError') 
              }).then(() => {
                return
              }) 
            }
            return rowData
          })
          // Llamada al back enviando el objeto 
          ParameterDataService.uploadCSV(this.csvData).then(
            Response => {
              console.log(Response.data)
              this.contadores = Response.data.contadores
              this.modals.reportOK = true
              this.$refs.fileInput.value = ''
            },
            Error => {
              if(Error.response.data.headerError){
                this.msgReportError = Error.response.data.headerError
              }
              else{
                this.msgReportError = Error.response.data.error
              }
              
              console.log(Error.response.data)
              this.contadores = {}
              this.modals.reportError = true
              this.$refs.fileInput.value = ''
              this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
            }
          )
          
      //}
  },
  downloadCSV() {
      const csvRows = [];
      const headers = ['ID','LABEL', 'VALUE_TYPE', 'UNITS'];
      const languageNames = this.languages.map((language) => language.name);
      const languagesHeader = languageNames.map((name) => `LANGUAGE_${name}`);
      headers.push(...languagesHeader);
      csvRows.push(headers.join(';'));
      this.parametersActive.forEach((parameter) => {
        const rowData = [
          parameter.id,
          parameter.label,
          parameter.value_type,
          parameter.units,
        ];
        languageNames.forEach((language) => {
          const languageValue = parameter.languages.find((lang) => lang.language.name === language);
          const languageData = languageValue ? languageValue.name : '';
          rowData.push(languageData);
        });
        csvRows.push(rowData.join(';'));
      });
      const csvContent = csvRows.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'Parameters.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    openEditLanguageModal(element){
      this.$router.push('/parameters/languages/' + element.id)
    },
    handleRowClicked (item, index) {
      this.allOpenRows.map((ele) => {
          if (ele.id !== item.id && ele._showDetails) {
          this.$set(ele, '_showDetails', !ele._showDetails)
          }
      })
      this.allOpenRows = []
      this.$set(item, '_showDetails', !item._showDetails)
      this.allOpenRows.push(item)
    },
    validateState (ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    getData(){
      console.log('SE HACE')
        ParameterDataService.findAll('eraefaef').then(
          async response => {
            this.parameters = response.data
            this.parameters.sort((a, b) => (a.disabled - b.disabled));
            this.parametersActive = response.data.filter((parameter) => !parameter.disabled);

          },
          error => {
            this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: error.response.data.message
              })
            
            if (error.response.status == '401') {
              this.$router.push('dashboard-staff')
            }
          }
            
        )
        LanguageDataService.findAll().then(
          async response => {
            this.languages = response.data
          },
          error => {
            this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
          }
        )
    },
    addElement(){
        this.$validator.validateAll(['input-label', 'input-language', 'input-value', 'input-units']).then(async result => {
          if (!result) {
            return
          }
          var language = this.languages.find(f => f.name.toLowerCase() === 'english'.toLowerCase());
          var element = {
            label: this.label_form,
            value_type: this.value_type_form,
            units: this.units_form,
            language_id: language.id
          }
          ParameterDataService.create(element).then(
            async response => {
                this.$swal.fire({
                    icon: 'success',
                    title: this.$t('Alerts.ParameterCreated'),
                    text: ''
                    }).then(() => {
                      // Reset form
                      this.modals.create = false
                      this.label_form = ''
                      this.value_type_form = null
                      this.units_form = ''
                      this.language_form = null
                      this.$validator.reset()
                      this.getData()
                });
                
            },
            error => {
              this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                if (error.response.data.message == 'Validation error'){
                    this.$swal.fire({
                      icon: 'error',
                      title: this.$t('Alerts.ParameterNotCreated'),
                      text: this.$t('Alerts.ErrorParameterNotCreated')
                      }).then(() => {
                  });
                }
            }
          )
        })
    },
    openFileInput () {
        this.$refs['fileInput'].click()
    },
    openEditModal(element){
        console.log(element)
        this.selectedElement = {...element}
        this.modals.modify = true
    },
    openDeleteModal(element){
        console.log(element)
        this.selectedElement = {...element}
        this.modals.delete = true
    },
    updateElement(){
        this.$validator.validateAll(['input-label-modify', 'input-value-modify', 'input-units-modify']).then(async result => {
          if (!result) {
            return
          }
          console.log('DATOS DEL PARAMETRO A ACTUALIZAR: ',this.selectedElement)
          ParameterDataService.update(this.selectedElement).then(
              async response => {
                  console.log(response.data)
                  this.$swal.fire({
                      icon: 'success',
                      title: this.$t('Alerts.ParameterModified'),
                      text: ''
                      }).then(() => {
                        this.modals.modify = false
                        this.getData()
                  });
              },
              error => {
                  if (error.response.status == '409') {
                    this.$swal.fire({
                    icon: 'error',
                    text: this.$t('Alerts.ErrorParameterLabel')
                    }).then(() => {
                      this.getData() 
                    });
                  }
              }
          )
        })
        
    },
    applyChanges(){
      ParameterDataService.applyChanges(this.contadores).then(
              async response => {
                  this.$swal.fire({
                  icon: 'success',
                  title: this.$t('Alerts.ParameterListModified'),
                  text: ''
                  }).then(() => {
                    this.modals.reportOK = false
                    this.getData()
                  });

            },
            error => {
              this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
            }
    )
    },
    isEmpty(str) {
      return str.trim() === '';
    },
    deleteElement(){
      console.log(this.selectedElement.id)
      ParameterDataService.delete(this.selectedElement.id).then(
              async response => {
                  this.$swal.fire({
                  icon: 'success',
                  title: this.$t('Alerts.ParameterDeleted'),
                  text: ''
                  }).then(() => {
                    this.modals.delete = false
                    this.getData()
                  });

            },
            error => {
              this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
            }
    )
  }
}
}
</script>
<style>
.disabled-row {
  background-color: #f8f8f8;
  color: #999999;
}
.error-list {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

.error-item {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #721c24;
}

.error-item::before {
  color: #721c24;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* .row .col.d-flex {
  justify-content: flex-end;
} */
.counts-list {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
}

.counts-item {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #155724;
}

.counts-item::before {
  color: #155724;
  margin-right: 5px;
  margin-bottom: 5px;
}

@media (max-width: 465px) {
  .titleParam {
    text-align: center;
  }

  .buttonParam{
    float: center;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
    .buttonParam{
      font-size: 0.8em!important;
    }
}

</style>