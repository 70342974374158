<template>
    <div class="wrapper blog-post">
        <div class="page-header page-header-small ">
            <parallax
                class="page-header-image"
                :style="{'background-image': 'url(' + $getImgUrlSite('notices.jpg') +')', opacity: '100%'}"
            />
            <div class="content-center">
                <h1 class="title">
                    {{ $t("TutorialsLanding.Title") }}
                </h1> 
            </div>
        </div>
        <div class="section pb-0">
            <div class="container">
                <div class="row">
                    <div class="col-2  m-auto">
                        <b-form-group label="Show">
                            <b-form-select v-model="selectedCount" @change="updateDisplayedPosts">
                                <b-form-select-option :value="10">10</b-form-select-option>
                                <b-form-select-option :value="15">15</b-form-select-option>
                                <b-form-select-option :value="20">20</b-form-select-option>
                                <b-form-select-option :value="filteredArticles.length">{{ $t("DashBoardStaff.Options") }}</b-form-select-option>
¡                            </b-form-select>
                        </b-form-group>                        
                    </div>
                    <div class="col m-auto ">
                        <b-form-group label="Language">
                            <b-form-select v-model="languageFilter">
                                <b-form-select-option v-for="language in languages" :key="language.id" :value="language.id">
                                    {{ language.name | capitalizeFirstLetter }}
                                </b-form-select-option>
                                <b-form-select-option :value="null">{{ $t("TutorialsLanding.FilterL") }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>                        
                    </div>
                    <div class="col  m-auto">
                        <b-form-group label="Category">
                            <b-form-select v-model="categoryFilter" :options="categories" value-field="id" text-field="name">
                                <b-form-select-option :value="null">{{ $t("TutorialsLanding.FilterC") }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>                        
                    </div>
                    <div class="col m-auto">
                        <b-dropdown  class="my-custom-dropdown">
                            <template #button-content>
                                <span v-if="selectedOption">{{ selectedOption }}</span>
                                <span v-else> Order By</span>
                            </template>
                            <b-dropdown-item @click="selectOption('Date')">{{ $t("TutorialsLanding.Date") }}</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12 ml-auto mr-auto">
                        <h2 class="title">
                            {{ $t("TutorialsLanding.PostsList") }}
                        </h2>
                        <br>
                        <card v-for="article in articlesShow" :key="article.id" type="blog" plain>
                            <div class="row">
                                <div class="col-md-5">
                                    <template v-if="article.image != null && isVideo(article.image.url)">
                                        <video
                                            class="img img-raised rounded fixed-size-image"
                                            controls
                                            style="max-width: 100%"
                                        >
                                            <source :src="getVideoUrl(article.image.url)" type="video/mp4">
                                        </video>
                                    </template>        
                                    <template v-else>
                                        <template v-if="article.image">
                                            <img class="img img-raised rounded fixed-size-image"  :alt="article.image.description" :src="getImgUrl(article.image.url)">
                                        </template>
                                        <template v-else>
                                            <img  v-if="article.external_image == null" class="img img-raised rounded fixed-size-image"  alt="Image default" :src="$getImgUrlSite('blurred-image-1.jpg')">
                                            <img v-else class="img img-raised rounded fixed-size-image"  :alt="article.external_image_description" :src="article.external_image">
                                        </template>
                                    </template>
                                </div>
                                +t
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col">
                                            <h6 class="category text-primary">
                                                <b-icon icon="pie-chart-fill"></b-icon> {{article.category_post_name}}
                                            </h6>
                                            <router-link v-if="$store.state.auth.status.loggedIn" :to="`/post/${article.title_slug}`">
                                                <h3 class="card-title link-hover">
                                                {{article.title}}
                                                </h3>
                                            </router-link>
                                            <p>
                                                <b>{{article.subtitle}}</b>
                                            </p>
                                            <p class="card-description">
                                                <b>{{article.public_content}}</b>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p><b>{{article.createdAt | formatDate}}</b></p>
                                        </div>
                                        <div class="col">
                                            <router-link v-if="$store.state.auth.status.loggedIn" :to="`/post/${article.category_post_name}/${article.id}`" class="read-more-link" style="float:right">Read more...</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                        <div>
                            <b-pagination v-model="currentPage" :total-rows="filteredArticles.length" :per-page="perPage"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import BlogDataService from '../../services/BlogDataService'; 
import CategoriesPosts from '../../services/CategoriesPostsDataServices'  
import LanguageDataService from '../../services/LanguageDataService'

import { Card, DropDown } from '@/components';
export default {
    components: {
    Card,
    DropDown
    },
    data() {
        return {
            selectedOption: null,
            selectedCount: 10,
            posts: [],
            categories: [],
            languages: [],
            currentPage: 1,
            perPage: 10,
            languageFilter: null,
            categoryFilter: null, 
            allPosts: []
        }
    },
    watch: {
        selectedCount() {
            this.updatePageSize();
        },
        perPage() {
            this.currentPage = 1;
        }
    },

    filters: {
        capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    },
    computed: {
        
        totalPages() {
            const totalCount = this.filteredArticles.length;
            const totalPages = Math.ceil(totalCount / this.perPage);
            return totalPages > 0 ? totalPages : 1;
        },
        filteredArticles() {
            let filteredArticles = this.allPosts;
            if (this.languageFilter) {
                filteredArticles = filteredArticles.filter(
                    (article) => article.language_id === this.languageFilter
                )
            }

            if (this.categoryFilter) {
                filteredArticles = filteredArticles.filter(
                    (article) => article.category_post_id === this.categoryFilter
                )
            }

            return filteredArticles;
        },
        articlesShow() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage

            let displayedArticles = this.filteredArticles.slice(startIndex, endIndex)

            if (this.selectedOption === 'Date') {
                displayedArticles = displayedArticles.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt)
            });
            }

            return displayedArticles;
        },
        
    },
    created () {
        this.getData()
    },
    methods: {
        capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        updatePageSize() {
            // Actualizar el tamaño de página según el número seleccionado
            this.perPage = this.selectedCount;
            this.currentPage = 1;
        },
        previousPage() {
            // Ir a la página anterior
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            // Ir a la siguiente página
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        getData() {
            this.perPage = this.selectedCount;
            BlogDataService.findAll().then(
                Response => {
                    console.log('BLOGDATASERVICES:', Response.data.data)
                    this.posts = Response.data.data
                    this.allPosts = Response.data.data
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
            CategoriesPosts.findAll().then(
                Response => {

                    this.categories = Response.data.data
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
            LanguageDataService.findAll().then(
                async Response => {
                    this.languages = Response.data
                },
                Error => {
                    this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: Error.response.data.message
                        })
                    }
            )
        },
        selectOption (option) {
            this.selectedOption = option;
        },
        updateDisplayedPosts() {
        this.currentPage = 1;
        },
        getImgUrl (name) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${name}?withheaders=yes`
            //return 'https://p-ncm-public.s3.eu-south-2.amazonaws.com/' + name
            /*try{
                if(name){
                    var images = require.context('../../assets/multimedia/blog/images', false)
                    console.log(images('./' + name))
                    return images('./' + name)
                }
            }catch{
                console.log('error loading image')
            }*/
        },
    }
};

</script>
<style>
.my-custom-dropdown {
position: relative;
margin-top: 17px;
float:right;

}

.my-custom-dropdown .dropdown-toggle {
background-color: #5D6176;
color: #fff;
border: none;
padding: 10px 20px;
border-radius: 4px;
cursor: pointer;
}
.my-custom-dropdown .dropdown-toggle:hover {
background-color: #2a7520;
}

.read-more-link {
text-decoration: none;
font-size: 13px ;
color: inherit;
position: relative;
font-weight: bold;
color: #5D6176!important;
}

.read-more-link:hover {
color: #5D6176;
}

.read-more-link:hover::after {
opacity: 1;
}
.link-hover:hover {
text-decoration: underline;
}

</style>


