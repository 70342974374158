import http from '../http-common'
import authHeader from './AuthHeaderService'


const API_URL_PATH = 'api/ecosystem-service-parameter/'

class EcosystemServiceParameterDataService {

  findAll (accessToken) {
    return http.get(API_URL_PATH + 'findAll',{headers: authHeader()})
  }

  create (ecosystem_service_parameter, accessToken) {
    return http.post(API_URL_PATH + 'create', { ecosystem_service_parameter: ecosystem_service_parameter },{headers: authHeader()})
  }

  update (ecosystem_service_parameter, accessToken) {
    return http.put(API_URL_PATH + 'update', { id: ecosystem_service_parameter.id, ecosystem_service_parameter: ecosystem_service_parameter },{headers: authHeader()})
  }

  deleteByService (ecosystem_id, service_id, accessToken) {
    return http.delete(API_URL_PATH + 'delete-by-service?ecosystem_id=' + ecosystem_id + '&service_id=' + service_id, {headers: authHeader()})
  }

  deleteByParameter (ecosystem_id, service_id, parameter_id, accessToken) {
    return http.delete(API_URL_PATH + 'delete-by-parameter?ecosystem_id=' + ecosystem_id + '&service_id=' + service_id + '&parameter_id=' + parameter_id, {headers: authHeader()})
  }

  

}

export default new EcosystemServiceParameterDataService()
