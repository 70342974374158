<template>
    <div class="wrapper  dashboard-wrapper collapsed-sidebar">
        <div class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                    {{ $t("CategoriesPost.Title")}}
                    </h2> 
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <card>
                        <b-row>
                            <b-col>
                                <h6 class="titlePost">{{ $t("CategoriesPost.List")}}</h6>
                            </b-col>
                            <b-col>
                                <b-button-toolbar class="buttonPost" key-nav aria-label="Toolbar with button groups" style="float: right">
                                    <b-button-group class="mx-1">
                                        <n-button class="btn-simple ncm-btn" target="_blank" @click.native="modals.create = true">
                                        <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                                        {{ $t("CategoriesPost.Create")}}
                                        </n-button>
                                    </b-button-group>
                                </b-button-toolbar>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table 
                                ref="categoriesTable"
                                id="categoriesTable"
                                :fields="headers" 
                                :items="categories"
                                show-empty
                                select-mode="single"
                                style="overflow-x: auto;"
                                :current-page="currentPage"
                                :per-page="perPage"
                                sort-by="createdAt"
                                :sort-desc="true"
                                responsive="sm"> 
                                <template #empty="">
                                    {{$t('CategoriesPost.Empty')}}
                                </template>
                                    <template #cell(createdAt)="data">
                                        {{ data.item.createdAt | formatDate}}
                                    </template>
                                    <template #cell(updatedAt)="data">
                                        {{ data.item.updatedAt | formatDate}}
                                    </template>
                                    <template #cell(options)="data">
                                        <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Modify')" @click="openEditModal(data.item)">
                                            <b-icon icon="pen"></b-icon>
                                        </b-button>
                                        <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Delete')" @click="openDeleteModal(data.item)">
                                            <b-icon icon="trash"></b-icon>
                                        </b-button>
                                    </template>
                                </b-table>
                                <div class="pagination-container">
                                  <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                                  </b-pagination>
                                  <b-form-select v-model="perPage" class="pagination-select">
                                      <b-form-select-option :value="4">4</b-form-select-option>
                                      <b-form-select-option :value="8">8</b-form-select-option>
                                      <b-form-select-option :value="12">12</b-form-select-option>
                                      <b-form-select-option
                                          :value="categories.length">{{ $t('DashBoardStaff.Options') }}</b-form-select-option>
                                  </b-form-select>
                              </div>
                            </b-col>
                        </b-row>
                    </card>
                </b-col>
            </b-row>
        </div>
        <!-- Modal create  -->
        <modal v-show="modals.create" :show.sync="modals.create" header-classes="justify-content-center">
            <h4 slot="header" class="title title-up">
                {{ $t("CategoriesPost.Modals.Create.Title")}}
            </h4>
            <b-form-group :label="$t('CategoriesPost.Modals.Create.Name')">
                <b-form-input
                v-model="nameCategory"
                id="input-nameCategory"
                name="input-nameCategory"
                v-validate="{required: true, alpha_spaces: true}"
                :state="validateState('input-nameCategory')"
                aria-describedby="input-nameCategory-live-feedback"
                size="sm"
                :placeholder="$t('CategoriesPost.Modals.Create.Name')"
                ></b-form-input>
                <b-form-invalid-feedback id="input-nameCategory-live-feedback">{{ veeErrors.first('input-nameCategory') }}</b-form-invalid-feedback>
            </b-form-group>
            <template slot="footer">
                <n-button class="btn-simple ncm-btn" @click="addCategoryPost">
                    {{ $t("Parameters.Modals.Create.Footer.Save") }}
                </n-button>
                <n-button class="btn-simple ncm-btn-danger" @click.native="modals.create = false">
                    {{ $t("Parameters.Modals.Create.Footer.Close") }}
                </n-button>
            </template>
        </modal>
        <!-- Modal create  -->
        <modal v-show="modals.modify" :show.sync="modals.modify" header-classes="justify-content-center">
            <h4 slot="header" class="title title-up">
                {{ $t("CategoriesPost.Modals.Modify.Title")}}
            </h4>
            <b-form-group :label="$t('CategoriesPost.Modals.Modify.Name')">
                <b-form-input
                v-model="selectedElement.name"
                id="input-nameCategory-modify"
                name="input-nameCategory-modify"
                v-validate="{required: true, alpha_spaces: true}"
                :state="validateState('input-nameCategory-modify')"
                aria-describedby="input-nameCategory-modify-live-feedback"
                size="sm"
                placeholder="Name *"
                ></b-form-input>
                <b-form-invalid-feedback id="input-nameCategory-modify-live-feedback">{{ veeErrors.first('input-nameCategory-modify') }}</b-form-invalid-feedback>
            </b-form-group>
            <template slot="footer">
                <n-button class="btn-simple ncm-btn">
                    {{ $t("Parameters.Modals.Create.Footer.Save") }}
                </n-button>
                <n-button class="btn-simple ncm-btn-danger" @click.native="modals.modify = false">
                    {{ $t("Parameters.Modals.Create.Footer.Close") }}
                </n-button>
            </template>
        </modal>
        <modal v-show="modals.deleteCategory" :show.sync="modals.deleteCategory" header-classes="justify-content-center">
            <h4 slot="header" class="title title-up">
                {{ $t("CategoriesPost.Modals.Delete.Title2")}} 
            </h4>
            <ul v-if="postToDelete[0] != undefined">
                <li v-for="(post, index) in 5" :key="index">
                    {{ postToDelete[index].title }}
                </li>
                <li v-if="postToDelete.length > 5">...</li>
            </ul>
            <b-form-group :label="$t('CategoriesPost.Modals.Delete.NewCat')">
            <b-form-select v-model="newCategoryPosts" 
                id="input-type"
                name="input-type"
                v-validate="{ required: true}"
                :state="validateState('input-type')"
                aria-describedby="input-type-live-feedback"
                :options="categories "
                value-field="id"
                text-field="name">
            <b-form-select-option :value="null">{{$t('Ecosystems.Modals.Create.TypePH')}}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback id="input-type-live-feedback">{{ veeErrors.first('input-type') }}</b-form-invalid-feedback>
            </b-form-group>
            <template slot="footer">
                <n-button class="btn-simple ncm-btn" @click="deleteAssociate">
                    {{ $t("Parameters.Modals.Create.Footer.Save") }}
                </n-button>
                <n-button class="btn-simple ncm-btn-danger" @click.native="modals.deleteCategory = false">
                    {{ $t("Parameters.Modals.Create.Footer.Close") }}
                </n-button>
            </template>
        </modal>
        <!-- Modal delete  -->
        <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
            <p>{{$t("CategoriesPost.Modals.Delete.Title")}}</p>
            <template slot="footer">
                <n-button type="neutral" link @click="deleteCategoryPost" >
                    {{ $t("Parameters.Modals.Delete.Footer.Save") }}
                </n-button>
                <n-button type="neutral" link @click.native="modals.delete = false">
                    {{ $t("Parameters.Modals.Delete.Footer.Close") }}
                </n-button>
            </template>
        </modal>
    </div>
</template>

<script>
import {Card, Modal, Button} from '@/components';
import CategoriesPosts from '../../services/CategoriesPostsDataServices'
export default {
    components: {
        Card,
        Modal,
        [Button.name]: Button,
    },
    data () {
        return {
            modals: {
                modify: false,
                delete: false,
                create: false,
                deleteCategory: false
            },
            selectedElement: {},
            nameCategory: '',
            categories: [],
            headers: [
                { key: 'name', sortable: true, label: this.$t('DashBoardStaff.Table.Name'), tdClass: 'table-title', thClass: 'table-title position-sticky' },
                { key: 'createdAt', label: this.$t('Parameters.Table.CreatedAt'), thClass: 'position-sticky', sortable: true },
                { key: 'updatedAt', label: this.$t('Parameters.Table.UpdatedAt'), thClass: 'position-sticky', sortable: true },
                { key: 'options', label: this.$t('DashBoardStaff.Table.Actions'), thClass: 'position-sticky' }
            ],
            currentPage: 1,
            perPage: 4,
            postToDelete: [],
            newCategoryPosts : null
        }
    },
    created() {
        this.getData()
    },
    computed: {
        totalPages (){
            return this.categories.length
        },
    },
    methods : {
        getData () {
            CategoriesPosts.findAll().then(
                Response => {
                    this.categories = Response.data.data
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })                
                }
            )
        },
        validateState (ref) {
            if (this.veeFields[ref] &&(this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
            return !this.veeErrors.has(ref)
            }
            return null
        },
        addCategoryPost(){
            this.$validator.validateAll(['input-nameCategory']).then(async result => {
                if (!result) {
                    return
                }
                CategoriesPosts.create(this.nameCategory).then(
                    Response => {
                        this.$swal.fire({
                            icon: 'success',
                            title: this.$t('AddPost.CategoryOK'),
                        }).then(() => {
                            this.modals.create = false
                            this.nameCategory = ''
                            this.$validator.reset()
                            this.getData()
                        })
                    },
                    Error => {
                        if (Error.response.status === 409) {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: this.$t('AddPost.CategoryError')
                            })
                        }
                    }
                )
            })
        },
        deleteCategoryPost () {
            CategoriesPosts.delete(this.selectedElement.id).then(
                Response => {
                    this.$swal.fire({
                    icon: 'success',
                    title: this.$t('AddPost.Delete')
                    }).then(() => {
                        this.modals.delete = false
                        this.getData()
                    })
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        deleteAssociate () {
            CategoriesPosts.deleteAssociate(this.selectedElement.id,this.newCategoryPosts).then(
                Response =>{
                    this.$swal.fire({
                    icon: 'success',
                    title: this.$t('AddPost.DeleteAsso')
                    }).then(() => {
                        this.modals.deleteCategory = false
                        this.getData()
                    })
                },
                Error =>{
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })                
                }
            )
        },
        updateCategoryPost () {
            CategoriesPosts.modify(this.selectedElement.id, this.selectedElement.name).then(
                Response => {
                    this.$swal.fire({
                    icon: 'success',
                    title: this.$t('AddPost.Modify')
                    }).then(() => {
                        this.modals.modify = false
                        this.getData()
                    })
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })                
                }
            )
        },
        openEditModal(element){
            this.selectedElement = {...element}
            this.modals.modify = true
        },
        openDeleteModal(element){
            this.selectedElement = {...element}
            this.modals.delete = true
        },
    }
}
</script>

<style scoped>
@media (max-width: 465px) {
  .titleServices {
    text-align: center;
  }

  .buttonServices{
    float: center;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}

@media (max-width: 1066px) {
    .dropdownServices {
        margin-top: 80px;
        margin-left: -25px;
    }
    .buttonServices{
      font-size: 0.8em;
    }
}
</style>