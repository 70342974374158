<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar">
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                        {{ $t("Multimedia.Title") }}
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <div class="full-hd-alert">
                    <h5 style="font-weight: 700; font-size: 15px">{{ $t("Multimedia.HD") }}</h5>
                    <p class="p-dashboard" style="font-size: 13px !important;">
                        <em>{{ $t('Multimedia.Text') }}</em>
                    </p>
                </div>
            </b-row>
            <b-row>
                <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"
                @vdropzone-queue-complete="completeUpload"
                >
                </vue-dropzone>
            </b-row>
            <b-row>
                <!-- <cropper
            ref="cropper"
            class="cropper"
            :src="img"
            :stencil-props="{
                aspectRatio: 10/12
            }"
            @change="change"
        /> -->
            </b-row>
            <b-row>
                <b-col cols="12">
                    <card>
                        <b-row>
                            <b-col class="m-auto">
                                <h6>{{ $t('Multimedia.All') }}</h6>
                            </b-col>

                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table
                                ref="parametersTable"
                                id="parametersTable"
                                :fields="headers"
                                :items="images"
                                show-empty
                                select-mode="single"
                                style="overflow-x: auto;"
                                :current-page="currentPage"
                                :per-page="perPage"
                                sort-by="createdAt"
                                :sort-desc="true"
                                responsive="sm">
                                <template #cell(name_image)="data">
                                    {{ data.item.url | nameFile}}
                                </template>
                                <template #cell(extension)="data">
                                    {{ data.item.url | extensionFile }}
                                </template>
                                <template #cell(image)="data">
                                    <template v-if="data.item.is_external == false">
                                        <template v-if="isVideo(data.item.url)">
                                            <video
                                                class="img img-raised table-image"
                                                controls
                                                height="98"
                                                width="175"
                                            >
                                                <source :src="getVideoUrl(data.item.url)" type="video/mp4">
                                            </video>
                                        </template>
                                        <template v-else>
                                            <img
                                                class="img img-raised table-image"
                                                :src="getImgUrl(data.item.url)"
                                                height="98"
                                                width="175"
                                                @error="handleImageError"
                                            >
                                        </template>
                                    </template>
                                    <template v-else>
                                        <img
                                        class="img img-raised table-image"
                                        :src="data.item.url"
                                        width="150"
                                        height="98"
                                        @error="handleImageError"
                                        >
                                    </template>
                                </template>
                                <template #cell(createdAt)="data">
                                    {{ data.item.createdAt | formatDate}}
                                </template>
                                <template #cell(updatedAt)="data">
                                    {{ data.item.updatedAt | formatDate}}
                                </template>
                                <template #cell(options)="data">
                                    <b-button v-if="!isVideo(data.item.url)" class="action-table" v-b-tooltip.hover.viewport :title="$t('Tooltips.Crop')" @click="openCropModal(data.item)">
                                        <b-icon icon="crop"></b-icon>
                                    </b-button>
                                    <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Ecosystems.Tooltips.Modify')" @click="openEditModal(data.item)">
                                        <b-icon icon="pen"></b-icon>
                                    </b-button>
                                    <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Delete')" @click="openDeleteModal(data.item)">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                </template>
                            </b-table>
                            <div class="pagination-container">
                                <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                                </b-pagination>
                                <b-form-select v-model="perPage" class="pagination-select">
                                    <b-form-select-option :value="5">5</b-form-select-option>
                                    <b-form-select-option :value="10">10</b-form-select-option>
                                    <b-form-select-option :value="20">20</b-form-select-option>
                                    <b-form-select-option
                                        :value="images.length">{{ $t('ExternalOrganizations.All') }}</b-form-select-option>
                                </b-form-select>
                            </div> 
                            </b-col>
                        </b-row>
                    </card>
                </b-col>
            </b-row>
        </b-container>

        <!-- Modal modify  -->
    <modal :show.sync="modals.modify" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
        {{ $t("Multimedia.Modals.Title") }}
        </h4>
        <b-form-group :label="$t('Multimedia.Path') + '(' + `${ext_file_edit}` + ')'">
            <b-form-input v-if="selectedElement"
                v-model="name_file_edit"
                id="input-path-modify"
                name="input-path-modify"
                v-validate="{ required: true}"
                :state="validateState('input-path-modify')"
                aria-describedby="input-path-modify-live-feedback"
                size="sm"
                :placeholder="$t('Multimedia.Path')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-path-modify-live-feedback">{{ veeErrors.first('input-path-modify') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('Multimedia.Description')">
            <b-form-input v-if="selectedElement"
                v-model="selectedElement.description"
                id="input-description-modify"
                name="input-description-modify"
                v-validate="{ required: true}"
                :state="validateState('input-description-modify')"
                aria-describedby="input-description-modify-live-feedback"
                size="sm"
                :placeholder="$t('Multimedia.Description')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-description-modify-live-feedback">{{ veeErrors.first('input-description-modify') }}</b-form-invalid-feedback>
        </b-form-group>
        <template slot="footer">
            <n-button class="btn-simple ncm-btn" @click="updateElement">
             {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" @click.native="modals.modify = false">
             {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
            </n-button>
        </template>
    </modal>

        <!-- Modal delete  -->
    <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" >
        <p>{{ $t("PostsMultimedia.Modals.Delete.Title") }} <b>{{ selectedElement.url }}</b></p>
        <template slot="footer">
        <n-button type="neutral" link @click="deleteElement" >
            {{ $t("Parameters.Modals.Delete.Footer.Save") }}
        </n-button>
        <n-button type="neutral" link @click.native="modals.delete = false">
            {{ $t("Parameters.Modals.Delete.Footer.Close") }}
        </n-button>
        </template>
    </modal>

    <!-- Modal crop  -->
    <modal modal-classes="modal-big" :show.sync="modals.crop" header-classes="justify-content-center">
    <h4 slot="header" class="title title-up">
        {{ $t("Multimedia.Modals.Crop.Title") }}
    </h4>

    <cropper v-if="modals.crop"
            ref="cropper"
            class="cropper"
            :canvas="false"
            :src="getImgUrl(selectedElement.url)"
            :stencil-props="{
                aspectRatio: 16/9
            }"
            @change="change"
        />
    <template slot="footer">
        <n-button class="btn-simple ncm-btn" @click="uploadCropImage">
             {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.crop = false">
            {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
        </n-button>
    </template>
    </modal>
    </div>
</template>
<script>
    import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal} from '@/components';
    import { API_KEY } from '@/constants'
    import {Table, TableColumn, Tooltip} from 'element-ui';
    import MultimediaBlogDataService from '@/services/MultimediaBlogDataService'
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import authHeader from "@/services/AuthHeaderService"
    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css';
    import swal from 'sweetalert2';
    window.Swal = swal;
export default {
    components: {
        Modal,
    Card,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [FormGroupInput.name]: FormGroupInput,
    vueDropzone: vue2Dropzone,
    Cropper
    },
    data() {
        return {
            minImageWidth: 1280,
            minImageHeight: 720,
            dropzoneOptions: {
                paramName: 'multimedia-blog',
                acceptedFiles: 'image/*, video/*',
                url: `${process.env.VUE_APP_VITE_API_URL}/api/multimedia-blog/upload-images`,
                thumbnailWidth: 200,
                maxFilesize: 1000,
                headers: authHeader(),
                dictDefaultMessage: this.$t('Languages.Table.DropFiles') + '<b-icon icon="trash"></b-icon>',
                addRemoveLinks: true,
                init: function() {
                    this.on("thumbnail", function(file) {
                        console.log('on thumbnail', file)
                        if (file.width < 1280 || file.height < 720) {
                            file.rejectDimensions();
                        }
                        else {
                            file.acceptDimensions();
                        }
                    })
                    this.on("addedfile", function(file, done) {
                        console.log('on added', file)

                    })
                    this.on("complete", function(file, done) {
                        console.log('on complete', file)

                    })
                },
                accept: function(file, done) {
                    if(file.type != 'video/mp4'){
                        file.rejectDimensions = function() {
                            done("Wrong image dimensions, minimum 1280x720");
                            Swal.fire({
                            icon: 'error',
                            title: 'Wrong image dimensions, minimum 1280x720'
                            });
                        };
                        file.acceptDimensions = done;
                    }
                    else{
                        done()
                    }

                }
            },
            modals: {
                delete: false,
                modify: false,
                crop: false,
            },
            images: [

            ],
            headers: [
                { key: 'image', label: this.$t('Languages.Table.ImageVideo'), thClass: 'table-title position-sticky', tdClass:'table-title' },
                { key: 'name_image', label: this.$t('Languages.Table.Name'), thClass: 'table-title position-sticky', tdClass:'table-title' },
                { key: 'extension', label: this.$t('Languages.Table.Extension'), thClass: 'table-title position-sticky', tdClass:'table-title' },
                { key: 'description', label: this.$t('Languages.Table.Description'), thClass: 'table-title position-sticky', tdClass:'table-title' },
                { key: 'createdAt', label: this.$t('Ecosystems.Table.CreatedAt'), thClass: 'position-sticky', sortable: true },
                { key: 'updatedAt', label: this.$t('Ecosystems.Table.UpdatedAt'), thClass: 'position-sticky', sortable: true },
                { key: 'options', label: this.$t('Languages.Table.Actions'), thClass: 'position-sticky' }
            ],
            selectedElement: '',
            currentPage: 1,
            perPage: 10,
            name_file_edit: '',
            ext_file_edit: '',
        }
    },
    computed: {
        totalPages(){
        return this.images.length
        },
    },
    created(){
        this.getData()
    },
    methods: {
        formatCropCopyName(url){
            var file_name = url.split('.')
            file_name.pop()
            console.log(file_name)
            file_name = file_name.join('')
            file_name = file_name.split('_')
            file_name.shift()
            file_name = file_name.join('')
            console.log(file_name)
            file_name = file_name + '_crop.jpg'
            return file_name
        },
        uploadCropImage() {
			const {coordinates, image, visibleArea, canvas} = this.$refs.cropper.getResult();
            console.log(image)
			if (canvas) {
				const form = new FormData();
				canvas.toBlob(blob => {
                    var file_name = this.formatCropCopyName(this.selectedElement.url)
                    blob.name = file_name
					form.append('file', blob, file_name);
                    console.log(blob)
					MultimediaBlogDataService.uploadPostImages(form).then(
                        response => {
                            console.log(response)

                        },
                        error => {
                            console.log((error.response && error.response.data) || error.message || error.toString())
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Alerts.ErrorUploading')
                                });
                        }
                    )
					// Perhaps you should add the setting appropriate file format here
				}, 'image/jpeg');
			}
		},
        change({ coordinates, canvas }) {
			console.log(coordinates, canvas)
		},
        getImgUrl (name) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${name}?withheaders=yes`
            //return 'https://p-ncm-public.s3.eu-south-2.amazonaws.com/' + name
            /*try{
                if(name){
                    var images = require.context('../../assets/multimedia/blog/images', false)
                    console.log(images('./' + name))
                    return images('./' + name)
                }
            }catch{
                console.log('error loading image')
            }*/
        },
        isVideo(url) {
            const videoExtensions = ['mp4', 'webm', 'ogg']; // Agrega las extensiones de video que deseas comprobar
            const extension = url.split('.').pop();
            return videoExtensions.includes(extension.toLowerCase());
        },

        getVideoUrl(url) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${url}?withheaders=yes`
            // try {
            //     if(url){
            //         return require('../../assets/multimedia/blog/images/' + url);
            //     }
            // } catch {
            //     console.log('error loading video')
            // }
            // Construye la ruta de archivo local para el video
        },

        handleImageError(event) {
            event.target.style.display = 'none'; // Oculta la imagen o video si no se puede cargar
        },
        handleAddedFile(file) {
            if (!this.isFileAccepted(file)) {
                this.$refs.myVueDropzone.removeFile(file);
            }
            // if (!this.isFileAccepted(file)) {
            //     this.$refs.myVueDropzone.removeFile(file);
            // }
        },
        isFileAccepted(file) {
            const acceptedTypes = this.dropzoneOptions.acceptedFiles.split(',');
            const fileType = file.type;
            return acceptedTypes.some(type => {
                const acceptedType = type.trim();

                if (acceptedType.endsWith('/*')) {
                const acceptedCategory = acceptedType.split('/')[0];
                return fileType.startsWith(acceptedCategory);
                }

                return fileType === acceptedType;
            })
        },
        updateElement(){
            this.$validator.validateAll(['input-description-modify', 'input-path-modify']).then(async result => {
                if (!result) {
                return
                }
                this.selectedElement.url = this.name_file_edit + '.' +  this.ext_file_edit
                MultimediaBlogDataService.update(this.selectedElement).then(
                    async response => {
                        console.log(response.data)
                        this.modals.modify = false
                        this.$swal.fire({
                            icon: 'success',
                            title: this.$t('Alerts.ImageModified'),
                            text: ''
                            }).then(() => {

                                this.getData()
                        });

                    },
                    error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.message
                        })
                })
            })

        },
        validateState (ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref)
            }
            return null
        },
        openEditModal(element){
            this.selectedElement = {...element}
            this.name_file_edit =  this.selectedElement.url.split('.').slice(0, -1).join('.')
            this.ext_file_edit = this.selectedElement.url.split('.').pop()
            this.modals.modify = true
        },
        openCropModal(element){
            console.log(element)
            this.selectedElement = {...element}
            this.modals.crop = true
        },
        completeUpload( file, xhr, formData){
            console.log('Complete upload!')
            console.log(file)
            console.log(xhr)
            console.log(formData)
            // this.$swal.fire({
            //     icon: 'success',
            //     title: 'Images uploaded!'
            // }).then(() => {
            //     this.getData()
            // });
            this.getData()
        },
        getData(){
            console.log('getData')
            MultimediaBlogDataService.getMultimediaImages().then(
            async response => {
                console.log(response.data)
                this.images = response.data.data
            },
            error => {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response.data.message
                })
            })
        },
        openFileInput () {
            this.$refs['fileInput'].click()
        },
        openDeleteModal(element){
            console.log(element)
            this.selectedElement = element
            this.modals.delete = true
        },

        uploadImages () {

                var formData = new FormData()

                var files = this.$refs['fileInput'].files
                console.log(files)
                for(var file of files){
                formData.append(file.name, file) // appending file
                }
                MultimediaBlogDataService.uploadPostImagesMulti(formData).then(
                response => {
                    if(response.status == 200){
                        this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.ImageUploaded'),
                        }).then(() => {
                            this.getData()
                        });

                    }

                },
                error => {
                    console.log((error.response && error.response.data) || error.message || error.toString())
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Alerts.ErrorUploading')
                        });
                })
        },
        deleteElement(){
            console.log(this.selectedElement)
            MultimediaBlogDataService.deleteTutorialImage(this.selectedElement.id).then(
                    async response => {
                        this.$swal.fire({
                            icon: 'info',
                            text:this.$t('Multimedia.InfoDelete')
                        }).then(() => { 
                            this.$swal.fire({
                            icon: 'success',
                            title: this.$t('Alerts.ImageDeleted'),
                            text: ''
                            }).then(() => {
                                this.modals.delete = false
                                this.getData()
                            });
                        })
                    },
                    error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.message
                        })
                })
        }
}}
</script>
<style scoped>
input[type="file"] {
    display: none;
}

.uploadBox {
    background-color: transparent !important;
    width: 100% !important;
}

.uploadBox .dropArea {
    border: 4px dashed #80808066 !important;
}

.uploadBox h3 {
    padding-top: 0em !important;
    font-size: 24px !important;
}

.dropzone{
    width: 100%;
    margin: 3%;
}

.cropper {
	width: 90%;
	background: #DDD;
    margin: auto;
}

.full-hd-alert{
    margin-left: 3%;
    margin-top: 1%;
    border: 1px solid #00000012;
    padding: 15px;
    border-radius: 18px;
    background-color: #5c9f5c1f;
    width: 94%;
}
.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}

</style>