downloadCSV<template>
  <div class="wrapper dashboard-wrapper collapsed-sidebar">
    <b-container class="container" style="margin-top: 5%; max-width: 94%;">
      <b-row class="page-header-bs">
        <b-col style="display: flex">
          <h2 class="card-title">
            {{ $t("ExternalOrganizations.Title") }}
          </h2> 
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12">
          <card>
            <b-row>
              <b-col >
                <h6 class="titleOrg">{{ $t("ExternalOrganizations.List") }}</h6>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col>
                <b-row class="justify-content-end align-items-end">
                  <b-col cols="12" sm="6" md="3" class="d-flex align-items-center" id="searchOrg">
                    <input type="search" placeholder="Search" class="form-control mr-2" style="max-height: 50px;" v-model="filter">
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="d-flex align-items-center" id="checkAll">
                    <b-checkbox id="newAdmin" v-model="selectAllChecked" @change="selectAll(selectAllChecked)">
                      {{$t('Utils.SelectAll')}}
                    </b-checkbox>
                  </b-col>
                  <b-col cols="12" sm="6" md="3" id="visibilityDropDown">
                    <b-form-group label="Visibility" class="mb-0">
                      <b-form-select v-model="visibilityFilter" style="font-size: 12px;">
                        <b-form-select-option value="null" append-top>{{ $t("ExternalOrganizations.All") }}</b-form-select-option>
                        <b-form-select-option value="active">{{ $t("ExternalOrganizations.Active") }}</b-form-select-option>
                        <b-form-select-option value="inactive">{{ $t("ExternalOrganizations.Inactive") }}</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="d-flex align-items-center" id="buttonDownload">
                    <n-button class="btn-simple ncm-btn m-auto" target="_blank" @click="downloadSelectedOrganizationsCSV">
                      <b-icon icon="file-earmark-arrow-down-fill" class="button-icon"></b-icon>
                      {{ $t("Parameters.Download") }}
                    </n-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table ref="ecosystemTable" 
                    id="ecosystemTable"
                    class="dashboard-table"
                    :fields="headers" 
                    :items="filteredOrgs"
                    select-mode="single"
                    show-empty
                    @row-selected="selectRow"
                    @sort-changed="sortingChanged"
                    :current-page="currentPage"
                    :per-page="perPage"
                    responsive="sm"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :tbody-tr-class="applyRowClass"
                    sort-by="createdAt"
                    :sort-desc="true"
                    @filtered="onFiltered"> 
                    <template #empty="">
                      {{ $t("ExternalOrganizations.NoOrgs") }}
                    </template>
                    <template #cell(phone)="data">
                        <b-button v-if="data.item.phone != null && data.item.phone != undefined && data.item.phone != ''" @click="copyToClipboard(data.item.phone)" class="action-table" v-b-tooltip.hover.viewport :title=data.item.phone>
                            <b-icon icon="telephone"></b-icon>
                        </b-button>
                        <b-button v-else class="action-table">
                            <p style=" margin: 0">N/A</p>
                        </b-button>
                    </template>
                    <template #cell(options)="data">
                        <b-button class="action-table" v-b-tooltip.hover.viewport title="Assign tokens" @click="openEditModal(data.item)">
                            <b-icon icon="coin"></b-icon>
                        </b-button>
                        <b-button class="action-table" v-b-tooltip.hover.viewport title="See more info" @click="openInfo(data.item)">
                            <b-icon icon="eye"></b-icon>
                        </b-button>
                        <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Delete')" @click="openDeleteModal(data.item)" hidden>
                            <b-icon icon="trash"></b-icon>
                        </b-button>
                    </template>
                    <template #cell(is_active)="data">
                        <b-button v-if="data.item.is_active" class="action-table" v-b-tooltip.hover.viewport title="Click to disable" @click="disableOrg(data.item)">
                            <b-icon icon="toggle-on"></b-icon>
                        </b-button>
                        <b-button v-else class="action-table" v-b-tooltip.hover.viewport title="Click to enable" @click="enableOrg(data.item)">
                          <b-icon icon="toggle-off"></b-icon>                              
                        </b-button>
                    </template>
                    <template #cell(is_selected)="data">
                        <b-checkbox v-model="data.item.is_selected" checked></b-checkbox>
                    </template>
                    <template #cell(createdAt)="data">
                        {{ data.item.createdAt | formatDate }}
                    </template>
                    <template #cell(updatedAt)="data">
                        {{ data.item.updatedAt | formatDate }}
                    </template>
                    <template #row-details="row" v-if="selectedElement" >
                        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
                        <b-row>
                            <b-col>
                                <div class="small-box admin-small-box bg-success" style="cursor: pointer !important; background-color: #a3a0a0!important;" @click.prevent="showMemberList(row.item.external_users)">
                                    <div class="inner">
                                        <h3 v-if="row.item.external_users != null" class="increase-count">{{row.item.external_users.length}}</h3>
                                        <h3 v-else class="increase-count"> ---</h3>
                                        <a >
                                            <p>{{$t('DashBoard.Members')}}</p>
                                        </a>
                                    </div>
                                    <div class="icon">
                                        <a >
                                            <i class="fa fa-users"/>
                                        </a>
                                    </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="small-box admin-small-box bg-success" style="cursor: pointer !important; background-color: #a3a0a0!important;" @click="showLayerList(row.item.organization_layers)">
                                    <div class="inner">
                                        <h3 v-if="row.item.organization_layers" class="increase-count">{{row.item.organization_layers.length}}</h3>
                                        <h3 v-else class="increase-count">---</h3>
                                        <a >
                                            <p>{{$t('DashBoard.Layers')}}</p>
                                        </a>
                                    </div>
                                    <div class="icon">
                                        <a >
                                            <i class="fa fa-align-justify"/>
                                        </a>
                                    </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="small-box admin-small-box bg-success" style="cursor: pointer !important; background-color: #a3a0a0!important;" @click="showProjectsList(row.item.projects)">
                                    <div class="inner">
                                        <h3 v-if="row.item.projects" class="increase-count">{{row.item.projects.length}}</h3>
                                        <h3 v-else class="increase-count">---</h3>
                                        <a >
                                            <p>{{$t('DashBoard.Projects')}}</p>
                                        </a>
                                    </div>
                                    <div class="icon">
                                        <a >
                                            <i class="fa fa-cubes" />
                                        </a>
                                    </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="small-box admin-small-box bg-success"  style="cursor: pointer !important; background-color: #a3a0a0!important;">
                                    <div class="inner">
                                      <h3 v-if="row.item.reports" class="increase-count">{{row.item.reports}}</h3>
                                      <h3 v-else class="increase-count">---</h3>
                                        <a >
                                          <p>{{$t('DashBoard.Reports')}}</p>
                                        </a>
                                    </div>
                                    <div class="icon">
                                        <a >
                                            <i class="fa fa-envelope" />
                                        </a>
                                    </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="small-box admin-small-box bg-success"  style="cursor: pointer !important; background-color: #a3a0a0!important;" @click.prevent="showTransferList(row.item.id)">
                                    <div class="inner">
                                      <h3 class="increase-count">{{row.item.balance}}</h3>
                                        <a>
                                          <p>{{$t('AddProject.Form.TokensAvailable')}}</p>
                                        </a>
                                    </div>
                                    <div class="icon">
                                        <a >
                                          <b-icon icon="coin"></b-icon>
                                        </a>
                                    </div>
                                </div>
                            </b-col>
                          </b-row>
                        </b-container>
                    </template>
                </b-table>
                <div class="pagination-container">
                    <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                    </b-pagination>
                    <b-form-select v-model="perPage" class="pagination-select">
                        <b-form-select-option :value="5">5</b-form-select-option>
                        <b-form-select-option :value="10">10</b-form-select-option>
                        <b-form-select-option :value="20">20</b-form-select-option>
                        <b-form-select-option
                            :value="organizations.length">{{ $t('ExternalOrganizations.All') }}</b-form-select-option>
                    </b-form-select>
                </div>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
    </b-container>

  <!-- Modal assign token  -->
  <modal :show.sync="modals.modify" header-classes="justify-content-center">
    <h4 slot="header" class="title title-up">
      {{ $t("ExternalOrganizations.Tokens") }}
    </h4>
    <b-form-group label="Tokens *">
        <b-form-input v-if="selectedElement"
          id="input-token"
          name="input-token"
          v-validate="{ required: true}"
          :state="validateState('input-token')"
          aria-describedby="input-token-live-feedback"
          size="sm"
          placeholder="Insert tokens"
          v-model="tokens_to_add"
        ></b-form-input>
        <b-form-invalid-feedback id="input-token-live-feedback">{{ veeErrors.first('input-token') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Description *">
        <b-form-input v-if="selectedElement"
          id="input-description-token"
          name="input-description-token"
          v-validate="{ required: true}"
          :state="validateState('input-description-token')"
          aria-describedby="input-description-token-live-feedback"
          size="sm"
          placeholder="Insert description"
          v-model="description_to_add_tokens"
        ></b-form-input>
        <b-form-invalid-feedback id="input-description-token-live-feedback">{{ veeErrors.first('input-description-token') }}</b-form-invalid-feedback>
    </b-form-group>
    <template slot="footer">
      <n-button class="btn-simple ncm-btn" @click="updateElement()">
        {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
      </n-button>
      <n-button class="btn-simple ncm-btn-danger" @click.native="modals.modify = false">
        {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
      </n-button>
    </template>
  </modal>
    <!-- Modal delete  -->
    <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
      <p>{{ $t("ExternalOrganizations.Delete") }}</p>
      <template slot="footer">
        <n-button type="neutral" link @click="deleteElement">
          {{ $t("Languages.Modals.Delete.Footer.Save") }}
        </n-button>
        <n-button type="neutral" link @click.native="modals.delete = false">
          {{ $t("Languages.Modals.Delete.Footer.Close") }}
        </n-button>
      </template>
    </modal>
    <modal :show.sync="modals.listLayers" :show-close="false" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("Layers") }}
      </h4>
      <b-table
        :fields="headersLayers"
        :items="organization_layers_selected"
        responsive="sm"
        striped
        hover
        show-empty
        :per-page="perPageLayers"
        :current-page="currentPageLayers">
        <template #empty="">
          {{ $t("ExternalOrganizations.NoLayers") }}
        </template>
      </b-table>
      <div style="display: flex">
        <b-pagination
          v-model="currentPageLayers"
          :total-rows="totalPagesLayers"
          :per-page="perPageLayers">
        </b-pagination>
        <b-form-select v-model="perPageProjects" class="pagination-select" style="min-width: 100px !important">
          <b-form-select-option :value="5">5</b-form-select-option>
          <b-form-select-option :value="10">10</b-form-select-option>
          <b-form-select-option :value="20">20</b-form-select-option>
          <b-form-select-option :value="organization_layers_selected.length">{{ $t("ExternalOrganizations.All") }}</b-form-select-option>
        </b-form-select>
      </div>
      <template slot="footer">
        <n-button v-if="organization_layers_selected.length > 0" class="btn-simple ncm-btn" type @click.native="downloadLayers()">
          {{ $t("Download") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.listLayers = false">
          {{ $t("Close") }}
        </n-button>
      </template>
    </modal>
    <modal :show.sync="modals.listProjects" :show-close="false" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("Projects.Title") }}
      </h4>
      <b-table
        :fields="headersProjects"
        :items="organization_projects_selected"
        responsive="sm"
        striped
        hover
        show-empty
        :per-page="perPageProjects"
        :current-page="currentPageProjects">
        <template #empty="">
          {{ $t("ExternalOrganizations.NoProjects") }}
        </template>
        <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
        </template>
      </b-table>
      <div style="display: flex">
        <b-pagination
          v-model="currentPageProjects"
          :total-rows="totalPagesProjects"
          :per-page="perPageProjects">
        </b-pagination>
        <b-form-select v-model="perPageProjects" class="pagination-select" style="min-width: 100px !important">
          <b-form-select-option :value="5">5</b-form-select-option>
          <b-form-select-option :value="10">10</b-form-select-option>
          <b-form-select-option :value="20">20</b-form-select-option>
          <b-form-select-option :value="organization_projects_selected.length">{{ $t("ExternalOrganizations.All") }}</b-form-select-option>
        </b-form-select>
      </div>
      <template slot="footer">
        <n-button v-if="organization_projects_selected.length > 0" class="btn-simple ncm-btn" type @click.native="downloadProjects()">
          {{ $t("Download") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.listProjects = false">
          {{ $t("Close") }}
        </n-button>
      </template>
    </modal>
    <modal :show.sync="modals.listMembers" :show-close="false" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("Members") }}
      </h4>
      <b-table
        :fields="headersMembers"
        :items="organization_members_selected"
        responsive="sm"
        striped
        hover
        show-empty
        :per-page="perPageMembers"
        :current-page="currentPageMembers">
        <template #empty="">
          {{ $t("ExternalOrganizations.NoMembers") }}
        </template>
      </b-table>
      <div style="display: flex">
        <b-pagination
          v-model="currentPageMembers"
          :total-rows="totalPagesMembers"
          :per-page="perPageMembers">
        </b-pagination>
        <b-form-select v-model="perPageMembers" class="pagination-select" style="min-width: 100px !important">
          <b-form-select-option :value="5">5</b-form-select-option>
          <b-form-select-option :value="10">10</b-form-select-option>
          <b-form-select-option :value="20">20</b-form-select-option>
          <b-form-select-option :value="organization_members_selected.length">{{ $t("ExternalOrganizations.All") }}</b-form-select-option>
        </b-form-select>
      </div>
      <template slot="footer">
        <n-button v-if="organization_members_selected.length > 0" class="btn-simple ncm-btn" type @click.native="downloadMembers()">
          {{ $t("Download") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.listMembers = false">
          {{ $t("Close") }}
        </n-button>
      </template>
    </modal>
    <modal :show.sync="modals.listTrasnfer" :show-close="false" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("ExternalOrganizationsTransfers.Title") }}
      </h4>
      <b-table
        :fields="headersTokens"
        :items="organization_history_selected"
        responsive="sm"
        striped
        hover
        show-empty
        :per-page="perPageTokens"
        :current-page="currentPageTokens">
        <template #empty="">
          {{ $t("ExternalOrganizations.NoTransactions") }}
        </template>
        <template #cell(created_at)="data">
            {{ data.item.created_at | formatDate }}
        </template>
      </b-table>
      <div style="display: flex">
        <b-pagination
          v-model="currentPageTokens"
          :total-rows="totalPagesTokens"
          :per-page="perPageTokens">
        </b-pagination>
        <b-form-select v-model="perPageTokens" class="pagination-select" style="min-width: 100px !important">
          <b-form-select-option :value="5">5</b-form-select-option>
          <b-form-select-option :value="10">10</b-form-select-option>
          <b-form-select-option :value="20">20</b-form-select-option>
          <b-form-select-option :value="organization_history_selected.length">{{ $t("ExternalOrganizations.All") }}</b-form-select-option>
        </b-form-select>
      </div>
      <template slot="footer">
        <n-button v-if="organization_history_selected.length > 0" class="btn-simple ncm-btn" type @click.native="downloadTransactions()">
          {{ $t("Download") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.listTrasnfer = false">
          {{ $t("Close") }}
        </n-button>
      </template>
    </modal>
  </div>
</template>
<script>
  import { Badge, Button, InfoSection,Modal, FormGroupInput, Card, Tabs, TabPane, Checkbox, DropDown} from '@/components';
  import { API_KEY } from '@/constants'
  import {Table, TableColumn, Tooltip} from 'element-ui';
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  });

  import OrganizationsDataService from '@/services/OrganizationsDataService'
  import WalletDataServices from '../../services/WalletDataServices';
  import ProjectsDataService from '@/services/ProjectsDataService'
  import EcosystemServiceDataService from '@/services/EcosystemServiceDataService'
  import ParameterDataService from '@/services/ParameterDataService'
  import EcosystemServiceParameterDataService from '@/services/EcosystemServiceParameterDataService'

export default {
name: 'External Organizations',
bodyClass: 'profile-page',
components: {
    Card,
    Modal,
    [Checkbox.name]: Checkbox,
    [DropDown.name]: DropDown,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [FormGroupInput.name]: FormGroupInput,
},
data() {
    return {
        modals: {
            modify: false,
            delete: false,
            create: false,
            listMembers: false,
            listLayers: false,
            listTrasnfer: false,
            listReports: false,
            listProjects: false,
        },
        tokens_to_add:null,
        organizations: [],
        selectedElement: null,
        visibilityFilter: null,
        organization_history_selected: [],
        organization_members_selected: [],
        organization_layers_selected: [],
        organization_projects_selected: [],
        organization_reports_selected: [],
        headers: [
          { key:'name',label: this.$t('Languages.Table.Name'), sortable: true },
          { key:'vat',label:'Vat', sortable: true },
          { key:'address1',label: this.$t('Languages.Table.Address'), sortable: true },
          { key:'country',label: this.$t('Languages.Table.Country'), sortable: true },
          { key:'phone',label: this.$t('Languages.Table.Phone') },
          { key:'is_active',label: this.$t('Languages.Table.Active'), sortable: true },
          { key:'is_selected',label: this.$t('SelectToDownload'), sortable: true },
          { key:'createdAt',label: this.$t('Parameters.Table.CreatedAt'), sortable: true },
          { key:'updatedAt',label:this.$t('Parameters.Table.UpdatedAt'), sortable: true },
          { key:'options', label: this.$t('Languages.Table.Actions'), thClass: 'position-sticky' }
        ],
        headersTokens: [
          { key:'type',label: this.$t('Type'), sortable: true },
          { key:'token_value',label: this.$t('Value'), sortable: true },
          { key:'created_at',label: this.$t('Parameters.Table.CreatedAt'), sortable: true },
        ],
        headersMembers: [
          { key:'name',label: this.$t('Name'), sortable: true },
          { key:'surname',label: this.$t('Surname'), sortable: true },
          { key:'phone',label: this.$t('Phone'), sortable: true },
          { key:'email',label: this.$t('Email'), sortable: true },
        ],
        headersLayers: [
          { key:'visible_name',label: this.$t('Name'), sortable: true },
          { key:'type',label: this.$t('Type'), sortable: true },
          { key:'projection',label: this.$t('Projection'), sortable: true },
        ],
        headersProjects: [
          { key:'name',label: this.$t('Name'), sortable: true },
          { key:'description',label: this.$t('Description'), sortable: true },
          { key:'createdAt',label: this.$t('Parameters.Table.CreatedAt'), sortable: true },
        ],
        headersReports: [
          { key:'name',label: this.$t('Name'), sortable: true },
          { key:'description',label: this.$t('Description'), sortable: true },
          { key:'createdAt',label: this.$t('Parameters.Table.CreatedAt'), sortable: true },
        ],
        allOpenRows: [],
        currentPage: 1,
        currentPageTokens: 1,
        perPage: 10,
        perPageTokens: 10,
        currentPageMembers: 1,
        perPageMembers: 10,
        currentPageProjects: 1,
        perPageProjects: 10,
        currentPageReports: 1,
        currentPageLayers: 1,
        perPageLayers: 10,
        perPageReports: 10,
        filterOrgs: '',
        filter: null,
        filterOn: [],
        selectAllChecked: false,
        description_to_add_tokens: null,
        // toggleInfo: false,
    }
},
computed: {
      getOrganizations() {
          return this.organizations
      },
      totalPages () {
          return this.getOrganizations.length
      },
      totalPagesTokens () {
        if (this.organization_history_selected == null) {
          this.organization_history_selected = []
        }
          return this.organization_history_selected.length
      },
      totalPagesMembers () {
        if (this.organization_members_selected == null) {
          this.organization_members_selected = []
        }
          return this.organization_members_selected.length
      },
      totalPagesProjects () {
        if (this.organization_projects_selected == null) {
          this.organization_projects_selected = []
        }
          return this.organization_projects_selected.length
      },
      totalPagesReports () {
        if (this.organization_reports_selected == null) {
          this.organization_reports_selected = []
        }
          return this.organization_reports_selected.length
      },
      totalPagesLayers () {
        if (this.organization_layers_selected == null) {
          this.organization_layers_selected = []
        }
          return this.organization_layers_selected.length
      },
      // function to filter orgs with the text field
      searchOrgs() {
          return this.headers.filter((item) => {
              return item.filterOrgs.toLowerCase().includes(this.filterOrgs.toLowerCase()) || 
              item.filterOrgs.toLowerCase().includes(this.filterOrgs.toLowerCase()) ||
              item.name.toLowerCase().includes(this.filterOrgs.toLowerCase()) ||
              item.address1.toLowerCase().includes(this.filterOrgs.toLowerCase());
          });
      },
      filteredOrgs() {
            if (this.visibilityFilter === null || this.visibilityFilter === 'null') {
                return this.organizations; // Return all invitations if no status is selected
            }else if(this.visibilityFilter === 'active'){
                console.log(this.organizations);
                return this.organizations.filter(item =>(item.is_active === true));
            }else if(this.visibilityFilter === 'inactive'){
                return this.organizations.filter(item =>(item.is_active === false));
            }
            // return this.invitations.filter(invitation => invitation.status === this.visibilityFilter);
        },
      // sortOptions() {
      //   // Create an options list from our fields
      //   return this.headers
      //     .filter(f => f.sortable)
      //     .map(f => {
      //       return { text: f.label, value: f.key }
      //     })
      // }
    },
created() {
  this.getData()
},
methods:{
  downloadMembers() {
    const csvRows = [];
    const headers = ['NAME', 'SURNAME','PHONE', 'EMAIL'];
    csvRows.push(headers.join(';'));
    this.organization_members_selected.forEach((member) => {
      const rowData = [
        member.name,
        member.surname,
        member.phone,
        member.email,
      ];
      csvRows.push(rowData.join(';'));
    });
    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'Members.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  downloadLayers() {
    const csvRows = [];
    const headers = ['NAME', 'TYPE','PROJECTION'];
    csvRows.push(headers.join(';'));
    this.organization_layers_selected.forEach((member) => {
      const rowData = [
        member.visible_name,
        member.type,
        member.projection,
      ];
      csvRows.push(rowData.join(';'));
    });
    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'Layers.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  downloadReports() {
    // const csvRows = [];
    // const headers = ['NAME', 'DESCRIPTION','CREATED_AT'];
    // csvRows.push(headers.join(';'));
    // this.layers_organization_selected.forEach((member) => {
    //   const rowData = [
    //     member.name,
    //     member.description,
    //     member.createdAt
    //   ];
    //   csvRows.push(rowData.join(';'));
    // });
    // const csvContent = csvRows.join('\n');
    // const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    // const link = document.createElement('a');
    // if (link.download !== undefined) {
    //   const url = URL.createObjectURL(blob);
    //   link.setAttribute('href', url);
    //   link.setAttribute('download', 'Projects.csv');
    //   link.style.visibility = 'hidden';
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // }
  },
  downloadProjects() {
    const csvRows = [];
    const headers = ['NAME', 'DESCRIPTION','CREATED_AT'];
    csvRows.push(headers.join(';'));
    this.organization_projects_selected.forEach((member) => {
      console.log(member);
      const rowData = [
        member.name,
        member.description,
        member.createdAt
      ];
      csvRows.push(rowData.join(';'));
    });
    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'Projects.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  downloadSelectedOrganizationsCSV() {
    const csvRows = [];
    const headers = ['NAME', 'VAT', 'ADDRESS', 'COUNTRY', 'PHONE', 'IS_ACTIVE', 'CREATED_AT', 'UPDATED_AT'];
    csvRows.push(headers.join(';'));

    this.organizations.filter(org => org.is_selected).forEach((organization) => {
      const rowData = [
        organization.name,
        organization.vat,
        organization.address1,
        organization.country,
        organization.phone,
        organization.is_active,
        organization.createdAt,
        organization.updatedAt
      ];
      csvRows.push(rowData.join(';'));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'SelectedOrganizations.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  downloadTransactions() {
    const csvRows = [];
    const headers = ['ID', 'TYPE', 'VALUE', 'CREATED_AT']; 
    csvRows.push(headers.join(';'));

    this.organization_history_selected.forEach((transaction) => {
      const rowData = [
        transaction.id,
        transaction.type,
        transaction.token_value,
        transaction.created_at,
      ];
      csvRows.push(rowData.join(';'));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'Transactions.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  selectAll(checked) {
    this.organizations.forEach((org, index) => {
      this.$set(this.organizations[index], 'is_selected', checked);
    });
    this.$forceUpdate(); 
  },
  showMemberList(members) {
    this.organization_members_selected = members
    this.modals.listMembers = true
  },
  showLayerList(layers) {
    this.organization_layers_selected = layers
    this.modals.listLayers = true
  },
  showProjectsList(layers) {
    this.organization_projects_selected = layers
    this.modals.listProjects = true
  },
  showReportsList(layers) {
    this.organization_reports_selected = layers
    this.modals.listReports = true
  },
  showTransferList(id) {
    WalletDataServices.findOne(id).then(
      Response => {
        this.organization_history_selected = Response.data.data.wallet_movements
        console.log(Response.data.data);

      },
      Error => {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: Error.response.data.message
        })
      }
    )
    this.modals.listTrasnfer = true
    
  },
  copyToClipboard(text) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.$swal.fire({
        icon: 'success',
        text: this.$t('Ecosystems.Copied')
    }) 
  },
  applyRowClass(row) {
      if (row != null) {
        if (!row.is_active) {
        return 'disabled-row';
      } else {
        return '';
      }
      }
    },  
  disableOrg (org){
    OrganizationsDataService.disable(org.id).then(
      Response => {
        console.log(Response)
        this.getData()
      },
      Error => {
        this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: Error.response.data.message
        })
      } 
    )
  },
  enableOrg (org){
    console.log(org)
    OrganizationsDataService.enable(org.id).then(
      Response => {
        this.getData()
      },
      Error => {
        this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: Error.response.data.message
        })
      } 
    )
  },
  onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length
          this.currentPage = 1
  },
  selectRow(items){
    console.log('selected:', items)
    this.selectedElement = items[0]
  },
  validateState (ref) {
    if (
      this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref)
      }
      return null
  },
  sortingChanged(item){
      this.allOpenRows.map((ele) => {
          if (ele.id !== item.id && ele._showDetails) {
          this.$set(ele, '_showDetails', !ele._showDetails)
          }
      })
  },
  handleRowClicked (item, index) {
      this.allOpenRows.map((ele) => {
          if (ele.id !== item.id && ele._showDetails) {
          this.$set(ele, '_showDetails', !ele._showDetails)
          }
      })
      this.allOpenRows = []
      this.$set(item, '_showDetails', !item._showDetails)
      this.allOpenRows.push(item)
  },
  getData(){
      OrganizationsDataService.findAll().then(
          Response => {
              this.organizations = Response.data.data
              this.organizations.forEach((org) => {
                org.is_selected = false
              });
          },
          Error => {
            this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: Error.response.data.message
        })
          }
      )
  },
  updateElement(){
      this.$validator.validateAll(['input-token','input-description-token']).then(result => {
          if (!result) {
              return
          }
          let data = {
              organization_id: this.selectedElement.id,
              level: 'organization',
              description: this.description_to_add_tokens,
              token_value: this.tokens_to_add,
              stripe_token_uuid: 'abcd',
          }
          WalletDataServices.reloadTokens(data).then(
              Response => {
                  this.$swal.fire({
                              icon: 'success',
                              title: this.$t('Alerts.TokensAddedSuccessfully'),
                              text: this.$t('Alerts.AddedToBalance')
                          }).then(() => {
                              this.modals.modify = false
                              this.getData()
                          });

              },
              Error => {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: Error.response.data.message
                })
              }
          )
      })
  },
  openCreateModal(){
    // Reset form
    this.name_form = ''
    this.code_form = ''
    this.$validator.reset(['input-label']);

    // Show form
    this.modals.create = true
  },
  openEditModal(element){
      console.log(element)
      this.selectedElement = {...element}
      this.modals.modify = true
  },
  openInfo(element){
      this.selectedElement = {...element}
      // this.toggleInfo = true
      console.log(element);
      this.handleRowClicked(element)
  },
  openDeleteModal(element){
      console.log(element)
      this.selectedElement = element
      this.modals.delete = true
  },
  deleteElement(){
      console.log(this.selectedElement)
      OrganizationsDataService.delete(this.selectedElement.id).then(
          async response => {
              console.log(response.data)
              this.modals.delete = false
              this.$swal.fire({
                icon: 'success',
                title: this.$t('Alerts.OrganizationDeleted'),
                text: ''
                }).then(() => {
                  this.getData()
                });
          },
          error => {
            this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.message
            })
          }
      )
  }
}
}
</script>
<style>
.disabled-row {
  background-color: #f8f8f8;
  color: #999999;
}

@media (max-width: 465px) {
  .titleOrg {
    text-align: center;
  }

  .buttonOrg {
    float: center;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}

@media (max-width: 575px){
  #searchOrg{
    margin-top: 10px;
  }
  #checkAll{
    margin-top: 10px;
  }
  #visibilityDropDown{
    margin-top: 10px;
  }
  #buttonDownload{
    margin-top: 20px;
  }
}
</style>