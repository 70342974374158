import http from "../http-common";
const API_URL_PATH = "api/cookie-settings/";
import authHeader from './AuthHeaderService'

class SettingsService {
    findAll() {
        return http.get (API_URL_PATH + 'find-settings', {headers: authHeader()})
    }
    findOne(id) {
        return  http.get (API_URL_PATH + 'find-setting',{id:id},{headers: authHeader()})
    }
  
    findCookies() {
        return http.get (API_URL_PATH + 'find-cookies')
    }
  
    create (cookie, language) {
        const body = {
            main_cookie: cookie.main_cookie,
            session_cookie: cookie.session_cookie,
            other_cookie: cookie.other_cookie,
            multimedia_cookie: cookie.multimedia_cookie,
            language_id: language
        }
        
        console.log(body)
        return http.post (API_URL_PATH + 'add-setting',body,{headers: authHeader()})
    }

    update (cookie) {
        const body = {
            id: cookie.id,
            main_cookie: cookie.main_cookie,
            session_cookie: cookie.session_cookie,
            other_cookie: cookie.other_cookie,
            multimedia_cookie: cookie.multimedia_cookie,
            language_id: cookie.language_id
        }
        return http.put(API_URL_PATH + 'update-setting',body,{headers: authHeader()})
    }
    
}
export default new SettingsService()
