<template>
    <div class="wrapper  dashboard-wrapper collapsed-sidebar">
        <div class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                        {{ $t("Profile.Title") }}
                    </h2> 
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="6" lg="4">
                    <card type="profile" class="mt-0">
                        <div>
                            <div class="card-avatar">
                                <template v-if="editingAvatar">
                                    <img v-if="imagePathEditing == null"
                                        class="img img-raised"
                                        :src="$getImgUrlSite('default-avatar.png')"
                                    >
                                    <img class="img img-raised" :src="imagePathEditing">
                                </template>
                                <template v-else>
                                    <img v-if="imagePath == null"
                                        class="img img-raised"
                                        :src="$getImgUrlSite('default-avatar.png')"
                                    >
                                    <img class="img img-raised" v-else :src="getImgUrl(imagePath)">
                                </template>
                            </div>
                            <h6 class="card-category">
                                <span v-if="$store.state.auth.user.data.is_superadmin">SUPERADMIN</span>
                                <span v-else>STAFF</span>
                            </h6>
                            <h4 class="card-title">
                                {{this.copyUser.name}}  {{this.copyUser.surname}}
                            </h4>
                            <h5 class="card-title">
                                {{this.copyUser.email}}
                            </h5>
                            <div class="card-footer">
                                <n-button id="newAdmin" class="btn-simple ncm-btn" target="_blank" @click.native="openFileInput()">
                                    <b-icon icon="cloud-arrow-up-fill" class="button-icon"></b-icon>
                                    {{ $t("Profile.Image.Upload") }}
                                </n-button>
                                <input ref="fileInput" type="file" name="profile-images" @change="setImage">
                                <n-button id="newAdmin" class="btn-simple ncm-btn-danger" target="_blank" @click.native="deleteImage()">
                                    <b-icon icon="trash-fill" class="button-icon"></b-icon>
                                    {{ $t("Profile.Image.Delete") }}
                                </n-button>
                                <template v-if="editingAvatar">
                                    <n-button type="primary" class="btn-simple btn-round btn-icon icon-title-dashboard"
                                            v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Save')" @click.native="updateAvatar()">
                                        <b-icon icon="check-lg"></b-icon>
                                    </n-button>
                                    <n-button type="danger" class="btn-simple btn-icon icon-title-dashboard"
                                            v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Cancel')" @click.native="toggleEditCancelAvatar()">
                                        <b-icon icon="x-lg"></b-icon>
                                    </n-button>
                                </template>
                            </div>
                        </div>
                    </card>
                    <card>
                        <b-row>
                            <b-col cols="12">
                                <h3 class="category text-primary" style="float: left;">
                                    <b-icon icon="lock-fill"></b-icon> {{ $t("Profile.Security") }}
                                </h3>
                                <template v-if="changePass">
                                    <n-button type="primary" class="btn-simple btn-round btn-icon icon-title-dashboard"
                                            v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Save')" @click="editPassword()">
                                        <b-icon icon="check-lg"></b-icon>
                                    </n-button>
                                    <n-button type="danger" class="btn-simple btn-icon icon-title-dashboard"
                                            v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Cancel')" @click="toggleEditCancelPass()">
                                        <b-icon icon="x-lg"></b-icon>
                                    </n-button>
                                </template>
                            </b-col>
                        </b-row>
                        <template v-if="!changePass">
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group style="font-weight: 600;">
                                        <div>
                                            <n-button id="newAdmin" class="btn-simple ncm-btn" target="_blank" @click.native="openChangePass()">
                                                <b-icon icon="key-fill" class="button-icon"></b-icon>
                                                {{ $t("Profile.ChangePassword") }}
                                            </n-button>
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </template>
                        <template v-else>
                            <b-row>
                                <b-col cols="10">
                                    <b-form-group :label=" $t('Profile.Labels.CurrentPassword')">
                                        <div class="d-flex">
                                            <b-form-input
                                                v-model="dataPass.currentPassword"
                                                id="input-dataPass-currentPassword"
                                                name="input-dataPass-currentPassword"
                                                v-validate="{ required: true}"
                                                type="password"
                                                aria-describedby="input-dataPass-currentPassword-live-feedback"
                                                :placeholder="$t('DashBoardStaff.Modals.NewStaff.Password')"
                                                class="no-border input-lg"
                                                :state="validateState('input-dataPass-currentPassword')"
                                            >
                                            </b-form-input>
                                        </div>
                                        <b-form-invalid-feedback id="input-dataPass-currentPassword-live-feedback">
                                            {{ veeErrors.first('input-dataPass-currentPassword') }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="2" class="d-flex show-pass-col" style="margin-top: 2% !important;">
                                    <span v-if="!variablePass" class="iconPass m-auto" @click="showPass()">
                                        <b-icon icon="eye" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon>
                                    </span>
                                    <span v-if="variablePass" class="iconPass m-auto" @click="showPass()">
                                        <b-icon icon="eye-slash" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon>
                                    </span>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="10">
                                    <b-form-group :label=" $t('Profile.Labels.Password')">
                                        <div class="d-flex">
                                            <b-form-input
                                                v-model="dataPass.newPassword"
                                                id="input-dataPass-newPassword"
                                                name="input-dataPass-newPassword"
                                                v-validate="{ required: true}"
                                                type="password"
                                                aria-describedby="input-dataPass-newPassword-live-feedback"
                                                :placeholder="$t('DashBoardStaff.Modals.NewStaff.Password')"
                                                class="no-border input-lg"
                                                :state="validateState('input-dataPass-newPassword')"
                                            >
                                            </b-form-input>
                                        </div>
                                        <b-form-invalid-feedback id="input-dataPass-newPassword-live-feedback">
                                            {{ veeErrors.first('input-dataPass-newPassword') }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="2" class="d-flex show-pass-col" style="margin-top: 2% !important;">
                                    <span v-if="!variablePass1" class="iconPass m-auto" @click="showPass1()">
                                        <b-icon icon="eye" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon>
                                    </span>
                                    <span v-if="variablePass1" class="iconPass m-auto" @click="showPass1()">
                                        <b-icon icon="eye-slash" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon>
                                    </span>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="10">
                                    <b-form-group :label=" $t('Profile.Labels.RepeatPassword')">
                                        <div class="d-flex">
                                            <b-form-input
                                                v-model="dataPass.newPasswordRepeat"
                                                id="input-dataPass-newPasswordRepeat"
                                                name="input-dataPass-newPasswordRepeat"
                                                v-validate="{ required: true}"
                                                type="password"
                                                aria-describedby="input-dataPass-newPasswordRepeat-live-feedback"
                                                :placeholder="$t('DashBoardStaff.Modals.NewStaff.Password')"
                                                class="no-border input-lg"
                                                :state="validateState('input-dataPass-newPasswordRepeat')"
                                            >
                                            </b-form-input>
                                        </div>
                                        <b-form-invalid-feedback id="input-dataPass-newPasswordRepeat-live-feedback">
                                            {{ veeErrors.first('input-dataPass-newPasswordRepeat') }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="2" class="d-flex show-pass-col" style="margin-top: 2% !important;">
                                    <span v-if="!variablePass2" class="iconPass m-auto" @click="showPass2()">
                                        <b-icon icon="eye" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon>
                                    </span>                                    
                                    <span v-if="variablePass2" class="iconPass m-auto" @click="showPass2()">
                                        <b-icon icon="eye-slash" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon>
                                    </span>
                                </b-col>
                            </b-row>
                        </template>
                    </card>
                </b-col>
                <b-col cols="12" md="8">
                <card>
                    <b-row>
                    <b-col>
                        <h3 style="float: left;" class="category text-primary">
                        <b-icon icon="file-person-fill" class="mr-2" />{{ $t("Profile.PersonalInfo") }}
                        </h3>
                        <template v-if="!editPersonalInfo">
                        <n-button type="primary" class="btn-simple btn-icon icon-title-dashboard" @click.native="toggleEdit()">
                            <b-icon icon="pen"></b-icon>
                        </n-button>
                        </template>
                        <template v-else>
                        <n-button type="primary" class="btn-simple btn-round btn-icon icon-title-dashboard" v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Save')" @click.native="editPersonalInformation()">
                            <b-icon icon="check-lg"></b-icon>
                        </n-button>
                        <n-button type="danger" class="btn-simple btn-icon icon-title-dashboard" v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Cancel')" @click.native="toggleEditCancel()">
                            <b-icon icon="x-lg"></b-icon>
                        </n-button>
                        </template>
                    </b-col>
                    </b-row>
                    <b-row class="mb-4">
                    <template v-if="!editPersonalInfo">
                        <b-col cols="12" md="6">
                        <b-form-group :label="$t('Profile.Labels.Name')">
                            <b-form-input v-model="user.name" disabled></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('Profile.Labels.LastName')">
                            <b-form-input v-model="user.surname" disabled></b-form-input>
                        </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                        <b-form-group :label="$t('Profile.Labels.Phone')">
                            <b-form-input v-model="user.phone" disabled></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('Profile.Labels.Email')">
                            <b-form-input v-model="user.email" disabled></b-form-input>
                        </b-form-group>
                        </b-col>
                    </template>
                    <template v-else>
                        <b-col cols="12" md="6">
                        <b-form-group :label="$t('Profile.Labels.Name')">
                            <b-form-input v-model="user.name" id="input-dataPersonalInformation-name" name="input-dataPersonalInformation-name" v-validate="{ required: true, alpha_spaces: true }" :state="validateState('input-dataPersonalInformation-name')" aria-describedby="input-dataPersonalInformation-name-live-feedback" size="sm" :placeholder="$t('DashBoardStaff.Modals.Modify.Name')"></b-form-input>
                            <b-form-invalid-feedback style="margin-left: 1% !important;" id="input-dataPersonalInformation-name-live-feedback">{{ veeErrors.first('input-dataPersonalInformation-name') }}</b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group :label="$t('Profile.Labels.LastName')">
                            <b-form-input v-model="user.surname" id="input-dataPersonalInformation-surname" name="input-dataPersonalInformation-surname" v-validate="{ required: true, alpha_spaces: true }" :state="validateState('input-dataPersonalInformation-surname')" aria-describedby="input-dataPersonalInformation-surname-live-feedback" size="sm" :placeholder="$t('DashBoardStaff.Modals.Modify.Surname')"></b-form-input>
                            <b-form-invalid-feedback style="margin-left: 1% !important;" id="input-dataPersonalInformation-surname-live-feedback">{{ veeErrors.first('input-dataPersonalInformation-surname') }}</b-form-invalid-feedback>
                        </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                        <b-form-group :label="$t('Profile.Labels.Phone')">
                            <b-form-input v-model="user.phone" id="input-dataPersonalInformation-phone" name="input-dataPersonalInformation-phone" v-validate="{ required: true }" :state="validateState('input-dataPersonalInformation-phone')" aria-describedby="input-dataPersonalInformation-phone-live-feedback" size="sm" :placeholder="$t('DashBoardStaff.Modals.Modify.Phone')"></b-form-input>
                            <b-form-invalid-feedback style="margin-left: 1% !important;" id="input-dataPersonalInformation-phone-live-feedback">{{ veeErrors.first('input-dataPersonalInformation-phone') }}</b-form-invalid-feedback>
                        </b-form-group>
                        </b-col>
                    </template>
                    </b-row>
                </card>
                <card>
                    <b-row>
                    <b-col cols="12" md="6">
                        <h3 style="float: left;" class="category text-primary">
                        <b-icon icon="gear-wide-connected"></b-icon> {{ $t("Profile.AdvancedInfo") }}
                        </h3>
                    </b-col>
                    </b-row>
                    <template>
                    <b-row>
                        <b-col>
                        <b-form-group label="Favourites ★" style="font-weight: 600;" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <template v-if="!changeFav">
                        <b-col cols="12" md="5">
                            <b-form-group :label="$t('Profile.Labels.EcosystemServicesFav')">
                            <b-form-select style="margin-top: 8px;" v-model="favourite_ecosystem_service_category_type" :options="types" value-field="id" text-field="name" disabled></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="5">
                            <b-form-group :label="$t('Profile.Labels.EcosystemFav')">
                            <b-form-select style="margin-top: 8px;" v-model="favourite_ecosystem_category_type" :options="ecosystem_types" value-field="id" text-field="name" disabled></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2">
                            <b-form-group style="margin-top: 26px; margin-bottom: 0px;">
                            <n-button type="primary" class="btn-simple ncm-btn btn-icon" @click.native="openChangeFav()">
                                <b-icon icon="star-half" class="button-icon m-auto"></b-icon>
                            </n-button>
                            </b-form-group>
                        </b-col>
                        </template>
                        <template v-if="changeFav">
                        <b-col cols="12" md="5">
                            <b-form-group :label="$t('Profile.Labels.EcosystemServicesFav')">
                            <b-form-select style="margin-top: 8px;" v-model="favourite_ecosystem_service_category_type" :options="types" value-field="id" text-field="name"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="5">
                            <b-form-group :label="$t('Profile.Labels.EcosystemFav')">
                            <b-form-select style="margin-top: 8px;" v-model="favourite_ecosystem_category_type" :options="ecosystem_types" value-field="id" text-field="name"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2">
                            <b-form-group>
                            <div>
                                <n-button type="primary" class="btn-simple btn-round btn-icon icon-title-dashboard m-auto" v-b-tooltip.hover :title="$t('Profile.Tooltips.Save')" @click.native="addFav()">
                                <b-icon icon="check-lg"></b-icon>
                                </n-button>
                                <n-button type="danger" class="btn-simple btn-icon icon-title-dashboard m-auto" v-b-tooltip.hover :title="$t('Profile.Tooltips.Cancel')" @click.native="toggleEditCancelFav()">
                                <b-icon icon="x-lg"></b-icon>
                                </n-button>
                            </div>
                            </b-form-group>
                        </b-col>
                        </template>
                    </b-row>
                    </template>
                </card>
                </b-col>
            </b-row>
        </div>
    </div>
  </template>
  
  <script>
    const $ = require('jquery')
    // Lo declaramos globalmente
    window.$ = $
    import StaffServices from '../../services/StaffServices';
    import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal} from '@/components';
    import { API_KEY } from '@/constants'
    import {Table, TableColumn, Tooltip} from 'element-ui';
    import Vue from 'vue'
    import * as VueGoogleMaps from 'vue2-google-maps'
    import RoleServices from '../../services/RoleServices';
    import EcosystemsServicesTypes from '@/services/EcosystemServicesTypesDataService'
    import EcosystemTypesDataService from '@/services/EcosystemTypesDataService'

import Login from '../../services/Login';
    Vue.use(VueGoogleMaps, {
      load: {
        key: API_KEY
      }
    });
  export default {
    name: 'DashboardStaff',
    bodyClass: 'dashboard-staff',
    components: {
        Card,
        Modal,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Tooltip.name]: Tooltip,
        [FormGroupInput.name]: FormGroupInput,
    }, 
    data() {
        return {
            variablePass: false,
            variablePass1: false,
            variablePass2: false,
            user_id: null,
            user: {},
            copyUser: {},
            dataPass: {},
            // modals: {
            //     exit: false
            // },
            editPersonalInfo: false,
            changePass: false,
            changeFav: false,
            // myRole: [],
            // headersRole: [
            // { key: 'ecosystem_language_module', label: this.$t('DashBoardStaff.Table.EcosystemLanguage') },
            // { key: 'layer_module', label: this.$t('DashBoardStaff.Table.LayerModule') },
            // { key: 'message_module', label: this.$t('DashBoardStaff.Table.MessageModule') },
            // { key: 'organization_module', label: this.$t('DashBoardStaff.Table.OrganizationModule') },
            // { key: 'report_module', label: this.$t('DashBoardStaff.Table.ReportModule') },
            // { key: 'blog_module', label: this.$t('DashBoardStaff.Table.BlogModule') },
            // { key: 'budget_module', label: this.$t('DashBoardStaff.Table.BudgetModule') },
            // ],
            types: [],
            ecosystem_types: [],
            categories:[],
            favourite_ecosystem_service_category_type: null,
            favourite_ecosystem_category_type: null,
            copy_favourite_ecosystem_service_category_type: null,
            copy_favourite_ecosystem_category_type: null,
            image: null,
            imagePath: null,
            imagePathEditing: null,
            editingAvatar: false,
            view_to: '/'
        }
    },
    created () {
        let data = JSON.parse(localStorage.getItem('user'))
        console.log('data:', data)
        this.user_id = data.data.id 
        this.getData()
    },
    
    // beforeRouteLeave(to, from, next) {
    //     console.log('view to:', to)
    //     this.view_to = to
    //     if (this.editing)  
    //         this.modals.exit = true
    //     else
    //         next()
    // },
    methods:{
        getData() {
            // this.myRole = []
            StaffServices.findOne(this.user_id).then(
                Response => {
                this.user = Response.data.data
                this.copy_favourite_ecosystem_category_type = this.user.favourite_ecosystem_category_type
                this.copy_favourite_ecosystem_service_category_type = this.user.favourite_ecosystem_service_category_type
                this.favourite_ecosystem_category_type = this.user.favourite_ecosystem_category_type
                this.favourite_ecosystem_service_category_type = this.user.favourite_ecosystem_service_category_type
                this.imagePath = this.user.profile_image
                this.copyUser = {...this.user}
                // RoleServices.findOne(this.user.role_id).then(
                //     Response => {
                //     this.myRole.push(Response.data.data)
                //     },
                //     Error => {
                //     console.log(Error.data)
                //     })
                // },
                // Error => {
                // console.log(Error.data)
                }
            )
            EcosystemsServicesTypes.findAll().then(
                Response => {
                this.types = Response.data.data
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
            EcosystemTypesDataService.findAll().then(
                Response => {
                this.ecosystem_types = Response.data.data
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        editPersonalInformation () {
           
            this.$validator.validateAll(['input-dataPersonalInformation-name','input-dataPersonalInformation-surname','input-dataPersonalInformation-phone']).then(result => {
                if (!result) {
                    return
                }
                this.user.id = this.user_id
                StaffServices.modifyStaff(this.user).then(
                Response => {
                    if (Response.status === 200) {
                        this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Profile.Alerts.EditPersonalInfo.OK')
                        }).then(() => {
                            this.getData()
                            // this.editing = false
                            this.editPersonalInfo = !this.editPersonalInfo
                        });
                    }
                },
                Error => {
                    if (Error.response.status === 404) {
                        this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Profile.Alerts.EditPersonalInfo.NotFound')
                        })
                    }
                    if (Error.response.status === 400) {
                        this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Profile.Alerts.EditPersonalInfo.BadRequest')
                        })
                    }
                    if (Error.response.status === 500) {
                        this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Errors.InternalServerError')
                        })
                    }
                })
            })
        },
        toggleEdit () {
            // this.editing = true
            this.$swal.fire({
                icon: 'info',
                text:this.$t('Profile.Alerts.EditPersonalInfo.Info')

                // text: this.$t('Profile.Alerts.EditPersonal.Info.')
            })
            this.editPersonalInfo = !this.editPersonalInfo
        },
        toggleEditCancel () {
            // this.editing = false
            this.getData()
            this.editPersonalInfo = !this.editPersonalInfo
        },
        
        toggleEditCancelAvatar() {
            // this.editing = false
            this.editingAvatar = false
            this.$refs['fileInput'].value = ''
            this.getData()
        },
        openChangePass () {
            this.editing = true
            this.changePass = true
        },
        openChangeFav () {
            this.changeFav = true
        },
        editPassword() {
            this.$validator.validateAll(['input-dataPass-currentPassword','input-dataPass-newPassword','input-dataPass-newPasswordRepeat']).then(result => {
                if (!result) {
                    return
                }
                let dataChange = this.dataPass
                if (dataChange.newPassword != dataChange.newPasswordRepeat) {
                    this.$swal.fire({
                    icon: 'error',
                    title: this.$t('Profile.Alerts.EditPersonalInfo.DontMatch')
                    })
                    return
                }
                // End point
                Login.changePass(this.dataPass).then(
                    Response => {
                        if (Response.status === 200) {
                        this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Profile.Alerts.EditPersonalInfo.OK')
                        }).then(() => {
                            this.getData()
                            this.changePass = !this.changePass
                            this.dataPass = {}
                        })
                        }
                    },
                    Error => {
                        if (Error.response.status === 404) {
                            this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Profile.Alerts.EditPersonalInfo.NotFound')
                            })
                        }
                        if (Error.response.status === 400) {
                            console.log(Error.response)
                            this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Profile.Alerts.EditPersonalInfo.BadRequest')
                            })
                        }
                        if (Error.response.status === 500) {
                            this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Errors.InternalServerError')
                            })
                        }
                    }
                )
            })
        },
   
        exitWithoutChanges(){
            console.log('exit without changes')
            this.modals.exit = false
            this.editing = false
            this.$router.push(this.view_to)
        },
        toggleEditCancelPass () {
            // this.editing = false
            this.$validator.reset()
            this.dataPass = {}
            this.changePass = !this.changePass
        },
        toggleEditCancelFav () {
            this.changeFav = !this.changeFav
            if (this.copy_favourite_ecosystem_category_type == null) this.favourite_ecosystem_category_type = null
            else  this.favourite_ecosystem_category_type = this.copy_favourite_ecosystem_category_type
            if (this.copy_favourite_ecosystem_service_category_type == null) this.favourite_ecosystem_service_category_type = null
            else this.favourite_ecosystem_service_category_type = this.copy_favourite_ecosystem_service_category_type
            console.log(this.copy_favourite_ecosystem_category_type)
            console.log(this.favourite_ecosystem_category_type)
        },
        showPass () {
            if (!this.variablePass) this.variablePass = true
            else this.variablePass = false
            var tipo = document.getElementById("input-dataPass-currentPassword");
            if (tipo.type == "password"){
                tipo.type = "text";
            } else {
                tipo.type = "password";
            }
        },
        showPass1 () {
            if (!this.variablePass1) this.variablePass1 = true
            else this.variablePass1 = false
            var tipo = document.getElementById("input-dataPass-newPassword");
            if (tipo.type == "password"){
                tipo.type = "text";
            } else {
                tipo.type = "password";
            }
        },
        showPass2 () {
            if (!this.variablePass2) this.variablePass2 = true
            else this.variablePass2 = false
            var tipo = document.getElementById("input-dataPass-newPasswordRepeat");
            if (tipo.type == "password"){
                tipo.type = "text";
            } else {
                tipo.type = "password";
            }
        },
        validateState (ref) {
        if (
            this.veeFields[ref] &&
            (this.veeFields[ref].dirty || this.veeFields[ref].validated)
        ) {
            return !this.veeErrors.has(ref)
        }
        return null
        },
        openFileInput () {
            this.$refs['fileInput'].click()
        },
        setImage(){  
            this.editingAvatar = true
            this.editing = true
            this.image = this.$refs['fileInput'].files[0]
            this.imagePathEditing = URL.createObjectURL(this.image)
            console.log(this.image)
            console.log(this.imagePathEditing)

        },
        deleteImage(){
            this.editingAvatar = true
            this.image = null
            this.imagePathEditing = null
        },
        updateAvatar () {
            
            var user_id = this.$store.state.auth.user.data.id
            var formData = new FormData()
            console.log(this.image)
            // formData.append('id', user_id)
            // formData.append('delete_path', this.imagePath)
            formData.append('profile-images', this.image) // appending file
            

            StaffServices.updateAvatar(formData).then(
            response => {
                if(response.status == 200){
                    this.$swal.fire({
                    icon: 'success',
                    title: this.$t('Profile.Alerts.UpdateAvatar.OK')
                    }).then(() => {
                        this.editingAvatar = false
                        this.$store.state.auth.user.data.profile_image = response.data.user.profile_image
                        let user = this.$store.state.auth.user
                        localStorage.setItem('user', JSON.stringify(user))
                        this.getData()
                    });
                
                }
                
            },
            error => {
                console.log((error.response && error.response.data) || error.message || error.toString())
                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('Profile.Alerts.UpdateAvatar.Error')
                    });
            }

            )
        
        },
        getImgUrl (name) {
            try{
                if(name){
                    return `${process.env.VUE_APP_VITE_BUCKET_CDN}/profile-images/${name}?withheaders=yes`
                    /// Regular exp for png: \.png$/
                    //var images = require.context('../../assets/profile_images/', false, /\.png$/)
                    //console.log(images('./' + name))
                    //return images('./' + name)
                }
            }catch{
                this.imagePath = null
            }
        },
        addFav () {
            let data = {
                user_id : this.user_id,
                ecosystem_category_type: this.favourite_ecosystem_category_type,
                ecosystem_service_category_type: this.favourite_ecosystem_service_category_type
            }
            console.log(data)
            StaffServices.updateFav(data).then(
                Response => {
                console.log(Response)
                this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Profile.Alerts.UpdateFav.OK')
                        }).then(() => {
                            this.getData()
                            this.changeFav = false
                        });
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })               
                 }
            )
        }

    }
  }
  </script>
<style scoped>

input[type="file"] {
    display: none;
}
</style>