import http from '../http-common'
import authHeader from "./AuthHeaderService"
const API_POST_URL_PATH = 'api/multimedia-blog/'
class MultimediaBlogDataService { 
    

    uploadPostImages(formData){
        console.log('DATOS DE SUBIDA IMAGENES ', formData)
        var headers = authHeader()
        headers['Content-Type'] = 'multipart/form-data'
        return http.post(API_POST_URL_PATH + 'upload-images', formData, {
            headers: headers
        })
    }

    uploadPostImagesMulti(formData){
        var headers = authHeader()
        headers['Content-Type'] = 'multipart/form-data'
        return http.post(API_POST_URL_PATH + 'upload-images-multi', formData, {
            headers: headers
        })
    }

    getMultimediaImages(){
        return http.get(API_POST_URL_PATH + 'images', {headers: authHeader()})
    }

    deletePostImage(file_name){
        return http.delete(API_POST_URL_PATH + 'image?id=' + file_name + '&is_post=true', {headers: authHeader()})
    }

    deleteTutorialImage(file_name){
        return http.delete(API_POST_URL_PATH + 'image?id=' + file_name + '&is_post=false', {headers: authHeader()})
    }

    update (image) {
        return http.put(API_POST_URL_PATH + 'update', { id: image.id, image: image },{headers: authHeader()})
    }
    
}
export default new MultimediaBlogDataService()