import http from '../http-common'
import authHeader from './AuthHeaderService'

const API_URL_PATH = 'api/parameter/'

class ParameterDataService {

  findAll () {
    return http.get(API_URL_PATH + 'find-all',{headers: authHeader()})
  }

  findOne (id) {
    return http.get(API_URL_PATH + 'find-one?id=' + id,{headers: authHeader()})
  }

  create (parameter) {
    return http.post(API_URL_PATH + 'create', { parameter: parameter },{headers: authHeader()})
  }

  update (parameter) {
    return http.put(API_URL_PATH + 'update', { id: parameter.id, parameter: parameter },{headers: authHeader()})
  }

  delete (id,) {
    return http.delete(API_URL_PATH + 'delete?id=' + id, {headers: authHeader()})
  }

  exists (label) {
    return http.get(API_URL_PATH + 'exists?label=' + label, {headers: authHeader()})
  }
  createParameterLanguage (parameter_language) {
    return http.post(API_URL_PATH + 'create-parameter-language', { parameter_language: parameter_language },{headers: authHeader()})
  }

  updateParameterLanguage (parameter_language) {
    return http.put(API_URL_PATH + 'update-parameter-language', { parameter_language: parameter_language },{headers: authHeader()})
  }

  deleteParameterLanguage (parameter_id, language_id) {
    return http.delete(API_URL_PATH + 'delete-parameter-language?parameter_id=' + parameter_id + '&language_id=' + language_id,{headers: authHeader()})
  }
  uploadCSV (data) {
    return http.post(API_URL_PATH + 'check-parameter-json', data, {headers: authHeader()})
  }

  applyChanges (data) {
    return http.put(API_URL_PATH + 'modify-parameter', data, {headers: authHeader()})
  }

}

export default new ParameterDataService()
