<template>
    <div class="wrapper  dashboard-wrapper collapsed-sidebar" style="margin-top: 5%; max-width: 99%;">
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                    {{ $t("AddProject.Title") }}
                    </h2> 
                </b-col>
                <b-col>
                    <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right">
                        <b-button-group class="mx-1">
                            <n-button
                            id="newAdmin"
                            class="btn-simple ncm-btn-back"
                            target="_blank"
                            @click="goBack()"
                            >
                            <b-icon icon="box-arrow-in-left" class="button-icon"></b-icon>
                                {{$t("EcosystemEdit.ButtonBack")}}
                            </n-button>
                        </b-button-group>
                        <b-button-group class="mx-1">
                            <n-button
                            id="newAdmin"
                            class="btn-simple ncm-btn"
                            style="font-weight: 800 !important; float: right;"
                            target="_blank"
                            @click.prevent="AddProject()"
                            >
                            <b-icon icon="cloud-arrow-up-fill" class="button-icon" ></b-icon>
                            {{ $t("AddProject.SaveProject") }}
                            </n-button>
                        </b-button-group>
                    </b-button-toolbar>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <card>
                        <b-row>
                            <b-col cols="4" class="mr-auto" style="float: left; margin-bottom: 15px">
                                <span><h6 class=" mb-0">{{ $t("AddProject.ProjectDetails") }}</h6></span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="8">
                                <b-row>
                                    <b-col>
                                        <b-form-group :label="$t('AddProject.Form.Name')" label-for="input-name">
                                            <b-form-input
                                            v-model="project.name"
                                            id="input-name"
                                            name="input-name"
                                            v-validate="{ required: true}"
                                            :state="validateState('input-name')"
                                            aria-describedby="input-name-live-feedback"
                                            size="sm"
                                            :placeholder="$t('AddProject.Form.Name')"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-name-live-feedback">{{ veeErrors.first('input-name') }}</b-form-invalid-feedback>
                                        </b-form-group>
                                        <b-form-group :label="$t('AddProject.Form.Description')" label-for="input-description">
                                            <b-form-input
                                            v-model="project.description"
                                            id="input-description"
                                            name="input-description"
                                            v-validate="{ required: true}"
                                            :state="validateState('input-description')"
                                            aria-describedby="input-description-live-feedback"
                                            size="sm"
                                            :placeholder="$t('AddProject.Form.Description')"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-description-live-feedback">{{ veeErrors.first('input-description') }}</b-form-invalid-feedback>
                                        </b-form-group>
                                        <div class="d-flex">
                                            <b-form-group class="ml-2">
                                                <b-form-checkbox
                                                    id="checkbox-1"
                                                    v-model="shared_wallet"
                                                    name="checkbox-1"
                                                    style="margin-top: 20px"
                                                    >
                                                    <label style="margin-top: 2px;">{{ $t("AddProject.Form.SharedWallet") }}</label>
                                                </b-form-checkbox>
                                            </b-form-group>
                                            <b-form-group class="ml-2">
                                                <b-form-checkbox
                                                    id="checkbox-2"
                                                    v-model="automatic_renewal"
                                                    name="checkbox-2"
                                                    style="margin-top: 20px"
                                                    >
                                                    <label style="margin-top: 2px;">{{ $t("AddProject.Form.AtomaticRenewal") }}</label>
                                                </b-form-checkbox>
                                            </b-form-group>  
                                        </div>
                                        <b-form-group v-if="!shared_wallet" :label="$t('AddProject.Amount')" label-for="input-amount">
                                            <b-form-input
                                            v-model="project.amount"
                                            id="input-amount"
                                            name="input-amount"
                                            type="number"
                                            v-validate="{ number: true}"
                                            :state="validateState('input-amount')"
                                            aria-describedby="input-amount-live-feedback"
                                            size="sm"
                                            :placeholder="$t('AddProject.Amount')"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-amount-live-feedback">{{ veeErrors.first('input-amount') }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group :label="$t('AddProject.Form.Manager')" label-for="input-manager">
                                    <b-form-select 
                                        v-model="project.user_id"
                                        id="input-manager"
                                        name="input-manager"
                                        v-validate="{ required: true}"
                                        aria-describedby="input-manager-live-feedback"
                                        >
                                        <b-form-select-option :value="null">{{ $t("AddProject.SelectPM") }}</b-form-select-option>
                                        <b-form-select-option v-for="user in users" :key="user" :value="user.id">
                                        {{ user.name }} ({{ user.email }})
                                        </b-form-select-option>
                                        <b-form-invalid-feedback id="input-description-live-feedback">{{ veeErrors.first('input-manager') }}</b-form-invalid-feedback>
                                    </b-form-select>
                                </b-form-group>
                                <card>
                                    <b-row>
                                        <b-col class="d-flex">
                                            <span><h6>{{ $t("AddProject.Analysts") }}</h6></span>
                                        </b-col>
                                    </b-row>
                                    <b-form-tags id="tags-with-dropdown" v-model="value_analysts" no-outer-focus class="mb-2">
                                        <template v-slot="{ tags, disabled, addTag, removeTag }">
                                            <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                                            <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                                <b-form-tag
                                                @remove="removeTag(tag)"
                                                :title="tag"
                                                :disabled="disabled"
                                                variant="info"
                                                >{{ tag }}</b-form-tag>
                                            </li>
                                            </ul>
                                            <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
                                            <template #button-content>
                                                <b-icon icon="plus"></b-icon> {{ $t("AddProject.AddUsers") }}
                                            </template>
                                            <b-dropdown-form @submit.stop.prevent="() => {}">
                                                <b-form-group
                                                :label="$t('AddProject.SearchLabel')"
                                                label-for="tag-search-input"
                                                label-cols-md="auto"
                                                class="mb-0"
                                                label-size="sm"
                                                :description="searchDesc(value_analysts)"
                                                :disabled="disabled"
                                                >
                                                    <b-form-input
                                                        v-model="search"
                                                        id="tag-search-input"
                                                        type="search"
                                                        size="sm"
                                                        autocomplete="off"
                                                        v-on:keyup.enter="onSearchEnter({ addTag })"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-dropdown-form>
                                            <b-dropdown-divider></b-dropdown-divider>
                                            <b-dropdown-item-button 
                                                v-for="option in availableOptions(value_analysts)"
                                                style="cursor: pointer;"
                                                :key="option"
                                                :style="{ cursor: 'pointer' }"
                                                @click.native.capture.stop="addTag(option)"
                                            >
                                                {{ option }}
                                            </b-dropdown-item-button>
                                            <b-dropdown-text v-if="availableOptions(value_analysts).length === 0">
                                                {{$t("AddProject.SearchEmpty")}}
                                            </b-dropdown-text>
                                            </b-dropdown>
                                        </template>
                                    </b-form-tags>
                                </card>
                            </b-col>
                        </b-row>
                    </card>
                </b-col>
            </b-row>
        </b-container>
        <modal :show.sync="modals.exit" class="modal-primary" :show-close="false" header-classes="justify-content-center">
            <p>{{$t("ParameterLanguageEdit.ExitViewMessage")}}</p>
            <template slot="footer">
                <n-button type="neutral" link @click="exitWithoutChanges()">
                {{ $t("Ecosystems.Modals.Delete.Footer.Save") }}
                </n-button>
                <n-button type="neutral" link @click="modals.exit = false">
                {{ $t("Ecosystems.Modals.Delete.Footer.Close") }}
                </n-button>
            </template>
        </modal>
    </div>
</template>
<script>
import { Card, Modal, ModalOutside, Button,Checkbox } from '@/components';
import { VueEditor } from "vue2-editor";
import OrganizationsDataService from '../../services/OrganizationsDataService'; 
import ProjectsDataService from '../../services/ProjectsDataService'
export default {
    components: {
        Card,
        [Checkbox.name]: Checkbox,
        VueEditor,
        Modal,
        [Button.name]: Button,
    }, 
    data: () => ({
        modals: {
            exit: false,
        },
        search: '',
        project: {
            user_id: null
        },
        shared_wallet: true,
        automatic_renewal:true,
        editing: false,
        view_to: '/projects-management',
        value_analysts: [],
        users: [],
        organization_id: null,
        role: null

    }),
    created() {
        this.organization_id = this.$orgSelected.id
        this.getData()
        this.editing = true
    },
    beforeRouteLeave(to, from, next) {
        this.view_to = to
        if (this.editing)  
            this.modals.exit = true
        else
            next()
    },
    computed: {
        criteria() {
            return this.search.trim().toLowerCase()
        },
    },
    watch: {
        $orgSelected(newValue){
            this.organization_id = newValue.id
            this.getData()
        }
    },
    methods: {
        checkExternalRole(user){
            
            if(this.$roleSelected.user_role == 'admin')
                this.role = 'admin'
            else
                this.role = 'other'
        },
        searchDesc (value_analysts) {
            if (this.criteria && this.availableOptions(value_analysts).length === 0) {
                return 'There are no tags matching your search criteria'
            }
                return ''
        },
        availableOptions(value_analysts) {
            const criteria = this.criteria
            var values = this.users.map(f => f.name + ' ' + f.surname + ' ' + '(' + f.email + ')')
            const options = values.filter(opt => value_analysts.indexOf(opt) === -1)
            if (criteria) {
                var match = options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1);
                return match
            }
            return options
        },
        exitWithoutChanges(){
            this.editing = false
            this.$router.push(this.view_to)
        },
        goBack () {
            this.$router.push('/projects-management')
        },
        
        getData () {
            OrganizationsDataService.findByOrganizationId(this.organization_id).then(
                Response => {
                    var users = Response.data.data
                    this.users = users.filter(f => f.id != this.$store.state.auth.user.data.id)  
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        AddProject() {
            this.$validator.validateAll([]).then(result => {
                if (!result) {
                    return
                }   
                var regExp = /\(([^)]+)\)/;
                var analysts = []
                for(var analyst of this.value_analysts){
                    var user = this.users.filter(f => '(' + f.email + ')' == regExp.exec(analyst)[0])[0]
                    analysts.push({user_id: user.id})
                }
                var ammount = !this.shared_wallet ? this.project.ammount : undefined
                var data = {
                    name : this.project.name,
                    description : this.project.description,
                    share_wallet: this.shared_wallet,
                    ammount: ammount,
                    organization_id: this.organization_id,
                    user_id: this.project.user_id,
                    project_manager_automatic_renewal: this.automatic_renewal,
                    analysts: analysts
                }
                ProjectsDataService.SetProject(data).then(
                    Response => {
                        console.log(Response.data)
                        this.$swal.fire({
                                icon: 'success',
                                title: this.$t('Alerts.ProjectAddedCorrectly'),
                            }).then(() => {
                                this.getData()
                                this.editing = false
                                this.$router.push({ path: '/projects-management' })
                            });
                    },
                    Error => {
                        this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Alerts.ErrorAddingProject'),
                            }).then(() => {
                                this.getData()
                                this.editing = false
                                this.$router.push({ path: '/projects-management' })
                            });
                    }
                )
            })
            
        },
        validateState (ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
            return !this.veeErrors.has(ref)
            }
            return null
        },
    }
}
</script>
<style>

</style>