<template>
    <div v-if="!expired" class="page-header signup-page section-image">
        <div class="page-header-image"
        :style="{'background-image': 'url(' + $getImgUrlSite('bg15.jpg') +')'}"/>
        <div class="content" style="margin-top: 8% !important">
            <div class="container">
                <b-row>
                    <b-col class="m-auto col-md-8 col-lg-6">                        
                        <BCard class="mt-5 plan-card">
                            <div class="row mb-3">
                                <div class="col">
                                    <label style="color: black; font-size: 1.3em;">{{ email }}</label>
                                </div>
                            </div> 
                            <div class="row">
                                <div class="col">
                                    <span>
                                        <h6 class="highlighted-title" style="color: rgb(0, 0, 0); font-size: 1.8em;">{{ $t('SignUpInvitation.Title') }} <p>{{nameOrg}}</p> </h6>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="card-avatar m-auto">
                                        <template v-if="imgOrg != null">
                                            <img 
                                                style="max-height: 60px; border-radius: 50%;"
                                                class="img img-raised"
                                                :src="getImgUrl(imgOrg)"
                                            >
                                        </template>
                                        <template v-else>
                                            <img  
                                                style="max-height: 60px; border-radius: 50%;"
                                                class="img img-raised"
                                                :src="$getImgUrlSite('EJEMPLO-LOGO.png')"
                                            >
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5" style="margin-top: 13px!important;">
                                <div class="col">
                                    <b-form-group :label="$t('SignUp.Name')" class="formSignUpLabel" label-for="input-user-name">
                                        <b-form-input
                                        v-model="dataFormUser.name"
                                        id="input-user-name"
                                        name="input-user-name"
                                        v-validate="{ required: true}"
                                        class="no-border input-lg"
                                        style="border: 1px solid #a2a0a0;"
                                        :placeholder="$t('SignUp.Name')"
                                        :state="validateState('input-user-name')"
                                        aria-describedby="input-user-name-live-feedback"
                                        size="sm"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-user-name-live-feedback">{{ veeErrors.first('input-user-name') }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </div>
                                <div class="col">
                                    <b-form-group :label="$t('SignUp.Surname')" class="formSignUpLabel" label-for="input-user-surname">
                                        <b-form-input
                                        v-model="dataFormUser.surname"
                                        id="input-user-surname"
                                        name="input-user-surname"
                                        v-validate="{ required: true}"
                                        class="no-border input-lg"
                                        style="border: 1px solid #a2a0a0;"
                                        :placeholder="$t('SignUp.Surname')"
                                        :state="validateState('input-user-surname')"
                                        aria-describedby="input-user-surname-live-feedback"
                                        size="sm"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-user-surname-live-feedback">{{ veeErrors.first('input-user-surname') }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col">
                                    <b-form-group :label="$t('SignUp.Phone')" class="formSignUpLabel" label-for="input-user-phone">
                                        <b-form-input
                                        v-model="dataFormUser.phone"
                                        id="input-user-phone"
                                        name="input-user-phone"
                                        class="no-border input-lg"
                                        style="border: 1px solid #a2a0a0;"
                                        :placeholder="$t('SignUp.Phone')"
                                        size="sm"
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <b-form-group :label="$t('SignUp.Password')" class="formSignUpLabel" label-for="input-user-password">
                                        <b-form-input
                                        v-model="dataFormUser.password"
                                        id="input-user-password"
                                        name="input-user-password"
                                        type="password"
                                        v-validate="{ required: true}"
                                        class="no-border input-lg"
                                        style="border: 1px solid #a2a0a0;"
                                        :placeholder="$t('SignUp.Password')"
                                        :state="validateState('input-user-password')"
                                        aria-describedby="input-user-password-live-feedback"
                                        size="sm"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-user-password-live-feedback">{{ veeErrors.first('input-user-password') }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </div>
                                <div class="col-2 d-flex show-pass-col" style="margin-top: 0% !important; margin-left: -18% !important">
                                    <span v-if="!variablePass" class="iconPass m-auto" @click="showPass()"><b-icon icon="eye" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
                                    <span v-if="variablePass" class="iconPass m-auto" @click="showPass()"><b-icon icon="eye-slash" class="iconPass" style="color: black; margin-left: 0 !important; margin-top: 3px !important;" id="togglePassword"></b-icon></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <b-button class="btn-simple ncm-btn" @click="signUpExternalUser()">
                                    {{ $t("SignUp.Sign") }}
                                    <b-icon icon="file-person"></b-icon>
                                </b-button>
                                </div>
                            </div>
                        </BCard>
                    </b-col>
                </b-row>
            </div>
        </div>
        <footer-logo background-color="#ffffff"></footer-logo>
    </div>
</template>

<script>
import FooterLogo from '@/layout/FooterLogo';
import ExternalUserService from '../../services/ExternalUserDataService'
import InvitationDataService from '../../services/InvitationDataService';
    export default {
        components: {
            FooterLogo
        },
        computed : {},
        async created() {
            var isAuthenticated = false;
            if (localStorage.getItem('user')) isAuthenticated = true
            else isAuthenticated = false
            if (isAuthenticated) {
                this.$swal.fire({
                    icon: 'warning',
                    title: this.$t('Alerts.SessionActive')
                }).then(() => {
                    this.$router.push('/')
                })
            } else {
                await InvitationDataService.checkInvitation(this.$route.query.token).then(
                Response => {
                    this.email = Response.data.mail
                    this.nameOrg = Response.data.org_name
                    this.imgOrg = Response.data.org_img
                },
                Error => {
                    this.expired = true
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Alerts.ErrorInvitation')
                    }).then(() => {
                        this.$router.push('/')
                    })
                })
            }
        },
        data() {
            return {
                dataFormUser: {},
                variablePass: false,
                expired: false,
                email: '',
                imgOrg: null,
                nameOrg: null
            }
        },
        methods: {
            signUpExternalUser () {
                this.$validator.validateAll(['input-user-name','input-user-surname','input-user-email','input-user-password']).then(result => {
                    if (!result) {
                    return
                    }
                    this.dataFormUser.email = this.email
                    this.dataFormUser.isAdmin = false
                    this.dataFormUser.token = this.$route.query.token
                    if(!this.dataFormUser.phone) this.dataFormUser.phone = null
                    console.log(this.dataFormUser)
                    ExternalUserService.createInvitation(this.dataFormUser).then(
                        Response => {
                            this.$swal.fire({
                                icon: 'success',
                                title: this.$t('Alerts.ExternalUserInvitationCreated')
                            }).then(() => {
                                this.dataFormUser = {}
                                this.$validator.reset()
                                this.$router.push('/')
                            });
                        },
                        Error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('Alerts.ErrorCreatingInvitation')
                            }).then(() => {})                        }
                    )


                })
            },
            getImgUrl (name) {
                try{
                    if(name){
                        return `${process.env.VUE_APP_VITE_BUCKET_CDN}/organization-images/${name}?withheaders=yes`
                    }
                }catch{
                    this.imagePath = null
                }
            },
            showPass() {
                this.variablePass = !this.variablePass;
                var tipo = document.getElementById("input-user-password");
                if (tipo.type === "password") {
                    tipo.type = "text";
                } else {
                    tipo.type = "password";
                }
            },
            validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
            },
        }
    }
</script>

<style>
.plan-card{
  border-radius:5% !important;
  background-color: rgba(255, 255, 255, 0.9);
  min-height: 550px;
}
</style>