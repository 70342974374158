import http from "../http-common";
const API_URL_PATH = "api/mail/";
import authHeader from './AuthHeaderService'

class SettingsMailDataService {
    findAll() {
        return http.get (API_URL_PATH + 'find-mails', {headers: authHeader()})
    }
    findOne(id) {
        return http.get (API_URL_PATH + 'find-mail?id=' + id,{headers: authHeader()})
    }
    create (mail) {
        const body = {
            type: mail.type,
            content: mail.content,
        }
        console.log(body)
        return http.post (API_URL_PATH + 'create-mail',body,{headers: authHeader()})
    }
    update (mail) {
        const body = {
            id: mail.id,
            type: mail.type,
            content: mail.content,
        }
        console.log(body);
        return http.put(API_URL_PATH + 'update-mail',body,{headers: authHeader()})
    }
    delete (id){
        return http.delete(API_URL_PATH + 'delete-mail?id=' + id,  {headers: authHeader()})
    }

}
export default new SettingsMailDataService()
