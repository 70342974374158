<template>
  <nav :class="classes" class="navbar navbar-expand-lg">
    <div class="container">
      <div class="navbar-translate" style="margin-left: 2%;">
        <slot v-bind="slotData" />
        <!-- <navbar-toggle-button :toggled="showMenu" @click.native.stop="toggle" /> -->
      </div>
      <div class="navbar-menu-container" v-if="!isMobileView">
        <slot name="navbar-menu" v-bind="slotData" />
      </div>

      <div class="navbar-menu-dropdown" v-else>
        <!-- <drop-down>
          <template v-slot:title>
            <button @click="toggleDropdown" class="btn btn-primary dropdown-toggle" round>
              Profile Info
            </button>
          </template>
          <div :class="{ 'dropdown-menu-navbar': true, 'show': dropdownOpen }">
            <slot name="navbar-menu" v-bind="slotData"></slot>
          </div>
        </drop-down> -->
        
        <button @click="toggleDropdown" class="dropdown-toggle">
          {{ $t('LogStaffNavBar.ProfileInfo') }}
        </button>
        <div :class="{ 'dropdown-menu-navbar': true, 'show': dropdownOpen }" ref="dropdownMenu" style="position: absolute; top: 100%; background-color: #c3c3c3;">
          <slot name="navbar-menu" v-bind="slotData" />
        </div>
      </div>
      <!-- <div v-if="$slots['navbar-menu'] || $scopedSlots['navbar-menu']" id="navigation"
        class="collapse navbar-collapse show" data-color="orange">
        <ul v-click-outside="close" class="navbar-nav" :class="menuClasses">
          <slot name="navbar-menu" v-bind="slotData" />
        </ul>
      </div> -->
    </div>
  </nav>
</template>
<script>
let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();
    }, 66);
  }
}

export default {
  name: 'Navbar',
  components: {},
  provide() {
    return {
      closeNavbar: this.close
    }
  },
  props: {
    transparent: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'relative'
    },
    menuClasses: {
      type: [String, Object, Array]
    },
    colorOnScroll: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'white',
      validator(value) {
        return ['white', 'default', 'primary', 'danger', 'success', 'warning', 'info'].includes(value);
      }
    },
  },
  data() {
    return {
      showMenu: false,
      extraNavClasses: '',
      currentScrollValue: 0,
      isMobileView: window.innerWidth <= 991,
      dropdownOpen: false
    }
  },
  computed: {
    slotData() {
      return {
        toggle: this.toggle,
        open: this.open,
        close: this.close,
        isToggled: this.showMenu
      }
    },
    classes() {
      let color = `bg-${this.type}`;
      let navPosition = `navbar-${this.position}`;
      let colorOnScrollTransparent = this.colorOnScroll && this.currentScrollValue < this.colorOnScroll;

      if (this.position === 'fixed') {
        navPosition = 'fixed-top';
      }

      return [
        { 'navbar-transparent': (this.transparent || colorOnScrollTransparent) },
        { [color]: (!this.transparent && this.colorOnScroll === 0) },
        navPosition, this.extraNavClasses]
    }
  },
  mounted() {
    document.addEventListener('scroll', this.scrollListener);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollListener);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    setNav(value) {
      let docClasess = document.body.classList;
      if (value) {
        docClasess.add('nav-open')
      } else {
        docClasess.remove('nav-open')
      }
      let isOpen = docClasess.contains('nav-open');
      let eventToTrigger = isOpen ? 'open' : 'close';
      this.showMenu = isOpen;
      this.$emit(eventToTrigger)
    },
    toggle() {
      this.setNav(!this.showMenu)
    },
    open() {
      this.setNav(true);
    },
    close() {
      if (this.showMenu === false) {
        return
      }
      this.setNav(false);
    },
    handleScroll() {
      let scrollValue = document.body.scrollTop || document.documentElement.scrollTop;
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `bg-${this.type}`;
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = ''
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll)
    },
    handleResize() {
      this.isMobileView = window.innerWidth <= 991;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      this.$nextTick(() => {
        this.adjustDropdownSize();
      });
    },
    adjustDropdownSize() {
      //This makes the dropdown menu scrollable if it's too long
      const dropdownMenu = this.$refs.dropdownMenu;
      if (dropdownMenu) {
        const maxHeight = window.innerHeight - dropdownMenu.getBoundingClientRect().top - 10;
        dropdownMenu.style.maxHeight = `${maxHeight}px`;
        dropdownMenu.style.overflowY = 'auto';
      }
    }
  }
}
</script>
<style scoped>
.navbar-menu-container {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-top: 10px;
}

.navbar-menu-dropdown {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  position: relative;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
}

.dropdown-menu-navbar {
  display: none;
  flex-direction: row; /* Use flexbox for horizontal layout */
  justify-content: flex-start; /* Adjust to align items to the start */
  align-items: center; /* Center items vertically */
  position: absolute;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  z-index: 1000;
  right: 0!important;
  /* min-width: 80%; */
}

.dropdown-menu-navbar.show {
  display: flex; /* Change display to flex */
  /* margin-left: 40px!important; */
}

@media(max-width: 991px) {
  .navbar-menu-container {
    display: none;
  }

  .navbar-menu-dropdown {
    justify-content: flex-end;
    margin-top: -25px;
  }
  .dropdown-menu-navbar.show {
    color: black;
  }
}
</style>