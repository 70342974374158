<template>
  <div class="page-header signup-page section-image">
    <div v-if="loading" id="toHidden"
            style="
            position: absolute;
            top     :0;
            left    : 0;
            height  : 100%;
            width   :  100%;
            background-color:azure;
            z-index : 1060;
            opacity : 0.6;"
            >
        </div>
        <b-icon  v-if="loading" style="
            z-index: 2000;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: black;
            "
            icon="three-dots"
            animation="cylon"
            font-scale="4">
        </b-icon>
        <div class="page-header-image"
        :style="{'background-image': 'url(' + $getImgUrlSite('bg17.jpg') +')'}"/>
        <div class="content-sign">
            <div class="container">
              
                <b-row>
                    <b-col class="m-auto col-md-8 col-lg-6">                        
                        <BCard class="mt-5" style="border-radius: 2.5%;"> 
                            <div
                                slot="header"
                                class="logo-container"
                            >
                                <img
                                    v-lazy="$getImgUrlSite('LogoNoSpinOff.png')"
                                    style="max-height: 75px; max-width: 175px; margin: 30px;"
                                    alt="logo"
                                >
                            </div>                           
                            <div class="row">
                                <div class="col">
                                    <span>
                                        <h6 class="highlighted-title" style="color: rgb(0, 0, 0)">PDF reports downloader</h6>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                  <b-form-group label="" class="formSignUpLabel">
                                    <b-form-input
                                      v-model="token"
                                      id="input-token"
                                      name="input-token"
                                      :rules="['required']"
                                      aria-describedby="input-token-live-feedback"
                                      placeholder="Insert a token for download"
                                      class="no-border input-lg"
                                      style="border: 1px solid #a2a0a0;"
                                      :state="validateState('input-token-live-feedback')"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="input-token-live-feedback">{{ veeErrors.first('input-token-live-feedback') }}</b-form-invalid-feedback>
                                  </b-form-group>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col">
                                    <b-button class="btn-simple ncm-btn" @click="accept()">
                                        DOWNLOAD
                                        <b-icon icon="download"></b-icon>
                                    </b-button>
                                </div>
                                
                            </div>
                        </BCard>
                    </b-col>
                </b-row>
            </div>
        </div>
        <footer-logo background-color="#ffffff"/>
    </div>
</template>
<script>
  import { Badge, Button, InfoSection,Modal, FormGroupInput,Tabs, TabPane, Checkbox, DropDown} from '@/components';
  import { API_KEY } from '@/constants'
  import FooterLogo from '@/layout/FooterLogo';
  import { Card } from 'element-ui';
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  });

  import ReportsDataService from '@/services/ReportsDataService'

  export default {
  name: 'report-download',
  components: {
    FooterLogo,
    Card,
  },
  data() {
      return {
          loading: false,
          organization_id: undefined,
          role: undefined,
          msgReportError: [],
          msgReportOK: [],
          token: null
      }
  },
  computed: {
       
      },
  watch: {
    $orgSelected(newValue){
        console.log('Org selected changed!', newValue)
        this.organization_id = newValue.id
        this.checkExternalRole(this.$store.state.auth.user)
        this.getData()
    }
  },
  created() {
    this.organization_id = this.$orgSelected.id
    this.checkExternalRole(this.$store.state.auth.user)
    this.getData()
  },
  methods:{
    async accept(){
      this.loading = true
      await ReportsDataService.downloadReport(this.token).then(
              async response => {
                console.log(response)
                const href = URL.createObjectURL(response.data);
                
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'report_compressed'); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                this.loading = false

              },
              error => {
                this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
              }
          )
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    clearErrors(){
      this.msgReportError = []
      this.msgReportOK = []
    },
    checkExternalRole(user){
        console.log('$roleSelected', this.$roleSelected)
        this.role = this.$roleSelected.user_role
        console.log(this.role)
    },
    
    getData(){
        
    }
  }
  }
  </script>
  <style scoped>
  .disabled-row {
    background-color: #f8f8f8;
    color: #999999;
  }

  
  ::v-deep .dz-preview{
      min-width: 100px !important;
      max-width: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important;
  }

  ::v-deep .dz-image{
      min-width: 100px !important;
      max-width: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important;
  }


  ::v-deep .dz-progress{
      visibility: hidden;
  }

  ::v-deep .dz-remove{
    position: absolute !important;
    top: 0px !important;
    left: 69px !important;
    max-width: 20px !important;
    max-height: 20px !important;
    padding: 0px !important;
    border: none !important;
    color: #fe2626b0 !important;
  }

  .layers-size-modal{
    margin-bottom: 0;
    font-size: 13px;
    font-style: italic;
    color: #ffffff8a;
  }

  .error-list {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 1px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

.error-item {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #721c24;
}

.error-item::before {
  color: #721c24;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* .row .col.d-flex {
  justify-content: flex-end;
} */
.checks-ok-list {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
}

.checks-ok-item {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #155724;
}

.checks-ok-item::before {
  color: #155724;
  margin-right: 5px;
  margin-bottom: 5px;
}

::v-deep #dropzone{
  padding: 0;
  min-height: auto;
  margin-bottom: 16px;
}
::v-deep .card-modal > .card-body {
  padding: 0.8em!important;
  /* overflow-y: hidden; */
}

  </style>