<template>
  <div class="wrapper dashboard-wrapper collapsed-sidebar">
    <b-container class="container" style="margin-top: 5%; max-width: 94%;">
      <b-row class="page-header-bs">
        <b-col cols="3" style="display: flex">
          <h2 class="card-title">
            {{ $t("EcosystemServices.Title") }}
          </h2> 
        </b-col>
        <b-col cols="3" style="display: flex">
            <drop-down class="dropdownServices">
              <n-button style="margin-top: 2%"
                  slot="title"
                  class="dropdown-toggle"
                  type="primary"
                  data-toggle="dropdown"
              >
                  <template v-if="type_filter == undefined">
                    {{$t("Utils.DropDownType")}}
                  </template>
                  <template v-else>
                    {{ $t("EcosystemCategories.Filter") }} <b>{{type_filter.name}}</b>
                  </template>
              </n-button>
              <a v-for="(type, index) of types"
                  style="cursor: pointer;" class="dropdown-item" :key="index" @click="filterCategory(type)" :class="{ 'active': type.name === type_filter.name }"
              >{{type.name}}</a> 
            </drop-down>
        </b-col>
        
      </b-row>
      <b-row>
        <b-col cols="12">
          <card>
            <b-row>
              <b-col>
                <h6 class="titleServices"> {{ $t("EcosystemServices.List") }}</h6>
              </b-col>
              <b-col>
                <b-button-toolbar class="buttonServices" key-nav aria-label="Toolbar with button groups" style="float: right">
                  <b-button-group class="mx-1">
                    <n-button class="btn-simple ncm-btn" target="_blank" @click.native="openCreateModal()">
                      <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                      {{ $t("EcosystemServices.Create") }}
                    </n-button>
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table ref="ecosystemServicesTable"
                    id="ecosystemServicesTable"
                    :fields="headers" 
                    :items="services_to_show"
                    select-mode="single"
                    selectable
                    show-empty
                    @row-selected="selectRow"
                    @row-clicked="handleRowClicked"
                    @sort-changed="sortingChanged"
                    style="overflow-x: auto"
                    responsive="sm"
                    :current-page="currentPage"
                    :per-page="perPage"
                    sort-by="createdAt"
                    :sort-desc="true"> 
                    <template #empty="scope">
                      {{$t('EcosystemServices.Table.Empty')}}
                    </template>
                    <template #cell(createdAt)="data">
                        {{ data.item.createdAt | formatDate}}
                    </template>
                    <template #cell(updatedAt)="data">
                        {{ data.item.updatedAt | formatDate}}
                    </template>
                    <template #cell(services_childs)="data">
                        <template  v-if="data.item.services_childs.length > 0">
                          {{ data.item.services_childs.length }}
                        </template>
                        <template v-else>
                          -
                        </template>
                    </template>
                    <template #cell(options)="data">
                        <b-button class="action-table" v-b-tooltip.hover.viewport.viewport :title="$t('EcosystemServices.Tooltips.Modify')" @click="openEditModal(data.item)">
                          <b-icon icon="pen"></b-icon>
                        </b-button>
                        <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('EcosystemServices.Tooltips.Language')" @click="openEditLanguageModal(data.item)">
                          <b-icon icon="translate"></b-icon>
                        </b-button>
                        <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('EcosystemServices.Tooltips.Delete')" @click="openDeleteModal(data.item)">
                          <b-icon icon="trash"></b-icon>
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-card class="row-details-card">
                          <div v-if="selectedElement" class="info-container">
                            <b-row>
                              <b-form-group :label="$t('Ecosystems.Table.Label')" class="info-group" label-class="info-group-label">
                                  <p class="info-group-input">
                                    {{ selectedElement.label }}
                                  </p>
                              </b-form-group>
                            </b-row>
                            <b-row>
                              <b-form-group :label="$t('Ecosystems.Table.Childs')" class="info-group" label-class="info-group-label">
                                <p class="info-group-input">
                                    <template v-if="selectedElement.services_childs.length > 0">
                                      {{ selectedElement.services_childs.map(f => f.label).join(', ') }}
                                    </template>
                                    <template v-else>
                                      {{ $t("EcosystemServices.NotChilds") }}
                                    </template>
                                  </p>
                              </b-form-group>
                            </b-row>
                          </div>
                          <div v-else class="info-container-empty">
                            <p class="p-dashboard text-centered">{{ $t("EcosystemServices.Please") }}</p>
                          </div>
                        </b-card>
                    </template>
                </b-table>
                <div class="pagination-container">
                    <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                    </b-pagination>
                    <b-form-select v-model="perPage" class="pagination-select">
                        <b-form-select-option :value="5">5</b-form-select-option>
                        <b-form-select-option :value="10">10</b-form-select-option>
                        <b-form-select-option :value="20">20</b-form-select-option>
                        <b-form-select-option
                            :value="ecosystem_services.length">{{ $t('EcosystemServices.All') }}</b-form-select-option>
                    </b-form-select>
                </div>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
    </b-container>
    <!-- Modal create  -->
    <modal :show.sync="modals.create" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("EcosystemServices.Modals.Create.Title") }}
      </h4>
      <b-form-group :label="$t('EcosystemServices.Modals.Modify.Label') + ' *' ">
          <b-form-input
            v-model="label_form"
            id="input-label"
            name="input-label"
            v-validate="{ required: true}"
            :state="validateState('input-label')"
            aria-describedby="input-label-live-feedback"
            size="sm"
            :placeholder=" $t('EcosystemServices.Modals.Create.Label') + ' *'  "
          ></b-form-input>
          <b-form-invalid-feedback id="input-label-live-feedback">{{ veeErrors.first('input-label') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('EcosystemServices.Modals.Create.Type') + ' *'  ">
            <b-form-select v-model="type_form" 
              id="input-type"
              name="input-type"
              v-validate="{ required: true}"
              :state="validateState('input-type')"
              aria-describedby="input-type-live-feedback"
              :options="types"
              value-field="id"
              text-field="name">
              <b-form-select-option :value="null">{{$t('Ecosystems.Modals.Create.TypePH')}}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback id="input-type-live-feedback">{{ veeErrors.first('input-type') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('Ecosystems.Modals.Create.Category') + ' *'  ">
            <b-form-select v-model="category_form" 
              id="input-category"
              name="input-category"
              v-validate="{ required: true}"
              :state="validateState('input-category')"
              aria-describedby="input-category-live-feedback"
              :options="categories_available"
              value-field="id"
              text-field="name"
              @change="filterParents()">
              <b-form-select-option :value="null">{{$t('Ecosystems.Modals.Create.CategoryPH')}}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback id="input-category-live-feedback">{{ veeErrors.first('input-category') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('EcosystemServices.Modals.Create.Parent')">
            <b-form-select v-model="parent_form" 
              id="input-parent"
              name="input-parent"
              :options="parents_to_show"
              value-field="id"
              text-field="label">
              <b-form-select-option :value="null">{{$t('Ecosystems.Modals.Create.ParentPH')}}</b-form-select-option>
            </b-form-select>
      </b-form-group>
      <template slot="footer">
        <n-button class="btn-simple ncm-btn" @click="addElement">
          {{ $t("EcosystemServices.Modals.Create.Footer.Save") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.create = false">
          {{ $t("EcosystemServices.Modals.Create.Footer.Close") }}
        </n-button>
      </template>
    </modal>

    <!-- Modal modify  -->
    <modal :show.sync="modals.modify" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("EcosystemServices.Modals.Modify.Title") }}
      </h4>
      <b-form-group :label="$t('EcosystemServices.Modals.Modify.Label') + ' *' ">
          <b-form-input v-if="selectedElement"
            v-model="selectedElement.label"
            id="input-label-modify"
            name="input-label-modify"
            v-validate="{ required: true}"
            :state="validateState('input-label-modify')"
            aria-describedby="input-label-modify-live-feedback"
            size="sm"
            :placeholder=" $t('EcosystemServices.Modals.Modify.Label') + ' *'  "
          ></b-form-input>
          <b-form-invalid-feedback id="input-label-modify-live-feedback">{{ veeErrors.first('input-label-modify') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group v-if="selectedElement" :label="$t('Ecosystems.Modals.Create.Type') + ' *'  ">
            <b-form-select v-model="selectedElement.category.category_type_id" 
              id="input-type-modify"
              name="input-type-modify"
              v-validate="{ required: true}"
              :state="validateState('input-type-modify')"
              aria-describedby="input-type-modify-live-feedback"
              :options="types"
              value-field="id"
              text-field="name">
              <b-form-select-option :value="null">{{$t('Ecosystems.Modals.Create.TypePH')}}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback id="input-type-modify-live-feedback">{{ veeErrors.first('input-type-modify') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group v-if="selectedElement" :label="$t('Ecosystems.Modals.Create.Category') + ' *'  ">
            <b-form-select v-model="selectedElement.category_id" 
              id="input-category-modify"
              name="input-category-modify"
              v-validate="{ required: true}"
              :state="validateState('input-category-modify')"
              aria-describedby="input-category-modify-live-feedback"
              :options="categories_available_modify"
              value-field="id"
              text-field="name"
              @change="filterParents()">
              <b-form-select-option :value="null">{{$t('Ecosystems.Modals.Create.CategoryPH')}}</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback id="input-category-modify-live-feedback">{{ veeErrors.first('input-category-modify') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group v-if="selectedElement" :label="$t('EcosystemServices.Modals.Modify.Parent')">
            <b-form-select v-model="selectedElement.parent_id" 
              id="input-parent"
              name="input-parent"
              :options="parents_to_show"
              value-field="id"
              text-field="label">
              <b-form-select-option :value="null">{{$t('Ecosystems.Modals.Modify.ParentPH')}}</b-form-select-option>
            </b-form-select>
      </b-form-group>
      <template slot="footer">
        <n-button class="btn-simple ncm-btn" @click="updateElement">
           {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.modify = false">
           {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
        </n-button>
      </template>
    </modal>

    <!-- Modal delete  -->
    <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
      <p>{{ $t("EcosystemServices.Modals.Delete.Title") }}</p>
      <template slot="footer">
        <n-button type="neutral" link @click="deleteElement">
          {{ $t("EcosystemServices.Modals.Delete.Footer.Save") }}
        </n-button>
        <n-button type="neutral" link @click.native="modals.delete = false">
          {{ $t("EcosystemServices.Modals.Delete.Footer.Close") }}
        </n-button>
      </template>
    </modal>
    
  </div>
</template>

<script>
  import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal, DropDown} from '@/components';
  import { API_KEY } from '@/constants'
  import {Table, TableColumn, Tooltip} from 'element-ui';
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  });

  import EcosystemServiceDataService from '@/services/EcosystemServiceDataService'
  import LanguageDataService from '@/services/LanguageDataService'
  import EcosystemServicesCategoriesDataService from '@/services/EcosystemServicesCategoriesDataService';
  import EcosystemServicesTypesDataService from '@/services/EcosystemServicesTypesDataService';
  import StaffServices from '../../services/StaffServices';

export default {
  name: 'EcosystemServices',
  bodyClass: 'profile-page',
  components: {
      Modal,
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
      [DropDown.name]: DropDown,
  },
  data() {
    return {
      modals: {
          modify: false,
          delete: false,
          create: false,
      },
      user: {},
      user_id: null,
      ecosystem_services: [],
      services_to_show: [],
      categories: [],
      types: [],
      selectedElement: null,
      label_form: '',
      parent_form: null,
      type_form: null,
      category_form: null,
      headers: [
        { key: 'label', sortable: true, label: this.$t('EcosystemServices.Table.Label'), tdClass: 'table-title', thClass: 'table-title position-sticky' }, 
        { key: 'services_childs', label: this.$t('EcosystemServices.Table.Childs'), thClass: 'position-sticky' },
        { key: 'category.name', sortable: true, label: this.$t('Ecosystems.Table.Category'), thClass: 'position-sticky' },
        { key: 'category.type.name', sortable: true, label: this.$t('Ecosystems.Table.Type'), thClass: 'position-sticky' },  
        { key: 'createdAt', label: this.$t('EcosystemServices.Table.CreatedAt'), thClass: 'position-sticky', sortable: true },
        { key: 'updatedAt', label: this.$t('EcosystemServices.Table.UpdatedAt'), thClass: 'position-sticky', sortable: true },
        { key: 'options', label: this.$t('Languages.Table.Actions'), thClass: 'position-sticky' }
      ],
      allOpenRows: [],
      currentPage: 1,
      perPage: 10,
      languages: [],
      language_form: null,
      type_filter: null,
      parents_to_show: []
    }
  },
  created() {
    this.getData(true)
  },
  computed: {
    totalPages(){
      return this.ecosystem_services.length
    },
    categories_available(){
      var available = this.categories.filter(f => f.category_type_id == this.type_form)
      return available
    },
    categories_available_modify(){
      var available = this.categories.filter(f => f.category_type_id == this.selectedElement.category.category_type_id)
      return available
    }
  },
  methods:{
    filterParents(){
      this.parents_to_show = this.ecosystem_services.filter(f => f.category.id == this.category_form)
    },
    filterCategory(type) {
      if (type != null){
        this.services_to_show = this.ecosystem_services.filter(f => f.category.category_type_id == type.id)
      }
      else
        this.services_to_show = this.ecosystem_services
        this.type_filter = type
        this.type_form = this.type_filter.id
    },
    openEditLanguageModal(element){
      this.$router.push('/ecosystem-services/languages/' + element.id)
    },
    selectRow(items){
      console.log('selected:', items)
      this.selectedElement = items[0]
    },
    handleRowClicked (item, index) {
      this.allOpenRows.map((ele) => {
        if (ele.id !== item.id && ele._showDetails) {
        this.$set(ele, '_showDetails', !ele._showDetails)
        }
      })
      this.allOpenRows = []
      this.$set(item, '_showDetails', !item._showDetails)
      this.allOpenRows.push(item)
    },
    validateState (ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    sortingChanged(item, index){
      this.allOpenRows.map((ele) => {
        if (ele.id !== item.id && ele._showDetails) {
          this.$set(ele, '_showDetails', !ele._showDetails)
        }
      })
    },
    async getData(filter_favourite){
      let data = JSON.parse(localStorage.getItem('user'))
      this.user_id = data.data.id 
      await StaffServices.findOne(this.user_id).then(
        Response => {
        this.user = Response.data.data
        }
      )
      await EcosystemServiceDataService.findAll('eraefaef').then(
        async response => {
          this.ecosystem_services = response.data
          this.services_to_show = this.ecosystem_services
        },
        error => {
            this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.message
            })
            if (error.response.status == '401') {
                this.$router.push('dashboard-staff')
            }
        }
      )
      await LanguageDataService.findAll().then(
        async response => {
          this.languages = response.data
        },
        error => {
          this.$swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.message
            })
        }
      )
      await EcosystemServicesCategoriesDataService.findAll().then(
      async response => {
        console.log(response.data)
        this.categories = response.data.data
      },
      error => {
        this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message
          })
        }
      )
      await EcosystemServicesTypesDataService.findAll().then(
        async response => {
          this.types = response.data.data
          if (filter_favourite)
            this.type_filter = this.types.filter(f => f.id == this.user.favourite_ecosystem_service_category_type)[0]
          else
            this.type_filter =  this.types.filter(f => f.id == this.type_filter)[0]
            this.type_form = this.type_filter.id
            this.filterCategory(this.type_filter)
        },
        error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message
          })
        }
      )
    },
    addElement(){
        this.$validator.validateAll(['input-label', 'input-type', 'input-category']).then(async result => {
          if (!result) {
            return
          }
          var element = {}
          var language = this.languages.find(f => f.name.toLowerCase() === 'english'.toLowerCase());
          console.log(language)
          if (this.parent_form != null && this.parent_form !== undefined){
            element = {
              label: this.label_form,
              parent_id: this.parent_form,
              language_id: language.id,
              category_id: this.category_form
            }
          } else {
            element = {
              label: this.label_form,
              language_id: language.id,
              category_id: this.category_form
            }
          }       
          EcosystemServiceDataService.create(element).then(
            async response => {
              this.$swal.fire({
                icon: 'success',
                title: this.$t('Alerts.EcosystemServiceCreated'),
                text: ''
              }).then(() => {
                  // Rester form
                  this.modals.create = false
                  this.label_form = ''
                  this.language_form = null
                  this.parent_form = null;
                  this.$validator.reset();
                  this.type_filter = this.type_form
                  this.getData(false)
              });
            },
            error => {
              this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message
          })
            }
          )
        })
    },
    openCreateModal(){
      // Reset form
      this.label_form = ''
      this.parent_form = null
      this.language_form = null
      this.category_form = null
      this.$validator.reset(['input-label','input-category','input-type'])
      // Show form
      this.modals.create = true
    },
    openEditModal(element){
      console.log(element)
      this.selectedElement = {...element}
      this.modals.modify = true
    },
    openDeleteModal(element){
      console.log(element)
      this.selectedElement = element
      this.modals.delete = true
    },
    updateElement(){
      this.$validator.validateAll(['input-label-modify', 'input-type-modify', 'input-category-modify']).then(async result => {
        if (!result) {
          return
        }
        EcosystemServiceDataService.update(this.selectedElement).then(
          async response => {
              this.modals.modify = false
              this.$swal.fire({
                icon: 'success',
                title: this.$t('Alerts.EcosystemServiceModified'),
                text: ''
              }).then(() => {
                this.type_filter = this.type_form
                this.getData(false)
              });

          },
          error => {
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message
          })
          }
        )
      }) 
    },
    deleteElement(){
      EcosystemServiceDataService.delete(this.selectedElement.id).then(
        async response => {
          this.modals.delete = false
          this.$swal.fire({
            icon: 'success',
            title: this.$t('Alerts.EcosystemServiceDeleted'),
            text: ''
          }).then(() => {
            this.type_filter = this.selectedElement.category.type.id
            this.getData(false)
          });
        },
        error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message
          })
        }
      )
    }
  }
}
</script>
<style scoped>

.dropdown-item.active {
  font-weight: bold; 
  background-color: white !important;
  color: #5D6176 !important/* Establece el estilo de fuente para la opción seleccionada */
}

.dropup, .dropright, .dropdown, .dropleft {
    position: relative;
    margin-bottom: auto;
}

@media (max-width: 465px) {
  .titleServices {
    text-align: center;
  }

  .buttonServices{
    float: center;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}

@media (max-width: 1066px) {
    .dropdownServices {
        margin-top: 80px;
        margin-left: -25px;
    }
    .buttonServices{
      font-size: 0.8em;
    }
}
</style>