<template>
    <div class="wrapper-home  collapsed-sidebar" style="margin-top: 5%; max-width: 99%;">
        <div v-if="loading" id="toHidden"
                            style="
                                position: absolute;
                                top     :0;
                                left    : 0;
                                height  : 100%;
                                width   :  100%;
                                background-color:azure;
                                z-index : 1000;
                                opacity : 0.6;"
                            >
        </div>
        <b-icon  v-if="loading" style="
        z-index: 2000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        "
        icon="three-dots"
        animation="cylon"
        font-scale="4">
        </b-icon>
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                <h2 class="card-title">
                    {{$t('Organization.Title')}}
                </h2> 
                </b-col>
            </b-row>
            <b-row>
            <b-col>
                <card class="card-h-100">
                    <b-row>
                        <b-col cols="4" style="float: left; display: flex;">
                            <span class="mt-auto mb-auto"><h6 class=" mb-0">{{$t('Organization.Orgs')}}</h6></span>
                        </b-col>
                        <b-col>
                            <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right;">
                                <b-button-group class="mx-1">
                                    <b-button  class="btn-simple ncm-btn" style="cursor: pointer !important; margin-left: 4%;" @click="modals.createOrg = true"><b-icon icon="plus-circle-fill" class="button-icon"></b-icon>{{$t('DashBoard.CreateOrg')}}</b-button>
                                    <!-- <n-button
                                        id="newAdmin"
                                        class="btn-simple ncm-btn"
                                        style="font-weight: 800 !important;"
                                        target="_blank"
                                        @click.native="modals.invite = true"
                                    >
                                    <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                                    {{$t('UsersManagement.Invite')}}
                                    </n-button> -->
                                </b-button-group>
                            </b-button-toolbar>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-table
                            style=" max-height: 550px; overflow-x: auto;"
                            ref="projectTable"
                            id="projectTable"
                            :fields="headers" 
                            :items="organizations"
                            responsive="sm"
                            show-empty
                            :current-page="currentPage"
                            :per-page="perPage"
                            > 
                            <template #empty="">
                                {{$t('Organization.OrgEmpty')}}
                            </template>
                            
                            <template #cell(created_at)="data">
                            {{ data.item.created_at | formatDate}}
                            </template>

                            <template #cell(roles)="data">
                                <template v-for="(rol, index) of data.item.roles">
                                    <b-form-tag
                                    :title="tag"
                                    variant="info"
                                    no-remove
                                    >{{ parseRoleName(rol.user_role) }}</b-form-tag>
                                </template>
                            
                            </template>



                            </b-table>
                            <div class="pagination-container">
                                <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                                </b-pagination>
                                <b-form-select v-model="perPage" class="pagination-select">
                                    <b-form-select-option :value="5">5</b-form-select-option>
                                    <b-form-select-option :value="10">10</b-form-select-option>
                                    <b-form-select-option :value="20">20</b-form-select-option>
                                    <b-form-select-option
                                        :value="users.length">{{ $t('UsersManagement.All') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </b-col>
                    </b-row>
                </card>
            </b-col>
        </b-row>
    </b-container>
    <modal v-show="modals.createOrg" :show.sync="modals.createOrg" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        CREATE NEW ORGANIZATION
      </h4>
      <div class="row mt-4">
        <div class="col">
          <b-form-group :label="$t('SignUp.Name')" class="formSignUpLabel" label-for="input-org-name">
              <b-form-input
                v-model="name"
                id="input-org-name"
                name="input-org-name"
                v-validate="{ required: true}"
                class="no-border input-lg"
                style="border: 1px solid #a2a0a0;"
                :placeholder="$t('SignUp.Name')"
                :state="validateState('input-org-name')"
                aria-describedby="input-org-name-live-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-org-vat-live-feedback">{{ veeErrors.first('input-org-name') }}</b-form-invalid-feedback>
            </b-form-group>
        </div>
        <div class="col">
          <b-form-group label="VAT *" class="formSignUpLabel" label-for="input-org-vat">
            <b-form-input
                v-model="vat"
                id="input-org-vat"
                name="input-org-vat"
                v-validate="{ required: true}"
                class="no-border input-lg"
                style="border: 1px solid #a2a0a0;"
                placeholder="VAT"
                :state="validateState('input-org-vat')"
                aria-describedby="input-org-vat-live-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-org-vat-live-feedback">{{ veeErrors.first('input-org-vat') }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-form-group label="Description" class="formSignUpLabel" label-for="input-org-description">
            <b-form-input
                v-model="description"
                id="input-org-description"
                name="input-org-description"
                v-validate="{}"
                class="no-border input-lg"
                style="border: 1px solid #a2a0a0;"
                placeholder="Description"
                :state="validateState('input-org-description')"
                aria-describedby="input-org-description-live-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-org-description-live-feedback">{{ veeErrors.first('input-org-description') }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-form-group :label="$t('SignUp.Address')" class="formSignUpLabel " label-for="input-org-address1">
            <b-form-input
                v-model="address1"
                id="input-org-address1"
                name="input-org-address1"
                v-validate="{ required: true}"
                class="no-border input-lg"
                style="border: 1px solid #a2a0a0;"
                :placeholder="$t('SignUp.Address')"
                :state="validateState('input-org-address1')"
                aria-describedby="input-org-address1-live-feedback"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback id="input-org-address1-live-feedback">{{ veeErrors.first('input-org-address1') }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-form-group :label="$t('SignUp.Country')" class="formSignUpLabel" label-for="input-org-country">
            <b-form-select 
                v-model="country"
                id="input-org-country"
                name="input-org-country"
                v-validate="{ required: true}"
                :state="validateState('input-org-country')"
                aria-describedby="input-org-country-live-feedback"
                :options="countries"
                value-field="value"
                style="background-color: transparent; border: 1px solid #a2a0a0; border-radius: 20px;"
                text-field="text"
                @change="updatePhonePrefix">
              </b-form-select>
              <b-form-invalid-feedback id="input-org-country-live-feedback">{{ veeErrors.first('input-org-country') }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-form-group :label="$t('SignUp.Phone')" class="formSignUpLabel" label-for="input-org-phone">
            <div class="input-group">
                <b-form-input
                    v-model="phonePrefix"
                    
                    class="no-border input-lg"
                    style="border: 1px solid #a2a0a0; background-color: transparent; width: 80px;"> <!-- Input solo para mostrar el prefijo -->
                </b-form-input>
                <b-form-input
                    v-model="phone"
                    id="input-org-phone"
                    name="input-org-phone"
                    v-validate="{}"
                    class="no-border input-lg"
                    style="border: 1px solid #a2a0a0; width: calc(100% - 80px);"
                    :placeholder="$t('SignUp.Phone')"
                    :state="validateState('input-org-phone')"
                    aria-describedby="input-org-phone-live-feedback"
                    size="sm">
                </b-form-input>
            </div>
              <b-form-invalid-feedback id="input-org-phone-live-feedback">{{ veeErrors.first('input-org-phone') }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <template slot="footer">
        <n-button class="btn-simple ncm-btn" style="cursor: pointer;" @click="createOrganization">
           {{ $t("Parameters.Modals.Create.Footer.Save") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" style="cursor: pointer;" @click="modals.createOrg = false">
           {{ $t("Parameters.Modals.Create.Footer.Close") }}
        </n-button>
      </template>
    </modal>
    
    </div>
</template>
<script>
import { Badge, Button, InfoSection,Modal, FormGroupInput, Card, Tabs, TabPane, Checkbox, DropDown} from '@/components';
import InvitatonDataServices from '../../services/InvitationDataService'
import OrganizationsDataService from '../../services/OrganizationsDataService'
import {Table, TableColumn, Tooltip} from 'element-ui';

import moment from 'moment'
import ExternalUserDataService from '../../services/ExternalUserDataService';



export default {
    components : {
        Card,
        Modal,
        [Checkbox.name]: Checkbox,
        [DropDown.name]: DropDown,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Tooltip.name]: Tooltip,
        [FormGroupInput.name]: FormGroupInput,
    },
    data() {
        return {
            // userLS: null,
            countries : [
                { "text": "Afghanistan", "value": "AF", "phone_prefix": "+93" },
                { "text": "Åland Islands", "value": "AX", "phone_prefix": "+358" },
                { "text": "Albania", "value": "AL", "phone_prefix": "+355" },
                { "text": "Algeria", "value": "DZ", "phone_prefix": "+213" },
                { "text": "American Samoa", "value": "AS", "phone_prefix": "+1" },
                { "text": "Andorra", "value": "AD", "phone_prefix": "+376" },
                { "text": "Angola", "value": "AO", "phone_prefix": "+244" },
                { "text": "Anguilla", "value": "AI", "phone_prefix": "+1" },
                { "text": "Antarctica", "value": "AQ", "phone_prefix": "+672" },
                { "text": "Antigua and Barbuda", "value": "AG", "phone_prefix": "+1" },
                { "text": "Argentina", "value": "AR", "phone_prefix": "+54" },
                { "text": "Armenia", "value": "AM", "phone_prefix": "+374" },
                { "text": "Aruba", "value": "AW", "phone_prefix": "+297" },
                { "text": "Australia", "value": "AU", "phone_prefix": "+61" },
                { "text": "Austria", "value": "AT", "phone_prefix": "+43" },
                { "text": "Azerbaijan", "value": "AZ", "phone_prefix": "+994" },
                { "text": "Bahamas", "value": "BS", "phone_prefix": "+1" },
                { "text": "Bahrain", "value": "BH", "phone_prefix": "+973" },
                { "text": "Bangladesh", "value": "BD", "phone_prefix": "+880" },
                { "text": "Barbados", "value": "BB", "phone_prefix": "+1" },
                { "text": "Belarus", "value": "BY", "phone_prefix": "+375" },
                { "text": "Belgium", "value": "BE", "phone_prefix": "+32" },
                { "text": "Belize", "value": "BZ", "phone_prefix": "+501" },
                { "text": "Benin", "value": "BJ", "phone_prefix": "+229" },
                { "text": "Bermuda", "value": "BM", "phone_prefix": "+1" },
                { "text": "Bhutan", "value": "BT", "phone_prefix": "+975" },
                { "text": "Bolivia", "value": "BO", "phone_prefix": "+591" },
                { "text": "Bosnia and Herzegovina", "value": "BA", "phone_prefix": "+387" },
                { "text": "Botswana", "value": "BW", "phone_prefix": "+267" },
                { "text": "Bouvet Island", "value": "BV", "phone_prefix": "" },
                { "text": "Brazil", "value": "BR", "phone_prefix": "+55" },
                { "text": "British Indian Ocean Territory", "value": "IO", "phone_prefix": "" },
                { "text": "Brunei Darussalam", "value": "BN", "phone_prefix": "+673" },
                { "text": "Bulgaria", "value": "BG", "phone_prefix": "+359" },
                { "text": "Burkina Faso", "value": "BF", "phone_prefix": "+226" },
                { "text": "Burundi", "value": "BI", "phone_prefix": "+257" },
                { "text": "Cambodia", "value": "KH", "phone_prefix": "+855" },
                { "text": "Cameroon", "value": "CM", "phone_prefix": "+237" },
                { "text": "Canada", "value": "CA", "phone_prefix": "+1" },
                { "text": "Cape Verde", "value": "CV", "phone_prefix": "+238" },
                { "text": "Cayman Islands", "value": "KY", "phone_prefix": "+1" },
                { "text": "Central African Republic", "value": "CF", "phone_prefix": "+236" },
                { "text": "Chad", "value": "TD", "phone_prefix": "+235" },
                { "text": "Chile", "value": "CL", "phone_prefix": "+56" },
                { "text": "China", "value": "CN", "phone_prefix": "+86" },
                { "text": "Christmas Island", "value": "CX", "phone_prefix": "+61" },
                { "text": "Cocos (Keeling) Islands", "value": "CC", "phone_prefix": "+61" },
                { "text": "Colombia", "value": "CO", "phone_prefix": "+57" },
                { "text": "Comoros", "value": "KM", "phone_prefix": "+269" },
                { "text": "Congo", "value": "CG", "phone_prefix": "+242" },
                { "text": "Congo, The Democratic Republic of the", "value": "CD", "phone_prefix": "+243" },
                { "text": "Cook Islands", "value": "CK", "phone_prefix": "+682" },
                { "text": "Costa Rica", "value": "CR", "phone_prefix": "+506" },
                { "text": "Cote D'Ivoire", "value": "CI", "phone_prefix": "+225" },
                { "text": "Croatia", "value": "HR", "phone_prefix": "+385" },
                { "text": "Cuba", "value": "CU", "phone_prefix": "+53" },
                { "text": "Cyprus", "value": "CY", "phone_prefix": "+357" },
                { "text": "Czech Republic", "value": "CZ", "phone_prefix": "+420" },
                { "text": "Denmark", "value": "DK", "phone_prefix": "+45" },
                { "text": "Djibouti", "value": "DJ", "phone_prefix": "+253" },
                { "text": "Dominica", "value": "DM", "phone_prefix": "+1" },
                { "text": "Dominican Republic", "value": "DO", "phone_prefix": "+1" },
                { "text": "Ecuador", "value": "EC", "phone_prefix": "+593" },
                { "text": "Egypt", "value": "EG", "phone_prefix": "+20" },
                { "text": "El Salvador", "value": "SV", "phone_prefix": "+503" },
                { "text": "Equatorial Guinea", "value": "GQ", "phone_prefix": "+240" },
                { "text": "Eritrea", "value": "ER", "phone_prefix": "+291" },
                { "text": "Estonia", "value": "EE", "phone_prefix": "+372" },
                { "text": "Ethiopia", "value": "ET", "phone_prefix": "+251" },
                { "text": "Falkland Islands (Malvinas)", "value": "FK", "phone_prefix": "+500" },
                { "text": "Faroe Islands", "value": "FO", "phone_prefix": "+298" },
                { "text": "Fiji", "value": "FJ", "phone_prefix": "+679" },
                { "text": "Finland", "value": "FI", "phone_prefix": "+358" },
                { "text": "France", "value": "FR", "phone_prefix": "+33" },
                { "text": "French Guiana", "value": "GF", "phone_prefix": "+594" },
                { "text": "French Polynesia", "value": "PF", "phone_prefix": "+689" },
                { "text": "French Southern Territories", "value": "TF", "phone_prefix": "" },
                { "text": "Gabon", "value": "GA", "phone_prefix": "+241" },
                { "text": "Gambia", "value": "GM", "phone_prefix": "+220" },
                { "text": "Georgia", "value": "GE", "phone_prefix": "+995" },
                { "text": "Germany", "value": "DE", "phone_prefix": "+49" },
                { "text": "Ghana", "value": "GH", "phone_prefix": "+233" },
                { "text": "Gibraltar", "value": "GI", "phone_prefix": "+350" },
                { "text": "Greece", "value": "GR", "phone_prefix": "+30" },
                { "text": "Greenland", "value": "GL", "phone_prefix": "+299" },
                { "text": "Grenada", "value": "GD", "phone_prefix": "+1" },
                { "text": "Guadeloupe", "value": "GP", "phone_prefix": "+590" },
                { "text": "Guam", "value": "GU", "phone_prefix": "+1" },
                { "text": "Guatemala", "value": "GT", "phone_prefix": "+502" },
                { "text": "Guernsey", "value": "GG", "phone_prefix": "+44" },
                { "text": "Guinea", "value": "GN", "phone_prefix": "+224" },
                { "text": "Guinea-Bissau", "value": "GW", "phone_prefix": "+245" },
                { "text": "Guyana", "value": "GY", "phone_prefix": "+592" },
                { "text": "Haiti", "value": "HT", "phone_prefix": "+509" },
                { "text": "Heard Island and Mcdonald Islands", "value": "HM", "phone_prefix": "" },
                { "text": "Holy See (Vatican City State)", "value": "VA", "phone_prefix": "+379" },
                { "text": "Honduras", "value": "HN", "phone_prefix": "+504" },
                { "text": "Hong Kong", "value": "HK", "phone_prefix": "+852" },
                { "text": "Hungary", "value": "HU", "phone_prefix": "+36" },
                { "text": "Iceland", "value": "IS", "phone_prefix": "+354" },
                { "text": "India", "value": "IN", "phone_prefix": "+91" },
                { "text": "Indonesia", "value": "ID", "phone_prefix": "+62" },
                { "text": "Iran, Islamic Republic Of", "value": "IR", "phone_prefix": "+98" },
                { "text": "Iraq", "value": "IQ", "phone_prefix": "+964" },
                { "text": "Ireland", "value": "IE", "phone_prefix": "+353" },
                { "text": "Isle of Man", "value": "IM", "phone_prefix": "+44" },
                { "text": "Israel", "value": "IL", "phone_prefix": "+972" },
                { "text": "Italy", "value": "IT", "phone_prefix": "+39" },
                { "text": "Jamaica", "value": "JM", "phone_prefix": "+1" },
                { "text": "Japan", "value": "JP", "phone_prefix": "+81" },
                { "text": "Jersey", "value": "JE", "phone_prefix": "+44" },
                { "text": "Jordan", "value": "JO", "phone_prefix": "+962" },
                { "text": "Kazakhstan", "value": "KZ", "phone_prefix": "+7" },
                { "text": "Kenya", "value": "KE", "phone_prefix": "+254" },
                { "text": "Kiribati", "value": "KI", "phone_prefix": "+686" },
                { "text": "Korea, Democratic People'S Republic of", "value": "KP", "phone_prefix": "+850" },
                { "text": "Korea, Republic of", "value": "KR", "phone_prefix": "+82" },
                { "text": "Kuwait", "value": "KW", "phone_prefix": "+965" },
                { "text": "Kyrgyzstan", "value": "KG", "phone_prefix": "+996" },
                { "text": "Lao People'S Democratic Republic", "value": "LA", "phone_prefix": "+856" },
                { "text": "Latvia", "value": "LV", "phone_prefix": "+371" },
                { "text": "Lebanon", "value": "LB", "phone_prefix": "+961" },
                { "text": "Lesotho", "value": "LS", "phone_prefix": "+266" },
                { "text": "Liberia", "value": "LR", "phone_prefix": "+231" },
                { "text": "Libyan Arab Jamahiriya", "value": "LY", "phone_prefix": "+218" },
                { "text": "Liechtenstein", "value": "LI", "phone_prefix": "+423" },
                { "text": "Lithuania", "value": "LT", "phone_prefix": "+370" },
                { "text": "Luxembourg", "value": "LU", "phone_prefix": "+352" },
                { "text": "Macao", "value": "MO", "phone_prefix": "+853" },
                { "text": "Macedonia, The Former Yugoslav Republic of", "value": "MK", "phone_prefix": "+389" },
                { "text": "Madagascar", "value": "MG", "phone_prefix": "+261" },
                { "text": "Malawi", "value": "MW", "phone_prefix": "+265" },
                { "text": "Malaysia", "value": "MY", "phone_prefix": "+60" },
                { "text": "Maldives", "value": "MV", "phone_prefix": "+960" },
                { "text": "Mali", "value": "ML", "phone_prefix": "+223" },
                { "text": "Malta", "value": "MT", "phone_prefix": "+356" },
                { "text": "Marshall Islands", "value": "MH", "phone_prefix": "+692" },
                { "text": "Martinique", "value": "MQ", "phone_prefix": "+596" },
                { "text": "Mauritania", "value": "MR", "phone_prefix": "+222" },
                { "text": "Mauritius", "value": "MU", "phone_prefix": "+230" },
                { "text": "Mayotte", "value": "YT", "phone_prefix": "+262" },
                { "text": "Mexico", "value": "MX", "phone_prefix": "+52" },
                { "text": "Micronesia, Federated States of", "value": "FM", "phone_prefix": "+691" },
                { "text": "Moldova, Republic of", "value": "MD", "phone_prefix": "+373" },
                { "text": "Monaco", "value": "MC", "phone_prefix": "+377" },
                { "text": "Mongolia", "value": "MN", "phone_prefix": "+976" },
                { "text": "Montserrat", "value": "MS", "phone_prefix": "+1" },
                { "text": "Morocco", "value": "MA", "phone_prefix": "+212" },
                { "text": "Mozambique", "value": "MZ", "phone_prefix": "+258" },
                { "text": "Myanmar", "value": "MM", "phone_prefix": "+95" },
                { "text": "Namibia", "value": "NA", "phone_prefix": "+264" },
                { "text": "Nauru", "value": "NR", "phone_prefix": "+674" },
                { "text": "Nepal", "value": "NP", "phone_prefix": "+977" },
                { "text": "Netherlands", "value": "NL", "phone_prefix": "+31" },
                { "text": "Netherlands Antilles", "value": "AN", "phone_prefix": "+599" },
                { "text": "New Caledonia", "value": "NC", "phone_prefix": "+687" },
                { "text": "New Zealand", "value": "NZ", "phone_prefix": "+64" },
                { "text": "Nicaragua", "value": "NI", "phone_prefix": "+505" },
                { "text": "Niger", "value": "NE", "phone_prefix": "+227" },
                { "text": "Nigeria", "value": "NG", "phone_prefix": "+234" },
                { "text": "Niue", "value": "NU", "phone_prefix": "+683" },
                { "text": "Norfolk Island", "value": "NF", "phone_prefix": "+672" },
                { "text": "Northern Mariana Islands", "value": "MP", "phone_prefix": "+1" },
                { "text": "Norway", "value": "NO", "phone_prefix": "+47" },
                { "text": "Oman", "value": "OM", "phone_prefix": "+968" },
                { "text": "Pakistan", "value": "PK", "phone_prefix": "+92" },
                { "text": "Palau", "value": "PW", "phone_prefix": "+680" },
                { "text": "Palestinian Territory, Occupied", "value": "PS", "phone_prefix": "+970" },
                { "text": "Panama", "value": "PA", "phone_prefix": "+507" },
                { "text": "Papua New Guinea", "value": "PG", "phone_prefix": "+675" },
                { "text": "Paraguay", "value": "PY", "phone_prefix": "+595" },
                { "text": "Peru", "value": "PE", "phone_prefix": "+51" },
                { "text": "Philippines", "value": "PH", "phone_prefix": "+63" },
                { "text": "Pitcairn", "value": "PN", "phone_prefix": "+64" },
                { "text": "Poland", "value": "PL", "phone_prefix": "+48" },
                { "text": "Portugal", "value": "PT", "phone_prefix": "+351" },
                { "text": "Puerto Rico", "value": "PR", "phone_prefix": "+1" },
                { "text": "Qatar", "value": "QA", "phone_prefix": "+974" },
                { "text": "Reunion", "value": "RE", "phone_prefix": "+262" },
                { "text": "Romania", "value": "RO", "phone_prefix": "+40" },
                { "text": "Russian Federation", "value": "RU", "phone_prefix": "+7" },
                { "text": "RWANDA", "value": "RW", "phone_prefix": "+250" },
                { "text": "Saint Helena", "value": "SH", "phone_prefix": "+290" },
                { "text": "Saint Kitts and Nevis", "value": "KN", "phone_prefix": "+1" },
                { "text": "Saint Lucia", "value": "LC", "phone_prefix": "+1" },
                { "text": "Saint Pierre and Miquelon", "value": "PM", "phone_prefix": "+508" },
                { "text": "Saint Vincent and the Grenadines", "value": "VC", "phone_prefix": "+1" },
                { "text": "Samoa", "value": "WS", "phone_prefix": "+685" },
                { "text": "San Marino", "value": "SM", "phone_prefix": "+378" },
                { "text": "Sao Tome and Principe", "value": "ST", "phone_prefix": "+239" },
                { "text": "Saudi Arabia", "value": "SA", "phone_prefix": "+966" },
                { "text": "Senegal", "value": "SN", "phone_prefix": "+221" },
                { "text": "Serbia and Montenegro", "value": "CS", "phone_prefix": "+381" },
                { "text": "Seychelles", "value": "SC", "phone_prefix": "+248" },
                { "text": "Sierra Leone", "value": "SL", "phone_prefix": "+232" },
                { "text": "Singapore", "value": "SG", "phone_prefix": "+65" },
                { "text": "Slovakia", "value": "SK", "phone_prefix": "+421" },
                { "text": "Slovenia", "value": "SI", "phone_prefix": "+386" },
                { "text": "Solomon Islands", "value": "SB", "phone_prefix": "+677" },
                { "text": "Somalia", "value": "SO", "phone_prefix": "+252" },
                { "text": "South Africa", "value": "ZA", "phone_prefix": "+27" },
                { "text": "South Georgia and the South Sandwich Islands", "value": "GS", "phone_prefix": "" },
                { "text": "Spain", "value": "ES", "phone_prefix": "+34" },
                { "text": "Sri Lanka", "value": "LK", "phone_prefix": "+94" },
                { "text": "Sudan", "value": "SD", "phone_prefix": "+249" },
                { "text": "Suriname", "value": "SR", "phone_prefix": "+597" },
                { "text": "Svalbard and Jan Mayen", "value": "SJ", "phone_prefix": "" },
                { "text": "Swaziland", "value": "SZ", "phone_prefix": "+268" },
                { "text": "Sweden", "value": "SE", "phone_prefix": "+46" },
                { "text": "Switzerland", "value": "CH", "phone_prefix": "+41" },
                { "text": "Syrian Arab Republic", "value": "SY", "phone_prefix": "+963" },
                { "text": "Taiwan, Province of China", "value": "TW", "phone_prefix": "+886" },
                { "text": "Tajikistan", "value": "TJ", "phone_prefix": "+992" },
                { "text": "Tanzania, United Republic of", "value": "TZ", "phone_prefix": "+255" },
                { "text": "Thailand", "value": "TH", "phone_prefix": "+66" },
                { "text": "Timor-Leste", "value": "TL", "phone_prefix": "+670" },
                { "text": "Togo", "value": "TG", "phone_prefix": "+228" },
                { "text": "Tokelau", "value": "TK", "phone_prefix": "+690" },
                { "text": "Tonga", "value": "TO", "phone_prefix": "+676" },
                { "text": "Trinidad and Tobago", "value": "TT", "phone_prefix": "+1" },
                { "text": "Tunisia", "value": "TN", "phone_prefix": "+216" },
                { "text": "Turkey", "value": "TR", "phone_prefix": "+90" },
                { "text": "Turkmenistan", "value": "TM", "phone_prefix": "+993" },
                { "text": "Turks and Caicos Islands", "value": "TC", "phone_prefix": "+1" },
                { "text": "Tuvalu", "value": "TV", "phone_prefix": "+688" },
                { "text": "Uganda", "value": "UG", "phone_prefix": "+256" },
                { "text": "Ukraine", "value": "UA", "phone_prefix": "+380" },
                { "text": "United Arab Emirates", "value": "AE", "phone_prefix": "+971" },
                { "text": "United Kingdom", "value": "GB", "phone_prefix": "+44" },
                { "text": "United States", "value": "US", "phone_prefix": "+1" },
                { "text": "United States Minor Outlying Islands", "value": "UM", "phone_prefix": "" },
                { "text": "Uruguay", "value": "UY", "phone_prefix": "+598" },
                { "text": "Uzbekistan", "value": "UZ", "phone_prefix": "+998" },
                { "text": "Vanuatu", "value": "VU", "phone_prefix": "+678" },
                { "text": "Venezuela", "value": "VE", "phone_prefix": "+58" },
                { "text": "Viet Nam", "value": "VN", "phone_prefix": "+84" },
                { "text": "Virgin Islands, British", "value": "VG", "phone_prefix": "+1" },
                { "text": "Virgin Islands, U.S.", "value": "VI", "phone_prefix": "+1" },
                { "text": "Wallis and Futuna", "value": "WF", "phone_prefix": "+681" },
                { "text": "Western Sahara", "value": "EH", "phone_prefix": "+212" },
                { "text": "Yemen", "value": "YE", "phone_prefix": "+967" },
                { "text": "Zambia", "value": "ZM", "phone_prefix": "+260" },
                { "text": "Zimbabwe", "value": "ZW", "phone_prefix": "+263" }
            ],
            auxOrganizationID: -99,
            loading: false,
            dataFormOrg:{},
            modals: {
                createOrg : false
            },
            currentPage: 1,
            perPage: 10,
            currentPage2: 1,
            organization_id: 31,
            perPage2: 10,
            visibilityFilter: null,
            user: {},
            email_inv: null,
            users: [],
            inv: [],
            invitations: [],
            filteredArrayInvitations: [],
            projects: [],
            headers: [
                { key: 'name', sortable: true, label: this.$t('Organization.OrgName'), tdClass: 'table-title', thClass: 'table-title' }, 
                { key: 'created_at', sortable: true, label: this.$t('Organization.OrgDate'), tdStyle: { width: '65%' }, thStyle: { width: '65%' } },
                { key: 'roles', sortable: true, label: this.$t('Organization.OrgRoles'), tdClass: 'table-title' },
            ],
            auxOrganizationLS: [],
            organizations: [],
            name: null,
            vat: null,
            address1: null,
            phone: null,
            phonePrefix: null,
            country: null,
            description: null,
            
        }
    },
    computed: {
        
        onUpdate() {
            this.$refs.userTable.refresh();
            this.$forceUpdate();
        },
        totalPages () {
            return this.organizations.length
        },
        totalPages2 () {
            return this.invitations.length
        },
        formattedPhoneNumber() {
            return this.phonePrefix + ' ' + this.phoneNumber;
        }
    },
    watch: {
        $orgSelected(newValue){
            console.log('Org selected changed!', newValue)
            this.organization_id = newValue.id
            this.getData()
        }
    },
    created () {
        this.organization_id = this.$orgSelected.id
        this.auxOrganizationID = JSON.parse(localStorage.getItem("user")).admin.organization_id
        this.auxOrganizationLS = JSON.parse(localStorage.getItem("user")).admin
        this.getData()
    },
    beforeUpdate(){
        if (this.auxOrganizationID != JSON.parse(localStorage.getItem("user")).admin.organization_id) { 
            this.auxOrganizationID = JSON.parse(localStorage.getItem("user")).admin.organization_id
            console.log("kikiki", this.auxOrganizationID);
        }
    },
    // updated(){
    //     // console.log("1", this.auxOrganizationLS.organization_id, JSON.parse(localStorage.getItem("user")).admin.organization_id);
    //     if (this.auxOrganizationLS.organization_id != JSON.parse(localStorage.getItem("user")).admin.organization_id) {
    //         console.log("2");
    //         this.getData()
    //         this.auxOrganizationLS = JSON.parse(localStorage.getItem("user")).admin
    //     }
    // },
    methods: {
        updatePhonePrefix() {
            if (this.country) {
                const selectedCountry = this.countries.find(c => c.value === this.country);
                if (selectedCountry) {
                    this.phonePrefix = selectedCountry.phone_prefix;
                }
            }
        },
        parseRoleName(role){
            if(role){
                var role_name = role.replace('_', ' ')
                console.log(role_name)
                return role_name
            }
        },
        createOrganization(){
        this.$validator.validateAll(['input-org-name', 'input-org-vat', 'input-org-address1', 'input-org-country']).then(async result => {
            if (!result) {
                return
            }
            
            let dataToSend2 = {
              user_id: this.$store.state.auth.user.data.id,
              name: this.name,
              description: this.description,
              vat: this.vat,
              address1: this.address1,
              country: this.country
            }
            console.log(dataToSend2);
            OrganizationsDataService.create(dataToSend2).then(
                  () => {
                    // Hace que se cierre el modal cuando creamos el proyecto
                    this.modals.createOrg = false
                    this.$swal.fire({
                            icon: 'success',
                            title: this.$t('Alerts.CreatedOrganization'),
                        }).then(() => {
                            // this.modals.invite = false
                            this.getData()
                            this.name= null,
                            this.description = null,
                            this.vat = null,
                            this.address1 = null,
                            this.phone = null,
                            this.country = null
                        });       
                  }
            )
        })
        },
        applyRowClass(row) {
            if (row != null) {
                if (row.invitation.is_rejected) {
                return 'disabled-row';
                }  else {
                    return '';
                }
            }
        },
        async getData () {
            await OrganizationsDataService.getOrganizationsFromAUser(JSON.parse(localStorage.getItem("user")).data.id).then(
                async Response => {
                let organizations = Response.data.data
                console.log(organizations)
                for(let org of organizations){
                    await ExternalUserDataService.getUserRole(org.id, JSON.parse(localStorage.getItem("user")).data.id, 'false').then(
                        async Response => {
                        org.roles = Response.data.data
                        
                        },
                        Error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: Error.response.data.message
                            })
                        }
                    );
                } 
                this.organizations = organizations
                console.log(this.organizations)
                
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        validateState (ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref)
            }
            return null
        }
    }
    
}
</script>
<style>
.disabled-row {
  background-color: #f8f8f8;
  color: #999999;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.card-h-100 {
    height: 100%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #5D6176;
  background-color: #5D6176;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  border-color: #5D6176;
}
.custom-width-modal .modal-dialog {
    max-width: 1000px;
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
</style>