<template>
  <div 
    id="app" 
    class="app"
  >
  <template>
    <sidebar-menu ref="menu"
      v-if="currentRouteName === 'dashboard-staff' || currentRouteName === 'staff-management' || currentRouteName === 'ecosystems' || currentRouteName === 'ecosystem-services'
      || currentRouteName === 'parameters' || currentRouteName === 'ecosystems-edit' || currentRouteName === 'services-categories' || currentRouteName === 'services-types' || currentRouteName === 'profile' || currentRouteName === 'languages'
      || currentRouteName === 'ecosystems-language-edit' || currentRouteName === 'ecosystem-services-language-edit' || currentRouteName === 'parameters-language-edit'
      || currentRouteName === 'ecosystem-categories' || currentRouteName === 'ecosystem-types' || currentRouteName === 'posts'|| currentRouteName === 'add-post' || currentRouteName === 'posts-edit' || currentRouteName === 'post-categories'
      || currentRouteName === 'posts-multimedia' || currentRouteName === 'tutorials-categories' || currentRouteName === 'tutorials'|| currentRouteName === 'add-tutorial' || currentRouteName === 'tutorials-edit' 
      || currentRouteName === 'multimedia' || currentRouteName === 'settings-cookies' || currentRouteName === 'settings-emails' || currentRouteName === 'external-organizations' || currentRouteName === 'external-organizations-transfers'
      || currentRouteName === 'staff-layers' || currentRouteName === 'admin-panel-cadastre' || currentRouteName === 'admin-panel-data-layers' || currentRouteName === 'external-users'" 
      :menu="menu"  
      @toggle-collapse="onToggleCollapse" 
      @item-click="onItemClick"
      :width= this.sideBarWidth
    >
      <div @click="goToLanding" style="cursor: pointer"
        v-if="collapsedSideLogo"
        slot="header" 
        class="sidebar-img-container"
      >
        
          <span
            class="nav-item sidebar-img"
            to=""
          > 
            <img
              :src="$getImgUrlSite('LogoNoSpinOff.png')" 
              alt="logo"
            >
        </span>
      </div>
    </sidebar-menu>
    <sidebar-menu :class="{ 'sidebar-collapsed': $sidebarCollapsed }" ref="menu2"
      v-if="currentRouteName === 'home-user' || currentRouteName === 'users-management'|| currentRouteName === 'projects-management' || 
            currentRouteName === 'wallet' || currentRouteName === 'user-history' || currentRouteName === 'settings' || currentRouteName === 'add-project' ||
            currentRouteName === 'organization-settings' || currentRouteName === 'maps'|| currentRouteName === 'ncm-layers' || currentRouteName === 'invitations' || 
            currentRouteName === 'view-organization' || currentRouteName === 'users-management-pm' || currentRouteName === 'reports' || currentRouteName === 'pdf-design'"
      :menu="menuHome"  
      @toggle-collapse="onToggleCollapse2" 
      @item-click="onItemClick"
      :width= this.sideBarWidth
    >
      <div @click="goToLanding" style="cursor: pointer"
        v-if="collapsedSideLogo"
        slot="header" 
        class="sidebar-img-container"
      >
        
          <span
            class="nav-item sidebar-img"
            to=""
          > 
            <img 
              :src="$getImgUrlSite('LogoNoSpinOff.png')" 
              alt="logo"
            >
        </span>
        
      </div>
      <div v-if="collapsedSideLogo"  slot="header" style="display: flex; margin-top: 5%; margin-bottom: 2%; width: 100%; color: white;">
        <div class="row m-2">
          <div class="col d-flex">
            <p class="m-auto text-center">{{ $store.state.auth.user.data.name  + ' ' + $store.state.auth.user.data.surname + ' ' + '(' + $store.state.auth.user.data.email + ')'}}</p>
          </div>
        </div>
      </div>
      <div v-if="collapsedSideLogo"  slot="header" style="display: flex; margin-top: 5%; margin-bottom: 1%; width: 100%; color: white;">
        <!-- <a style=" text-align: center;">{{$t('SideBar.SelectOrg')}}:</a> -->
        <b v-if="getOrganizationsData.length === 1" style="display: flex; margin-top: 5%; margin-bottom: 5%; margin:auto; color: white;">{{ orgSelected.name }}</b>
        <drop-down v-else class="m-auto">
          <n-button
              slot="title"
              class="dropdown-toggle"
              
              type="primary"
              data-toggle="dropdown"
          >
              <b v-if="!orgSelected">{{$t('SideBar.SelectOrg')}}</b>
              <b v-else>{{ orgSelected.name }}</b>
          </n-button>
          <a v-for="(org, index) of getOrganizationsData"
            class="dropdown-item" style="cursor: pointer;" :key="index" @click="chooseOrg(org)"
          >
          <div>{{org.name}}</div>  
        </a> 
        </drop-down>
      </div>
      <div v-if="collapsedSideLogo" slot="header" style="color: white">
        <div class="row">
          <div class="col d-flex">
            <div class="card-avatar m-auto" v-if="orgSelected">
              <img  @click="goToViewOrg()" v-if="orgSelected.organization_image == null"
                  style="max-height: 100px; border-radius: 50%; cursor: pointer"
                  class="img img-raised"
                  :src="$getImgUrlSite('EJEMPLO-LOGO.png')"
              >
              <img style="max-height: 60px; border-radius: 50%;" class="img img-raised" v-else :src="getImgUrl(orgSelected.organization_image)">
              
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="collapsedSideLogo" slot="header" style="display: flex;cursor: pointer; margin: 10px; margin-top: 15px; color: white;">
        <a class="row" style="text-align: center; margin: 0 auto;">{{$t('SideBar.SelectRole')}}:</a>
      </div>
      <div class="row" v-if="collapsedSideLogo" slot="header" style="display: flex;cursor: pointer; color: white;">

        <b class="row m-auto" v-if="roles.length === 0" style="text-align: center; margin: 0 auto;">{{$t('SideBar.NoRoles')}}</b>
        <b class="row m-auto" v-else-if="roles.length === 1" style="text-align: center; margin: 0 auto;">{{ roles[0].user_role | formatRole }}</b>
        <drop-down v-else class="m-auto">
          <n-button
              slot="title"
              class="dropdown-toggle"
              type="primary"
              data-toggle="dropdown"
          >
              
              <b v-if="!roleSelected">{{$t('Roles.SelectRole')}}</b>
              <b v-else>{{ roleSelected.user_role | formatRole }}</b>
          </n-button>
          <a v-for="(role, index) of roles"
            class="dropdown-item" :key="index" @click="chooseRole(role, orgSelected)"
          >
          <div>{{role.user_role | formatRole}}</div>  
        </a> 
        </drop-down>
        
      </div>
      <div class="row" v-if="collapsedSideLogo" slot="header" style="display: flex;cursor: pointer; margin: 10px; margin-top: 15px; color: white;">
        <a class="row" style="text-align: center; margin: 0 auto;">{{$t('SideBar.SelectProject')}}:</a>
      </div>
      <div class="row" v-if="collapsedSideLogo" slot="header" style="display: flex; cursor: pointer; color: white; margin-bottom: 2%">
        <b v-if="projects.length === 0" style="margin: 0 auto; text-align: center;">{{$t('SideBar.NoProjects')}}</b>
        <b v-else-if="projects.length === 1" style="margin: 0 auto; text-align: center;">{{ projects[0].name }}</b>
        <drop-down v-else class="m-auto">
          <n-button
              slot="title"
              class="dropdown-toggle"
              type="primary"
              data-toggle="dropdown"
          >
              
              <!-- <b v-if="!roleSelected">{{$t('Roles.SelectRole')}}</b> -->
              <b v-if="$projectSelected.name">{{ $projectSelected.name }}</b>
              <b v-else>{{ $projectSelected }}</b>
          </n-button>
          <a v-for="(project, index) of projects"
            class="dropdown-item" :key="index" @click="chooseProject(project, orgSelected)"
          >
          <div>{{ project.name }}</div>  
        </a> 
        </drop-down>
        
      </div>
      
    </sidebar-menu>
  </template>
  <router-view name="header" />
  <router-view />
  <router-view name="footer" />
  </div>
</template>
<script>
import Login from './services/Login'
import { Badge, DropDown, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal} from '@/components';
import OrganizationsDataService from './services/OrganizationsDataService';
import ExternalUserDataService from './services/ExternalUserDataService';
import ProjectsDataService from './services/ProjectsDataService';
import WalletDataServices from './services/WalletDataServices';

import {emitter} from '@/main.js'
import ExternalOrganizationsTransfers from './pages/example-pages/ExternalOrganizationsTransfers.vue';

export default {  
  components: {
        DropDown,
        Modal,
        Button
    },
  data() {
    return {
        user: null,
        modals: {
          createOrg : false
        },
        is_side_bar_collapsed: window.innerWidth < 1783 ? true : false,
        dataFormOrg:{},
        user_id: null,
        roles: [],
        projects: [],
        collapsedSideLogo: false,
        orgSelected: null,
        roleSelected: null,
        projectSelected: null,
        organizations: -1,
        organizationData: [],
        countries : [
        { "text": "Afghanistan", "value": "AF" },
        { "text": "Åland Islands", "value": "AX" },
        { "text": "Albania", "value": "AL" },
        { "text": "Algeria", "value": "DZ" },
        { "text": "American Samoa", "value": "AS" },
        { "text": "Andorra", "value": "AD" },
        { "text": "Angola", "value": "AO" },
        { "text": "Anguilla", "value": "AI" },
        { "text": "Antarctica", "value": "AQ" },
        { "text": "Antigua and Barbuda", "value": "AG" },
        { "text": "Argentina", "value": "AR" },
        { "text": "Armenia", "value": "AM" },
        { "text": "Aruba", "value": "AW" },
        { "text": "Australia", "value": "AU" },
        { "text": "Austria", "value": "AT" },
        { "text": "Azerbaijan", "value": "AZ" },
        { "text": "Bahamas", "value": "BS" },
        { "text": "Bahrain", "value": "BH" },
        { "text": "Bangladesh", "value": "BD" },
        { "text": "Barbados", "value": "BB" },
        { "text": "Belarus", "value": "BY" },
        { "text": "Belgium", "value": "BE" },
        { "text": "Belize", "value": "BZ" },
        { "text": "Benin", "value": "BJ" },
        { "text": "Bermuda", "value": "BM" },
        { "text": "Bhutan", "value": "BT" },
        { "text": "Bolivia", "value": "BO" },
        { "text": "Bosnia and Herzegovina", "value": "BA" },
        { "text": "Botswana", "value": "BW" },
        { "text": "Bouvet Island", "value": "BV" },
        { "text": "Brazil", "value": "BR" },
        { "text": "British Indian Ocean Territory", "value": "IO" },
        { "text": "Brunei Darussalam", "value": "BN" },
        { "text": "Bulgaria", "value": "BG" },
        { "text": "Burkina Faso", "value": "BF" },
        { "text": "Burundi", "value": "BI" },
        { "text": "Cambodia", "value": "KH" },
        { "text": "Cameroon", "value": "CM" },
        { "text": "Canada", "value": "CA" },
        { "text": "Cape Verde", "value": "CV" },
        { "text": "Cayman Islands", "value": "KY" },
        { "text": "Central African Republic", "value": "CF" },
        { "text": "Chad", "value": "TD" },
        { "text": "Chile", "value": "CL" },
        { "text": "China", "value": "CN" },
        { "text": "Christmas Island", "value": "CX" },
        { "text": "Cocos (Keeling) Islands", "value": "CC" },
        { "text": "Colombia", "value": "CO" },
        { "text": "Comoros", "value": "KM" },
        { "text": "Congo", "value": "CG" },
        { "text": "Congo, The Democratic Republic of the", "value": "CD" },
        { "text": "Cook Islands", "value": "CK" },
        { "text": "Costa Rica", "value": "CR" },
        { "text": "Cote D'Ivoire", "value": "CI" },
        { "text": "Croatia", "value": "HR" },
        { "text": "Cuba", "value": "CU" },
        { "text": "Cyprus", "value": "CY" },
        { "text": "Czech Republic", "value": "CZ" },
        { "text": "Denmark", "value": "DK" },
        { "text": "Djibouti", "value": "DJ" },
        { "text": "Dominica", "value": "DM" },
        { "text": "Dominican Republic", "value": "DO" },
        { "text": "Ecuador", "value": "EC" },
        { "text": "Egypt", "value": "EG" },
        { "text": "El Salvador", "value": "SV" },
        { "text": "Equatorial Guinea", "value": "GQ" },
        { "text": "Eritrea", "value": "ER" },
        { "text": "Estonia", "value": "EE" },
        { "text": "Ethiopia", "value": "ET" },
        { "text": "Falkland Islands (Malvinas)", "value": "FK" },
        { "text": "Faroe Islands", "value": "FO" },
        { "text": "Fiji", "value": "FJ" },
        { "text": "Finland", "value": "FI" },
        { "text": "France", "value": "FR" },
        { "text": "French Guiana", "value": "GF" },
        { "text": "French Polynesia", "value": "PF" },
        { "text": "French Southern Territories", "value": "TF" },
        { "text": "Gabon", "value": "GA" },
        { "text": "Gambia", "value": "GM" },
        { "text": "Georgia", "value": "GE" },
        { "text": "Germany", "value": "DE" },
        { "text": "Ghana", "value": "GH" },
        { "text": "Gibraltar", "value": "GI" },
        { "text": "Greece", "value": "GR" },
        { "text": "Greenland", "value": "GL" },
        { "text": "Grenada", "value": "GD" },
        { "text": "Guadeloupe", "value": "GP" },
        { "text": "Guam", "value": "GU" },
        { "text": "Guatemala", "value": "GT" },
        { "text": "Guernsey", "value": "GG" },
        { "text": "Guinea", "value": "GN" },
        { "text": "Guinea-Bissau", "value": "GW" },
        { "text": "Guyana", "value": "GY" },
        { "text": "Haiti", "value": "HT" },
        { "text": "Heard Island and Mcdonald Islands", "value": "HM" },
        { "text": "Holy See (Vatican City State)", "value": "VA" },
        { "text": "Honduras", "value": "HN" },
        { "text": "Hong Kong", "value": "HK" },
        { "text": "Hungary", "value": "HU" },
        { "text": "Iceland", "value": "IS" },
        { "text": "India", "value": "IN" },
        { "text": "Indonesia", "value": "ID" },
        { "text": "Iran, Islamic Republic Of", "value": "IR" },
        { "text": "Iraq", "value": "IQ" },
        { "text": "Ireland", "value": "IE" },
        { "text": "Isle of Man", "value": "IM" },
        { "text": "Israel", "value": "IL" },
        { "text": "Italy", "value": "IT" },
        { "text": "Jamaica", "value": "JM" },
        { "text": "Japan", "value": "JP" },
        { "text": "Jersey", "value": "JE" },
        { "text": "Jordan", "value": "JO" },
        { "text": "Kazakhstan", "value": "KZ" },
        { "text": "Kenya", "value": "KE" },
        { "text": "Kiribati", "value": "KI" },
        { "text": "Korea, Democratic People'S Republic of", "value": "KP" },
        { "text": "Korea, Republic of", "value": "KR" },
        { "text": "Kuwait", "value": "KW" },
        { "text": "Kyrgyzstan", "value": "KG" },
        { "text": "Lao People'S Democratic Republic", "value": "LA" },
        { "text": "Latvia", "value": "LV" },
        { "text": "Lebanon", "value": "LB" },
        { "text": "Lesotho", "value": "LS" },
        { "text": "Liberia", "value": "LR" },
        { "text": "Libyan Arab Jamahiriya", "value": "LY" },
        { "text": "Liechtenstein", "value": "LI" },
        { "text": "Lithuania", "value": "LT" },
        { "text": "Luxembourg", "value": "LU" },
        { "text": "Macao", "value": "MO" },
        { "text": "Macedonia, The Former Yugoslav Republic of", "value": "MK" },
        { "text": "Madagascar", "value": "MG" },
        { "text": "Malawi", "value": "MW" },
        { "text": "Malaysia", "value": "MY" },
        { "text": "Maldives", "value": "MV" },
        { "text": "Mali", "value": "ML" },
        { "text": "Malta", "value": "MT" },
        { "text": "Marshall Islands", "value": "MH" },
        { "text": "Martinique", "value": "MQ" },
        { "text": "Mauritania", "value": "MR" },
        { "text": "Mauritius", "value": "MU" },
        { "text": "Mayotte", "value": "YT" },
        { "text": "Mexico", "value": "MX" },
        { "text": "Micronesia, Federated States of", "value": "FM" },
        { "text": "Moldova, Republic of", "value": "MD" },
        { "text": "Monaco", "value": "MC" },
        { "text": "Mongolia", "value": "MN" },
        { "text": "Montserrat", "value": "MS" },
        { "text": "Morocco", "value": "MA" },
        { "text": "Mozambique", "value": "MZ" },
        { "text": "Myanmar", "value": "MM" },
        { "text": "Namibia", "value": "NA" },
        { "text": "Nauru", "value": "NR" },
        { "text": "Nepal", "value": "NP" },
        { "text": "Netherlands", "value": "NL" },
        { "text": "Netherlands Antilles", "value": "AN" },
        { "text": "New Caledonia", "value": "NC" },
        { "text": "New Zealand", "value": "NZ" },
        { "text": "Nicaragua", "value": "NI" },
        { "text": "Niger", "value": "NE" },
        { "text": "Nigeria", "value": "NG" },
        { "text": "Niue", "value": "NU" },
        { "text": "Norfolk Island", "value": "NF" },
        { "text": "Northern Mariana Islands", "value": "MP" },
        { "text": "Norway", "value": "NO" },
        { "text": "Oman", "value": "OM" },
        { "text": "Pakistan", "value": "PK" },
        { "text": "Palau", "value": "PW" },
        { "text": "Palestinian Territory, Occupied", "value": "PS" },
        { "text": "Panama", "value": "PA" },
        { "text": "Papua New Guinea", "value": "PG" },
        { "text": "Paraguay", "value": "PY" },
        { "text": "Peru", "value": "PE" },
        { "text": "Philippines", "value": "PH" },
        { "text": "Pitcairn", "value": "PN" },
        { "text": "Poland", "value": "PL" },
        { "text": "Portugal", "value": "PT" },
        { "text": "Puerto Rico", "value": "PR" },
        { "text": "Qatar", "value": "QA" },
        { "text": "Reunion", "value": "RE" },
        { "text": "Romania", "value": "RO" },
        { "text": "Russian Federation", "value": "RU" },
        { "text": "RWANDA", "value": "RW" },
        { "text": "Saint Helena", "value": "SH" },
        { "text": "Saint Kitts and Nevis", "value": "KN" },
        { "text": "Saint Lucia", "value": "LC" },
        { "text": "Saint Pierre and Miquelon", "value": "PM" },
        { "text": "Saint Vincent and the Grenadines", "value": "VC" },
        { "text": "Samoa", "value": "WS" },
        { "text": "San Marino", "value": "SM" },
        { "text": "Sao Tome and Principe", "value": "ST" },
        { "text": "Saudi Arabia", "value": "SA" },
        { "text": "Senegal", "value": "SN" },
        { "text": "Serbia and Montenegro", "value": "CS" },
        { "text": "Seychelles", "value": "SC" },
        { "text": "Sierra Leone", "value": "SL" },
        { "text": "Singapore", "value": "SG" },
        { "text": "Slovakia", "value": "SK" },
        { "text": "Slovenia", "value": "SI" },
        { "text": "Solomon Islands", "value": "SB" },
        { "text": "Somalia", "value": "SO" },
        { "text": "South Africa", "value": "ZA" },
        { "text": "South Georgia and the South Sandwich Islands", "value": "GS" },
        { "text": "Spain", "value": "ES" },
        { "text": "Sri Lanka", "value": "LK" },
        { "text": "Sudan", "value": "SD" },
        { "text": "Suriname", "value": "SR" },
        { "text": "Svalbard and Jan Mayen", "value": "SJ" },
        { "text": "Swaziland", "value": "SZ" },
        { "text": "Sweden", "value": "SE" },
        { "text": "Switzerland", "value": "CH" },
        { "text": "Syrian Arab Republic", "value": "SY" },
        { "text": "Taiwan, Province of China", "value": "TW" },
        { "text": "Tajikistan", "value": "TJ" },
        { "text": "Tanzania, United Republic of", "value": "TZ" },
        { "text": "Thailand", "value": "TH" },
        { "text": "Timor-Leste", "value": "TL" },
        { "text": "Togo", "value": "TG" },
        { "text": "Tokelau", "value": "TK" },
        { "text": "Tonga", "value": "TO" },
        { "text": "Trinidad and Tobago", "value": "TT" },
        { "text": "Tunisia", "value": "TN" },
        { "text": "Turkey", "value": "TR" },
        { "text": "Turkmenistan", "value": "TM" },
        { "text": "Turks and Caicos Islands", "value": "TC" },
        { "text": "Tuvalu", "value": "TV" },
        { "text": "Uganda", "value": "UG" },
        { "text": "Ukraine", "value": "UA" },
        { "text": "United Arab Emirates", "value": "AE" },
        { "text": "United Kingdom", "value": "GB" },
        { "text": "United States", "value": "US" },
        { "text": "United States Minor Outlying Islands", "value": "UM" },
        { "text": "Uruguay", "value": "UY" },
        { "text": "Uzbekistan", "value": "UZ" },
        { "text": "Vanuatu", "value": "VU" },
        { "text": "Venezuela", "value": "VE" },
        { "text": "Viet Nam", "value": "VN" },
        { "text": "Virgin Islands, British", "value": "VG" },
        { "text": "Virgin Islands, U.S.", "value": "VI" },
        { "text": "Wallis and Futuna", "value": "WF" },
        { "text": "Western Sahara", "value": "EH" },
        { "text": "Yemen", "value": "YE" },
        { "text": "Zambia", "value": "ZM" },
        { "text": "Zimbabwe", "value": "ZW" }
        ],
        tempUser: 1,
        name: null,
        vat: null,
        address1: null,
        phone: null,
        country: null,
        description: null,
        menuHome: [],
        current_wallet_value: 0,
        tokensValue: 0,
        windowWidth: window.innerWidth,
        $sidebarCollapsed: false,
        sideBarWidth: '274px',
      }
    },
    watch: {
      $projectSelected(newValue){
          console.log('Project selected changed!', newValue)
          this.projectSelected = newValue
      },
      $tokensValue(newValue){
        console.log('Tokens value changed!', newValue)
      },
    },
    computed: {

      getOrganizationSelectedData(){
        return this.orgSelected
      },
      getOrganizationsData(){
          return this.organizationData
      },
      getOrganizations() {
          return this.organizations
      },
      currentRouteName () {
      return this.$route.name;
      },
      menu(){
        var menu = [
          {
              href: '/dashboard-staff',
              // title: 'Dashboard',
              title: this.$t("DashBoard.DashBoard"),
              icon: 'fa fa-chart-area'
          },
          {
              href: '/staff-management',
              // title: 'Staff management',
              title: this.$t("DashBoard.StaffManagement"),
              icon: 'fa fa-user',
              hidden: !this.userPermissions('organization_module'),
          },
          {
              href: '/languages',
              // title: 'Language Management',
              title: this.$t("DashBoard.LanguageManagement"),
              icon: 'fa fa-language',
              hidden: !this.userPermissions('ecosystem_language_module') && !this.userPermissions('settings_module'),
          },
          {
              // title: 'Ecosystems Management ',
              title: this.$t("DashBoard.EcosystemsManagement"),
              icon: 'fa fa-leaf',
              showChild: false,
              hidden: !this.userPermissions('ecosystem_language_module'),
              child: [
                  {
                      // title: 'Ecosystems',
                      title: this.$t("DashBoard.Ecosystems"),
                      child: [
                      { 
                        href: '/ecosystem-types',
                        // title: 'Types',
                        title: this.$t("DashBoard.Types"),
                      },
                      { 
                        href: '/ecosystem-categories',
                        // title: 'Categories',
                        title: this.$t("DashBoard.Categories"),
                      },
                      { 
                        href: '/ecosystems',
                        // title: 'List',
                        title: this.$t("DashBoard.List"),
                        alias: ['/ecosystems', '/ecosystems/:id']
                      }
                    ]
                  },
                  {
                      // title: 'Ecosystem services',
                      title: this.$t("DashBoard.EcosystemServices"),
                      child: [
                      { 
                        href: '/services-types',
                        title: this.$t("DashBoard.Types"),
                        // title: 'Types'
                      },
                      { 
                        href: '/services-categories',
                        title: this.$t("DashBoard.Categories"),
                        // title: 'Categories'
                      },
                      { 
                        href: '/ecosystem-services',
                        title: this.$t("DashBoard.List"),
                        // title: 'List'
                      }
                    ]
                  },
                  {
                      href: '/parameters',
                      title: this.$t("DashBoard.Parameters"),
                      // title: 'Parameters'
                  },
              ]
          },
          {
            title: 'Blog',
            icon: 'fa fa-th',
            hidden: !this.userPermissions('blog_module'),
            child: [
              { 
                // title: 'Posts',
                title: this.$t("DashBoard.Posts"),
                child: [
                  {
                    href:'/posts/categories',
                    title: this.$t("DashBoard.Categories"),
                    // title: 'Categories'
                  },
                  {
                    href:'/posts',
                    title: this.$t("DashBoard.Posts"),
                    // title: 'List',
                    alias: ['/posts', '/posts/add-post', '/posts/edit/:id']
                  }
                ]
              },
              {
                // title: 'Multimedia',
                title: this.$t("DashBoard.Multimedia"),
                href: '/multimedia'
              },
              { 
                // title: 'Tutorials',
                title: this.$t("DashBoard.Tutorials"),
                child: [
                  {
                    href:'/tutorials/categories',
                    // title: 'Categories',
                    title: this.$t("DashBoard.Categories"),
                  },
                  {
                    href:'/tutorials',
                    // title: 'List',
                    title: this.$t("DashBoard.Lists"),
                    alias: ['/tutorials', '/tutorials/add-tutorial', '/tutorials/edit/:id']
                  }
                ]
              },
            ]
          },
          {
              // title: 'External Organizations',
              title: this.$t("DashBoard.ExternalOrganizations"),
              icon: 'fa fa-user-circle',
              showChild: false,
              child: [
                  {
                      // title: 'List',
                      title: this.$t("DashBoard.List"),
                      href: '/external-organizations',
                  },
                  {
                      // title: 'Transfers',
                      title: this.$t("DashBoard.Transfers"),
                      href: '/external-organizations-transfers',
                  },
                  {
                      // title: 'Users',
                      title: this.$t("DashBoard.Users"),
                      href: '/external-users',
                  },
                ]
          },
          {
              // title: 'Map Layers',
              title: this.$t("DashBoard.MapLayers"),
              icon: 'fa fa-map',
              showChild: false,
              hidden: !this.userPermissions('organization_module'),
              child: [
                  {
                      // title: 'Map',
                      title: this.$t("DashBoard.Map"),
                      href: '/staff-layers',
                  },
                  {
                      // title: 'Cadastre',
                      title: this.$t("DashBoard.Cadastre"),
                      href: '/admin-panel-cadastre',
                  },
                  {
                      title: 'Data Layers',
                      href: '/admin-panel-data-layers',
                  },
                ]
          },
          {
              // title: 'Text Settings',
              title: this.$t("DashBoard.TextSettings"),
              icon: 'fa fa-cog',
              showChild: false,
              hidden: !this.userPermissions('settings_module'),
              child: [
                  {
                      title: 'Cookies',
                      href: '/settings-cookies',
                  },
                  {
                      title: 'Emails',
                      href: '/settings-emails',
                  },
                ]
          },
        ]
        return menu;
      },
    },
    updated() {
      if (localStorage.getItem('user') != null && this.tempUser == 1) {
      console.log('UPDATED') 
      this.tempUser = 2
      this.getData()
      //this.getUserRoles()
      //this.getUserMainRole()
      }
    },
    async created ()  {
      //this.user = JSON.parse(localStorage.getItem('user')).data
      //console.log('user', this.user)
      console.log('NCM')
      console.log('route:', this.currentRouteName)
      if(this.currentRouteName !== 'landing' && this.currentRouteName !== 'privacy'
        && this.currentRouteName !== 'cookies' && this.currentRouteName !== 'data-policy'
        && this.currentRouteName !== 'reset-password' && this.currentRouteName !== 'activeUser'
        && this.currentRouteName !== 'active-external-user' && this.currentRouteName !== 'explore'
        && this.currentRouteName !== 'Advantages' && this.currentRouteName !== 'sign-up'
        && this.currentRouteName !== 'sign-up-invitation' && this.currentRouteName !== 'sign-up-accept'
        && this.currentRouteName !== 'accept-invitation-analyst' && this.currentRouteName !== 'report-check'
        && this.currentRouteName !== 'report-download' && this.currentRouteName !== 'login'
        && this.currentRouteName !== 'staff' ){
          await Login.checkSession().then(
                Response => {
                    console.log('user verified')
                    this.onToggleCollapse(false)
                    this.onToggleCollapse2(false)
                    this.getData()
                    this.handleResize()
                },
                Error => {
                  this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Session token not verified, please login'
                    }).then(() => {
                        this.logout()
                    })
                }
            )
        }
      
      
      
    },
    watch: {
      windowWidth: {
        // Cuando se hace resize manual, entra a esta función
        handler(newWidth) {
          if (newWidth < 1783) {
            this.collapsedSideLogo = false;
            this.$sidebarCollapsed = true;
            emitter.emit('toggleSideBar');
            this.sideBarWidth = '50px';
          } else {
            this.collapsedSideLogo = true;
            this.$sidebarCollapsed = false;
            emitter.emit('toggleSideBar');
            this.sideBarWidth = '274px';
          }
        },
        immediate: true
      }
    },
    mounted() {
      // Llama a la función para verificar el tamaño inicial
      this.handleResize();
      // Añade un event listener para el evento resize
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      // Remueve el event listener cuando el componente se destruya
      window.removeEventListener('resize', this.handleResize);
    },
    methods : {
      handleResize() {
        this.windowWidth = window.innerWidth;
      },
      async findProjectsByOrganizationId() {
          if (this.orgSelected && this.roleSelected) {
            await ProjectsDataService.findProjectsByOrganizationIdSideBar(this.orgSelected.id, this.roleSelected.user_role).then(
              Response => {
                  this.projects = Response.data.data   
                  this.projectSelected = null
                  if(this.projects){
                    if(this.projects.length > 0){
                      this.projectSelected = this.projects[0] 
                      this.$projectSelected = this.projectSelected
                    }
                  }
                  
                  this.$currentProjects = this.projects
                  
              },
              Error => {
                this.$swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: Error.response.data.message
                  })
              }
          )
        }   
      },    
      async getUserMainRole(){
        const favoriteRole = await this.roles.find(item => {return item.is_favourite === true});
        
        if (favoriteRole) {
          this.roleSelected = favoriteRole
          this.$roleSelected = this.roleSelected
        } 
        else 
        {
          if(this.roles.find(item => {return item.user_role == 'admin'})){
            
            this.roleSelected = this.roles.find(item => {return item.user_role == 'admin'})
            console.log(this.roleSelected)
            this.$roleSelected = this.roleSelected
          }
          else if(this.roles.find(item => {return item.user_role == 'project_manager'})){
            this.roleSelected = this.roles.find(item => {return item.user_role == 'project_manager'})
            this.$roleSelected = this.roleSelected
          }
          else if(this.roles.find(item => {return item.user_role == 'analyst'})){
            this.roleSelected = this.roles.find(item => {return item.user_role == 'analyst'})
            this.$roleSelected = this.roleSelected
          }
          else{
            this.roleSelected = undefined
            this.$roleSelected = this.roleSelected
          }

        } 
      },

      async getUserRoles(){
        console.log('Entramos ahora a buscar los roles en esta organizacion', this.orgSelected)
        await ExternalUserDataService.getUserRole(this.orgSelected.id, JSON.parse(localStorage.getItem('user')).data.id, 'false').then(
            async Response => {
              this.roles = Response.data.data
            },
            Error => {
              this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: Error.response.data.message
              })
            }
        );
      },
      async getData(){
        if (JSON.parse(localStorage.getItem('user')).noStaff) {
          await OrganizationsDataService.getOrganizationsFromAUser(JSON.parse(localStorage.getItem('user')).data.id).then(
              async Response => {
              this.organizationData = Response.data.data
              console.log(this.organizationData)
              if(this.organizationData.length > 0 && !this.orgSelected){
                if (JSON.parse(localStorage.getItem('user')).data.favourite_organization){
                  this.orgSelected = this.organizationData.find(org => org.id == JSON.parse(localStorage.getItem('user')).data.favourite_organization)
                }else{
                  this.orgSelected = this.organizationData[0]
                }
                this.$orgSelected = this.orgSelected         
              }

              },
              Error => {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: Error.response.data.message
                })
              }
          )
        }
        await this.getUserRoles()
        await this.getUserMainRole()
        await this.findProjectsByOrganizationId()
        await this.getTokensValue()
        this.selectMenu()
        
        

      },
      async chooseOrg (org) {
                
          this.orgSelected = org
          console.log('org selected view', this.orgSelected)
          this.$orgSelected = this.orgSelected
          console.log('org selected global', this.$orgSelected)
          await this.getUserRoles()
          if (this.$route.name != 'home-user') {
            this.$router.push({name: 'home-user'})
          }
          // await this.getUserRoles()
          
          // await this.getData()       
      },
      chooseProject(project, orgSelected){
        
          this.projectSelected = project
          this.$projectSelected = this.projectSelected
          this.getTokensValue()
          console.log(this.projectSelected)

        },
      selectMenu(){
        
        if (this.roleSelected.user_role == 'project_manager') {
            this.menuHome = [
              {
                  href: '/home-user',
                  // title: 'Home',
                  title: this.$t("DashBoard.Home"),
                  icon: 'fa fa-home'
              },
              {
                  href: '/projects-management',
                  // title: 'Projects Management',
                  title: this.$t("DashBoard.ProjectsManagement"),
                  icon: 'fa fa-book',
              },
              {
                  href: '/reports',
                  // title: 'Reports',
                  title: this.$t("DashBoard.ReportsManagement"),
                  icon: 'fa fa-file',

              },
              {
                  href: '/ncm-layers',
                  // title: 'NCM layers',
                  title: this.$t("DashBoard.NCMLayers"),
                  hidden: this.$roleSelected.user_role == 'project_manager',
                  icon: 'fa fa-map',
              },
              {
                  href: '/wallet',
                  // title: 'Wallet',
                  title: this.$t("DashBoard.Wallet"),
                  icon: 'fa fa-credit-card',
              },
              {
                  href: '/view-organization',
                  // title: 'Wallet',
                  title: this.$t("DashBoard.ViewOrg"),
                  icon: 'fa fa-building',
              },
            ]
            
          } else if(this.roleSelected.user_role == 'admin') {
            this.menuHome = [
              {
                  href: '/home-user',
                  // title: 'Home',
                  title: this.$t("DashBoard.Home"),
                  icon: 'fa fa-home'
              },
              {
                // title: 'External Organizations',
                title: this.$t("DashBoard.Users"),
                icon: 'fa fa-users',
                showChild: false,
                child: [
                    {
                        title: this.$t("DashBoard.Members"),
                        href: '/users-management',
                        icon: 'fa fa-user'
                    },
                    {
                        title: this.$t("DashBoard.UserHistory"),
                        href: '/user-history',
                        icon: 'fa fa-history'
                    },
                    {
                        title: this.$t("DashBoard.Invitations"),
                        href: '/invitations',
                        icon: 'fa fa-inbox'
                    },
                  ]
              },
              {
                  href: '/projects-management',
                  // title: 'Projects Management',
                  title: this.$t("DashBoard.ProjectsManagement"),
                  icon: 'fa fa-book',


              },
              {
                  href: '/reports',
                  // title: 'Reports',
                  title: this.$t("DashBoard.ReportsManagement"),
                  icon: 'fa fa-file',

              },
              {
                  href: '/ncm-layers',
                  // title: 'NCM layers',
                  title: this.$t("DashBoard.NCMLayers"),
                  icon: 'fa fa-map',
              },
              {
                  href: '/wallet',
                  // title: 'Wallet',
                  title: this.$t("DashBoard.Wallet"),
                  icon: 'fa fa-credit-card',
              },
              {
                  href: '/organization-settings',
                  // title: 'Settings',
                  title: this.$t("OrganizationSettings.Title"),
                  icon: 'fa fa-cog',
              },
              {
                  href: '/view-organization',
                  // title: 'Wallet',
                  title: this.$t("DashBoard.ViewOrg"),
                  icon: 'fa fa-building',
              },
            ]
            
          } else if (this.roleSelected.user_role == 'analyst'){
            this.menuHome = [
              {
                  href: '/home-user',
                  // title: 'Home',
                  title: this.$t("DashBoard.Home"),
                  icon: 'fa fa-home'
              },
              {
                  href: '/projects-management',
                  // title: 'Projects Management',
                  title: this.$t("DashBoard.Projects"),
                  icon: 'fa fa-book',
              },
              {
                  href: '/reports',
                  // title: 'Reports',
                  title: this.$t("DashBoard.Reports"),
                  icon: 'fa fa-file',

              },
              {
                  href: '/ncm-layers',
                  // title: 'NCM layers',
                  title: this.$t("DashBoard.NCMLayers"),
                  hidden: this.$roleSelected.user_role == 'project_manager',
                  icon: 'fa fa-map',
              },
              {
                  href: '/view-organization',
                  // title: 'Wallet',
                  title: this.$t("DashBoard.ViewOrg"),
                  icon: 'fa fa-building',
              },
            ]
            this.$swal.fire({
              icon: 'Success',
              title: this.$t("Roles.MessageAnalyst") + this.orgSelected.name,
              text: ''
            });
          } else if(this.roleSelected.user_role == 'No role'){
            this.menuHome = [
              // {
              //     href: '/home-user',
              //     // title: 'Home',
              //     title: this.$t("DashBoard.Home"),
              //     icon: 'fa fa-home'
              // },
            ]
          }
          console.log(this.currentRouteName)
          console.log(this.menuHome.filter(f => f.href == '/' + this.currentRouteName))
          if(this.menuHome.filter(f => f.href == '/' + this.currentRouteName).length == 0){
            //this.$router.push('/home-user')
          }

          // console.log(this.$refs['menu2'])
          // console.log(this.$refs['menu2'].$children.length)

      },
      async chooseRole(role, orgSelected){
          this.roleSelected = role
          this.$roleSelected = this.roleSelected
          
          this.selectMenu()
          await this.findProjectsByOrganizationId()
          this.getTokensValue()
          if(this.roleSelected.user_role == 'admin'){
            this.$swal.fire({
              icon: 'Success',
              title: this.$t("Roles.MessageAdmin") + this.orgSelected.name,
              text: ''
            });
          }
          else if(this.roleSelected.user_role == 'project_manager'){
            await this.$router.push('/home-user')
            this.$swal.fire({
              icon: 'Success',
              title: this.$t("Roles.MessagePM") + this.orgSelected.name,
              text: ''
            });
            
          }
          else if(this.roleSelected.user_role == 'analyst'){
            await this.$router.push('/home-user')
            this.$swal.fire({
              icon: 'Success',
              title: this.$t("Roles.MessageAnalyst") + this.orgSelected.name,
              text: ''
            });
          }
          
        // }
      },
      getTokensValue(){
        
        console.log('VAMOS A ENTRAR COMO ', this.roleSelected.user_role)
        console.log('this.$tokensValue', this.$tokensValue)
        if(this.roleSelected.user_role == 'admin'){
          if (this.orgSelected) {
            WalletDataServices.getTokens(this.orgSelected.id).then(
                Response => {
                    console.log('this.$tokensValue', this.$tokensValue)
                    this.$tokensValue = Response.data.balance.toFixed(2)
                    console.log(this.$tokensValue)
                },
                Error => {
                  this.$swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: Error.response.data.message
                  })
                }
            )
          }
        }
        else if (this.roleSelected.user_role == 'project_manager'){
          if (this.orgSelected && this.roleSelected && this.$projectSelected) {
          WalletDataServices.getTokens(this.orgSelected.id, this.roleSelected.user_role, this.$projectSelected.id).then(
                      Response => {
                        this.$tokensValue = Response.data.balance.toFixed(2)
                      },
                      Error => {
                        this.$swal.fire({
                          icon: 'error',
                          title: 'Error',
                          text: Error.response.data.message
                        })
                      }
                  )
          }
      }
      else if(this.roleSelected.user_role == 'analyst'){
        this.$tokensValue = this.$projectSelected.tokens_available.toFixed(2)
        console.log('this.$tokensValue analyst', this.$tokensValue)
        console.log(this.$projectSelected)
        }
      },
      userPermissions(permission){
        //var user = JSON.parse(localStorage.getItem('user'))
        var user = JSON.parse(localStorage.getItem('user'))
        if(user.data.is_superadmin)
          return true
        else
          if(user.data.role)
            return user.data.role[permission];
          else
            return false
      },
      goToLanding(){
        this.$router.push('/')
      },
      goToViewOrg(){
        this.$router.push('/view-organization')
      },
      logout () {
        Login.logout()
        this.$router.push({ path: '/' })
        this.$store.state.auth.status.loggedIn = false
        //JSON.parse(localStorage.getItem('user')) = null
      },
      onToggleCollapse(collapsed) {
        if (!collapsed) {
          console.log('!collapsed1', collapsed);
          if(this.windowWidth < 1783){
            this.collapsedSideLogo = false
          }else{
            this.collapsedSideLogo = true
          }
          $('.dashboard-wrapper').removeClass('collapsed-sidebar1')
          $('.breadcrumb ').removeClass('responsive-nav-1')
          $('.breadcrumb').addClass('responsive-nav-2')
          $('.dashboard-wrapper').addClass('collapsed-sidebar')
          $('.navbar-translate').addClass('navbar-translate-expanded')
          this.$sidebarCollapsed = false
          emitter.emit('toggleSideBar')
        } else {
          // Sin expandir
          console.log('collapsed1', collapsed);
          this.collapsedSideLogo = false
          $('.dashboard-wrapper').removeClass('collapsed-sidebar')
          $('.breadcrumb ').removeClass('responsive-nav-2')
          $('.breadcrumb').addClass('responsive-nav-1')
          $('.dashboard-wrapper').addClass('collapsed-sidebar1')
          $('.navbar-translate').removeClass('navbar-translate-expanded')
          this.$sidebarCollapsed = true
          emitter.emit('toggleSideBar')
        }
      },
      onToggleCollapse2(collapsed) {
        // this.is_side_bar_collapsed =  collapsed
        if (!collapsed) {
          if(this.windowWidth < 1783){
            this.collapsedSideLogo = false
          }else{
            this.collapsedSideLogo = true
          }
          $('.wrapper-home').removeClass('collapsed-sidebar1')
          $('.wrapper-home').addClass('collapsed-sidebar')
          $('.navbar-translate').addClass('navbar-translate-expanded')
          this.$sidebarCollapsed = false
          emitter.emit('toggleSideBar')
        } else {
          // Sin expandir
          console.log('collapsed2', collapsed);
          this.collapsedSideLogo = false
          $('.wrapper-home').removeClass('collapsed-sidebar')
          $('.wrapper-home').addClass('collapsed-sidebar1')
          $('.navbar-translate').removeClass('navbar-translate-expanded')
          this.$sidebarCollapsed = true
          emitter.emit('toggleSideBar')
        }
      },
      onItemClick(event, item, node) {
        var menu = this.$refs.menu
        console.log('event:', event)
        console.log('item:', item)
        console.log('node:', node)
        console.log('menu:', menu)
        if (item.title === "Log Out") {
          $('.app').removeClass('collapsed-sidebar')
          $('.app').removeClass('collapsed-sidebar1')
          this.logout()
        }
      },
      validateState (ref) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.veeErrors.has(ref)
        }
        return null
      },
      getImgUrl (name) {
            try{
                if(name){
                    return `${process.env.VUE_APP_VITE_BUCKET_CDN}/organization-images/${name}?withheaders=yes`
                    /// Regular exp for png: \.png$/
                    //var images = require.context('../../assets/profile_images/', false, /\.png$/)
                    //console.log(images('./' + name))
                    //return images('./' + name)
                }
            }catch{
                this.imagePath = null
            }
        },
    }
  }
</script>
<style>
.navbar p {
  color: white !important;
}

.title {
  text-transform: uppercase;

}
.card-title {
  text-transform: uppercase;
}
.btn {
text-transform: uppercase;
}
.responsive-nav-1 {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
    margin-left: 0px !important;
}
 .responsive-nav-2 {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
    margin-left: 165px !important;
}

 #usersTable tr {
  height: 50px !important;
}
#projectTable tr {
  height: 50px !important;
}
.plan-card{
  border-radius:5% !important;
  background-color: rgba(255, 255, 255, 0.9);
  min-height: 570px;
  max-height: 570px;
}
.plan-card-2{
  border-radius:3% !important;
  background-color: rgba(255, 255, 255, 0.9);
  min-height: 370px;
  max-height: 370px;
}
.fixed-size-image {
  max-width: 350px;
  min-width: 350px;
  min-height: 200px;
  max-height: 200px;
  object-fit: cover; 
}
.fixed-size-image-tuto {
  max-width: 250px;
  min-width: 250px;
  min-height: 150px;
  max-height: 150px;
  object-fit: cover; 
}
.card-blog-tutorial{
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.card-blog-tutorial:hover {
  transform: scale(1.1);
}
.image-upload-container {
  justify-content: center;
  align-items: center;
}

.image-upload-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px dashed grey;
  padding: 20px;
  margin: 15px;
  cursor: pointer;
}

.image-upload-text {
  font-size: 18px;
  margin-top: 10px;
}

.image-upload-icon {
  margin-top: 10px;
  font-size: 24px;
  color: grey;
}
table,tr,th,td {
    text-align:center;

}

.table-title{
  text-align: left !important;
}

.table > thead > tr > th{
    border-bottom-width: 1px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    border: 0 !important;
}

.table td{
  padding: 1px !important;
}

.collapsed-sidebar {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-left: 12%;
}
.collapsed-sidebar1 {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-left: 0px;
}
.navBarDash.navbar-transparent.navbar {
    background-color: #1e1e21 !important;
    position: fixed;
    top: 0;
    z-index: 1;
    color: aliceblue !important;
}
.navBarDash.navbar {
    position: fixed;
    top: 0;
    z-index: 1;
    color: aliceblue !important;
    max-height: 66px !important;
}

/* .navBarExternal.navbar-transparent.navbar {
    background-color: #000000 !important;
    position: fixed;
    top: 0;
    z-index: 1;
    color: aliceblue !important;
}
.navBarExternal.navbar {
    position: fixed;
    top: 0;
    z-index: 1;
    color: aliceblue !important;
} */


.check-title-icon{
  margin-left: 10px; 
  font-size: 26px; 
  color: #5D6176; 
  cursor: pointer
}

.b-form-tag{
  font-size: 90% !important;
  color: #fff !important;
  border-color: #5D6176 !important;
  background-color: #5D6176 !important;
  padding: 4px 8px !important;
  text-transform: uppercase !important;
  font-size: 0.8142em !important;
  line-height: 12px !important;
  border: 1px solid !important;
  margin-bottom: 5px !important;
  color: #fff;
  border-radius: 0.875rem !important;
  display: inline-block !important;
  font-weight: 700 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
}

.b-form-tags{
  padding: 16px !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 5px !important;
}

.form-control{
  height: 100% !important;
}

/* .navBarDash .container{
  margin-right: 23px !important;
}
.navBarExternal .container{
  margin-right: 23px !important;
} */

.btn:disabled{
  cursor: not-allowed !important;
}
.icon-title-dashboard {
  margin-top: 0 !important;
  margin-left: 15px !important;
  border: none !important;
}
.action-table{
  font-size: 13px !important;
  background-color: transparent !important;
  color: black !important;
  padding: 8px !important;
}
.public-private-posts:hover{
  font-size: 13px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 8px !important;
  cursor:default !important;
}
.public-private-posts {
  font-size: 13px !important;
  background-color: transparent !important;
  color: black !important;
  padding: 8px !important;
}
.trash {
  color: rgb(200, 21, 21) !important;
}
.pen {
  color: #043927 !important;
}
.action-table:hover{
  font-size: 13px !important;
  background-color: transparent !important;
  opacity: 0.5 !important;
  box-shadow: none !important;
  padding: 8px !important;
}

.b-tooltip{
  color: white !important;
  font-weight: 500 !important;
}

.tooltip-inner{
  background-color: black !important;
  box-shadow: 0px 0px 4px black;
  opacity: 1 !important;
}

.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: black !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: black !important;
}

.invalid-feedback{
  margin-left: 4% !important;
}

.v-sidebar-menu .vsm--link { 
  font-size: 13px !important;
  cursor: pointer;
}
.v-sidebar-menu .vsm--link .vsm--link_level-1  { 
  font-size: 13px !important;
}
@layer { 
  .v-sidebar-menu.vsm_collapsed .vsm--link_level-1.vsm--link_hover, .v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover {background-color: transparent !important;} 
}
/* .v-sidebar-menu {
  cursor: pointer;
} */
.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  background-color: black  !important;

}
.v-sidebar-menu .vsm--mobile-item {
  background-color: #5D6176!important;
}
.v-sidebar-menu .vsm--mobile-bg {
  background-color: #5D6176!important;
}
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1.vsm--link_hover .vsm--icon {
  background-color: #5D6176!important;
}
.vsm--list {
  margin: 0 !important;
  padding: 0 !important;
}
.v-sidebar-menu .vsm--mobile-item >.vsm--item {
  background-color: #5D6176!important;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 {
  background-color: #5D6176!important;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-3 vsm-title{
  text-indent: 28px !important;
}
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1.vsm--link_hover, .v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover {
    background-color: transparent !important;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-3 {
  opacity: 100% !important;
  text-indent: 28px !important;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1.vsm--link_active{
  background-color: #5D6176!important;
}
/* .v-sidebar-menu.vsm_expanded .vsm--item_open {
  background-color: #5D6176!important
} */
.v-sidebar-menu .vsm--link_exact-active, .v-sidebar-menu .vsm--link_active {
  background-color: #5D6176 !important;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon:active {
  background-color: transparent !important;

}
.v-sidebar-menu .vsm_collapsed .vsm--link_level-1 .vsm--link_hover .vsm--icon, .v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover .vsm--icon{
  background-color: transparent !important;
}
.v-sidebar-menu .vsm_collapsed .vsm--link_level-1 .vsm--link_hover , .v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover {
  background-color: transparent !important;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
  background-color: transparent !important;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1{
  background-color: transparent !important;
}
.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active, .v-sidebar-menu .vsm--link_level-1.vsm--link_active {
    -webkit-box-shadow: 3px 0px 0px 0px  transparent   !important;
    box-shadow: 3px 0px 0px 0px  transparent  !important;
}
.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active, .vsn--link_hover.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
    -webkit-box-shadow: 3px 0px 0px 0px transparent inset !important;
    box-shadow: 3px 0px 0px 0px  transparent inset !important;
}

.navBarDash .container{
  margin-left: 2% !important;
  max-width: 100% !important;
}
.navBarExternal .container{
  margin-left: 0% !important;
  max-width: 100% !important;
}

.breadcrumb{
  background-color: transparent !important;
  margin-bottom: 0px !important;
}

.breadcrumb-item.active {
    color: #ffffff !important;
    font-weight: 700 !important;
}

.p-dashboard{
  font-size: 16px !important;
  font-weight: 500 !important;
}

.info-container-empty{
  border: 1px solid #00000061;
  height: 95%;
  border-radius: 12px;
  background-color: #d2d2d736;
}

.info-container{
  padding: 30px;
  border: 1px solid #00000061;
  height: 95%;
  border-radius: 12px;
  background-color: #d2d2d736;
}

.text-centered{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link-forgot{
  color: #fff !important;
}
.link-forgot:hover{
  color: #5D6176!important;
}
.card-body{
  height: 100% !important;
}

.info-group{
  margin-right: 10% !important;
  margin-left: 1% !important;
  text-align: left !important;
}

.info-group .info-group-label{
  font-size: 16px !important;
  font-weight: 700 !important;
  padding-bottom: 0px !important;
  
}
.swal2-styled.swal2-confirm {
  background-color: #5D6176!important;
}

.swal2-styled.swal2-deny{
  background-color: #5D6176!important;
}
/* .swal2-styled.swal2-confirm:focus {
  -webkit-box-shadow: #5D6176!important;
  box-shadow: #5D6176!important;
}
.swal2-styled.swal2-confirm:focus {
    -webkit-box-shadow: #5D6176!important;
    box-shadow: 0 0 0 3px #5D6176!important;
} */
.info-group .info-group-input{
  background-color: transparent !important;
    border: none !important;
    color: black !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    padding-top: 0px !important;
    padding-left: 8px !important;
    cursor: text !important;
}
.nav-landing{
  color: black !important;
  font-weight: 600 !important;
}
.nav-home{
  color: rgb(243, 243, 243) !important;
  font-weight: 600 !important;
}
.sidebar-img-container{
  height: 8% !important;
}

.sidebar-img{
  margin: 0;
  position: absolute;
  top: 4%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.v-sidebar-menu {
  background-color: #1e1e21 !important;
}

.navbar-translate-expanded{
  margin-left: 30%;
}

.page-item.active .page-link {
    z-index: 3 !important;
    color: #fff !important;
    background-color: #5D6176!important;
    border-color: #5D6176!important;
}
.btn-tables:hover {
  color: rgb(255, 255, 255) !important;
  background-color: #5D6176!important;
}
.pagination-select{
  min-width: 7% !important;
  max-width: 7% !important;
}

.dashboard-table{
  overflow-x: auto !important; 
  /* max-height: 415px !important;  */
  /* min-height: 415px !important; */
}
.dashboard-table-role{
  overflow-x: auto !important; 
  max-height: 325px !important; 
  /* min-height: 325px !important; */
}
.table-role{
  overflow-x: auto !important; 
  max-height: 325px !important; 
  /* min-height: 325px !important; */
}
.table-role td{
  border-top: none !important; 
  /* min-height: 325px !important; */
}
@layer {
  .pagination .page-item .page-link {
  border-radius: 0px !important;
  }
}


.label-dashboard{
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 3 !important;
}

.ncm-btn{
  cursor: pointer !important;
  border-radius: 20px !important;
  border-color: #5D6176!important;
  color: #5D6176!important;
  font-weight: 800 !important;
}
.ncm-btn:hover{
  color: white !important;
  background-color: #5D6176!important;
}

.ncm-btn-danger{
  font-weight: 800 !important;
  border-radius: 20px !important;
  border-color: #cc0101!important;
  color: #cc0101!important;
}

.ncm-btn-danger:hover{
  color: white !important;
  background-color: #cc0101!important;
}
.ncm-btn-back{
  font-weight: 800 !important;
  border-radius: 20px !important;
  border-color: #5D6176!important;
  color: #5D6176!important;
  font-weight: 800 !important;
}
.ncm-btn-back:hover{
  color: white !important;
  background-color: #5D6176!important;
}

.ncm-btn-danger{
  font-weight: 800 !important;
  border-radius: 20px !important;
  border-color: #ff0000 !important;
  color: #ff0000 !important;
  font-weight: 800 !important;
}

.ncm-btn-danger:hover{
  color: white !important;
  background-color: #ff0000 !important;
}

.ncm-btn-secondary{
  font-weight: 800 !important;
  border-radius: 20px !important;
  border-color: #000000 !important;
  color: #000000 !important;
  font-weight: 800 !important;
}

.ncm-btn-secondary:hover{
  color: white !important;
  background-color: #000000 !important;
}

.button-icon{
  margin-right: 5px !important;
}

.modal.modal-mini .modal-footer button:first-child {
  opacity: 1 !important;
}

/* .modal.modal-mini .modal-footer button:last-child {
  opacity: 0.5 !important;
}

.modal.modal-primary .modal-footer button:last-child {
  opacity: 0.5 !important;
} */

.contact input::placeholder {
  color: rgb(221, 221, 221) !important;
}
.contact input:focus {
  color: rgb(0, 0, 0) !important;
}
.contact input:focus::placeholder {
  color: rgb(0, 0, 0) !important;
}

.contact-input.is-valid{
  color: white !important;
}

.contact-input.is-invalid{
  color: white !important;
}

.contact textarea {
  color: #FFF !important;
}
.contact textarea:focus {
  color: rgb(0, 0, 0) !important;
}

.login-button-link{
  margin-left: auto;
  margin-top: 3%;
  height: 100%;
}

.table-input{
  border-radius: 0px !important;
}

.section-image .description, .section-image .info .icon:not(.icon-circle) {
    color: rgb(255 255 255) !important;
}

.btn-landing{
  background-color: #5D6176!important;
}
.input-traduction-table{
  max-width: 175px;
  min-width: 175px;
}

.input-language-table{
  max-width: 100px;
  min-width: 100px;
}

.img-title{
  text-align: center;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}

.page-header.page-header-small {
    min-height: 32vh !important;
    max-height: 440px;
}

.category{
  font-size: 16px !important;
}

.ql-editor{
  max-height: 350px !important;
}

.modal-big{
  min-width: 40%;
}
.dropdown-item.active {
  font-weight: bold; 
  background-color: white !important;
  color: #5D6176 !important/* Establece el estilo de fuente para la opción seleccionada */
}


.dz-error-message{
  top: 5px !important;
}

body {
  overflow: auto !important;
}

.ql-editor iframe {
    pointer-events: none;
}

.dz-preview{
  min-width: 200px !important;
  min-height: 200px !important;
}

.max-w-300{
  max-width: 300px !important;
}


.breadcrumb-item a{
  color: white !important;
}

.nav-dropdown a{
  color: white !important;
}

.navBarDash.bg-white {
  background-color: #1e1e21 !important;
}
.navBarExternal.bg-white {
  background-color: #5D6176 !important;
}

.table-image{
  object-fit: cover;
  margin-top: 0.5rem;
}

.alert.alert-success {
    background-color: rgb(44 121 118) !important;
}

.navbar.navbar-transparent {
  background-color: white !important;
}
.create-org{
  font-weight: 600 !important;
  color: rgb(44 121 118) !important;
  background-color: #c8c9cc !important;
}

.iconPass{
    margin: auto!important;
    height: 20px!important;
    width: 20px!important;
    margin-left: 10px!important;
    margin-top: 20px;
    padding-top: 1px;
    color: #5D6176 !important;
  }

  .show-pass-col{
    margin-left: -20% !important; 
    margin-top: 4% !important;
  }

  .admin-small-box {
    position: relative;
  }

  .bg-success {
    background-color: #19a185;
  }

  .small-box {
    border-radius: 2px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  }

  .admin-small-box .inner {
    position: relative;
    color: #fff !important;
    padding: 20px;
  }

  .admin-small-box .icon {
    top: 0px;
    right: 20px;
    z-index: 0;
    font-size: 60px;
    color: rgba(0, 0, 0, 0.10);
  
  
  }

  .small-box .icon {
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    position: absolute;
    top: 0px;
    right: 10px;
    z-index: 0;
    font-size: 60px;
    color: rgba(0,0,0,0.15);
  }

  .admin-small-box .icon a {
    color: rgba(0, 0, 0, 0.10) !important;
    outline: none !important;
  }

  .small-box h3 {
    font-size: 38px;
    font-weight: bold;
    margin: 0 0 10px 0;
    white-space: nowrap;
    padding: 0;
}

.admin-small-box .inner a {
    color: #fff !important;
}

.navbar-translate {
    margin-left: 2% !important;
}

.dz-preview{
  z-index: 0 !important;
}

.img-rounded{
  max-width: 100%;
  border-radius: 50%;
}

.dropdown-toggle:after{
  margin-left: 10px !important;
  font-size: 17px !important;
}


.modal-wms .modal-dialog{
    max-width: 60% !important;
    transform: translate(0, 30%) !important;
    /* position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important; */
}

.modal-upload .modal-dialog{
    max-width: 70% !important;
    /* position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important; */
}

td.table-title{
  margin-left: 1% !important;
}


.ol-geocoder.gcd-gl-container {
    top: 21.875em !important;
}

body strong {
  display: none;
}
.dropdown-list{
  max-width: 80px;
}
.navBarDash.navbar{
    z-index: 700;
}

.vue-country-select{
    border: 1px solid transparent !important;;
}
.vue-country-select .dropdown:hover{
    background-color: transparent!important;
}

.vue-country-select .dropdown.open{
    background-color: transparent!important;
}

.locale1{
  margin-right: 10px !important;
  color: black !important;
}

.vue-country-select:focus-within {
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.08), 0 0 rgba(255, 255, 255, 0.6) !important;    
  border-color: #ffffff;
}
.vue-country-select .dropdown-list {
    z-index: 1;
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    max-height: 200px;
    overflow-y: hidden !important; 
    position: absolute;
    top: 100%;
    left: -1px;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 390px;
}
.tooltip {
  border-bottom: 0px solid #000000 !important;
}
</style>