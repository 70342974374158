<template>
    <div class="page-header signup-page section-image">
        <div class="page-header-image"
        :style="{'background-image': 'url(' + $getImgUrlSite('bg17.jpg') +')'}"/>
        <div class="content-sign">
            <div class="container">
                <b-row>
                    <b-col class="m-auto col-md-8 col-lg-6">                        
                        <BCard class="mt-5" style="border-radius: 2.5%;"> 
                            <div
                                slot="header"
                                class="logo-container"
                            >
                                <img
                                    v-lazy="$getImgUrlSite('LogoNoSpinOff.png')"
                                    style="max-height: 75px; max-width: 175px; margin: 30px;"
                                    alt="logo"
                                >
                            </div>                           
                            <div class="row">
                                <div class="col">
                                    <span>
                                        <h6 class="highlighted-title" style="color: rgb(0, 0, 0)">{{ $t("AcceptInvitation.Analyst") }}<p>{{nameOrg}}</p> </h6>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="card-avatar m-auto">
                                        <template v-if="imgOrg != null">
                                            <img 
                                                style="max-height: 60px; border-radius: 50%;"
                                                class="img img-raised"
                                                :src="getImgUrl(imgOrg)"
                                            >
                                        </template>
                                        <template v-else>
                                            <img  
                                                style="max-height: 60px; border-radius: 50%;"
                                                class="img img-raised"
                                                :src="$getImgUrlSite('EJEMPLO-LOGO.png')"
                                            >
                                        </template>    
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                    <div class="col">
                                        <p class="mt-5 mb-0" style="color: black" v-if="project">{{$t('AcceptInvitation.ProjectInfo1')}} <em><b>{{ project.name }}</b></em>, {{$t('AcceptInvitation.ProjectInfo2')}}</p>
                                    </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <b-button class="btn-simple ncm-btn" @click="accept()">
                                        {{ $t("LandingPage.CookiesNotice.accept") }}
                                        <b-icon icon="check"></b-icon>
                                    </b-button>
                                </div>
                                <div class="col">
                                    <b-button class="btn-simple ncm-btn-danger" @click="decline()">
                                        {{ $t("LandingPage.CookiesNotice.decline") }}
                                        <b-icon icon="x"></b-icon>
                                    </b-button>
                                </div>
                            </div>
                        </BCard>
                    </b-col>
                </b-row>
            </div>
        </div>
        <footer-logo background-color="#ffffff"/>
    </div>
</template>

<script>
import FooterLogo from '@/layout/FooterLogo';
import { Card } from 'element-ui';
import InvitationDataService from '../../services/InvitationDataService';
import ProjectsDataService from '../../services/ProjectsDataService';

    export default {
        components: {
            FooterLogo,
            Card
        },
        data() {
            return {
                dataFormUser: {},
                variablePass: false,
                imgOrg: null,
                nameOrg: null,
                project: null
            }
        },
        async created() {
            var isAuthenticated = false;
            if (localStorage.getItem('user')) isAuthenticated = true
            else isAuthenticated = false
            if (isAuthenticated) {
                this.$swal.fire({
                    icon: 'warning',
                    title: this.$t('AcceptInvitation.Session')
                }).then(() => {
                    this.$router.push('/')
                })
            } else {
                await InvitationDataService.checkInvitation(this.$route.query.token).then(
                Response => {
                    this.email = Response.data.mail
                    this.nameOrg = Response.data.org_name
                    this.imgOrg = Response.data.org_img
                    this.project = Response.data.project
                },
                Error => {
                    this.expired = true
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Alerts.ErrorWithInvitation')
                    }).then(() => {
                        this.$router.push('/')
                    })
                }
            )
            }
            
        },
        methods: {
            accept () {
                ProjectsDataService.acceptInvitationAnalyst(this.$route.query.token).then(
                    Response => {
                        this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.InvitationAcceptedAnalyst')
                        }).then(() => {
                            this.$router.push('/')
                        })
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Alerts.ErrorAcceptingInvitationAnalyst')
                        }).then(() => {
                            this.$router.push('/')
                        })
                    }
                )
            },
            decline () {
                InvitationDataService.decline(this.$route.query.token).then(
                    Response => {
                        this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.InvitationDeclinedAnalyst')
                        }).then(() => {
                            this.$router.push('/')
                        })
                    },
                    Error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('Alerts.ErrorDecliningInvitationAnalyst')
                        }).then(() => {
                            this.$router.push('/')
                        })
                    }
                )
            }
            ,
            getImgUrl (name) {
                try{
                    if(name){
                        return `${process.env.VUE_APP_VITE_BUCKET_CDN}/organization-images/${name}?withheaders=yes`
                    }
                }catch{
                    this.imagePath = null
                }
            }
        }
    }
</script>

<style>

.content-sign {
    margin-top: 8%;
    text-align: center;
    margin-bottom: 50px;
}
</style>