<template>
  <div class="wrapper landing-page">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        :style="{'background-image': 'url(' + $getImgUrlSite('bg11.jpg') +')', opacity: '90%'}" 
      />
      <div class="content-center">
        <h1 class="title">
          {{ $t("LandingPage.title") }} 
        </h1> 
      </div>
    </div>
    <div 
      id="who"
      class="container"
    >
      <div class="section">
        <div 
          class="row"
        >
          <card
            type="blog"
            plain
          >
            <div
              slot="image"
              class="row ml-2 mr-2"
            >
              <div class="col-md-6  mt-auto mb-0">
                <img
                  class="img img-raised rounded"
                  :src="$getImgUrlSite('bg4.jpg')"
                  alt="sectionWho"
                >
              </div>
              <div class="col-md-6 p-auto  text-center">
                <h2 class="title">
                  {{ $t("LandingPage.Who.title") }}
                </h2>
                <p class="mt-3">
                  
                    {{ $t("LandingPage.Who.text1") }}
                  
                </p>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div
      id="whatwe" 
    >
      <div
        class="testimonials-1 section-image"
        :style="{'background-image': 'url(' + $getImgUrlSite('bg11.jpg') + ')'}"
      > 
        <div 
          class="container"
        >
          <card
            type="blog"
            plain
          >
          
            <div
              slot="image"
              class="row ml-2 mr-2"
            >
            <div class="col-md-6 p-auto  text-center">
                <h2 class="title">
                  {{ $t("LandingPage.WhatWeThinnk.title") }}
                </h2>
                <p class="mt-3">
                  
                    {{ $t("LandingPage.WhatWeThinnk.text") }}
                  
                </p>
              </div>
              <div class="col-md-6  mt-auto mb-0">
                <img
                  class="img img-raised rounded"
                  :src="$getImgUrlSite('bg44.jpg')"
                  alt="sectionWhatWeThink"
                >
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div 
      id="what"
      class="container"
    >
      <div class="section">
        <div 
          class="row"
        >
          <card
            type="blog"
            plain
          >
            <div
              class="row"
            >
              <div class="col-md-6  mt-auto mb-0">
                <img
                  class="img img-raised rounded"
                  :src="$getImgUrlSite('bg3.jpg')"
                  alt="sectionWhat"
                >
              </div>
              <div class="col-md-6 p-auto  text-center">
                <h2 class="title">
                  {{ $t("LandingPage.What.title") }}
                </h2>
                  <p>
                     {{ $t("LandingPage.What.text1") }} 
                  </p>
              </div>
            </div>
          </card>
        </div>
        <!-- <div v-if="!$store.state.auth.status.loggedIn" class="row">
          <div class="col-md-10 ml-auto mr-auto text-center">
            <router-link to="/advantages" class="overlay-link">{{ $t("LandingPage.What.see") }}</router-link> 
          </div>
        </div> -->
      </div>
    </div>
    <div 
      id="contact" 
      class="section pb-0 pt-0"
    >
      <div
        class="testimonials-1 section-image"
        :style="{'background-image': 'url(' + $getImgUrlSite('bg19.jpg') + ')', opacity: '95%'}"
      > 
        <div class="container">
          <b-row>
            <h2 class="title">
            {{ $t("LandingPage.Contact.title") }}
            </h2>
          </b-row>
          <b-row>
            <b-col>
            <card plain class="contact" no-body>
              <b-card-body style="width: 60%; margin:auto">
                <b-form-group>
                  <label class="card-description text-white" style="font-weight: 500 !important;">{{ $t("LandingPage.Contact.Name") }} *</label>
                  <b-form-input
                    class="contact-input"
                    v-model="formContact.name"
                    id="input-formContact-name"
                    name="input-formContact-name"
                    v-validate="{ required: true}"
                    :state="validateState('input-formContact-name')"
                    aria-describedby="input-formContact-name-live-feedback"
                    size="sm"
                    :placeholder=" $t('LandingPage.Contact.Name')">
                  </b-form-input>
                  <b-form-invalid-feedback style="margin-left: 1% !important; color: white !important; font-size: 12px;" id="input-formContact-name-live-feedback">{{ veeErrors.first('input-formContact-name') }}</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group>
                  <label class="card-description text-white" style="font-weight: 500 !important;">{{ $t("LandingPage.Contact.Subject") }}  *</label>
                  <b-form-input 
                    class="contact-input"
                    v-model="formContact.subject"
                    id="input-formContact-subject"
                    name="input-formContact-subject"
                    v-validate="{ required: true}"
                    :state="validateState('input-formContact-subject')"
                    aria-describedby="input-formContact-subject-live-feedback"
                    size="sm"
                    :placeholder=" $t('DashBoardStaff.Modals.Modify.Subject')">
                  </b-form-input>
                  <b-form-invalid-feedback style="margin-left: 1% !important; color: white !important; font-size: 12px;" id="input-formContact-subject-live-feedback">{{ veeErrors.first('input-formContact-subject') }}</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group>
                  <label class="card-description text-white" style="font-weight: 500 !important;">{{ $t("LandingPage.Contact.Email") }} *</label>
                  <b-form-input 
                    class="contact-input"
                    v-model="formContact.email"
                    id="input-formContact-email"
                    name="input-formContact-email"
                    v-validate="{ required: true, email: true }"
                    :state="validateState('input-formContact-email')"
                    aria-describedby="input-formContact-email-live-feedback"
                    size="sm"
                    :placeholder=" $t('DashBoardStaff.Modals.Modify.Email')">
                  </b-form-input>
                  <b-form-invalid-feedback style="margin-left: 1% !important; color: white !important; font-size: 12px;" id="input-formContact-email-live-feedback">{{ veeErrors.first('input-formContact-email') }}</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group>
                  <label for="input-formContact-text" class="card-description text-white" style="font-weight: 500 !important;">{{ $t("LandingPage.Contact.Message") }} *</label>
                  <textarea 
                  class="form-control contact-input"
                    v-model="formContact.text"
                    id="input-formContact-text"
                    name="input-formContact-text"
                    v-validate="{ required: true}"
                    :state="validateState('input-formContact-text')"
                    aria-describedby="input-formContact-text-live-feedback"
                    size="sm"
                  >
                  </textarea>
                  <b-form-invalid-feedback style="margin-left: 1% !important; color: white !important; font-size: 12px;" id="input-formContact-text-live-feedback">{{ veeErrors.first('input-formContact-text') }}</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group>
                  <div class="d-flex">
                    <n-button
                    class="btn-simple ncm-btn"
                    style="background-color: white;"
                    type="primary"
                    round
                    @click.prevent="sendMail()"
                  >
                    <b-icon icon="person-lines-fill" class="mr-2"></b-icon>{{ $t("LandingPage.Contact.ContactUs") }}
                  </n-button>
                    <template v-if="sendOK && !erroMsg">
                    <span class="m-auto">
                      <p class="text-white" style="font-size: 15px; margin: auto;">
                        <b-icon icon="check2-circle" class="mr-2"></b-icon> {{ $t("LandingPage.Contact.text6") }}
                      </p>
                    </span>
                  </template>
                  <template v-if="sendOK && erroMsg">
                    <span class="m-auto">
                      <p style="font-size: 15px; margin: auto; color: red;font-weight: 600;">
                        <b-icon icon="x-lg" class="mr-2"></b-icon>{{ $t("LandingPage.Contact.text7") }}
                      </p>
                    </span>
                  </template>
                  </div>
                  </b-form-group>
              </b-card-body>
              
            </card>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <modal
      modal-classes="modal-big"
      :show.sync="modals.classic"
      header-classes="justify-content-center"
    >
    <div class="cookies" style=" max-height: 800px;">
      <h4 class="title" style="margin-top: 0; text-align: center;">
        {{ $t("LandingPage.CookiesNotice.title") }}
      </h4>        
      <b-row>
        <b-col>
          <div style="font-size: 13px;text-align: justify;" v-html="cookie.main_cookie"/>  
        </b-col>
      </b-row>
      <b-row>
          <b-col class="d-flex" >
              <span><h6>{{ $t("LandingPage.CookiesNotice.Session") }}</h6></span>
              <b-button variant="link" @click="toggleCollapseSession" style="font-size: 10px; margin-top: 0; padding-top:0 ;">
                  <b-icon style="color: #5D6176 ;" v-if="!isCollapsedSession" icon="chevron-double-down"></b-icon>
                  <b-icon style="color: #5D6176 ;" v-if="isCollapsedSession" icon="chevron-double-up"></b-icon>
              </b-button>
          </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex" style="margin-bottom: 10px;">
          <div class="custom-control custom-switch">
            <input v-model="sessionCookiesStorage" type="checkbox" class="custom-control-input" id="customSwitches2" disabled>
            <label v-if="!sessionCookiesStorage" class="custom-control-label" for="customSwitches2">Not accepted</label>
            <label v-else class="custom-control-label" for="customSwitches2">Accepted</label>
          </div>
        </b-col>
      </b-row>
      <b-collapse v-model="isCollapsedSession">
        <div style="font-size: 13px;text-align: justify; " v-html="cookie.session_cookie"/> 
      </b-collapse> 
      <b-row>
        <b-col class="d-flex" >
            <span><h6>{{ $t("LandingPage.CookiesNotice.Other") }}</h6></span>
              <b-button variant="link" @click="toggleCollapseOther" style="font-size: 10px; margin-top: 0; padding-top:0 ;">
                  <b-icon style="color: #5D6176 ;" v-if="!isCollapsedOther" icon="chevron-double-down"></b-icon>
                  <b-icon style="color: #5D6176 ;" v-if="isCollapsedOther" icon="chevron-double-up"></b-icon>
              </b-button>
          </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex" style="margin-bottom: 10px;">
          <div class="custom-control custom-switch">
            <input v-model="otherCookiesStorage" type="checkbox" class="custom-control-input" id="customSwitches3">
            <label v-if="!otherCookiesStorage" class="custom-control-label" for="customSwitches3">Not accepted</label>
            <label v-else class="custom-control-label" for="customSwitches3">Accepted</label>
          </div>
        </b-col>
      </b-row>
      <b-collapse v-model="isCollapsedOther">
        <div style="font-size: 13px;text-align: justify; " v-html="cookie.other_cookie"/> 
      </b-collapse> 
      <b-row>
        <b-col class="d-flex" >
            <span><h6>{{ $t("LandingPage.CookiesNotice.Multimedia") }}</h6></span>
              <b-button variant="link" @click="toggleCollapseMultimedia" style="font-size: 10px; margin-top: 0; padding-top:0 ;">
                  <b-icon style="color: #5D6176 ;" v-if="!isCollapsedMultimedia" icon="chevron-double-down"></b-icon>
                  <b-icon style="color: #5D6176 ;" v-if="isCollapsedMultimedia" icon="chevron-double-up"></b-icon>
              </b-button>
          </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex" style="margin-bottom: 10px;">
          <div class="custom-control custom-switch">
            <input v-model="multimediaCookiesStorage" type="checkbox" class="custom-control-input" id="customSwitches4">
            <label v-if="!multimediaCookiesStorage" class="custom-control-label" for="customSwitches4">{{ $t("LandingPage.CookiesNotice.not_accepted") }}</label>
            <label v-else class="custom-control-label" for="customSwitches4">{{ $t("LandingPage.CookiesNotice.accepted") }}</label>
          </div>
        </b-col>
      </b-row>
      <b-collapse v-model="isCollapsedMultimedia">
        <div style="font-size: 13px;text-align: justify; " v-html="cookie.multimedia_cookie"/> 
      </b-collapse>   
      </div>
      <div class="d-flex">
          <n-button 
          class="btn-simple ncm-btn m-auto"
          type="primary"
          @click="cookiesA()"
        >
        {{ $t("LandingPage.CookiesNotice.accept") }}
        </n-button>
        </div>
    </modal>
  </div>
</template>
<script>
  import SettingsService from '../../services/SettingsDataService'
  import LanguageDataService from '../../services/LanguageDataService';
  import { Card, Button, Modal, InfoSection, FormGroupInput } from '@/components';
  import Login from '../../services/Login';
  export default {
    name: 'LandingPage',
    bodyClass: 'landing-page',
    components: {
      Card,
      Modal,
      InfoSection,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
      [Button.name]: Button,
    },
    data() {
      return {
        cookies:{},
        cookie: {},
        first : true,
        modals: {
          classic: false,
        },
        isCollapsedSession: false,
        isCollapsedOther: false,
        isCollapsedMultimedia: false,
        showOverlay: true,
        formContact:{},
        sendOK: false,
        erroMsg: false,
        languages: [],
        language_id: null,
        sessionCookiesStorage:true,
        otherCookiesStorage: true,
        multimediaCookiesStorage: true
      }
    },
    async created () {
      await LanguageDataService.findAll().then(
        Response => {
          this.languages = Response.data
          const idiomaEncontrado = this.languages.find(idioma => idioma.code.startsWith(process.env.VUE_APP_VITE_DEFAULT_LOCALE))
          if(idiomaEncontrado != undefined)
            this.language_id = idiomaEncontrado.id
          else
            this.language_id = this.languages.find(f => f.name == 'english')[0].id
        },
        Error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: Error.response.data.message
          })
        }
      )
      await SettingsService.findCookies().then(
          Response=> {
              this.cookies = Response.data.data
              const cookieEncontrada = this.cookies.find(cookie => cookie.language_id === this.language_id);
              this.cookie = cookieEncontrada
              },
          Error=>{
              console.log('Error en findOne del mensaje de cookies')
          }
      )
      console.log('USER LOGUEADO: ' + this.$store.state.auth.status.loggedIn)
      if (!localStorage.getItem('cookies_accept')) {
        this.modals.classic = true
      } else {
        this.modals.classic = false
      }
    },
    
    methods : {
        toggleCollapseSession() {
          this.isCollapsedSession = !this.isCollapsedSession;
        },
        toggleCollapseOther() {
          this.isCollapsedOther = !this.isCollapsedOther;
        },
        toggleCollapseMultimedia() {
          this.isCollapsedMultimedia = !this.isCollapsedMultimedia;
        },
        cookiesA () {
          const cookies_accept = {
            session: this.sessionCookiesStorage,
            other: this.otherCookiesStorage,
            multimedia: this.multimediaCookiesStorage,
          }
          const cookies_accept_JSON = JSON.stringify(cookies_accept);

          // Guardar el objeto en el localStorage
          localStorage.setItem('cookies_accept', cookies_accept_JSON);
          this.modals.classic = false
        },
        sendMail() {
          this.$validator.validateAll(['input-formContact-email', 'input-formContact-name', 'input-formContact-subject']).then(result => {
                if (!result) {
                    return
                }
            this.sendOK = true 
            this.$validator.validateAll(['input-formContact-text']).then(result => {
                if (!result) {
                  this.erroMsg = true
                    return
                }
                Login.sendEmail(this.formContact).then(
                  Response => {
                    console.log('Email enviado')
                    this.formContact = {}
                    this.$validator.reset()                
                    this.erroMsg = false
                    this.sendOK = true
                  },
                  Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                  }
                )
          })
          })
          
        },
        validateState (ref) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
            return !this.veeErrors.has(ref)
        }
        return null
        },
    }
  }
</script>
<style scoped>

.contact-input::placeholder{
  color: rgba(255, 255, 255, 0.945) !important;
  font-size: 14px !important;
}

.contact-label{
  font-weight: 500 !important;
}

.contact-input{
  font-size: 14px !important;
}
.sectionWhat {
  position: relative;
}
/* 
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); 
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
*/
.overlay-link {
  color: #5D6176;
  margin-top: 15px;
  font-size: 16px;
  text-decoration: none;
  padding: 8px 16px;
  background-color: #ffffff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: inline-block;
  border: 2px solid #5D6176;
}

.overlay-link:hover {
  background-color: #5D6176;
  color: #ffffff;
}


</style>
