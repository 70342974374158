import http from '../http-common'
import authHeader from './AuthHeaderService'
const API_URL_PATH = 'api/wallet_movement/'

class WalletDataServices {

    findAll(){
      return http.get(API_URL_PATH + 'find-all', {headers: authHeader()})
    }
    findOne(id){
      return http.get(API_URL_PATH + 'find-one?organization_id=' + id, {headers: authHeader()})
    }
    getTokens (org_id, role, project) {
      return http.get(API_URL_PATH + 'get-tokens?organization_id=' + org_id + '&role=' + role + '&project=' + project,  {headers: authHeader()})
    } 

    payment (data) {
      return http.post(API_URL_PATH + 'payment', data ,  {headers: authHeader()})
    } 
    paymentIntent (data) {
      return http.post(API_URL_PATH + 'payment-intent', data ,  {headers: authHeader()})
    } 
    reloadTokens (data) {
        return http.post (API_URL_PATH + 'reload-tokens', data, {headers: authHeader()})
    }
    
    distributeTokens (data) {
      return http.post (API_URL_PATH + 'distribute-tokens', data, {headers: authHeader()})
    }
    getInfoTransfer(transfer_id){
      return http.get(API_URL_PATH + 'get-info-transfer?transfer_id=' + transfer_id, {headers: authHeader()})
    }
    renewalQuote(id, org_id, role_id, id_user_renewal){

      const data = {
        id: id,
        organization_id: org_id,
        role_id: role_id.user_role,
        to_user_id_user_renewal: id_user_renewal 
      }
      return http.post(API_URL_PATH + 'renewal-quote', data, {headers: authHeader()})
    }
}
export default new WalletDataServices ()
