import http from '../http-common'
import authHeader from "./AuthHeaderService"
const API_URL_PATH = 'api/staff/'


class StaffServices { 
    findAll () {
        return http.get(API_URL_PATH + 'staffs', { headers: authHeader() })
    }
    findOne (id) { 
        const body = {id: id}
        return http.get(API_URL_PATH + 'staff?id=' + id,{ headers: authHeader() })
    }
    createStaff (data) {
        const body =  {
            name: data.name,
            surname: data.surname,
            email: data.email,
            password: data.password,
            role_id: data.role_id,
            super_admin: data.super_admin
        }
        
        return http.post(API_URL_PATH + 'create-staff',body, { headers: authHeader() })
    }
    modifyStaff (data) {
        const body =  {
            id: data.id,
            name: data.name,
            surname: data.surname,
            email: data.email,
            phone: data.phone,
            super_admin: data.super_admin,
            is_active: data.is_active,
        }
        return http.put(API_URL_PATH + 'update-staff',body, { headers: authHeader() })
    }
    updateFav (data) {
        const body =  {
            id: data.user_id,
            ecosystem_category_type_id: data.ecosystem_category_type,
            ecosystem_service_category_type_id: data.ecosystem_service_category_type
        }
        return http.put(API_URL_PATH + 'update-fav',body, { headers: authHeader() })
    }
    deleteStaff (id) {
        return http.delete(API_URL_PATH + 'delete-staff?id=' + id, { headers: authHeader() })
    }
    setRole (id_user, id_role) {
        const body = {
            id_staff : id_user,
            id_role : id_role
        }
        return http.put(API_URL_PATH + 'set-staff-role',body, { headers: authHeader() })
    }
    changePass(data) {
        const body =  {
           current_password: data.currentPassword,
           password: data.newPassword
        }
        return http.post(API_URL_PATH + 'change-password',body, { headers: authHeader() })
    }
    updateAvatar(formData){
        var headers = authHeader()
        headers['Content-Type'] = 'multipart/form-data'
        return http.put(API_URL_PATH + 'update-avatar', formData, {
            headers: headers
        })
    }

    getPermissions () {
        return http.get(API_URL_PATH + 'get-permissions', { headers: authHeader() })
    }
}
export default new StaffServices()