<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar">
      <b-container class="container" style="margin-top: 5%; max-width: 94%;">
        <b-row class="page-header-bs">
          <b-col style="display: flex">
            <h2 class="card-title">
                {{ $t("Languages.Title") }}
            </h2> 
          </b-col>
          <b-col>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <card>
              <b-row>
                <b-col >
                  <h6 class="titleLanguages">{{$t("Languages.Table.Title")}}</h6>
                </b-col>
                <b-col>
                  <b-button-toolbar class="buttonLanguages" key-nav aria-label="Toolbar with button groups" style="float: right">
                    <b-button-group class="mx-1">
                      <n-button id="newAdmin" class="btn-simple ncm-btn"
                          target="_blank" @click.native="openCreateModal()">
                          <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                          {{$t("Languages.Table.Button")}}
                      </n-button>
                    </b-button-group>
                  </b-button-toolbar>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table ref="ecosystemTable"
                      id="ecosystemTable"
                      class="dashboard-table"
                      :fields="headers" 
                      :items="languages"
                      select-mode="single"
                      show-empty
                      @row-selected="selectRow"
                      :current-page="currentPage"
                      :per-page="perPage"
                      sort-by="createdAt"
                      :sort-desc="true"
                      responsive="sm"> 
  
                      <template #empty="scope">
                          {{$t('Languages.Table.Empty')}}
                      </template>
                      
                      <template #cell(createdAt)="data">
                          {{ data.item.createdAt | formatDate}}
                      </template>
  
                      <template #cell(updatedAt)="data">
                          {{ data.item.updatedAt | formatDate}}
                      </template>
  
                      <template #cell(options)="data">
                          <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Modify')" @click="openEditModal(data.item)">
                            <b-icon icon="pen"></b-icon>
                          </b-button>
                          <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Delete')" @click="openDeleteModal(data.item)">
                            <b-icon icon="trash"></b-icon>
                          </b-button>
                      </template>
  
                      <template #row-details="row">
                          <b-card class="row-details-card">
                              <div v-if="selectedElement" class="info-container">
                                <b-row>
                                  <b-form-group :label="$t('Languages.Table.Label')" class="info-group" label-class="info-group-label">
                                      <p class="info-group-input">
                                        {{ selectedElement.name }}
                                      </p>
                                  </b-form-group>
                                </b-row>
                              </div>
                          </b-card>
                          
                      </template>
  
                  </b-table>
                  <div class="pagination-container">
                      <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                      </b-pagination>
                      <b-form-select v-model="perPage" class="pagination-select">
                          <b-form-select-option :value="5">5</b-form-select-option>
                          <b-form-select-option :value="10">10</b-form-select-option>
                          <b-form-select-option :value="20">20</b-form-select-option>
                          <b-form-select-option
                              :value="languages.length">{{ $t('Languages.All') }}</b-form-select-option>
                      </b-form-select>
                  </div>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
      </b-container>
  
  
      <!-- Modal create  -->
      <modal :show.sync="modals.create" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t("Languages.Modals.Create.Title") }}
        </h4>
        <b-form-group :label="$t('Languages.Modals.Modify.Name') + ' *' ">
            <b-form-input
              v-model="name_form"
              id="input-name"
              name="input-name"
              v-validate="{ required: true}"
              :state="validateState('input-name')"
              aria-describedby="input-name-live-feedback"
              size="sm"
              :placeholder=" $t('Languages.Modals.Modify.Name') + ' *' "
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-live-feedback">{{ veeErrors.first('input-name') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('Languages.Modals.Modify.Code') + ' *' ">
            <b-form-input
              v-model="code_form"
              id="input-code"
              name="input-code"
              v-validate="{ required: true}"
              :state="validateState('input-code')"
              aria-describedby="input-code-live-feedback"
              size="sm"
              :placeholder=" $t('Languages.Modals.Modify.Name') + ' *' "
            ></b-form-input>
            <b-form-invalid-feedback id="input-code-live-feedback">{{ veeErrors.first('input-code') }}</b-form-invalid-feedback>
        </b-form-group>
        <template slot="footer">
          <n-button class="btn-simple ncm-btn" @click="addElement">
             {{ $t("Languages.Modals.Create.Footer.Save") }}
          </n-button>
          <n-button class="btn-simple ncm-btn-danger" @click.native="modals.create = false">
             {{ $t("Languages.Modals.Create.Footer.Close") }}
          </n-button>
        </template>
      </modal>

      <!-- Modal modify  -->
    <modal :show.sync="modals.modify" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("Languages.Modals.Modify.Title") }}
      </h4>
      <b-form-group :label="$t('Languages.Modals.Modify.Name') + ' *' ">
          <b-form-input v-if="selectedElement"
            v-model="selectedElement.name"
            id="input-name-modify"
            name="input-name-modify"
            v-validate="{ required: true}"
            :state="validateState('input-name-modify')"
            aria-describedby="input-name-modify-live-feedback"
            size="sm"
            :placeholder=" $t('Languages.Modals.Modify.Name') + ' *'  "
          ></b-form-input>
          <b-form-invalid-feedback id="input-name-modify-live-feedback">{{ veeErrors.first('input-name-modify') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('Languages.Modals.Modify.Code') + ' *' ">
          <b-form-input v-if="selectedElement"
            v-model="selectedElement.code"
            id="input-code-modify"
            name="input-code-modify"
            v-validate="{ required: true}"
            :state="validateState('input-code-modify')"
            aria-describedby="input-code-modify-live-feedback"
            size="sm"
            :placeholder=" $t('Languages.Modals.Modify.Name') + ' *'  "
          ></b-form-input>
          <b-form-invalid-feedback id="input-code-modify-live-feedback">{{ veeErrors.first('input-code-modify') }}</b-form-invalid-feedback>
      </b-form-group>
      <template slot="footer">
        <n-button class="btn-simple ncm-btn" @click="updateElement">
           {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.modify = false">
           {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
        </n-button>
      </template>
    </modal>
      <!-- Modal delete  -->
      <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
        <p>{{ $t("Languages.Modals.Delete.Title") }}</p>
        <template slot="footer">
          <n-button type="neutral" link @click="deleteElement">
            {{ $t("Languages.Modals.Delete.Footer.Save") }}
          </n-button>
          <n-button type="neutral" link @click.native="modals.delete = false">
            {{ $t("Languages.Modals.Delete.Footer.Close") }}
          </n-button>
        </template>
      </modal>
    </div>
  </template>
  <script>
    import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal} from '@/components';
    import { API_KEY } from '@/constants'
    import {Table, TableColumn, Tooltip} from 'element-ui';
    import Vue from 'vue'
    import * as VueGoogleMaps from 'vue2-google-maps'
    Vue.use(VueGoogleMaps, {
      load: {
        key: API_KEY
      }
    });
  
    import LanguageDataService from '@/services/LanguageDataService'
    import EcosystemServiceDataService from '@/services/EcosystemServiceDataService'
    import ParameterDataService from '@/services/ParameterDataService'
    import EcosystemServiceParameterDataService from '@/services/EcosystemServiceParameterDataService'
  
  export default {
  name: 'Languages',
  bodyClass: 'profile-page',
  components: {
      Modal,
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
  },
  data() {
      return {
          modals: {
              modify: false,
              delete: false,
              create: false,
          },
          languages: [],
          selectedElement: null,
          name_form: '',
          code_form: '',
          headers: [
            { key: 'name', sortable: true, label: this.$t('Languages.Table.Name'), tdClass: 'table-title', thClass: 'table-title position-sticky' },
            { key: 'code', sortable: true, label: this.$t('Languages.Table.Code'), thClass: 'position-sticky' },  
            { key: 'createdAt', label: this.$t('Languages.Table.CreatedAt'), thClass: 'position-sticky', sortable: true },
            { key: 'updatedAt', label: this.$t('Languages.Table.UpdatedAt'), thClass: 'position-sticky', sortable: true },
            { key: 'options', label: this.$t('Languages.Table.Actions'), thClass: 'position-sticky' }
          ],
          allOpenRows: [],
          currentPage: 1,
          perPage: 10
      }
  },
  computed: {
        totalPages(){
          return this.languages.length
        }
      },
  created() {
    this.getData()
  },
  methods:{
  
    selectRow(items){
      console.log('selected:', items)
      this.selectedElement = items[0]
    },
    validateState (ref) {
      if (
        this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.veeErrors.has(ref)
        }
        return null
    },
    handleRowClicked (item, index) {
        this.allOpenRows.map((ele) => {
            if (ele.id !== item.id && ele._showDetails) {
            this.$set(ele, '_showDetails', !ele._showDetails)
            }
        })
        this.allOpenRows = []
        this.$set(item, '_showDetails', !item._showDetails)
        this.allOpenRows.push(item)
    },
    getData(){
        LanguageDataService.findAll().then(
        async response => {
            console.log(response.data)
            this.languages = response.data
        },
        error => {
          this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message
          })
        }
        )
  
    },
   async addElement(){
  
        this.$validator.validateAll(['input-name', 'input-code']).then(async result => {
          if (!result) {
            return
          }
          var element = {
            name: this.name_form,
            code: this.code_form
        }

          await LanguageDataService.create(element).then(
              async response => {
                  console.log(response.data)
                  this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.LanguageCreated'),
                        text: ''
                        }).then(() => {
                          this.modals.create = false
                          this.getData()
                  });
              },
              error => {
                  console.log((error.response && error.response.data) ||
                      error.message ||
                      error.toString())
                      this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Alerts.ErrorCreatingLanguage'),
                        text: ''
                        });
            }
          )
        })  
    },
    updateElement(){
        this.$validator.validateAll(['input-name-modify', 'input-code-modify']).then(async result => {
            if (!result) {
            return
            }
            console.log(this.selectedElement)
            LanguageDataService.update(this.selectedElement).then(
                async response => {
                    console.log(response.data)
                    this.modals.modify = false
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.LanguageModified'),
                        text: ''
                        }).then(() => {
                            this.getData()
                    });

                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                        this.$swal.fire({
                              icon: 'success',
                              title: this.$t('Alerts.ErrorModifyingLanguage'),
                              text: ''
                              }).then(() => {
                                this.modals.create = false
                                this.getData()
                        });
            }
            )
        })
        
    },
    openCreateModal(){
      // Reset form
      this.name_form = ''
      this.code_form = ''
      this.$validator.reset(['input-label']);
  
      // Show form
      this.modals.create = true
    },
    openEditModal(element){
        console.log(element)
        this.selectedElement = {...element}
        this.modals.modify = true
    },
    openDeleteModal(element){
        console.log(element)
        this.selectedElement = element
        this.modals.delete = true
    },
    deleteElement(){
        console.log(this.selectedElement)
          LanguageDataService.delete(this.selectedElement.id).then(
                  async response => {
                      console.log(response.data)
                      this.modals.delete = false
                      this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.LanguageDeleted'),
                        text: ''
                        }).then(() => {
                          this.getData()
                        });
      
                  },
                  error => {
                      console.log((error.response && error.response.data) ||
                          error.message ||
                          error.toString())
                          this.$swal.fire({
                              icon: 'success',
                              title: this.$t('Alerts.ErrorDeletingLanguage'),
                              text: ''
                              }).then(() => {
                                this.modals.create = false
                                this.getData()
                          });
              }
              )
    }
  }
  }
</script>
<style>
@media (max-width: 465px) {
  .titleLanguages {
    text-align: center;
  }

  .buttonLanguages{
    float: center;
  }
}

.pagination-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pagination-select {
  min-width: 100px !important;
}

@media (max-width: 477px) {
  .pagination-container {
      flex-direction: column;
      align-items: center;
  }

  .pagination-select {
      margin-top: 10px;
      width: 100%;
  }
}
</style>