import http from '../http-common'
import authHeader from "./AuthHeaderService"
const API_POST_URL_PATH = 'api/post/'
class BlogDataService { 
    
    findAll () {
        return http.get(API_POST_URL_PATH + 'posts', {headers: authHeader()})
    }
    findAllExplore () {
        return http.get(API_POST_URL_PATH + 'explore')
    } 

    findAllPostLanding () {
        return http.get(API_POST_URL_PATH + 'landing', {headers: authHeader()})
    }

    findOne (id) {
        return http.get(API_POST_URL_PATH + 'post?id='+ id)
    }
    findOneByType (type) {
        const body = {type: type}
        return http.post(API_POST_URL_PATH + 'blog-type', body)
    }
    create (data) {
        return http.post(API_POST_URL_PATH + 'create-post', data, {headers: authHeader()})
    }
    modify (data) {
        return http.put(API_POST_URL_PATH + 'update-post', data, {headers: authHeader()})
    }
    delete (id) {
        return http.delete(API_POST_URL_PATH + 'delete-post?id=' + id, {headers: authHeader()})
    }

    uploadPostImages(formData){
        var headers = authHeader()
        headers['Content-Type'] = 'multipart/form-data'
        return http.post(API_POST_URL_PATH + 'upload-images', formData, {
            headers: headers
        })
    }

    uploadPostImagesMulti(formData){
        var headers = authHeader()
        headers['Content-Type'] = 'multipart/form-data'
        return http.post(API_POST_URL_PATH + 'upload-images-multi', formData, {
            headers: headers
        })
    }

    // getPostImages(){
    //     return http.get(API_POST_URL_PATH + 'images', {headers: authHeader()})
    // }

    deletePostImage(file_name){
        return http.delete(API_POST_URL_PATH + 'image?file=' + file_name, {headers: authHeader()})
    }

    findOneBySlug (slug) {
        return http.get(API_POST_URL_PATH + 'find-by-slug?slug='+ slug)
    }
    
}
export default new BlogDataService()