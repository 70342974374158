<template>
    <!-- <select v-model="$i18n.locale">
      <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale.value">
        {{ locale.flag }}
        <i class="flag flag-united-states"></i>
      </option>
    </select> -->
    <div>
        <!-- <button class="flag-btn" :class="{ 'flag-btn-selected': $i18n.locale === 'es' }" @click="changeLang('es')"><lang-flag iso="es" :squared="false" /></button>
        <button class="flag-btn" :class="{ 'flag-btn-selected': $i18n.locale === 'en' }" @click="changeLang('en')"><lang-flag iso="en" :squared="false" /></button> -->
            <vue-country-dropdown
                @onSelect="changeLang"
                :defaultCountry="'GB'"
                :dropdownOptions="dropdownOptions"
                :onlyCountries="['ES', 'GB']"
                :disabledFetchingCountry="true"
                :defaultCountryByAreaCode="90"
                :immediateCallSelectEvent="true"
                :enabledFlags="true"
                :showNameInput="false">
            </vue-country-dropdown>
    </div>
</template>

<script>
import {Tooltip} from 'element-ui';
import LangFlag from 'vue-lang-code-flags';
import {emitter} from '@/main.js'
import VueCountryDropdown from 'vue-country-dropdown'


export default {
    name: 'LocaleSwitcher',
    components: { 
        LangFlag,
        VueCountryDropdown
    },
    data() {
      return {
        dropdownOptions: {
            disabledDialCode: true,
        },
      }
  },
    created() {
        this.$i18n.locale = localStorage.getItem('lang')
    },
    methods: {
        changeLang({name, iso2, dialCode}) {
            console.log(name, iso2, dialCode);
            var lang = undefined
            if(iso2 == 'GB')
                lang = 'en'
            else if (iso2 == 'ES')
                lang = 'es'

            localStorage.setItem('lang', lang)
            this.$i18n.locale = lang
            emitter.emit(lang)
        },
    },
}

// export default {
//   name: "LocaleSwitcher",
//   data() {
//     return{
//       locales: [
//         { value: 'en', label: 'English', flag: '🇬🇧' },
//         { value: 'es', label: 'Spanish', flag: '🇪🇸' },
//       ]
//     }    
//   }
// };
</script>

<style>
.country-selector {
    display: flex;
    justify-content: center;
    align-items: center;
}
.country-selector.selected{
    border-color: transparent;
    outline: none;
}
.vue-country-select{
    border-color: transparent;
    outline: none;
}
</style>

