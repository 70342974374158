<template>
    <div class="wrapper  dashboard-wrapper collapsed-sidebar">
        <div class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                        {{ $t('OrganizationSettings.Title') }}
                    </h2>
                </b-col>
            </b-row>
            <b-row class="responsive-row">
                <b-col cols="4" cols-sm="2" cols-md="4" cols-lg="6" class="profile-card">
                    <card type="profile" class="mt-0 card" style="min-height: 320px;max-height: 320px;">
                        <div>
                            <div class="card-avatar m-auto" style="width: 70%">
                                <template v-if="editingAvatar">
                                    <img v-if="imagePathEditing == null" class="img img-raised"
                                        :src="$getImgUrlSite('EJEMPLO-LOGO.png')">
                                    <img class="img img-raised" :src="imagePathEditing">
                                </template>
                                <template v-else>
                                    <img v-if="imagePath == null" class="img img-raised"
                                        :src="$getImgUrlSite('EJEMPLO-LOGO.png')">
                                    <img class="img img-raised" v-else :src="getImgUrl(imagePath)">
                                </template>
                            </div>
                            <h6 class="card-category">
                                {{$t('Wallet.Organization')}}
                            </h6>
                            <h4 class="card-title">
                                {{this.copyOrg.name}} {{this.copyOrg.surname}}
                            </h4>
                            <h5 class="card-title">
                                {{this.copyOrg.email}}
                            </h5>

                            <div class="card-footer">
                                <n-button id="newAdmin" class="btn-simple ncm-btn" target="_blank"
                                    @click.native="openFileInput()">
                                    <b-icon icon="cloud-arrow-up-fill" class="button-icon"></b-icon>
                                    {{ $t("Profile.Image.Upload") }}
                                </n-button>
                                <input ref="fileInput" type="file" name="profile-images" @change="setImage">
                                <n-button id="newAdmin" class="btn-simple ncm-btn-danger" target="_blank"
                                    @click.native="deleteImage()">
                                    <b-icon icon="trash-fill" class="button-icon"></b-icon>
                                    {{ $t("Profile.Image.Delete") }}
                                </n-button>
                                <template v-if="editingAvatar">
                                    <n-button type="primary" class="btn-simple btn-round btn-icon icon-title-dashboard"
                                        v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Save')"
                                        @click.native="updateAvatar()">
                                        <b-icon icon="check-lg"></b-icon>
                                    </n-button>
                                    <n-button type="danger" class="btn-simple btn-icon icon-title-dashboard"
                                        v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Cancel')"
                                        @click.native="toggleEditCancelAvatar()">
                                        <b-icon icon="x-lg"></b-icon>
                                    </n-button>
                                </template>

                            </div>

                        </div>
                    </card>

                </b-col>
                <b-col cols="8" class="info-card">
                    <card class="card" style="min-height: 320px;">
                        <b-row>
                            <b-col>
                                <h3 style="float: left;" class="category text-primary">
                                    <b-icon icon="file-person-fill" class="mr-2" />{{ $t('OrganizationSettings.Info') }}
                                </h3>
                                <template v-if="!editPersonalInfo">
                                    <n-button type="primary" class="btn-simple btn-icon icon-title-dashboard"
                                        @click.native="toggleEdit()">
                                        <b-icon icon="pen"></b-icon>
                                    </n-button>
                                </template>
                                <template v-else>
                                    <n-button type="primary" class="btn-simple btn-round btn-icon icon-title-dashboard"
                                        v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Save')"
                                        @click.native="editPersonalInformation()">
                                        <b-icon icon="check-lg"></b-icon>
                                    </n-button>
                                    <n-button type="danger" class="btn-simple btn-icon icon-title-dashboard"
                                        v-b-tooltip.hover.viewport :title="$t('Profile.Tooltips.Cancel')"
                                        @click.native="toggleEditCancel()">
                                        <b-icon icon="x-lg"></b-icon>
                                    </n-button>
                                </template>
                            </b-col>
                        </b-row>
                        <b-row style="margin-bottom: 4.7%">
                            <template v-if="!editPersonalInfo">
                                <b-col>
                                    <b-form-group :label="this.$t('Languages.Table.OrganizationName')">
                                        <b-form-input v-model="organization.name" disabled></b-form-input>
                                    </b-form-group>
                                    <b-form-group :label="this.$t('Languages.Table.Address')">
                                        <b-form-input v-model="organization.address1" disabled></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group :label="'VAT'">
                                        <b-form-input v-model="organization.vat" disabled></b-form-input>
                                    </b-form-group>
                                    <b-form-group :label="this.$t('DashBoardStaff.Table.Phone')">
                                        <b-form-input v-model="organization.phone" disabled></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group :label="this.$t('Languages.Table.Country')">
                                        <b-form-input v-model="organization.country" disabled></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </template>
                            <template v-else>
                                <b-col>
                                    <b-form-group :label="'Name'">
                                        <b-form-input v-model="organization.name"
                                            id="input-dataPersonalInformation-name"
                                            name="input-dataPersonalInformation-name" v-validate="{ required: true }"
                                            :state="validateState('input-dataPersonalInformation-name')"
                                            aria-describedby="input-dataPersonalInformation-name-live-feedback"
                                            size="sm" :placeholder="'Name'"></b-form-input>
                                        <b-form-invalid-feedback style="margin-left: 1% !important;"
                                            id="input-dataPersonalInformation-name-live-feedback">{{
                            veeErrors.first('input-dataPersonalInformation-name')
                        }}</b-form-invalid-feedback>
                                    </b-form-group>
                                    <b-form-group :label="'Address'">
                                        <b-form-input v-model="organization.address1"
                                            id="input-dataPersonalInformation-address1"
                                            name="input-dataPersonalInformation-address1"
                                            v-validate="{ required: true }"
                                            :state="validateState('input-dataPersonalInformation-address1')"
                                            aria-describedby="input-dataPersonalInformation-address1-live-feedback"
                                            size="sm" :placeholder="'Address'" :disabled="!user.admin"></b-form-input>
                                        <b-form-invalid-feedback style="margin-left: 1% !important;"
                                            id="input-dataPersonalInformation-address1-live-feedback">{{
                            veeErrors.first('input-dataPersonalInformation-address1')
                        }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group :label="'VAT'">
                                        <b-form-input v-model="organization.vat" id="input-dataPersonalInformation-vat"
                                            name="input-dataPersonalInformation-vat"
                                            v-validate="{ required: true, integer: true }"
                                            :state="validateState('input-dataPersonalInformation-vat')"
                                            aria-describedby="input-dataPersonalInformation-vat-live-feedback" size="sm"
                                            :placeholder="'VAT'" :disabled="!user.admin"></b-form-input>
                                        <b-form-invalid-feedback style="margin-left: 1% !important;"
                                            id="input-dataPersonalInformation-vat-live-feedback">{{
                            veeErrors.first('input-dataPersonalInformation-vat')
                        }}</b-form-invalid-feedback>
                                    </b-form-group>
                                    <b-form-group :label="'Phone'">
                                        <b-form-input v-model="organization.phone"
                                            id="input-dataPersonalInformation-phone"
                                            name="input-dataPersonalInformation-phone" v-validate="{ integer: true }"
                                            :state="validateState('input-dataPersonalInformation-phone')"
                                            aria-describedby="input-dataPersonalInformation-phone-live-feedback"
                                            size="sm" :placeholder="'Phone'" :disabled="!user.admin"></b-form-input>
                                        <b-form-invalid-feedback style="margin-left: 1% !important;"
                                            id="input-dataPersonalInformation-phone-live-feedback">{{
                                            veeErrors.first('input-dataPersonalInformation-phone')
                                            }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group :label="'Country'">
                                        <b-form-input v-model="organization.country"
                                            id="input-dataPersonalInformation-country"
                                            name="input-dataPersonalInformation-country" v-validate="{ required: true }"
                                            :state="validateState('input-dataPersonalInformation-country')"
                                            aria-describedby="input-dataPersonalInformation-country-live-feedback"
                                            size="sm" :placeholder="'Country'" :disabled="!user.admin"></b-form-input>
                                        <b-form-invalid-feedback style="margin-left: 1% !important;"
                                            id="input-dataPersonalInformation-country-live-feedback">{{
                                            veeErrors.first('input-dataPersonalInformation-country')
                                            }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </template>
                        </b-row>
                    </card>

                </b-col>
            </b-row>
        </div>
        <!-- Modal modify 
    <modal :show.sync="modals.modify" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
            Modify Organization
        </h4>
        <b-form-group :label="$t('Languages.Modals.Modify.Name') + ' *' ">
            <b-form-input v-if="selectedElement"
            v-model="selectedElement.name"
            id="input-name-modify"
            name="input-name-modify"
            v-validate="{ required: true}"
            :state="validateState('input-name-modify')"
            aria-describedby="input-name-modify-live-feedback"
            size="sm"
            :placeholder=" $t('Languages.Modals.Modify.Name') + ' *'  "
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-modify-live-feedback">{{ veeErrors.first('input-name-modify') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Info">
            <b-form-input v-if="selectedElement"
            v-model="selectedElement.info"
            id="input-info-modify"
            name="input-info-modify"
            :state="validateState('input-info-modify')"
            aria-describedby="input-info-modify-live-feedback"
            size="sm"
            placeholder="Info"
            ></b-form-input>
            <b-form-invalid-feedback id="input-info-modify-live-feedback">{{ veeErrors.first('input-info-modify') }}</b-form-invalid-feedback>
        </b-form-group>
        <template slot="footer">
            <n-button class="btn-simple ncm-btn" @click="updateElement">
                {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
            </n-button>
            <n-button class="btn-simple ncm-btn-danger" @click="modals.modify = false">
                {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
            </n-button>
        </template>
    </modal> -->

        <!-- Modal delete  -->
        <modal :show.sync="modals.delete" class="modal-primary" :show-close="false"
            header-classes="justify-content-center" type="mini">
            <p>{{ $t('OrganizationSettings.Delete') }}</p>
            <template slot="footer">
                <n-button type="neutral" link @click="deleteElement">
                    {{ $t("Languages.Modals.Delete.Footer.Save") }}
                </n-button>
                <n-button type="neutral" link @click="modals.delete = false">
                    {{ $t("Languages.Modals.Delete.Footer.Close") }}
                </n-button>
            </template>
        </modal>
    </div>
</template>
  
  <script>
    const $ = require('jquery')
    // Lo declaramos globalmente
    window.$ = $
    import StaffServices from '../../services/StaffServices'
    import ExternalUserDataService from '../../services/ExternalUserDataService';
    import EcosystemServicesTypes from '../../services/EcosystemServicesTypesDataService';
    import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal} from '@/components';
    import { API_KEY } from '@/constants'
    import {Table, TableColumn, Tooltip} from 'element-ui';
    import Vue from 'vue'
    import * as VueGoogleMaps from 'vue2-google-maps'

import Login from '../../services/Login';
import OrganizationsDataService from '../../services/OrganizationsDataService';
    Vue.use(VueGoogleMaps, {
      load: {
        key: API_KEY
      }
    });
  export default {
    name: 'DashboardUser',
    bodyClass: 'dashboard-organization',
    components: {
        Card,
        Modal,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Tooltip.name]: Tooltip,
        [FormGroupInput.name]: FormGroupInput,
    }, 
    data() {
        return {
            modals: {
              modify: false,
              delete: false,
            },
            currentPage: 1,
            perPage: 10,
            organization: {},
            name: null,
            description: null,
            organization_id: null,
            proj: [],
            // organizations: [],
            selectedElement: null,
            variablePass: false,
            variablePass1: false,
            variablePass2: false,
            copyOrg: {},
            dataPass: {},
            // modals: {
            //     exit: false
            // },
            editPersonalInfo: false,
            image: null,
            imagePath: null,
            imagePathEditing: null,
            editingAvatar: false,
            view_to: '/',
            user: {}
        }
    },
    watch: {
        $orgSelected(newValue){
            console.log('Org selected changed!', newValue)
            this.organization_id = newValue.id
            this.organization = newValue
            this.getData()
        }
    },
    created () {
        //COGER ORG_ID del selector
        this.organization_id = this.$orgSelected.id
        this.organization = this.$orgSelected
        this.user = JSON.parse(localStorage.getItem('user'))
        this.checkExternalRole(this.user.data.id)
        this.getData()
    },
    
    // beforeRouteLeave(to, from, next) {
    //     console.log('view to:', to)
    //     this.view_to = to
    //     if (this.editing)  
    //         this.modals.exit = true
    //     else
    //         next()
    // },
    methods:{
        getData() {
            this.imagePath = this.organization.organization_image
            this.copyOrg = {...this.organization}
        }, 
        checkExternalRole(user){
            if(this.$roleSelected.user_role == 'admin')
                this.role = 'admin'
            else
                this.role = 'other'
        }, 
        editPersonalInformation () {
            if(this.user.admin){
                this.$validator.validateAll(['input-dataPersonalInformation-name','input-dataPersonalInformation-address1','input-dataPersonalInformation-phone', 'input-dataPersonalInformation-vat', 'input-dataPersonalInformation-country']).then(result => {
                if (!result) {
                    return
                }
                this.organization.id = this.organization_id
                OrganizationsDataService.update(this.organization).then(
                Response => {
                    if (Response.status === 200) {
                        this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Profile.Alerts.EditPersonalInfo.OK')
                        }).then(() => {
                            this.getData()
                            // this.editing = false
                            this.editPersonalInfo = !this.editPersonalInfo
                        });
                    }
                },
                Error => {
                    if (Error.response.status === 404) {
                        this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Profile.Alerts.EditPersonalInfo.NotFound')
                        })
                    }
                    if (Error.response.status === 400) {
                        this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Profile.Alerts.EditPersonalInfo.BadRequest')
                        })
                    }
                    if (Error.response.status === 500) {
                        this.$swal.fire({
                        icon: 'error',
                        title: this.$t('Errors.InternalServerError')
                        })
                    }
                })
            })
            }
            // else{
            //     this.$validator.validateAll(['input-dataPersonalInformation-name']).then(result => {
            //     if (!result) {
            //         return
            //     }
            //     this.organization.id = this.organization_id
            //     var data = {
            //         organization_id: this.organization.id,
            //         user_id: this.user.data.id,
            //         name: this.organization.name
            //     }
            //     OrganizationsDataService.updateAlias(data).then(
            //     Response => {
            //         if (Response.status === 200) {
            //             this.$swal.fire({
            //             icon: 'success',
            //             title: this.$t('Profile.Alerts.EditPersonalInfo.OK')
            //             }).then(() => {
            //                 this.getData()
            //                 // this.editing = false
            //                 this.editPersonalInfo = !this.editPersonalInfo
            //             });
            //         }
            //     },
            //     Error => {
            //         if (Error.response.status === 404) {
            //             this.$swal.fire({
            //             icon: 'error',
            //             title: this.$t('Profile.Alerts.EditPersonalInfo.NotFound')
            //             })
            //         }
            //         if (Error.response.status === 400) {
            //             this.$swal.fire({
            //             icon: 'error',
            //             title: this.$t('Profile.Alerts.EditPersonalInfo.BadRequest')
            //             })
            //         }
            //         if (Error.response.status === 500) {
            //             this.$swal.fire({
            //             icon: 'error',
            //             title: this.$t('Errors.InternalServerError')
            //             })
            //         }
            //     })
            // })
            // }
            
        },
        toggleEdit () {
            // this.editing = true
            // this.$swal.fire({
            //     icon: 'info',
            //     text:this.$t('Profile.Alerts.EditPersonalInfo.Info')

            //     // text: this.$t('Profile.Alerts.EditPersonal.Info.')
            // })
            this.editPersonalInfo = !this.editPersonalInfo
        },
        toggleEditCancel () {
            // this.editing = false
            this.getData()
            this.editPersonalInfo = !this.editPersonalInfo
        },
        
        toggleEditCancelAvatar() {
            // this.editing = false
            this.editingAvatar = false
            this.$refs['fileInput'].value = ''
            this.getData()
        },

        exitWithoutChanges(){
            console.log('exit without changes')
            this.modals.exit = false
            this.editing = false
            this.$router.push(this.view_to)
        },
        validateState (ref) {
        if (
            this.veeFields[ref] &&
            (this.veeFields[ref].dirty || this.veeFields[ref].validated)
        ) {
            return !this.veeErrors.has(ref)
        }
        return null
        },
        openFileInput () {
            this.$refs['fileInput'].click()
        },
        setImage(){  
            this.editingAvatar = true
            this.editing = true
            this.image = this.$refs['fileInput'].files[0]
            this.imagePathEditing = URL.createObjectURL(this.image)
            console.log(this.image)
            console.log(this.imagePathEditing)

        },
        deleteImage(){
            this.editingAvatar = true
            this.image = null
            this.imagePathEditing = null
        },
        updateAvatar () {
            
            var formData = new FormData()
            console.log(this.image)
            
            formData.append('org_id', this.organization.id)
            formData.append('organization-images', this.image) // appending file
            
            console.log("formData:", formData);
            

            OrganizationsDataService.updateAvatar(formData, this.user.admin).then(
            Response => {
                if(Response.status == 200){
                    this.$swal.fire({
                    icon: 'success',
                    title: this.$t('Profile.Alerts.UpdateAvatar.OK')
                    }).then(() => {
                        this.editingAvatar = false
                        this.organization.organization_image = Response.data.org.organization_image
                        this.$orgSelected.organization_image = Response.data.org.organization_image
                        this.getData()
                    });
                
                }
                
            },
            Error => {
                console.log((Error.Response && Error.Response.data) || Error.message || Error.toString())
                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('Profile.Alerts.UpdateAvatar.Error')
                    });
            }
            )                
        },
        getImgUrl (name) {
            try{
                if(name){
                    return `${process.env.VUE_APP_VITE_BUCKET_CDN}/organization-images/${name}?withheaders=yes`
                    /// Regular exp for png: \.png$/
                    //var images = require.context('../../assets/profile_images/', false, /\.png$/)
                    //console.log(images('./' + name))
                    //return images('./' + name)
                }
            }catch{
                this.imagePath = null
            }
        },

    }
  }
  </script>
<style scoped>

input[type="file"] {
    display: none;
}

@media (max-width: 1245px) {
  .responsive-row {
    flex-direction: column;
  }
  .profile-card, .info-card {
    margin-bottom: 15px;
    min-width: 100%;
  }
}

@media (max-width: 490px) {
    #newAdmin {
        font-size: 10px;
    }
}

@media (max-width: 444px) {
    #newAdmin {
        margin-top: -5px;
    }
}

@media (max-width: 1472px) {
    #newAdmin {
        margin-top: -5px;
    }
}

</style>