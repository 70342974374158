import http from '../http-common'
import authHeader from "./AuthHeaderService"
const API_URL_PATH = 'api/staff-user/'
class RoleServices { 
    findAll () {
        return http.get(API_URL_PATH + 'roles', { headers: authHeader() })
    }
    findOne (id) {
        const body = {id:id}
        return http.post(API_URL_PATH + 'role', body, { headers: authHeader() } )
    }
    create (data) {
        const body = {
            name: data.name,
            ecosystem_language_module : data.ecosystem_language_module,
            layer_module: data.layer_module,
            blog_module: data.blog_module,
            report_module: data.report_module,
            message_module: data.message_module,
            organization_module: data.organization_module,
            budget_module: data.budget_module
        }
        return http.post(API_URL_PATH + 'create-role', body , { headers: authHeader() })
    }
    modify (data) {
        const body = {
            id: data.id,
            name: data.name,
            ecosystem_language_module : data.ecosystem_language_module,
            layer_module: data.layer_module,
            blog_module: data.blog_module,
            report_module: data.report_module,
            message_module: data.message_module,
            organization_module: data.organization_module,
            budget_module: data.budget_module,
            settings_module: data.settings_module
        }
        return http.put(API_URL_PATH + 'update-su-role', body , { headers: authHeader() })
    }
    delete (id, force) {
        return http.delete(API_URL_PATH + 'delete-su-role?id=' + id + '&force=' + force, { headers: authHeader() })
    }
}
export default new RoleServices()