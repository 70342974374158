<template>
    <div class="wrapper  dashboard-wrapper collapsed-sidebar" style="margin-top: 5%; max-width: 99%;">
        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
            <b-row class="page-header-bs">
                <b-col style="display: flex">
                    <h2 class="card-title">
                    {{$t('CookiesSettings.Title')}}
                    </h2> 
                </b-col>
                <b-col class="buttonSave">
                    <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right">
                        <b-button-group class="mx-1">
                            <n-button
                            v-if="Object.keys(cookieSelected2).length !== 0"
                            id="newAdmin"
                            class="btn-simple ncm-btn"
                            style="font-weight: 800 !important; float: right;"
                            target="_blank"
                            @click.prevent="update()"
                            >
                            <b-icon icon="cloud-arrow-up-fill" class="button-icon" ></b-icon>
                            {{$t('CookiesSettings.Save')}}
                            </n-button>
                            <n-button
                            v-else
                            id="newAdmin"
                            class="btn-simple ncm-btn"
                            style="font-weight: 800 !important; float: right;"
                            target="_blank"
                            @click.prevent="create()"
                            >
                            <b-icon icon="cloud-arrow-up-fill" class="button-icon" ></b-icon>
                            {{$t('CookiesSettings.Save')}}
                            </n-button>
                        </b-button-group>
                    </b-button-toolbar>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="3" style="display: flex" class="languageDropDown">
                    <drop-down>
                    <n-button style="margin-top: 2%; "
                        slot="title"
                        class="dropdown-toggle"
                        type="primary"
                        data-toggle="dropdown"
                    >
                    
                    <template v-if="languageSelected == undefined">
                        ERROR
                        </template>
                    <template v-else>
                        {{ languageSelected.name }}
                    </template>
                </n-button>
                  <a v-for="(lg, index) of languages"
                  class="dropdown-item" :key="index" style="cursor: default;" @click="filterLg(lg)" :class="{ 'active': lg.name === languageSelected.name }"
                  >{{lg.name}}</a>
                    </drop-down>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col cols="12" md="6">
                    <b-card>
                    <b-row>
                        <b-col cols="9" md="10">
                        <h4 class="m-auto" style="font-weight: 600; margin-top: 5px;">{{$t('LandingPage.CookiesNotice.Main')}}</h4>
                        </b-col>
                        <b-col cols="3" md="2" class="text-right">
                        <b-button variant="link" @click="toggleCollapseMain" class="m-auto" style="font-size: 15px;">
                            <b-icon style="color: #5D6176;" v-if="!isCollapsedMain" icon="chevron-double-down"></b-icon>
                            <b-icon style="color: #5D6176;" v-if="isCollapsedMain" icon="chevron-double-up"></b-icon>
                        </b-button>
                        </b-col>
                    </b-row>
                    <b-collapse v-model="isCollapsedMain">
                        <b-row>
                        <b-col>
                            <vue-editor
                            class="mt-3"
                            v-model="cookieSelected.main_cookie"
                            ref="vue-editor"
                            :editor-toolbar="toolbarOptions"
                            @focus="startEditing()"
                            />
                        </b-col>
                        </b-row>
                    </b-collapse>
                    </b-card>
                </b-col>
                <b-col cols="12" md="6">
                    <b-card>
                    <b-row>
                        <b-col cols="9" md="10">
                        <h4 class="m-auto" style="font-weight: 600; margin-top: 5px;">{{$t('LandingPage.CookiesNotice.Session')}}</h4>
                        </b-col>
                        <b-col cols="3" md="2" class="text-right">
                        <b-button variant="link" @click="toggleCollapseSession" class="m-auto" style="font-size: 15px;">
                            <b-icon style="color: #5D6176;" v-if="!isCollapsedSession" icon="chevron-double-down"></b-icon>
                            <b-icon style="color: #5D6176;" v-if="isCollapsedSession" icon="chevron-double-up"></b-icon>
                        </b-button>
                        </b-col>
                    </b-row>
                    <b-collapse v-model="isCollapsedSession">
                        <b-row>
                        <b-col>
                            <vue-editor
                            class="mt-3"
                            v-model="cookieSelected.session_cookie"
                            ref="vue-editor"
                            :editor-toolbar="toolbarOptions"
                            @focus="startEditing()"
                            />
                        </b-col>
                        </b-row>
                    </b-collapse>
                    </b-card>
                </b-col>
                </b-row>
                <b-row>
                <b-col cols="12" md="6">
                    <b-card>
                    <b-row>
                        <b-col cols="9" md="10">
                        <h4 class="m-auto" style="font-weight: 600; margin-top: 5px;">{{$t('LandingPage.CookiesNotice.Other')}}</h4>
                        </b-col>
                        <b-col cols="3" md="2" class="text-right">
                        <b-button variant="link" @click="toggleCollapseOther" class="m-auto" style="font-size: 15px;">
                            <b-icon style="color: #5D6176;" v-if="!isCollapsedOther" icon="chevron-double-down"></b-icon>
                            <b-icon style="color: #5D6176;" v-if="isCollapsedOther" icon="chevron-double-up"></b-icon>
                        </b-button>
                        </b-col>
                    </b-row>
                    <b-collapse v-model="isCollapsedOther">
                        <b-row>
                        <b-col>
                            <vue-editor
                            class="mt-3"
                            v-model="cookieSelected.other_cookie"
                            ref="vue-editor"
                            :editor-toolbar="toolbarOptions"
                            @focus="startEditing()"
                            />
                        </b-col>
                        </b-row>
                    </b-collapse>
                    </b-card>
                </b-col>
                <b-col cols="12" md="6">
                    <b-card>
                    <b-row>
                        <b-col cols="9" md="10">
                        <h4 class="m-auto" style="font-weight: 600; margin-top: 5px;">{{$t('LandingPage.CookiesNotice.Multimedia')}}</h4>
                        </b-col>
                        <b-col cols="3" md="2" class="text-right">
                        <b-button variant="link" @click="toggleCollapseMultimedia" class="m-auto" style="font-size: 15px;">
                            <b-icon style="color: #5D6176;" v-if="!isCollapsedMultimedia" icon="chevron-double-down"></b-icon>
                            <b-icon style="color: #5D6176;" v-if="isCollapsedMultimedia" icon="chevron-double-up"></b-icon>
                        </b-button>
                        </b-col>
                    </b-row>
                    <b-collapse v-model="isCollapsedMultimedia">
                        <b-row>
                        <b-col>
                            <vue-editor
                            class="mt-3"
                            v-model="cookieSelected.multimedia_cookie"
                            ref="vue-editor"
                            :editor-toolbar="toolbarOptions"
                            @focus="startEditing()"
                            />
                        </b-col>
                        </b-row>
                    </b-collapse>
                    </b-card>
                </b-col>
                </b-row>
        </b-container>  
        <modal :show.sync="modals.exit" class="modal-primary" :show-close="false" header-classes="justify-content-center">
            <p>{{$t("EcosystemEdit.ExitViewMessage")}}</p>
            <template slot="footer">
                <n-button type="neutral" link @click="exitWithoutChanges()">
                    {{ $t("Ecosystems.Modals.Delete.Footer.Save") }}
                </n-button>
                <n-button type="neutral" link @click="modals.exit = false">
                    {{ $t("Ecosystems.Modals.Delete.Footer.Close") }}
                </n-button>
            </template>
        </modal>     
    </div>
</template>
<script>
  import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal, DropDown} from '@/components';
import {Table, TableColumn, Tooltip} from 'element-ui';
import { VueEditor } from "vue2-editor";

import SettingsService from '../../services/SettingsDataService';
import LanguageDataService from '../../services/LanguageDataService';
export default {
    components: {
      Modal,
      Card,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
      [DropDown.name]: DropDown,
      VueEditor

    }, 
    data: () => ({
        modals: {
            exit: false
        },
        isCollapsedSession: false,
        isCollapsedOther: false,
        isCollapsedMultimedia: false,
        isCollapsedMain: false,
        editing: false,
        toolbarOptions: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        ["link"],

    ],
    view_to: '',

    id: null,
    allCookies:[],
    languages:[],
    language: null,
    languageSelected: null,
    }),
    beforeRouteLeave(to, from, next) {
        this.view_to = to

        if (this.editing)  
            this.modals.exit = true
        else
            next()
    },
    created() {
        this.getData()
    },
    computed: {
        cookieSelected () {
            const selectedCookie = this.allCookies.find(cookie => cookie.language_id == this.language);
            return selectedCookie || {};
        },
        cookieSelected2 () {
            const selectedCookie2 = this.allCookies.find(cookie => cookie.language_id == this.language);
            return selectedCookie2 || {};
        },
    },
    methods: {
        startEditing(){
            console.log('editing')
            this.editing = true
        },
        toggleCollapseMain() {
            this.isCollapsedMain = !this.isCollapsedMain;
        },
        toggleCollapseSession() {
            this.isCollapsedSession = !this.isCollapsedSession;
        },
        toggleCollapseOther() {
            this.isCollapsedOther = !this.isCollapsedOther;
        },
        toggleCollapseMultimedia() {
            this.isCollapsedMultimedia = !this.isCollapsedMultimedia;
        },
        exitWithoutChanges(){
            this.editing = false
            this.modals.exit=false
            this.$router.push(this.view_to)
        },
        filterLg(lg) {
            this.language = lg.id
            this.languageSelected = lg
        },
        async getData() {
            try {
                const languageResponse = await LanguageDataService.findAll()
                this.languages = languageResponse.data;
                if (this.language == null) {
                    let lg = this.languages.filter(f => f.name.toUpperCase() == 'ENGLISH')[0]
                    this.language = lg.id;
                    this.languageSelected = lg
                }
                
                await SettingsService.findAll().then(
                    Response=> {
                        this.allCookies = Response.data.data
                        console.log(this.allCookies)
                        },
                    Error=>{
                        if (Error.response.status == '401') {
                            this.$router.push('dashboard-staff')
                        }
                    }
                )
            } catch (error) {
                console.log('Error en el findAll de las cookies:', error)
            }
            },
        validateState (ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
            return !this.veeErrors.has(ref)
            }
            return null
        },
        update () {
            // Llamada al endpoint actualzar cookies en el idioma seleccionado selectedCook
            SettingsService.update(this.cookieSelected).then(
                Response => {
                    this.$swal.fire({
                    icon: 'success',
                    title: this.$t('Alerts.CookiesModified')
                    }).then(() => {
                        this.getData()
                        this.editing = false
                        this.isCollapsedSession = false,
                        this.isCollapsedOther = false
                        this.isCollapsedMultimedia = false
                        this.isCollapsedMain = false
                    });
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        create () {
            // Llamada al endpoint crear cookies en el idioma seleccionado selectedCookie
            SettingsService.create(this.cookieSelected, this.language).then(
                Response => {
                    this.$swal.fire({
                    icon: 'success',
                    title: this.$t('Alerts.CookiesCreated')
                    }).then(() => {
                        this.getData()
                        this.editing = false
                        this.isCollapsedSession = false,
                        this.isCollapsedOther = false
                        this.isCollapsedMultimedia = false
                        this.isCollapsedMain = false
                    });
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        }
        
    }
}
</script>
<style>

@media (max-width: 416px) {
    .languageDropDown {
        margin-left: 70px;

    }
    .buttonSave{
        display: flex;
        justify-content: center;
    }
}

</style>