import http from '../http-common'
import authHeader from './AuthHeaderService'

const API_URL_PATH = 'api/ecosystem-service/'

class EcosystemServiceDataService {

  findAll () {
    return http.get(API_URL_PATH + 'find-all',{headers: authHeader()})
  }

  findOne (id) {
    return http.get(API_URL_PATH + 'find-one?id=' + id,{headers: authHeader()})
  }

  create (ecosystem_service) {
    return http.post(API_URL_PATH + 'create', { ecosystem_service: ecosystem_service },{headers: authHeader()})
  }

  update (ecosystem_service) {
    return http.put(API_URL_PATH + 'update', { id: ecosystem_service.id, ecosystem_service: ecosystem_service },{headers: authHeader()})
  }

  delete (id) {
    return http.delete(API_URL_PATH + 'delete?id=' + id, {headers: authHeader()})
  }

  exists (label) {
    return http.get(API_URL_PATH + 'exists?label=' + label, {headers: authHeader()})
  }

  createEcosystemServiceLanguage (ecosystem_service_language) {
    return http.post(API_URL_PATH + 'create-ecosystem-service-language', { ecosystem_service_language: ecosystem_service_language },{headers: authHeader()})
  }

  updateEcosystemServiceLanguage (ecosystem_service_language) {
    return http.put(API_URL_PATH + 'update-ecosystem-service-language', { ecosystem_service_language: ecosystem_service_language },{headers: authHeader()})
  }

  deleteEcosystemServiceLanguage (ecosystem_service_id, language_id) {
    return http.delete(API_URL_PATH + 'delete-ecosystem-service-language?ecosystem_service_id=' + ecosystem_service_id + '&language_id=' + language_id,{headers: authHeader()})
  }

}

export default new EcosystemServiceDataService()
