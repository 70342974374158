<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar">
        <div v-if="loading" id="toHidden"
            style="
            position: absolute;
            top     :0;
            left    : 0;
            height  : 100%;
            width   :  100%;
            background-color:azure;
            z-index : 1060;
            opacity : 0.6;"
            >
        </div>
        <b-icon  v-if="loading" style="
            z-index: 2000;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            "
            icon="three-dots"
            animation="cylon"
            font-scale="4">
        </b-icon>
      <b-container class="container" style="margin-top: 5%; max-width: 94%;">
        <b-row class="page-header-bs">
          <b-col style="display: flex">
            <h2 class="card-title">
              {{$t('PanelCadastres.Save')}}
            </h2> 
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <card>
              <b-row>
                <b-col>
                  <h6 class="titlePanel">{{$t('PanelCadastres.List')}}</h6>
                </b-col>
              <b-col>
                  <b-button-toolbar class="buttonPanel" key-nav aria-label="Toolbar with button groups" style="float: right">
                    <b-button-group class="mx-1">
                      <n-button id="newAdmin" class="btn-simple ncm-btn"
                          target="_blank" @click.native="openEditModal()">
                          <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                          {{$t('PanelCadastres.Add')}}
                      </n-button>
                    </b-button-group>
                  </b-button-toolbar>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table id="table_wms_layers" class="table table-hover"
                            style="max-height: 400px !important; min-height: 400px !important; overflow-x: auto !important;"
                            ref="table_wms_layers"
                            :items="cadastre_layers"
                            :fields="catastro_headers"
                            responsive="sm"
                            show-empty
                            :current-page="currentPage"
                            :per-page="perPage"
                            selectable
                            sort-by="Name"
                            select-mode="single"
                            @row-selected="selectRow"
                            @row-clicked="handleRowClicked"
                            @sort-changed="sortingChanged"
                            :sort-desc="true"
                            > 
                            <template #empty="">
                                {{ $t('Maps.NoCadastreLayers') }}
                            </template>

                            <!-- <template #cell(name)="data">
                                
                            </template> -->

                            <template #cell(is_active)="data">
                                <div v-if="data.item.is_active == true">
                                    <b-icon icon="check2"></b-icon>
                                </div>
                                <div v-else>
                                    <b-icon icon="x"></b-icon>
                                </div>
                            </template>

                            <template #cell(createdAt)="data">
                                {{ data.item.createdAt | formatDate }}
                            </template>
                            <template #cell(updatedAt)="data">
                                {{ data.item.updatedAt | formatDate }}
                            </template>

                            <template #cell(options)="data">
                              <!-- <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Modify')" @click="openModifyModal(data.item)">
                                  <b-icon icon="pen"></b-icon>
                              </b-button> -->
                              <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Delete')" @click="openDeleteModal(data.item)">
                                  <b-icon icon="trash"></b-icon>
                              </b-button>
                          </template>
                          <template #row-details="">
                                <b-card class="row-details-card">
                                    <div v-if="selectedElement" class="info-container">
                                        <b-row>
                                            <b-col>
                                                <b-row style="display: flex; justify-content: center;">
                                                    <h5>Info</h5>
                                                </b-row>
                                                    <b-table
                                                    ref="usersTable"
                                                    id="usersTable"
                                                    :fields="info_headers" 
                                                    :items="cadastre_layers"
                                                    responsive="sm"
                                                    show-empty
                                                    > 
                                                    <template #empty="">
                                                        {{ $t('ProjectsManagement.NoPM') }}
                                                    </template>

                                                    <template #cell(createdAt)="data">
                                                        {{ data.item.createdAt | formatDate }}
                                                    </template>

                                                    
                                                    </b-table>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-card>
                            </template>
                    </b-table>
                  <div class="pagination-container">
                      <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                      </b-pagination>
                      <b-form-select v-model="perPage" class="pagination-select">
                          <b-form-select-option :value="5">5</b-form-select-option>
                          <b-form-select-option :value="10">10</b-form-select-option>
                          <b-form-select-option :value="20">20</b-form-select-option>
                          <b-form-select-option
                              :value="cadastre_layers.length">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                      </b-form-select>
                  </div>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
      </b-container>
  
    <!-- Modal upload shapefile  -->
    <modal :show.sync="modals.upload" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t('PanelCadastres.Modals.Add') }}
      </h4>
      <b-form-group label="Country *">
        <b-form-select id="layer" v-model="country_form"
          :options="countries"
          value-field="alpha-3" 
          text-field="name" 
          class="form-select form-select-sm" 
          aria-label=".form-select-sm example">
          <b-form-select-option :value="null">{{ $t('PanelCadastres.Modals.Select') }}</b-form-select-option>
        </b-form-select>
          <b-form-invalid-feedback id="input-country-live-feedback">{{ veeErrors.first('input-country') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Code *">
          <b-form-input
            id="input-country"
            name="input-country"
            v-validate="{ required: true}"
            :state="validateState('input-country')"
            aria-describedby="input-country-live-feedback"
            size="sm"
            placeholder="Country *"
            v-model="country_form"
            disabled
          ></b-form-input>
          <b-form-invalid-feedback id="input-country-live-feedback">{{ veeErrors.first('input-country') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Projection *">
          <b-form-input
            id="input-projection"
            name="input-projection"
            v-validate="{ required: true}"
            :state="validateState('input-projection')"
            aria-describedby="input-projection-live-feedback"
            size="sm"
            placeholder="Projection *"
            v-model="projection_form"
          ></b-form-input>
          <b-form-invalid-feedback id="input-projection-live-feedback">{{ veeErrors.first('input-projection') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Link WMS *">
          <b-form-input
            id="input-linkwms"
            name="input-linkwms"
            v-validate="{ required: true}"
            :state="validateState('input-linkwms')"
            aria-describedby="input-linkwms-live-feedback"
            size="sm"
            placeholder="Link WMS *"
            v-model="linkwms_form"
          ></b-form-input>
          <b-form-invalid-feedback id="input-linkwms-live-feedback">{{ veeErrors.first('input-linkwms') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Link WFS *">
          <b-form-input
            id="input-linkwfs"
            name="input-linkwfs"
            v-validate="{ required: true}"
            :state="validateState('input-linkwfs')"
            aria-describedby="input-linkwfs-live-feedback"
            size="sm"
            placeholder="Link WFS *"
            v-model="linkwfs_form"
          ></b-form-input>
          <b-form-invalid-feedback id="input-linkwfs-live-feedback">{{ veeErrors.first('input-linkwfs') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Type name *">
          <b-form-input
            id="input-typename"
            name="input-typename"
            v-validate="{ required: true}"
            :state="validateState('input-typename')"
            aria-describedby="input-typename-live-feedback"
            size="sm"
            placeholder="Layer name type for features"
            v-model="type_name_form"
          ></b-form-input>
          <b-form-invalid-feedback id="input-typename-live-feedback">{{ veeErrors.first('input-typename') }}</b-form-invalid-feedback>
      </b-form-group>
      
      <template slot="footer">
        <n-button class="btn-simple ncm-btn" @click="addCadastre()">
          {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.upload = false">
          {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
        </n-button>
      </template>
    </modal>
      <!-- Modal delete  -->
      <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
        <p>{{ $t('PanelCadastres.Modals.Delete') }}</p>
        <template slot="footer">
          <n-button type="neutral" link @click="deleteElement">
            {{ $t("Languages.Modals.Delete.Footer.Save") }}
          </n-button>
          <n-button type="neutral" link @click.native="modals.delete = false">
            {{ $t("Languages.Modals.Delete.Footer.Close") }}
          </n-button>
        </template>
      </modal>
    </div>
</template>
  <script>
  import { Badge, Button, InfoSection,Modal, FormGroupInput, Card, Tabs, TabPane, Checkbox, DropDown} from '@/components';
  import { API_KEY } from '@/constants'
  import {Table, TableColumn, Tooltip} from 'element-ui';
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  });

  import CadastreDataService from '@/services/CadastreDataService'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  import countries_json from '@/assets/country_codes/slim-3.json'

  export default {
  name: 'staff-layers',
  components: {
      Card,
      Modal,
      [Checkbox.name]: Checkbox,
      [DropDown.name]: DropDown,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
      vueDropzone: vue2Dropzone,
  },
  data() {
      return {
          modals: {
              upload: false,
              delete: false,
              create: false,
              modify: false,
          },
          tokens_to_add:null,
          cadastre_layers: [],
          selectedElement: null,
          visibilityFilter: null,
          catastro_headers: [
          // { key: 'internal_name', label: 'Name', sortable: true},
            // { key: 'visible_name', label: 'Visible name', sortable: true},
            { key: 'name', label: this.$t('Languages.Table.CountryName'), sortable: true},
            { key: 'country', label: this.$t('Languages.Table.Code3Alpha'), sortable: true},
            { key: 'projection', label: this.$t('Languages.Table.Projection'), sortable: true},
            { key: 'is_active', label: this.$t('Languages.Table.Active'), sortable: true},
            // { key: 'wms_link', label: 'Link WMS'},
            // { key: 'wfs_link', label: 'Link WFS'},
            { key: 'type_name', label: this.$t('Languages.Table.TypeName')},
            { key:'createdAt',label: this.$t('Parameters.Table.CreatedAt'), sortable: true },
            { key:'updatedAt',label: this.$t('Parameters.Table.UpdatedAt'), sortable: true },
            { key:'options', label: this.$t('Languages.Table.Actions'), thClass: 'position-sticky' }
          ],
          info_headers: [
            { key: 'wms_link', label: 'Link WMS'},
            { key: 'wfs_link', label: 'Link WFS'},
          ],
          allOpenRows: [],
          currentPage: 1,
          perPage: 10,
          filterOrgs: '',
          filter: null,
          filterOn: [],
          loading: false,
          label_form: '',
          filesSize: 0,
          projection_form: '',
          linkwfs_form: '',
          linkwms_form: '',
          country_form: null,
          code_form: '',
          type_name_form: '',
          countries: countries_json,
      }
  },
  computed: {
        totalPages () {
            return this.cadastre_layers.length
        },        
      },
  created() {
    this.getData()
    console.log(this.countries)
  },
  methods:{

    // selectCode(){
    //   this.code_form = this.countries.filter(f => f.name == this.country_form)['alpha-3']
    // },
    addCadastre(){
      this.$validator.validateAll().then(async result => {
                if (!result) {
                    return
                }   

                var country_name = this.countries.filter(f => f['alpha-3'] == this.country_form)[0].name
                var data = {
                    name: country_name,
                    country : this.country_form,
                    projection : this.projection_form,
                    wms_link : this.linkwms_form,
                    wfs_link: this.linkwfs_form,
                    type_name: this.type_name_form
                }
                // Endpoint que pasandole: nombre de proyecto, descripcion, shared_wallet (true o false, si es true, pasar ammount tokens(opcionales, solo valor si shared_wallet es false)),
                // organization_id, user_id, project_manager, y se crea el nuevo proyecto, sin analistas

                this.loading = true
                  CadastreDataService.uploadCadastre(data).then(
                      response => {
                          if(response.status == 200){
                              this.loading = false
                              this.$swal.fire({
                                icon: 'success',
                                title: this.$t('PanelCadastres.Uploaded')
                              }).then(() => {
                                  this.modals.upload = false
                                  this.getData()
                              });
                          
                          }
                          
                      },
                      error => {
                          this.$swal.fire({
                              icon: 'error',
                              title: this.$t('PanelCadastres.Error')
                              });
                          this.loading = false
                          this.modals.upload = false
                      }
                )
            })
    },
    applyRowClass(row) {
        if (row != null) {
          if (!row.is_active) {
          return 'disabled-row';
        } else {
          return '';
        }
        }
      },  
    selectRow(items){
      this.selectedElement = items[0]
    },
    sortingChanged(item, index){
      this.allOpenRows.map((ele) => {
        if (ele.id !== item.id && ele._showDetails) {
          this.$set(ele, '_showDetails', !ele._showDetails)
        }
      })
    },
    validateState (ref) {
      if (
        this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.veeErrors.has(ref)
        }
        return null
    },
    handleRowClicked (item, index) {
        this.allOpenRows.map((ele) => {
            if (ele.id !== item.id && ele._showDetails) {
            this.$set(ele, '_showDetails', !ele._showDetails)
            }
        })
        this.allOpenRows = []
        this.$set(item, '_showDetails', !item._showDetails)
        this.allOpenRows.push(item)
    },
    getData(){
        CadastreDataService.findAll().then(
            Response => {
                this.cadastre_layers = Response.data.data
            },
            Error => {
              this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: Error.response.data.message
              })            
            }
        )
    },
    openCreateModal(){
      // Reset form
      this.name_form = ''
      this.code_form = ''
      this.$validator.reset(['input-label']);
  
      // Show form
      this.modals.create = true
    },
    openEditModal(element){
        this.projection_form = ''
        this.label_form = ''
        this.modals.upload = true
    },
    openDeleteModal(element){
        console.log(element)
        this.selectedElement = element
        this.modals.delete = true
    },
    openModifyModal(){
        this.modals.modify = true
    },
    deleteElement(){
        // console.log('Selected element: ', this.selectedElement)
        CadastreDataService.delete(this.selectedElement.id).then(
            async response => {
                console.log(response.data)
                this.modals.delete = false
                this.$swal.fire({
                  icon: 'success',
                  title: this.$t('PanelCadastres.Delete'),
                  }).then(() => {
                    this.getData()
                  });
            },
            error => {
              this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message
                    })
                this.modals.delete = false
            }
        )
    }
  }
  }
  </script>
  <style scoped>
  .disabled-row {
    background-color: #f8f8f8;
    color: #999999;
  }

  
  ::v-deep .dz-preview{
      min-width: 100px !important;
      max-width: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important;
  }

  ::v-deep .dz-image{
      min-width: 100px !important;
      max-width: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important;
  }


  ::v-deep .dz-progress{
      visibility: hidden;
  }

@media (max-width: 465px) {
  .titlePanel {
    text-align: center!important;
  }

  .buttonPanel{
    float: center!important;
  }
}

@media (max-width: 465px) {
  .titleOrg {
    text-align: center;
  }

  .buttonOrg {
    float: center;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}
  </style>