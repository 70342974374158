import http from '../http-common'
import authHeader from "./AuthHeaderService"
const API_URL_PATH = 'api/external-user/'


class ExternalUserDataService {
    findAll () {
        return http.get(API_URL_PATH + 'find-all', { headers: authHeader() })
    }
    findOne (id) { 
        const body = {id: id}
        return http.get(API_URL_PATH + 'find-one?id=' + id,{ headers: authHeader() })
    }
    login (email,password) {
        return http.post(API_URL_PATH + 'login', {email:email,password:password});
    }
    create (external_user) {
        const body = {
            name: external_user.name,
            surname: external_user.surname,
            email: external_user.email,
            password: external_user.password,
            isAdmin: external_user.isAdmin,
            organization_id: null,
            organization: {
                name: external_user.organization.name,
                vat: external_user.organization.vat,
                address1: external_user.organization.address1,
                phone: external_user.organization.phone,
                country: external_user.organization.country
            }
        }
        return http.post(API_URL_PATH + 'create',body)
    }
    update (data) {
        const body = {
          id: data.id,
          name: data.name,
          lastname: data.lastname,
          phone: data.phone,
          user_id: data.user_id
        }
        return http.put(API_URL_PATH + 'update', body,  {headers: authHeader()})
      }
    delete (id) {
        return http.delete(API_URL_PATH + 'delete?id=' + id,  {headers: authHeader()})
    }
    changePass(data) {
        const body =  {
            current_password: data.currentPassword,
            password: data.newPassword
        }
        return http.put(API_URL_PATH + 'change-password',body, { headers: authHeader() });
    }
    createInvitation (external_user_invitation) {
        const body = {
            name: external_user_invitation.name,
            surname: external_user_invitation.surname,
            email: external_user_invitation.email,
            password: external_user_invitation.password,
            phone: external_user_invitation.phone,
            isAdmin: external_user_invitation.isAdmin,
            token: external_user_invitation.token,
        }
        return http.post(API_URL_PATH + 'create',body)
    }
    updateAvatar(formData){
        var headers = authHeader()
        headers['Content-Type'] = 'multipart/form-data'
        return http.put(API_URL_PATH + 'update-avatar', formData, {
            headers: headers
        })
    }
    activate (token) {
        return http.put(API_URL_PATH + 'activate-account', {token:token});
    }
    setFav(body) {
        return http.put(API_URL_PATH + 'set-favourites',body,{ headers: authHeader() });
    }
    getUserRole(org_id,user_id, all) {
        return http.get(API_URL_PATH + 'get-user-role?organization_id=' + org_id + '&user_id=' + user_id + '&all=' + all, { headers: authHeader() })
    }


}
export default new ExternalUserDataService()