import { render, staticRenderFns } from "./ReportsDownload.vue?vue&type=template&id=28bda006&scoped=true"
import script from "./ReportsDownload.vue?vue&type=script&lang=js"
export * from "./ReportsDownload.vue?vue&type=script&lang=js"
import style0 from "./ReportsDownload.vue?vue&type=style&index=0&id=28bda006&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28bda006",
  null
  
)

export default component.exports