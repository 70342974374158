<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar">
      <b-container class="container" style="margin-top: 5%; max-width: 94%;">
        <b-row class="page-header-bs">
          <b-col style="display: flex">
            <h2 class="card-title">
              {{ $t("ExternalOrganizationsTransfers.Title") }}
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <card>
              <b-row class="mb-4">
                <b-col>
                  <h6 class="titleTransfer">{{ $t("ExternalOrganizationsTransfers.List") }}</h6>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col>
                  <b-row class="justify-content-start align-items-start">
                    <b-col cols="12" sm="6" md="3" class="d-flex align-items-center" id="searchTransfer">
                      <input type="search" placeholder="Search" class="form-control mr-2" style="max-height: 50px;" v-model="filter">
                    </b-col>
                    <b-col cols="12" sm="6" md="4" id="selectOption">
                      <b-form-group class="d-flex justify-content-end">
                        <b-form-radio v-model="filterType" value="all" class="mr-2">{{ $t("ExternalOrganizationsTransfers.All") }}</b-form-radio>
                        <b-form-radio v-model="filterType" value="reload">{{ $t("ExternalOrganizationsTransfers.StripeHistory") }}</b-form-radio>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="5" class="d-flex align-items-end" id="buttonDownload">
                      <n-button class="btn-simple ncm-btn ml-auto" target="_blank" @click="downloadCSV()">
                        <b-icon icon="file-earmark-arrow-down-fill" class="button-icon"></b-icon>
                        {{ $t("Parameters.Download") }}
                      </n-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <!-- v-for="item in filteredTableData" :key="item.id"   ESTO DENTRO DE LA B-TABLE-->
                  <b-table
                      ref="ecosystemTable"
                      id="ecosystemTable"
                      class="dashboard-table"
                      :fields="headers"
                      :items="filteredTransfers"
                      show-empty
                      :current-page="currentPage"
                      :per-page="perPage"
                      responsive="sm"
                      sort-by="created_at"
                      :sort-desc="true"
                    >
                      <template #empty="">
                        {{ $t("ExternalOrganizationsTransfers.NoTransfers") }}
                      </template>
                      <template #cell(email)="data">
                        <template v-if="data.item.staff_user_id">
                          {{ data.item.staff_user.email }}
                        </template>
                        <template v-else-if="data.item.external_user_id">
                          {{ data.item.external_user.email }}
                        </template>
                        <template v-else>
                          ---
                        </template>
                      </template>
                      <template #cell(type)="data">
                        <template v-if="data.item.type == 'reload'">
                          <b-button class="action-table" v-b-tooltip.hover.viewport @click="copyToClipboard(data.item.payment.stripe_token_uuid)"  :title=data.item.payment.stripe_token_uuid>
                              {{ data.item.type }}
                          </b-button>
                        </template>
                        <template v-else>
                          <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('NoData')">
                              {{ data.item.type }}
                          </b-button>
                        </template>
                      </template>
                      <template #cell(staff_user_id)="data">
                        <template v-if="data.item.staff_user_id">
                          {{ data.item.staff_user.name }}
                        </template>
                        <template v-else>
                          ---
                        </template>
                      </template>
                      <template #cell(project)="data">
                        <template v-if="data.item.project_id">
                          {{ data.item.project.name }}
                        </template>
                        <template v-else>
                          ---
                        </template>
                      </template>
                      <template #cell(options)="data">
                          <b-button class="action-table" v-b-tooltip.hover.viewport title="Assign tokens" @click="openEditModal(data.item)">
                              <b-icon icon="coin"></b-icon>
                          </b-button>
                          <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Delete')" @click="openDeleteModal(data.item)" hidden>
                              <b-icon icon="trash"></b-icon>
                          </b-button>
                      </template>
                      <template #cell(is_active)="data">
                          <div v-if="data.item.is_active == true">
                              <b-icon icon="check2"></b-icon>
                          </div>
                          <div v-else>
                              <b-icon icon="x"></b-icon>
                          </div>
                      </template>
                      <template #cell(share_wallet)="data">
                          <div v-if="data.item.share_wallet == true">
                              <b-icon icon="check2"></b-icon>
                          </div>
                          <div v-else>
                              <b-icon icon="x"></b-icon>
                          </div>
                      </template>
                      <template #cell(created_at)="data">
                          {{ data.item.created_at | formatDate }}
                      </template>
                      <!-- <template #row-details="row">
                          <b-card class="row-details-card">
                              <div v-if="selectedElement" class="info-container">
                                <b-row>
                                  <b-form-group :label="$t('Languages.Table.Label')" class="info-group" label-class="info-group-label">
                                      <p class="info-group-input">
                                        {{ selectedElement.name }}
                                      </p>
                                  </b-form-group>
                                </b-row>
                              </div>
                          </b-card>

                      </template> -->

                  </b-table>
                  <div class="pagination-container">
                      <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                      </b-pagination>
                      <b-form-select v-model="perPage" class="pagination-select">
                          <b-form-select-option :value="5">5</b-form-select-option>
                          <b-form-select-option :value="10">10</b-form-select-option>
                          <b-form-select-option :value="20">20</b-form-select-option>
                          <b-form-select-option
                              :value="transfers.length">{{ $t('ExternalOrganizationsTransfers.All') }}</b-form-select-option>
                      </b-form-select>
                  </div>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
      </b-container>

    <!-- Modal assign token  -->
    <modal :show.sync="modals.modify" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("ExternalOrganizationsTransfers.Tokens") }}
      </h4>
      <b-form-group label="Tokens *">
          <b-form-input v-if="selectedElement"
            id="input-token"
            name="input-token"
            v-validate="{ required: true}"
            :state="validateState('input-token')"
            aria-describedby="input-token-live-feedback"
            size="sm"
            placeholder="Insert tokens"
          ></b-form-input>
          <b-form-invalid-feedback id="input-token-live-feedback">{{ veeErrors.first('input-token') }}</b-form-invalid-feedback>
      </b-form-group>
      <template slot="footer">
        <n-button class="btn-simple ncm-btn" @click="updateElement">
          {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.modify = false">
          {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
        </n-button>
      </template>
    </modal>
      <!-- Modal delete  -->
      <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
        <p>{{ $t("ExternalOrganizationsTransfers.Delete") }}</p>
        <template slot="footer">
          <n-button type="neutral" link @click="deleteElement">
            {{ $t("Languages.Modals.Delete.Footer.Save") }}
          </n-button>
          <n-button type="neutral" link @click.native="modals.delete = false">
            {{ $t("Languages.Modals.Delete.Footer.Close") }}
          </n-button>
        </template>
      </modal>
    </div>
  </template>
  <script>
    import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal, DropDown} from '@/components';
    import { API_KEY } from '@/constants'
    import {Table, TableColumn, Tooltip} from 'element-ui';
    import Vue from 'vue'
    import * as VueGoogleMaps from 'vue2-google-maps'
    Vue.use(VueGoogleMaps, {
      load: {
        key: API_KEY
      }
    });
    import ExternalUserDataService from '../../services/ExternalUserDataService';
    import StaffServices from '../../services/StaffServices';
    import WalletDataServices from '@/services/WalletDataServices'
    import ProjectsDataService from '@/services/ProjectsDataService'
    import EcosystemServiceDataService from '@/services/EcosystemServiceDataService'
    import ParameterDataService from '@/services/ParameterDataService'
    import EcosystemServiceParameterDataService from '@/services/EcosystemServiceParameterDataService'

  export default {
  name: 'External Organizations Transfers',
  bodyClass: 'profile-page',
  components: {
      Modal,
      Card,
      [Checkbox.name]: Checkbox,
      [DropDown.name]: DropDown,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
  },
  data() {
      return {
          modals: {
              modify: false,
              delete: false,
              create: false,
          },
        //   organizations: [],
          transfers: [],
          selectedElement: null,
          headers: [
            { key: 'source', label: this.$t('Source'), sortable: true},
            { key: 'email', label: this.$t('Languages.Table.Email'), sortable: true},
            // { key:'organization_id',label:'Organization Id' },
            { key:'token_value',label: this.$t('Languages.Table.TokenValue'), sortable: true },
            // { key:'description',label:'Description', sortable: true  },
            { key:'type',label: this.$t('Languages.Table.Classification'), sortable: true  },
            { key:'level',label: this.$t('Languages.Table.Level'), sortable: true  },
            { key:'organization.name',label: this.$t('Org'), sortable: true  },
            { key:'project',label: this.$t('Project'), sortable: true  },
            // { key:'project_id',label:'Project Id' },
            // { key:'user_id',label:'User Id' },
            { key:'staff_user_id',label: this.$t('Languages.Table.StaffUser'), sortable: true },
            // { key:'report_id',label:'Report Id'},
            { key:'created_at',label: this.$t('Parameters.Table.CreatedAt'), sortable: true  },
          ],
          allOpenRows: [],
          currentPage: 1,
          perPage: 10,
          filterOrgs: '',
          filter: null,
          filterOn: [],
          infoTransfer:[],
          headersInfoTransfer: [
            { key:'description',label: this.$t('Languages.Table.Description') },
            { key: 'stripe_token_uuid', label: this.$t('Languages.Table.TokenStripe')},
          ],
          perPageInfo: 10,
          currentPageInfo: 1,
          filterType: 'all',
      }
  },
  computed: {
        filteredTransfers() {
          if (this.filterType === 'reload') {
            return this.transfers.filter(transfer => transfer.type === 'reload');
          } else {
            return this.transfers;
          }
        },
        getTransfers() {
            return this.transfers
        },
        totalPages () {
            return this.getTransfers.length
        },
        // function to filter orgs with the text field
        searchOrgs() {
            return this.headers.filter((item) => {
                return item.filterOrgs.toLowerCase().includes(this.filterOrgs.toLowerCase()) ||
                item.filterOrgs.toLowerCase().includes(this.filterOrgs.toLowerCase()) ||
                item.name.toLowerCase().includes(this.filterOrgs.toLowerCase()) ||
                item.address1.toLowerCase().includes(this.filterOrgs.toLowerCase());
            });
        },
        // sortOptions() {
        //   // Create an options list from our fields
        //   return this.headers
        //     .filter(f => f.sortable)
        //     .map(f => {
        //       return { text: f.label, value: f.key }
        //     })
        // }
      },
  async created() {
    await this.getData()
  },
  methods:{
    downloadCSV(){
      console.log('download') 
      const csvRows = [];
      const headers = ['SOURCE', 'EMAIL (STAFF)','EMAIL (EXTERNAL)', 'TOKEN_VALUE', 'CLASSITICATION', 'LEVEL', 'ORGANIZATION', 'PROJECT', 'CREATED_AT'];
      csvRows.push(headers.join(';'));
      this.transfers.forEach((member) => {
        const rowData = [
          member.source,
          member.staff_user ? member.staff_user.email : '--------' ,
          member.external_user ? member.external_user.email : '--------',
          member.token_value,
          member.type,
          member.level,
          member.organization.name,
          member.project ? member.project.name : '--------',
          member.created_at
        ];
        csvRows.push(rowData.join(';'));
      });
      const csvContent = csvRows.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'Token_transfers.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    totalPagesInfo () {
        return this.selectedElement.layers.length
    },
    // onFiltered(filteredItems) {
    //         // Trigger pagination to update the number of buttons/pages due to filtering
    //         this.totalRows = filteredItems.length
    //         this.currentPage = 1
    // },
    selectRow(items){
      console.log('selected:', items)
      this.selectedElement = items[0]
      WalletDataServices.getInfoTransfer(this.selectedElement.id).then(
            Response => {
                this.infoTransfer = Response.data.data
                console.log(this.infoTransfer);
            },
            Error => {
              this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: Error.response.data.message
              })
            }
        )
    },
    validateState (ref) {
      if (
        this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.veeErrors.has(ref)
        }
        return null
    },
    handleRowClicked (item, index) {
        this.allOpenRows.map((ele) => {
            if (ele.id !== item.id && ele._showDetails) {
            this.$set(ele, '_showDetails', !ele._showDetails)
            }
        })
        this.allOpenRows = []
        this.$set(item, '_showDetails', !item._showDetails)
        
        this.allOpenRows.push(item)
    },
    sortingChanged(item){
            this.allOpenRows.map((ele) => {
                if (ele.id !== item.id && ele._showDetails) {
                this.$set(ele, '_showDetails', !ele._showDetails)
                }
            })
        },
    async getData(){
        await WalletDataServices.findAll().then(
            async Response => {
                this.transfers = Response.data.data
                console.log(this.transfers);
            },
            Error => {
              this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: Error.response.data.message
              })
            }
        )

    },
    async addElement(){

    },
    updateElement(){
        this.$validator.validateAll(['input-token-modify']).then(async result => {
            if (!result) {
                return
            }
            OrganizationsDataService.update(this.selectedElement).then(
                async response => {
                    console.log(response.data)
                    this.modals.modify = false
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.TokensAssigned'),
                        text: ''
                        }).then(() => {
                            this.getData()
                    });
                },
                error => {
                  this.$swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: error.response.data.message
                  })
                }
            )
        })
    },
    copyToClipboard(text) {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        this.$swal.fire({
            icon: 'success',
            text: this.$t('Ecosystems.Copied')
        }) 
    },
    openCreateModal(){
      // Reset form
      this.name_form = ''
      this.code_form = ''
      this.$validator.reset(['input-label']);

      // Show form
      this.modals.create = true
    },
    openEditModal(element){
        console.log(element)
        this.selectedElement = {...element}
        this.modals.modify = true
    },
    openDeleteModal(element){
        console.log(element)
        this.selectedElement = element
        this.modals.delete = true
    },
    deleteElement(){
        console.log(this.selectedElement)
        OrganizationsDataService.delete(this.selectedElement.id).then(
            async response => {
                console.log(response.data)
                this.modals.delete = false
                this.$swal.fire({
                  icon: 'success',
                  title: this.$t('Alerts.OrganizationDeleted'),
                  text: ''
                  }).then(() => {
                    this.getData()
                  });
            },
            error => {
              this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: error.response.data.message
              })
            }
        )
    }
  }
  }
  </script>
  <style scoped>
    tr {
      min-height: 60px !important;
    }

    
@media (max-width: 465px) {
  .titleTransfer {
    text-align: center;
  }

  .buttonTransfer {
    float: center;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}

@media (max-width: 575px){
  #searchTransfer{
    margin-top: 10px;
  }
  #selectOption{
    margin-top: 10px;
    margin-left: -70px;
  }
  #buttonDownload{
    /* margin-top: 20px; */
    margin-left: -40px;
  }
}
  </style>