<template>
    <div class="wrapper dashboard-wrapper collapsed-sidebar">
      <b-container class="container" style="margin-top: 5%; max-width: 94%;">
        <b-row class="page-header-bs">
          <b-col style="display: flex">
            <h2 class="card-title">
              {{ $t("ExternalOrganizations.ExternalUsers") }}
            </h2> 
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <card>
              <b-row>
                <b-col>
                  <h6 class="titleExternal">{{ $t("ExternalOrganizations.ExternalUsersList") }}</h6>
                </b-col>
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end" id="searchExternal">
                  <!-- <b-button :disabled="!filter" @click="filter = ''" style="float:right">Clear</b-button> -->
                  <input type="search" placeholder="Search" class="form-control" style="max-width: 100%; max-height: 40px;" v-model="filter">
                </b-col>
                <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center" id="buttonDownload">
                  <n-button class="btn-simple ncm-btn" target="_blank" @click="downloadUsersCSV">
                    <b-icon icon="file-earmark-arrow-down-fill" class="button-icon"></b-icon>
                    {{ $t("Parameters.Download") }}
                  </n-button>
                </b-col>
                <!-- <b-col>
                  <b-button-toolbar key-nav aria-label="Toolbar with button groups" style="float: right" hidden>
                    <b-button-group class="mx-1">
                      <n-button id="newAdmin" class="btn-simple ncm-btn"
                          target="_blank" @click.native="openCreateModal()">
                          <b-icon icon="plus-circle-fill" class="button-icon"></b-icon>
                          CREATE ORGANIZATION
                      </n-button>
                    </b-button-group>
                  </b-button-toolbar>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col>
                  <b-table ref="ecosystemTable" 
                      id="ecosystemTable"
                      class="dashboard-table"
                      :fields="headersUsers" 
                      :items="users"
                      select-mode="single"
                      selectable
                      show-empty
                      @row-selected="selectRow"
                      @row-clicked="openInfo"
                      :current-page="currentPage"
                      :per-page="perPage"
                      responsive="sm"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      sort-by="created_at"
                      :sort-desc="true"
                      @filtered="onFiltered"> 
                      <template #empty="">
                        {{ $t("ExternalOrganizations.NoUsers") }}
                      </template>
                      <template #cell(options)="data">
                        <!-- <b-button class="action-table" v-b-tooltip.hover.viewport title="See more info" @click="openInfo(data.item)">
                            <b-icon icon="eye"></b-icon>
                        </b-button> -->
                          <!-- <b-button class="action-table" v-b-tooltip.hover.viewport title="Assign tokens" @click="openEditModal(data.item)">
                              <b-icon icon="coin"></b-icon>
                          </b-button>
                          <b-button class="action-table" v-b-tooltip.hover.viewport :title="$t('Parameters.Tooltips.Delete')" @click="openDeleteModal(data.item)" hidden>
                              <b-icon icon="trash"></b-icon>
                          </b-button> -->
                      </template>
                      <template #cell(phone)="data">
                          <b-button v-if="data.item.phone != null && data.item.phone != undefined && data.item.phone != ''" class="action-table" v-b-tooltip.hover.viewport @click="copyToClipboard(data.item.phone)"  :title=data.item.phone>
                              <b-icon icon="telephone"></b-icon>
                          </b-button>
                          <b-button v-else class="action-table">
                              <p style=" margin: 0">N/A</p>
                          </b-button>
                      </template>
                      <template #cell(is_active)="data">
                          <div v-if="data.item.is_active == true">
                              <b-icon icon="check2"></b-icon>
                          </div>
                          <div v-else>
                              <b-icon icon="x"></b-icon>
                          </div>
                      </template>
                      <template #cell(share_wallet)="data">
                          <div v-if="data.item.share_wallet == true">
                              <b-icon icon="check2"></b-icon>
                          </div>
                          <div v-else>
                              <b-icon icon="x"></b-icon>
                          </div>
                      </template>
                      <template #cell(created_at)="data">
                          {{ data.item.created_at | formatDate }}
                      </template>
                      <!-- <template #row-details="" v-if="selectedElement">
                            <b-card class="row-details-card">
                                <div v-if="selectedElement" class="info-container">
                                    <b-table
                                    ref="usersTable"
                                    id="usersTable"
                                    :fields="headersInfoUser" 
                                    :items="infoUser"
                                    responsive="sm"
                                    show-empty
                                    :current-page="currentPageInfo"
                                    :per-page="perPageInfo"
                                    > 
                                    <template #empty="">
                                        {{ $t('ExternalOrganizationsTransfers.NoInfoToShow') }}
                                    </template>

                                    <template #cell(createdAt)="data">
                                        {{ data.item.createdAt | formatDate }}
                                    </template>

                                    <template #cell(options)="data">
                                        <b-button v-if="!selectedElement.share_wallet" class="action-table" v-b-tooltip.hover.viewport title="Add tokens" @click="openAssignTokensModal(data.item)">
                                            <b-icon icon="coin"></b-icon>
                                        </b-button>
                                    </template>

                                    </b-table>
                                    <div style="display: flex">
                                        <b-pagination
                                            v-model="currentPageInfo"
                                            :total-rows="totalPagesInfo"
                                            :per-page="perPageInfo">
                                        </b-pagination>
                                        <b-form-select v-model="perPageInfo" class="pagination-select">
                                            <b-form-select-option :value="3">3</b-form-select-option>
                                            <b-form-select-option :value="5">5</b-form-select-option>
                                            <b-form-select-option :value="10">10</b-form-select-option>
                                            <b-form-select-option :value="20">20</b-form-select-option>
                                            <b-form-select-option :value="totalPagesInfo">{{ $t('ProjectsManagement.All') }}</b-form-select-option>
                                        </b-form-select>
                                    </div>
                                    
                                </div>
                            </b-card>   
                      </template> -->

                      <template #row-details="row" v-if="selectedElement" >
                        <b-container class="container" style="margin-top: 5%; max-width: 94%;">
                        <b-row>
                            <b-col>
                                <div class="small-box admin-small-box bg-success" style="cursor: pointer !important; background-color: #a3a0a0!important;" @click="showOrganizations(row.item)">
                                    <div class="inner">
                                        <h3 v-if="row.item.organizations != null" class="increase-count">{{row.item.organizations.length}}</h3>
                                        <h3 v-else class="increase-count"> ---</h3>
                                        <a >
                                            <p>{{$t('DashBoard.Organizations')}}</p>
                                        </a>
                                    </div>
                                    <div class="icon">
                                        <a >
                                            <i class="fa fa-address-card"/>
                                        </a>
                                    </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="small-box admin-small-box bg-success" style="cursor: pointer !important; background-color: #a3a0a0!important;" @click="showLayers(row.item)">
                                    <div class="inner">
                                        <h3 v-if="row.item.layers" class="increase-count">{{row.item.layers.length}}</h3>
                                        <h3 v-else class="increase-count">---</h3>
                                        <a >
                                            <p>{{$t('DashBoard.Layers')}}</p>
                                        </a>
                                    </div>
                                    <div class="icon">
                                        <a >
                                            <i class="fa fa-tags" />
                                        </a>
                                    </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="small-box admin-small-box bg-success" style="cursor: pointer !important; background-color: #a3a0a0!important;" @click="showMovements(row.item)">
                                    <div class="inner">
                                        <h3 v-if="row.item.movements" class="increase-count">{{row.item.movements.length}}</h3>
                                        <h3 v-else class="increase-count">---</h3>
                                        <a >
                                            <p>{{$t('DashBoard.Movements')}}</p>
                                        </a>
                                    </div>
                                    <div class="icon">
                                        <a >
                                            <i class="fa fa-exchange-alt" />
                                        </a>
                                    </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="small-box admin-small-box bg-success"  style="cursor: pointer !important; background-color: #a3a0a0!important;" @click="showReports(row.item)">
                                    <div class="inner">
                                      <h3 v-if="row.item.reports" class="increase-count">{{row.item.reports.length}}</h3>
                                      <h3 v-else class="increase-count">---</h3>
                                        <a >
                                          <p>{{$t('DashBoard.Reports')}}</p>
                                        </a>
                                    </div>
                                    <div class="icon">
                                        <a >
                                            <i class="fa fa-envelope" />
                                        </a>
                                    </div>
                                </div>
                            </b-col>
                          </b-row>
                        </b-container>
                    </template>
                  </b-table>
                  <div class="pagination-container">
                      <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage">
                      </b-pagination>
                      <b-form-select v-model="perPage" class="pagination-select">
                          <b-form-select-option :value="5">5</b-form-select-option>
                          <b-form-select-option :value="10">10</b-form-select-option>
                          <b-form-select-option :value="20">20</b-form-select-option>
                          <b-form-select-option
                              :value="users.length">{{ $t('ExternalOrganizationsTransfers.All') }}</b-form-select-option>
                      </b-form-select>
                  </div>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
      </b-container>
      <modal v-if="selectedElement" :show.sync="modals.listReports" :show-close="false" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t("Reports") }}
        </h4>
        <b-table
          :fields="headersReports"
          :items="selectedElement.reports"
          responsive="sm"
          striped
          hover
          show-empty
          :per-page="perPageReports"
          :current-page="currentPageReports"
          style="overflow-x: auto; width: 435px;"
          >
          <template #empty="">
            {{ $t("NoReports") }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
          </template>
        </b-table>
        <div style="display: flex">
          <b-pagination
            v-model="currentPageReports"
            :total-rows="totalPagesReports"
            :per-page="perPageReports">
          </b-pagination>
          <b-form-select v-model="perPageReports" class="pagination-select" style="min-width: 100px !important">
            <b-form-select-option :value="5">5</b-form-select-option>
            <b-form-select-option :value="10">10</b-form-select-option>
            <b-form-select-option :value="20">20</b-form-select-option>
            <b-form-select-option :value="selectedElement.reports.length">{{ $t("ExternalOrganizations.All") }}</b-form-select-option>
          </b-form-select>
        </div>
        <template slot="footer">
          <n-button v-if="selectedElement.reports.length > 0" class="btn-simple ncm-btn" type @click.native="downloadReports()">
            {{ $t("Download") }}
          </n-button>
          <n-button class="btn-simple ncm-btn-danger" @click.native="modals.listReports = false">
            {{ $t("Close") }}
          </n-button>
        </template>
      </modal>
      <modal v-if="selectedElement" :show.sync="modals.listLayers" :show-close="false" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t("Layers") }}
        </h4>
        <b-table
          :fields="headersLayers"
          :items="selectedElement.layers"
          responsive="sm"
          striped
          hover
          show-empty
          :per-page="perPageLayers"
          :current-page="currentPageLayers"
          style="overflow-x: auto; width: 435px;"
          >
          <template #empty="">
            {{ $t("NoLayers") }}
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDate }}
          </template>
        </b-table>
        <div style="display: flex">
          <b-pagination
            v-model="currentPageLayers"
            :total-rows="totalPagesLayers"
            :per-page="perPageLayers">
          </b-pagination>
          <b-form-select v-model="perPageLayers" class="pagination-select" style="min-width: 100px !important">
            <b-form-select-option :value="5">5</b-form-select-option>
            <b-form-select-option :value="10">10</b-form-select-option>
            <b-form-select-option :value="20">20</b-form-select-option>
            <b-form-select-option :value="selectedElement.layers.length">{{ $t("ExternalOrganizationsLayers.All") }}</b-form-select-option>
          </b-form-select>
        </div>
        <template slot="footer">
          <n-button v-if="selectedElement.layers.length > 0" class="btn-simple ncm-btn" type @click.native="downloadLayers()">
            {{ $t("Download") }}
          </n-button>
          <n-button class="btn-simple ncm-btn-danger" @click.native="modals.listLayers = false">
            {{ $t("Close") }}
          </n-button>
        </template>
      </modal>
      <modal v-if="selectedElement" :show.sync="modals.listOrganizations" :show-close="false" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t("Organization.Orgs") }}
        </h4>
        <b-table
          :fields="headersOrganizations"
          :items="selectedElement.organizations"
          responsive="sm"
          striped
          hover
          show-empty
          :per-page="perPageOrganizations"
          :current-page="currentPageOrganizations"
          style="overflow-x: auto; width: 435px;"
          >
          <template #empty="">
            {{ $t("NoOrganizations") }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
          </template>
        </b-table>
        <div style="display: flex">
          <b-pagination
            v-model="currentPageOrganizations"
            :total-rows="totalPagesOrganizations"
            :per-page="perPageOrganizations">
          </b-pagination>
          <b-form-select v-model="perPageOrganizations" class="pagination-select" style="min-width: 100px !important">
            <b-form-select-option :value="5">5</b-form-select-option>
            <b-form-select-option :value="10">10</b-form-select-option>
            <b-form-select-option :value="20">20</b-form-select-option>
            <b-form-select-option :value="selectedElement.organizations.length">{{ $t("ExternalOrganizationsLayers.All") }}</b-form-select-option>
          </b-form-select>
        </div>
        <template slot="footer">
          <n-button v-if="selectedElement.organizations.length > 0" class="btn-simple ncm-btn" type @click.native="downloadOrganizations()">
            {{ $t("Download") }}
          </n-button>
          <n-button class="btn-simple ncm-btn-danger" @click.native="modals.listOrganizations = false">
            {{ $t("Close") }}
          </n-button>
        </template>
      </modal>
      <modal v-if="selectedElement" :show.sync="modals.listMovements" :show-close="false" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("ExternalOrganizationsTransfers.Title") }}
      </h4>
      <b-table
        :fields="headersMovements"
        :items="selectedElement.movements"
        responsive="sm"
        striped
        hover
        show-empty
        :per-page="perPageMovements"
        :current-page="currentPageMovements">
        <template #empty="">
          {{ $t("ExternalOrganizations.NoTransactions") }}
        </template>
        <template #cell(created_at)="data">
            {{ data.item.created_at | formatDate }}
        </template>
      </b-table>
      <div style="display: flex">
        <b-pagination
          v-model="currentPageMovements"
          :total-rows="totalPagesMovements"
          :per-page="perPageMovements">
        </b-pagination>
        <b-form-select v-model="perPageMovements" class="pagination-select" style="min-width: 100px !important">
          <b-form-select-option :value="5">5</b-form-select-option>
          <b-form-select-option :value="10">10</b-form-select-option>
          <b-form-select-option :value="20">20</b-form-select-option>
          <b-form-select-option :value="selectedElement.movements.length">{{ $t("ExternalOrganizations.All") }}</b-form-select-option>
        </b-form-select>
      </div>
      <template slot="footer">
        <n-button v-if="selectedElement.movements.length > 0" class="btn-simple ncm-btn" type @click.native="downloadTransactions()">
          {{ $t("Download") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.listMovements = false">
          {{ $t("Close") }}
        </n-button>
      </template>
    </modal>
    <!-- Modal assign token  -->
    <modal :show.sync="modals.modify" header-classes="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("ExternalOrganizationsTransfers.Tokens") }}
      </h4>
      <b-form-group label="Tokens *">
          <b-form-input v-if="selectedElement"
            id="input-token"
            name="input-token"
            v-validate="{ required: true}"
            :state="validateState('input-token')"
            aria-describedby="input-token-live-feedback"
            size="sm"
            placeholder="Insert tokens"
          ></b-form-input>
          <b-form-invalid-feedback id="input-token-live-feedback">{{ veeErrors.first('input-token') }}</b-form-invalid-feedback>
      </b-form-group>
      <template slot="footer">
        <n-button class="btn-simple ncm-btn" @click="updateElement">
          {{ $t("EcosystemServices.Modals.Modify.Footer.Save") }}
        </n-button>
        <n-button class="btn-simple ncm-btn-danger" @click.native="modals.modify = false">
          {{ $t("EcosystemServices.Modals.Modify.Footer.Close") }}
        </n-button>
      </template>
    </modal>
      <!-- Modal delete  -->
      <modal :show.sync="modals.delete" class="modal-primary" :show-close="false" header-classes="justify-content-center" type="mini">
        <p>{{ $t("ExternalOrganizationsTransfers.Delete") }}</p>
        <template slot="footer">
          <n-button type="neutral" link @click="deleteElement">
            {{ $t("Languages.Modals.Delete.Footer.Save") }}
          </n-button>
          <n-button type="neutral" link @click.native="modals.delete = false">
            {{ $t("Languages.Modals.Delete.Footer.Close") }}
          </n-button>
        </template>
      </modal>
      
    </div>
  </template>
  <script>
    import { Badge, Button, InfoSection, FormGroupInput, Card, Tabs, TabPane, Checkbox, Modal, DropDown} from '@/components';
    import { API_KEY } from '@/constants'
    import {Table, TableColumn, Tooltip} from 'element-ui';
    import Vue from 'vue'
    import * as VueGoogleMaps from 'vue2-google-maps'
    Vue.use(VueGoogleMaps, {
      load: {
        key: API_KEY
      }
    });
  
    import WalletDataServices from '@/services/WalletDataServices'
    import ProjectsDataService from '@/services/ProjectsDataService'
    import EcosystemServiceDataService from '@/services/EcosystemServiceDataService'
    import ParameterDataService from '@/services/ParameterDataService'
    import EcosystemServiceParameterDataService from '@/services/EcosystemServiceParameterDataService'
import ExternalUserDataService from '../../services/ExternalUserDataService';
  
  export default {
  name: 'External Users',
  bodyClass: 'profile-page',
  components: {
      Modal,
      Card,
      [Checkbox.name]: Checkbox,
      [DropDown.name]: DropDown,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [FormGroupInput.name]: FormGroupInput,
  },
  data() {
      return {
          modals: {
              modify: false,
              delete: false,
              create: false,
              listOrganizations: false,
              listLayers: false,
              listMovements: false,
              listReports: false
          },
        //   organizations: [],
          users: [],
          selectedElement: null,
          headersUsers: [
              { key: 'name', sortable: true, label: this.$t('DashBoardStaff.Table.Name'), tdClass: 'table-title', thClass: 'table-title' }, 
              { key: 'surname', sortable: true, label: this.$t('DashBoardStaff.Table.Surname') },
              { key: 'phone', label: this.$t('DashBoardStaff.Table.Phone') },
              { key: 'email', label: this.$t('DashBoardStaff.Table.Email') },
          ],
          headersOrganizations: [
              { key: 'name', sortable: true, label: this.$t('DashBoardStaff.Table.Name'), tdClass: 'table-title', thClass: 'table-title' }, 
              { key: 'address1', sortable: true, label: this.$t('Address1') },
              { key: 'vat', sortable: true, label: this.$t('Vat') },
              { key: 'country', sortable: true, label: this.$t('Country') },
              { key: 'createdAt', label: this.$t('Parameters.Table.CreatedAt') },
          ],
          headersLayers: [
              { key: 'name', sortable: true, label: this.$t('DashBoardStaff.Table.Name'), tdClass: 'table-title', thClass: 'table-title' }, 
              { key: 'description', sortable: true, label: this.$t('DashBoardStaff.Table.Description') },
              { key: 'options', label: this.$t('Languages.Table.Actions'), thClass: 'position-sticky' }
          ],
          headersMovements: [
            { key:'type',label: this.$t('Type'), sortable: true },
            { key:'token_value',label: this.$t('Value'), sortable: true },
            { key:'created_at',label: this.$t('Parameters.Table.CreatedAt'), sortable: true },
          ],
          headersReports: [
            { key:'name',label: this.$t('Name'), sortable: true },
            { key:'description',label: this.$t('Description'), sortable: true },
            { key:'createdAt',label: this.$t('Parameters.Table.CreatedAt'), sortable: true },
          ],
          allOpenRows: [],
          currentPage: 1,
          perPage: 10,
          currentPageOrganizations: 1,
          perPageOrganizations: 10,
          currentPageLayers: 1,
          perPageLayers: 10,
          currentPageMovements: 1,
          perPageMovements: 10,
          currentPageReports: 1,
          perPageReports: 10,
          filterOrgs: '',
          filter: null,
          filterOn: [],
          infoUser:[],
          headersInfoUser: [
            { key: 'roles', sortable: true, label: this.$t('DashBoardStaff.Table.Role') },
            { key: 'wallet_movements', label:'Wallet Movements'},
          ],
          perPageInfo: 10,
          currentPageInfo: 1,
      }
  },
  computed: {
        getUsers() {
            return this.users
        },
        totalPages () {
            return this.getUsers.length
        },
        totalPagesLayers () {
          if (this.selectedElement == null){
            return 0
          }
            return this.selectedElement.layers.length
        },
        totalPagesMovements () {
          if (this.selectedElement == null){
            return 0
          }
            return this.selectedElement.movements.length
        },
        totalPagesReports () {
          if (this.selectedElement == null){
            return 0
          }
            return this.selectedElement.reports.length
        },
        totalPagesOrganizations () {
          if (this.selectedElement == null){
            return 0
          }
            return this.selectedElement.organizations.length
        },
        // function to filter orgs with the text field
        searchOrgs() {
            return this.headers.filter((item) => {
                return item.filterOrgs.toLowerCase().includes(this.filterOrgs.toLowerCase()) || 
                item.filterOrgs.toLowerCase().includes(this.filterOrgs.toLowerCase()) ||
                item.name.toLowerCase().includes(this.filterOrgs.toLowerCase()) ||
                item.address1.toLowerCase().includes(this.filterOrgs.toLowerCase());
            });
        },
        // sortOptions() {
        //   // Create an options list from our fields
        //   return this.headers
        //     .filter(f => f.sortable)
        //     .map(f => {
        //       return { text: f.label, value: f.key }
        //     })
        // }
      },
  created() {
    this.getData()
  },
  methods:{
    downloadUsersCSV () {
      const csvRows = [];
      const headers = ['NAME', 'SURNAME', 'PHONE', 'EMAIL']; // Add the desired headers
      csvRows.push(headers.join(';'));
      this.users.forEach((user) => {
        const rowData = [
          user.name,
          user.surname,
          user.phone,
          user.email
        ]; // Add the desired data fields
        csvRows.push(rowData.join(';'));
      });
      const csvContent = csvRows.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'Users.csv'); // Set the desired file name
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    downloadReports() {
      const csvRows = [];
      const headers = ['NAME', 'DESCRIPTION', 'CREATED_AT']; // Add the desired headers
      csvRows.push(headers.join(';'));
      this.selectedElement.movements.forEach((transaction) => {
        const rowData = [
          transaction.name,
          transaction.description,
          transaction.createdAt,
        ]; // Add the desired data fields
        csvRows.push(rowData.join(';'));
      });
      const csvContent = csvRows.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'Reports.csv'); // Set the desired file name
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    downloadLayers() {
      const csvRows = [];
      const headers = ['ID', 'NAME', 'DESCRIPTION']; // Add the desired headers
      csvRows.push(headers.join(';'));
      this.selectedElement.layers.forEach((layer) => {
        const rowData = [
          layer.id,
          layer.name,
          layer.description
        ]; // Add the desired data fields
        csvRows.push(rowData.join(';'));
      });
      const csvContent = csvRows.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'Layers.csv'); // Set the desired file name
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
      downloadOrganizations() {
        const csvRows = [];
        const headers = ['ID', 'NAME', 'ADDRESS', 'VAT', 'COUNTRY']; // Add the desired headers
        csvRows.push(headers.join(';'));
        this.selectedElement.organizations.forEach((user) => {
          const rowData = [
            user.id,
            user.name,
            user.address1,
            user.vat,
            user.country
          ]; // Add the desired data fields
          csvRows.push(rowData.join(';'));
        });
        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', 'Organizations.csv'); // Set the desired file name
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      },
    downloadTransactions() {
      const csvRows = [];
      const headers = ['ID', 'TYPE', 'VALUE', 'CREATED_AT']; 
      csvRows.push(headers.join(';'));

      this.selectedElement.movements.forEach((transaction) => {
        const rowData = [
          transaction.id,
          transaction.type,
          transaction.token_value,
          transaction.created_at,
        ];
        csvRows.push(rowData.join(';'));
      });

      const csvContent = csvRows.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'Transactions.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    showOrganizations(item){
        this.selectedElement = item
        this.modals.listOrganizations = true
      },
    showMovements(item){
      this.selectedElement = item
      this.modals.listMovements = true
    },
    showLayers(item){
      this.selectedElement = item
      this.modals.listLayers = true
    },
    showReports(item){
      this.selectedElement = item
      console.log(item.reports)
      this.modals.listReports = true
    },
    copyToClipboard(text) {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        this.$swal.fire({
            icon: 'success',
            text: this.$t('Ecosystems.Copied')
        }) 
    },
    openInfo(element){
        this.selectedElement = {...element}
        console.log(element);
        this.handleRowClicked(element)
    },
    totalPagesInfo () {
        return this.selectedElement.layers.length
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    selectRow(items){
      console.log('selected:', items)
      this.selectedElement = items[0]
    },
    validateState (ref) {
      if (
        this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.veeErrors.has(ref)
        }
        return null
    },
    handleRowClicked (item, index) {
      // this.openInfo(item)
      this.currentPage = 1
    },
    selectRow(items){
      console.log('selected:', items)
      this.selectedElement = items[0]
    },
    validateState (ref) {
      if (
        this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.veeErrors.has(ref)
        }
        return null
    },
    handleRowClicked (item, index) {
        this.allOpenRows.map((ele) => {
            if (ele.id !== item.id && ele._showDetails) {
            this.$set(ele, '_showDetails', !ele._showDetails)
            }
        })
        this.allOpenRows = []
        this.$set(item, '_showDetails', !item._showDetails)
        this.allOpenRows.push(item)
    },
    sortingChanged(item){
            this.allOpenRows.map((ele) => {
                if (ele.id !== item.id && ele._showDetails) {
                this.$set(ele, '_showDetails', !ele._showDetails)
                }
            })
        },
    getData(){
        ExternalUserDataService.findAll().then(
            Response => {
                this.users = Response.data.data
                console.log(this.users);
            },
            Error => {
              this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: Error.response.data.message
              })
            }
        )
        // ExternalUserDataService.getInfoUser().then(
        //     Response => {
        //         this.infoUser = Response.data.data
        //         console.log(this.infoUser);
        //     },
        //     Error => {
        //       this.$swal.fire({
        //           icon: 'error',
        //           title: 'Error',
        //           text: Error.response.data.message
        //       })
        //     }
        // )
    },
    async addElement(){
  
    },
    updateElement(){
        this.$validator.validateAll(['input-token-modify']).then(async result => {
            if (!result) {
                return
            }
            console.log(this.selectedElement)
            OrganizationsDataService.update(this.selectedElement).then(
                async response => {
                    console.log(response.data)
                    this.modals.modify = false
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('Alerts.TokensAssigned'),
                        text: ''
                        }).then(() => {
                            this.getData()
                    });
                },
                error => {
                  this.$swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: error.response.data.message
                  })
                }
            )
        })
    },
    openCreateModal(){
      // Reset form
      this.name_form = ''
      this.code_form = ''
      this.$validator.reset(['input-label']);
  
      // Show form
      this.modals.create = true
    },
    openEditModal(element){
        console.log(element)
        this.selectedElement = {...element}
        this.modals.modify = true
    },
    openDeleteModal(element){
        console.log(element)
        this.selectedElement = element
        this.modals.delete = true
    },
    deleteElement(){
        console.log(this.selectedElement)
        OrganizationsDataService.delete(this.selectedElement.id).then(
            async response => {
                console.log(response.data)
                this.modals.delete = false
                this.$swal.fire({
                  icon: 'success',
                  title: this.$t('Alerts.OrganizationDeleted'),
                  text: ''
                  }).then(() => {
                    this.getData()
                  });
            },
            error => {
              this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: error.response.data.message
              })
            }
        )
    }
  }
  }
</script>
<style scoped>
  tr {
    min-height: 60px !important;
  }

@media (max-width: 767px) {
  .titleExternal {
    text-align: center;
  }
}

.pagination-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-select {
    min-width: 100px !important;
}

@media (max-width: 477px) {
    .pagination-container {
        flex-direction: column;
        align-items: center;
    }

    .pagination-select {
        margin-top: 10px;
        width: 100%;
    }
}

@media (max-width: 575px){
  #searchExternal{
    margin-top: 10px;
  }
  #buttonDownload{
    /* margin-top: 20px; */
    /* margin-left: -40px; */
  }
}
</style>