<template>
    <div class="wrapper blog-post" style="font-size: 12px;">
        <div class="page-header page-header-small ">
            <parallax
                class="page-header-image"
                :style="{'background-image': 'url(' + $getImgUrlSite('bg51.jpg') +')', opacity: '100%'}" 
            />
            <div class="content-center">
                <h1 class="title">
                    {{ $t("Explore.Title") }}
                </h1> 
            </div>
        </div>
        <div id="posts" class="blogs-1">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 ml-auto mr-auto" >
                        <h2 class="title">
                            {{ $t("Explore.Post") }}
                        </h2>
                        <br>
                        <card v-for="(article) in paginatedArticles" :key="article.id" type="blog" plain>
                            <div class="row">
                                <div class="col-md-5">    
                                    <template v-if="article.image != null && isVideo(article.image.url)">
                                        <video
                                            class="img img-raised rounded fixed-size-image"
                                            controls
                                            style="max-width: 100%"
                                        >
                                            <source :src="getVideoUrl(article.image.url)" type="video/mp4">
                                        </video>
                                    </template>        
                                    <template v-else>
                                        <template v-if="article.image">
                                            <img class="img img-raised rounded fixed-size-image"  :alt="article.image.description" :src="getImgUrl(article.image.url)">
                                        </template>
                                        <template v-else>
                                            <img  v-if="article.external_image == null" class="img img-raised rounded fixed-size-image"  alt="Image default" :src="$getImgUrlSite('blurred-image-1.jpg')">
                                            <img v-else class="img img-raised rounded fixed-size-image"  :alt="article.external_image_description" :src="article.external_image">
                                        </template>
                                    </template>
                                    
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col">
                                            <h6 class="category" style="line-height: 2; color: #5D6176; margin-top: 0px;">
                                                <b-icon icon="pie-chart-fill"></b-icon> {{article.category_post_name}}
                                            </h6>
                                            
                                            <router-link :to="{name: 'Post', params: {category: article.category_post_name.toLowerCase(), title_slug: article.title_slug, from_page: currentPage}}">
                                                <h3 class="card-title link-hover">
                                                {{article.title}}
                                                </h3>
                                            </router-link>
                                            <p :title="article.subtitle"  class="card-description">
                                                <b>
                                                    <template v-if="article.subtitle.length > 100">
                                                        {{article.subtitle.substr( 0, 100) + '…' }}
                                                    </template>
                                                    <template v-else>
                                                        {{article.subtitle}}
                                                    </template>
                                                </b>

                                            </p>
                                            <p :title="article.public_content" v-if="article.public_content">
                                                <template v-if="article.public_content.length > 100">
                                                    {{article.public_content.substr( 0, 100) + '…' }}
                                                </template>
                                                <template v-else>
                                                    {{article.public_content}}
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <p><b>{{article.updatedAt | formatDate}}</b></p>
                                            
                                        </div>
                                        <div class="col">
                                            <router-link class="read-more-link" style="float:right" :to="{name: 'Post', params: {category: article.category_post_name.toLowerCase(), title_slug: article.title_slug, from_page: currentPage}}"> {{ $t("Explore.ReadMore") }}</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12" style="text-align: center">
                        <b-pagination v-if="filteredArticles.length > perPage" v-model="currentPage" :total-rows="filteredArticles.length" :per-page="perPage"></b-pagination>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12" style="text-align: center">
                        <b-button v-if="$store.state.auth.status.loggedIn" class="btn btn-simple ncm-btn" @click.prevent="$router.push({ path: '/posts-landing' })">{{ $t("Explore.GoToPost") }}</b-button>
                        <b-button v-else class="btn btn-simple ncm-btn" @click.prevent="$router.push({ path: '/advantages' })">{{ $t("Explore.AccessPrem") }}</b-button>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="testimonials-1 section-image"
            id="tutorials"
            :style="{'background-image': 'url(' + $getImgUrlSite('project15.jpg') +')', opacity: '95%'}" 
        > 
            <div class="container">
                <div class="row">
                    <div class="col-md-12 ml-auto mr-auto">
                        <h2 class="title">
                            {{ $t("Explore.Tutorials") }}
                        </h2>
                        <template v-if="!$store.state.auth.status.loggedIn">
                            <div class="row">
                                <div class="col-md-4">
                                    <router-link to="/advantages">
                                        <div class="card card-blog card-blog-tutorial " style="min-height: 390px;max-height: 390px;" :class="{ '': index > 0 || currentPage2 > 1 }">
                                            <div class="card-image">
                                                <img class="img rounded fixed-size-image" :alt="'mountains'" :src="getImgUrl('1687335535101_bg7.jpg')">
                                            </div>
                                            <div class="card-body">
                                                <h6 class="category text-primary">
                                                    NCM
                                                </h6>
                                                <h5 class="card-title">
                                                    {{ $t("Explore.TutorialAdvantages") }}
                                                </h5>
                                                <p class="card-description">
                                                    
                                                </p>
                                                <div class="card-footer">
                                                    <div class="author">
                                                        
                                                    </div>
                                                    <div class="stats stats-right">
                                                        <i class="now-ui-icons tech_watch-time" /> 1 min
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-md-4" v-for="(tutorial, index) in paginatedTutorials" :key="index"> 
                                    <router-link :to="`/tutorial/${tutorial.category_tutorial_name.toLowerCase()}/${tutorial.title_slug}`">
                                            <div class="card card-blog card-blog-tutorial" style="min-height: 390px;max-height: 390px;" :class="{ '': index > 0 || currentPage2 > 1 }">
                                                <div class="card-image">
                                                    <template v-if="tutorial.image != null && isVideo(tutorial.image.url)">
                                                        <video
                                                            class="img img-raised rounded fixed-size-image"
                                                            controls
                                                            style="max-width: 100%"
                                                        >
                                                            <source :src="getVideoUrl(tutorial.image.url)" type="video/mp4">
                                                        </video>
                                                    </template>        
                                                    <template v-else>
                                                        <template v-if="tutorial.image">
                                                            <img class="img img-raised rounded fixed-size-image"  :alt="tutorial.image.description" :src="getImgUrl(tutorial.image.url)">
                                                        </template>
                                                        <template v-else>
                                                            <img  v-if="tutorial.external_image == null" class="img img-raised rounded fixed-size-image"  alt="Image default" :src="$getImgUrlSite('blurred-image-2.jpg')">
                                                            <img v-else class="img img-raised rounded fixed-size-image"  :alt="tutorial.external_image_description" :src="tutorial.external_image">
                                                        </template>
                                                    </template>
                                                </div>
                                                <div class="card-body">
                                                    <h6 class="category text-primary">
                                                        {{tutorial.category_tutorial_name}}
                                                    </h6>
                                                    <h5 class="card-title">
                                                        {{tutorial.title}}
                                                    </h5>
                                                    <p class="card-description">
                                                        {{tutorial.public_content}}
                                                    </p>
                                                    <div class="card-footer">
                                                        <div class="author">
                                                            <span>{{tutorial.language_name | capitalizeFirstLetter}}</span>
                                                        </div>
                                                        <div class="stats stats-right">
                                                            <i class="now-ui-icons tech_watch-time" /> {{ calcularTiempoLectura(tutorial.content)}} min
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </router-link>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" style="text-align: center">
                                    <!-- Agregar la paginación para los tutoriales -->
                                    <b-pagination v-if="tutorials.length > perPage2" v-model="currentPage2" :total-rows="tutorials.length" :per-page="perPage2"></b-pagination>
                                </div>
                            </div>
                        </template>
                        <template v-if="$store.state.auth.status.loggedIn">
                            <div class="row">
                                <div class="col-md-4" v-for="(tutorial, index) in paginatedTutorials" :key="index">
                                    <router-link :to="`/tutorial/${tutorial.category_tutorial_name.toLowerCase()}/${tutorial.title_slug}`">
                                        <div class="card card-blog card-blog-tutorial" style="min-height: 390px;max-height: 390px;">
                                            <div class="card-image">
                                                <template v-if="tutorial.image != null && isVideo(tutorial.image.url)">
                                                        <video
                                                            class="img img-raised rounded fixed-size-image"
                                                            controls
                                                            style="max-width: 100%"
                                                        >
                                                            <source :src="getVideoUrl(tutorial.image.url)" type="video/mp4">
                                                        </video>
                                                    </template>        
                                                    <template v-else>
                                                        <template v-if="tutorial.image">
                                                            <img class="img img-raised rounded fixed-size-image"  :alt="tutorial.image.description" :src="getImgUrl(tutorial.image.url)">
                                                        </template>
                                                        <template v-else>
                                                            <img  v-if="tutorial.external_image == null" class="img img-raised rounded fixed-size-image"  alt="Image default" :src="$getImgUrlSite('blurred-image-2.jpg')">
                                                            <img v-else class="img img-raised rounded fixed-size-image"  :alt="tutorial.external_image_description" :src="tutorial.external_image">
                                                        </template>
                                                    </template>
                                            </div>
                                            <div class="card-body">
                                                <h6 class="category text-primary">
                                                    {{tutorial.category_tutorial_name}}
                                                </h6>
                                                <h5 class="card-title">
                                                    {{tutorial.title}}
                                                </h5>
                                                <p class="card-description">
                                                    {{tutorial.public_content}}
                                                </p>
                                                <div class="card-footer">
                                                    <div class="author">
                                                        <span>{{tutorial.language_name | capitalizeFirstLetter}}</span>
                                                    </div>
                                                    <div class="stats stats-right">
                                                        <i class="now-ui-icons tech_watch-time" /> {{ calcularTiempoLectura(tutorial.content)}} min
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" style="text-align: center">
                                    <!-- Agregar la paginación para los tutoriales -->
                                    <b-pagination v-if="tutorials.length > perPage2" v-model="currentPage2" :total-rows="tutorials.length" :per-page="perPage2"></b-pagination>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BlogDataService from '../../services/BlogDataService';
import TutorialDataService from '../../services/TutorialDataService';
import { Card } from '@/components';
export default {
    components: {
    Card
    },
    props: ['page'],
    data() {
        return {
            articles: [],
            tutorials: [],
            currentPage: 1,
            perPage: 4,
            currentPage2: 1,
            perPage2: 3
        }
    },
    filters: {
        capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    },
    created () {
        this.getData()
    },
    mounted(){
        if(this.$route.params.page)
            this.currentPage = this.$route.params.page
    },
    methods : {
        goToPost(article){
            console.log(this.currentPage)
            this.$router.push({name: 'Post', params: {category: article.category_post_name.toLowerCase(), title_slug: article.title_slug, from_page: this.currentPage}})
        },
        isVideo(url) {
            const videoExtensions = ['mp4', 'webm', 'ogg']; // Agrega las extensiones de video que deseas comprobar
            const extension = url.split('.').pop();
            return(videoExtensions.includes(extension.toLowerCase()))
        },
        getVideoUrl(url) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${url}?withheaders=yes`
            // try {
            //     if(url){
            //         return require('../../assets/multimedia/blog/images/' + url);
            //     }
            // } catch {
            //     console.log('error loading video')
            // }
            // Construye la ruta de archivo local para el video
        },
        calcularTiempoLectura (contenido) {
            const velocidadLectura = 300;
            const caracteresPorPalabra = 5;
            const palabras = Math.round(contenido.length / caracteresPorPalabra);
            const tiempoLectura = Math.ceil(palabras / velocidadLectura);
            return tiempoLectura;
        },
        capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        getData() {
            BlogDataService.findAllExplore().then(
                Response => {
                    console.log(Response.data.data)
                    this.articles = Response.data.data
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
            TutorialDataService.findAllExplore().then(
                Response => {
                    console.log(Response.data.data)
                    this.tutorials = Response.data.data
                },
                Error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: Error.response.data.message
                    })
                }
            )
        },
        getImgUrl (name) {
            return `${process.env.VUE_APP_VITE_BUCKET_CDN}/multimedia-blog/${name}?withheaders=yes`
            //return 'https://p-ncm-public.s3.eu-south-2.amazonaws.com/' + name
            /*try{
                if(name){
                    var images = require.context('../../assets/multimedia/blog/images', false)
                    console.log(images('./' + name))
                    return images('./' + name)
                }
            }catch{
                console.log('error loading image')
            }*/
        },
    },
    computed: {
        filteredArticles() {
            return this.articles;
        },
        filteredTutorials() {
            return this.tutorials;
        },
        paginatedArticles() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;
            return this.filteredArticles.slice(startIndex, endIndex);
        },
        paginatedTutorials() {
            const startIndex = (this.currentPage2 - 1) * this.perPage2;
            const endIndex = startIndex + this.perPage2;
            return this.filteredTutorials.slice(startIndex, endIndex);
        }
  }
    
  }
</script>
<style>


.read-more-link {
  text-decoration: none;
  color: inherit;
  position: relative;
  font-weight: bold;
  color: #5D6176!important;
}

.read-more-link:hover {
  color: #5D6176;
}

.read-more-link:hover::after {
  opacity: 1;
}
.ncm-disabled {
  position: relative;
  opacity: 0.5;
  background-color: rgba(128, 128, 128, 0.3);
  z-index: 1;
}

.disabled-tuto {
    position: relative;
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
}

.disabled-tuto::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
.b-pagination button[aria-checked="false"] {
  background: white !important;
}
.b-pagination button[aria-label="Go to next page"]:not([aria-disabled="true"]) {
    background-color: rgba(255, 255, 255, 0.745); 
    color: #5D6176;
}
.b-pagination button[aria-label="Go to next page"] {
    background-color: rgba(255, 255, 255, 0.745); 
    color: #5D6176;
}
.b-pagination button[aria-label="Go to previous page"]:not([aria-disabled="true"]) {
    background-color: rgba(255, 255, 255, 0.745); 
    color: #5D6176;
}
.b-pagination button[aria-label="Go to previous page"] {
    background-color: rgba(255, 255, 255, 0.745); 
    color: #5D6176;
}
.b-pagination button[aria-label="Go to last page"]:not([aria-disabled="true"]) {
    background-color: rgba(255, 255, 255, 0.745); 
    color: #5D6176;
}
.b-pagination button[aria-label="Go to first page"]:not([aria-disabled="true"]) {
    background-color: rgba(255, 255, 255, 0.745); 
    color: #5D6176;
}



</style>

