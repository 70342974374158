import http from '../http-common'
import authHeader from './AuthHeaderService'
const API_URL_PATH = 'api/reports/'


class ReportsDataService {
  findAll() {
    return http.get(API_URL_PATH + 'find-all', { headers: authHeader() })
  }

  delete(id) {
    return http.delete(API_URL_PATH + 'delete?id=' + id, { headers: authHeader() })
  }

  downloadReport(token) {
    return http.get(API_URL_PATH + 'download?token=' + token, { headers: authHeader(), responseType: 'blob' })
  }

  findByProject(project_id, role) {
    return http.get(API_URL_PATH + 'find-by-project?project_id=' + project_id + '&role=' + role, { headers: authHeader() })
  }

  downloadCopy(id, token) {
    return http.get(API_URL_PATH + 'download-copy?id=' + id, { headers: authHeader(), responseType: 'blob' })
  }
  
}

export default new ReportsDataService()
